@import "./styles/constants";
@import "./styles/utils";

.dp-pagination-container {
  display: flex;
  position: relative;

  .pagination-left {
    .btn-page-group {
      border-radius: 4px;
      height: $height-box-input;
      padding: 5px 10px;
    }
  }

  .icon-menu-list {
    height: 10px;
    fill: #A4AFB7;
  }

  .icon-unfold {
    height: 10px;
    fill: #A4AFB7;
  }

  .icon-arrow {
    width: 12px;
    fill: #A4AFB7;
  }

  //.btn-page-group {
  //  background-color: transparent;
  //  border: 1px solid #D7DAE2;
  //  border-radius: 17px;
  //  padding: 5px 10px;
  //  height: 34px;
  //}

  .label-page-selected {
    float: left;
    padding: 0 5px 0 5px;
    color: #787878;
  }

  .icon-container, .pagination-left {
    float: left;
  }

  //.btn-back, .btn-next {
  //  background-color: transparent;
  //  border: 1px solid #D7DAE2;
  //  padding: 7px 12px;
  //  height: 34px;
  //}


  //.btn-back {
  //  border-radius: 17px 0 0 17px;
  //}

  //.btn-next {
  //  border-radius: 0 17px 17px 0;
  //}

  .btn-center {
    background-color: transparent;
    height: 34px;
    border: 1px solid #D7DAE2;
    padding: 7px 10px 7px 7px;

    .icon-container {
      padding-left: 5px;
    }
  }

  .btn-center:hover {
    cursor: default;
  }

  .label-page-off {
    padding: 0 5px 0 0;
    color: #787878;
    font-weight: 300;
  }

  .btn-page-group:focus, .btn-back:focus, .btn-next:focus {
    border: 1px solid #D7DAE2 !important;
  }

  .btn-center:focus {
    border-top: 1px solid #D7DAE2 !important;
    border-bottom: 1px solid #D7DAE2 !important;
  }

  .popover-pagination-box {
    @include col100;
    padding: 10px 10px 5px 10px;
    color: #979797;
    text-align: center;
    position: relative;
  }

  .items-by-page {
    padding-bottom: 5px;
  }

  .items-by-page-value:hover {
    cursor: pointer;
  }

  .pagination-right {
    display: flex;
    padding-left: 10px;
    svg{
      width: 12px;
    }

    .btn-back {
      border-radius: 4px 0 0 4px;
      height: $height-box-input;
      padding: 7px 12px;
    }

    .btn-center {
      border-top: 1px solid $border-button-color-pagination;
      border-bottom: 1px solid $border-button-color-pagination;
      border-left: none;
      border-right: none;
      height: $height-box-input;
      border-radius: 0;
    }

    .btn-next {
      border-radius: 0 4px 4px 0;
      height: $height-box-input;
      padding: 7px 12px;
    }
  }
}
