$font-weight-semi-bold: 600;
$font-weight-regular: 400;
$arrow-color: #B4B4B4;

.dp-select-container{
  position: relative;
  font-size: 13px;
}

.dp-select-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  padding: 0 12px 0 20px;
  height: 44px;
  box-sizing: border-box;
  font-size: 14px;
  &:hover {
  cursor: pointer;
  };
}

.dp-select-arrow {
  width: 16px;
  user-select: none;
  pointer-events: none;
  fill: $arrow-color;
  &:hover {
    cursor: pointer;
  }
}

.dp-select-paper{
  z-index: 1000;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  &.p-abs{
    position: absolute;
  }
  &.p-rel{
    position: relative;
  }
}

.dp-select-options-list{
  padding: 0px 0px 10px 20px;
  &:hover{
    .dp-multiselect-option{
      cursor: pointer;
    }
  }
}

.dp-select-clear-button {
  display: flex;
  padding: 10px 10px 0px 0px;
  position: sticky;
  justify-content: flex-end;
  text-decoration: underline;
  font-family: inherit;
  span{
    cursor: pointer;
  }
}

.dp-multiselect-option {
  .dp-option {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  display: flex;
  position: relative;
  margin-bottom: 5px;
  span{
    margin-left: 5px;
  }
}

.dp-multiselect-display-val {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dp-select-subheader{
  font-weight: $font-weight-semi-bold;
}

.dp-select-overlay {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  position: fixed;
  z-index: 999;
}