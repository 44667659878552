
.container-images-details{
  width: 100%;
  display: flex;

  .container-image-selected{
    width: 55%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .car-image-selected{
      aspect-ratio: 20/9;
      float: left;
      margin-bottom: 10px;
      padding-right: 10px;
      width: 100%;
      text-align: center;
      position: relative;
      background-color: transparent;
      display: flex;
      justify-content: center;

      img{
        border-radius: 5px;
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }
  .container-details-gallery{
    float: left;
    padding: 10px 30px 0;
    width: 45%;
    display: flex;
    flex-direction: column;

    .row-info {
      align-items: center;
      align-self: stretch;
      display: flex;
      float: left;
      height: auto;
      width: 100%;

      .info-car-details {
        align-items: center;
        display: flex;

        .car-title {
          color: #7f7f7f;
          float: left;
          font-size: 30px;
          font-weight: 700;
          margin-top: 0;
        }
      }

      .row-info-price {
        display: flex;
        height: 35px;
        margin-left: auto;

        .price {
          align-items: flex-end;
          align-self: stretch;
          color: #0fbe2c;
          display: flex;
          font-size: 30px;
          height: 35px;
        }
      }

    }

    .row-info-details {
      display: flex;
      float: left;
      padding-top: 10px;
      width: 70%;

      .row-info-details-line .col-50, .col-49 {
        float: left;
        padding-right: 20px;
        width: 50%;

        .box-info{
          height: 36px;
          align-items: center;
          border: 1px solid #d9d9d9;
          border-radius: 22px;
          color: #7f7f7f;
          display: flex;
          margin-bottom: 3%;
          min-width: 140px;
          padding: 8px 10px;

          .wrapper, .wrapper-set {
            float: left;
            padding-left: 10px;
            padding-top: 5px;

            .image-style-width, .image-style-height {
              fill: #7f7f7f;
              width: 18px;
            }

            svg {
              height: 18px;
              width: 18px;
            }
          }

          .dot-color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            float: left;
            margin-left: 10px;
          }

          .label-color {
            text-align: center;
            margin-bottom: 0;
            padding-left: 8px;
            font-weight: 500;
            font-size: 14px;
          }

          .label-car-info {
            flex: 2 1;
            float: left;
            overflow: hidden;
            padding: 0 0 0 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #7F7F7F;

            span{
              font-size: 14px;
            }

          }
        }
      }
    }

    .car-image-gallery{
      width: 100%;
      float: left;
      margin: 30px 0 0 0;
      padding-top: 30px;
      border-top: 0.5px solid #7F7F7F;

      .content-image{
        aspect-ratio: 85/71;
        float: left;
        height: auto;
        padding-bottom: 5px;
        padding-right: 5px;
        width: 65px;
        img{
          border-radius: 5px;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .container-images-details .container-details-gallery {
    .row-info-details {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1300px){
  .container-images-details .container-details-gallery{
    .row-info{
      .info-car-details{
        .car-title{
          font-size: 22px;
        }
      }
      .row-info-price .price{
        font-size: 24px;
      }
    }

    .row-info-details{
      width: 85%;
    }
    .box-info{
      height: 24px;

      .dot-color{
        width: 15px;
        height: 15px;
      }
      .label-color{
        font-size: 12px;
      }
    }
  }

}

@media screen and (max-width: 1140px){
  .container-images-details .container-details-gallery{
    .row-info-details{
      width: 100%;
    }
    .box-info{
      height: 24px;

      .dot-color{
        width: 15px;
        height: 15px;
      }
      .label-color{
        font-size: 11px;
      }
      .label-car-info{
        span{
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 950px){
  .container-images-details .container-details-gallery{
    padding: 10px 10px 0 0;
    .row-info{
      .info-car-details{
        .car-title{
          font-size: 18px;
        }
      }
      .row-info-price .price{
        font-size: 20px;
      }
    }
    .row-info-details{
      width: 100%;
    }
    .box-info{
      height: 24px;

      .dot-color{
        width: 15px;
        height: 15px;
      }
      .label-color{
        font-size: 11px;
      }
      .label-car-info{
        span{
          font-size: 11px;
        }
      }
    }
  }

}

@media screen and (max-width: 785px){
  .container-images-details{
    flex-direction: column;
    .container-image-selected{
      width: 100%;
    }
    .container-details-gallery{
      width: 100%;
      padding: 10px 30px 0 ;

      .row-info{
        .info-car-details{
          .car-title{
            font-size: 18px;
          }
        }
        .row-info-price .price{
          font-size: 20px;
        }
      }


      .row-info-details{
        width: 100%;
      }
      .box-info{
        height: 24px;

        .dot-color{
          width: 15px;
          height: 15px;
        }
        .label-color{
          font-size: 11px;
        }
        .label-car-info{
          span{
            font-size: 11px;
          }
        }
      }
    }
  }


}
