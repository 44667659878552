@import "src/common/styles/utils";

#backToTop {
  color: #cdcdcd;
  height: auto;
  position: fixed;
  bottom: 15px;
  margin: 0;
  z-index: 150;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  right: 25px;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 0;

  span {
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-decoration: none;
    -o-border-radius: 52px;
    -moz-border-radius: 52px;
    -webkit-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: all .2s ease 0s;
    -moz-transition: all .2s ease 0s;
    -o-transition: all .2s ease 0s;
    border: 2px solid #e8e8e8;
    background: 0 0;
    text-align: center;

    i {
      font-size: 22px;
      color: #b0b0b0;
    }
  }
}

#backToTop :hover {
  cursor: pointer;
}

#backToTop.off {
  right: 25px;
  top: 0;
  opacity: 0;
}

#backToTop.on {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  right: 25px;
}

#backToTop.hold {
  position: absolute;
  top: -15px;
  height: fit-content;
}

#circleStack {
  border-radius: 50%;
  width: 40px;
  height: 40px;

  > div {
    height: 100%;
    @include item-center;
    border-radius: 50%;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}