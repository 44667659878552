@import "src/common/styles/utils";
@import "src/catalog/common/common";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght$100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght$100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght$100;700&display=swap');


$font-family: 'Open Sans', sans-serif;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 200;
$title-color: #272727;
$height-input-mobile-view: 36px;
$arrow-color: #CACACA;
$background-dark-color: #000;
$background-dark-color-2: #222;
$border-dark-color: #CACACA;

/***************** layout-get-in-touch ******************/

.layout-finance-container {
  .layout-get-in-touch {
    padding: 5% 20% 5% 20%;
  }
}

.layout-get-in-touch {
  @include row-base;
  padding: 5% 20% 5% 20%;
  background-color: #ffffff;

  .get-in-touch-box {
    @include row-base;
    background-color: #F4F4F4;
    padding: 30px;
    border-left: 10px solid #BC1B1B;
    display: flex;
  }

  .get-in-touch-tile {
    float: left;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;

    h1 {
      color: #515151;
      font-weight: $font-weight-bold;
      font-size: 44px;
      word-break: break-word;
    }
  }

  .get-in-touch-form {
    float: left;
    width: 50%;

    .form-first-row {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .form-first-row-element, .form-first-row-second-element {
        width: 49%;
      }
    }

    .input-text {
      padding-bottom: 10px;

      input {
        height: 44px;
        border-radius: 0;
        background-color: #ffffff;
      }

      .sample-error-input {
        border: 1px solid red !important;
        width: 100%;
      }
      .dp-text-input-error-message{
        margin: 0;
      }
    }

    .input-area {
      padding-bottom: 20px;

      textarea {
        height: 100px;
        border-radius: 0;
        background-color: #ffffff;
        resize: none;
      }
    }
  }

  .btn-send-content {
    float: right;
    .btn-send {
      width: auto;
      height: auto;
      padding: 12px 30px;
      font-weight: 600;
      font-size: 16px;
      span {
        letter-spacing: normal;
      }
    }
  }
}

.layout-container-5, .layout-finance-container, .layout-inventory-container-car-5{
  .layout-get-in-touch .btn-send-content .btn-send{
    font-weight: normal;
  }
}


.layout-inventory-container-car-5 {

  .layout-get-in-touch {
    margin: 20px 0 0 0;
    padding: 0;

    .get-in-touch-box {
      display: block;
    }

    .get-in-touch-tile {
      width: 100%;
      padding: 0;

      h1 {
        font-size: 24px;
        margin: 0;
        padding: 0 0 20px 0;
        font-weight: $font-weight-bold;
      }
    }

    .get-in-touch-form {
      width: 100%;
    }
  }
}

/***************** form-get-in-touch ******************/
.form-get-in-touch {
  padding-top: 25px;
  @include col100;

  form div input[type = "text"] {
    border: none;
    opacity: 0.7;
    outline: none;
    width: 100%;
  }

  form div input[type = "tel"] {
    border: none;
    opacity: 0.7;
    outline: none;
    width: 100%;
  }

  .container-label {
    label {
      font-weight: $font-weight-regular;
    }
  }

  .form-first-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .form-first-row-element, .form-first-row-second-element {
      display: flex;
      width: 49%;
      flex-direction: column;
    }
  }
  .btn-center {
    margin:auto
  }

}

/***************** get-in-touch-message-box ******************/
.container-home-modern-view-2 {
  #contactMain {
    .get-in-touch-message-box {
      flex: 0 0 50%;
    }
  }
}

.inv-car-info {
  .get-in-touch-message-box {
    display: inline-block;

    .alert {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }
}

/***************** form-flex-container ******************/

.form-get-in-touch {
  .form-flex-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .layout-finance-container .layout-get-in-touch {
    padding: 5% 10% 5% 10%;
  }
}

@media screen and (max-width: 1024px) {
  .layout-get-in-touch {
    //padding: 0 10% 5% 10%;
    padding: 0 20px 5% 20px;

    .get-in-touch-box {
      display: block;
      padding: 20px;
    }

    .get-in-touch-tile {
      width: 100%;
      padding: 0 5% 20px 5%;

      h1 {
        font-size: 24px;
      }
    }

    .get-in-touch-form {
      width: 100%;
      padding: 0 5%;
    }

    .btn-send-content {
      float: left;
    }
  }
  .layout-inventory-container-car-5 {
    .layout-get-in-touch .get-in-touch-form {
      padding: 0;
    }
  }
  .layout-container-5 {
    .layout-get-in-touch {
      padding: 0 20px 5% 20px;
    }
  }
  .layout-finance-container {
    .layout-get-in-touch {
      padding: 5% 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container-home-modern-view-3,
  .layout-finance-container{
    .layout-get-in-touch {
      .get-in-touch-box {
        border-left: none;
      }
    }
  }

  .layout-get-in-touch {
    padding: 5% 10px;

    .get-in-touch-tile {
      padding-left: 0;
    }

    .get-in-touch-form {
      padding: 0;
    }
  }
  .layout-container-main {
    .layout-get-in-touch {
      .get-in-touch-form {
        .btn-row {
          .btn-send-content {
            float: right;
          }
        }
      }
    }
  }

  .layout-finance-container {
    .layout-get-in-touch {
      .get-in-touch-form {
        .btn-row {
          .btn-send-content {
            float: right;

            .btn-send {
              @include item-center;
            }
          }
        }
      }
    }
  }

  .layout-container-5 {
    .layout-get-in-touch {
      .get-in-touch-form {
        padding: 0;

        .input-text, .input-area {
          .title-input-get-in {
            padding-left: 5px;
          }

          input, textarea {
            border: none;
          }
        }

        .btn-row {
          .btn-send-content {
            float: right;

            .btn-send {
              @include item-center;
            }
          }
        }
      }
    }
  }

  .layout-inventory-container-car-5 .inv-car-info, .layout-container-5, .layout-finance-container{
    .layout-get-in-touch, .get-in-touch-box{
      .get-in-touch-form .btn-row{
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 600px){
  .layout-get-in-touch {
    padding: 5% 10px!important;
  }
}

@media screen and (max-width: 480px) {
  .layout-finance-container {
    .layout-get-in-touch {
      padding: 5% 10px;
      .get-in-touch-box {
        padding: 10px;
        .get-in-touch-form {
          .form-first-row {
            display: flex;
            width: 100%;
            flex-direction: column;
           }
          .input-text, .input-area {
            width: 100%;
            .title-input-get-in {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }

  .layout-container-main, .layout-container-5 {
    .layout-get-in-touch {
      //padding: 5% 10px;
      .get-in-touch-box {
        .get-in-touch-form {
          .form-first-row {
            display: flex;
            width: 100%;
            flex-direction: column;

            .form-first-row-element, .form-first-row-second-element {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .form-get-in-touch {
    .form-flex-container {
      .form-first-row {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 30px;

        .form-first-row-element, .form-first-row-second-element {
          width: 100%;
        }
      }
    }
  }
  .layout-finance-container {
    .layout-get-in-touch {
      padding: 5% 10px;
    }
  }
  .layout-finance-container {
    .layout-get-in-touch {
      .get-in-touch-box {
        padding: 10px;
      }
    }
  }
  .layout-finance-container {
    .layout-get-in-touch {
      .get-in-touch-box {
        .form-first-row{
          flex-direction: column;
          width: 100%;
          .input-text{
            width:100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .layout-get-in-touch .get-in-touch-tile h1 {
    font-size: 18px;
  }
}

@media screen and (max-width: 380px) {
  .layout-container-5 {
    .layout-get-in-touch {
      .get-in-touch-box {
        padding: 10px;
      }
    }
  }
}

/***************** get-in-touch-send-button ******************/
//.readOnly {
//  font-weight: 600 !important;
//  font-size: 14px !important;
//}
