@import "src/common/styles/utils";

/*** Template5 ***/

.footer-modern {
  @include row-base;
  position: relative;

  .container-footer-background, .container-footer-background-template {
    //padding-top: 20px;
    width: 100%;
    height: 335px;
    position: absolute;
    overflow: visible;
    background-color: black;

    &.admin {
      position: unset
    }

    .footer-background, .footer-background-template {
      opacity: 0.4;
      width: 100%;
    }

    .footer-background {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .container-menu-footer-modern, .container-menu-footer-modern-template {
    @include row-base;
    text-align: center;
    position: absolute;
  }

  .container-tittle-modern {
    @include row-base;
    position: absolute;
    top: 50px;
  }

  .container-subscribe {
    @include row-base;
    position: relative;

    .subscribe-message {
      color: #fff;
      float: right;
      margin: 10px 0;
    }
  }

  .container-menu-footer-modern, .container-menu-footer-modern-template {
    border-top: 1px solid white;
    height: 50px;
    align-items: center;
    display: flex;
    top: 260px;
    width: 100%;
    padding-left: 50px;
    justify-content: space-between;

    .container-icon-social, .container-icon-social-setting {
      position: relative;
      display: flex;
      justify-content: center;
      right: 50%;

      .wrapper-instagram, .wrapper-facebook, .wrapper-twitter {
        float: left;
        padding-top: 3px;
        padding-right: 15px;
      }

      .icon-social-networks {
        height: 26px;
        width: 26px;
        fill: #ffffff;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .container-icon-social-setting {
      padding-left: 200px;
    }

    .container-copyright {
      position: relative;
      display: flex;
      float: left;

      .text-copyright {
        margin: 0;
        color: #ffffff;
      }
    }

  }

  .footer-tittle-modern, .footer-tittle-modern-setting {
    width: 100%;

    h3, label {
      color: #FFFFFF;
      margin-top: 0;
    }

    h3 {
      font-weight: $font-weight-bold;
      margin-bottom: 1px;
      font-size: 32px;
    }

    label {
      font-weight: 100;
      margin-bottom: 10px;
    }
  }

  .footer-tittle-modern-setting {
    label {
      font-size: 14px;
    }
  }

  .col-keyword, .col-keyboard-setting {
    width: 100%;
    float: left;
    position: relative;
    //--padding-right: 10px;

    input {
      border-radius: 3px;
      padding-right: 40px;
      height: 44px;
      background-color: transparent;
      opacity: 0.5;
      color: #ffffff !important;
    }
  }

  .col-keyboard-setting {
    width: 80%;

    input {
      height: 36px;
    }
  }

  .btn-subscribe-content, .btn-subscribe-content-setting {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 34px;
    width: 34px;

    .content-icon-arrow {
      float: left;
    }

    .icon-arrow {
      height: 14px;
      width: 14px;
      fill: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .btn-subscribe-content-setting {
    height: 36px;
    width: 36px;
  }

  .container-footer-left {
    display: flex;
    //align-items: center;
    height: inherit;
    position: relative;
    float: left;
    padding-left: 70px;

    .title-footer-left-one, .title-footer-left-one-setting, .title-footer-left-two, .title-footer-left-two-setting {
      display: flex;
      min-width: 300px;
      height: inherit;
      flex-direction: column;
      align-items: flex-start;

      label {
        font-size: 28px;
        color: #ffffff;
      }

      a {
        color: #ffffff;
        text-decoration: underline;
      }

      span {
        color: #ffffff;
      }
    }

    .title-footer-left-two {
      a {
        text-decoration: none !important;
      }
    }

    .title-footer-left-two-setting {
      min-width: 200px;
    }

    .title-footer-left-one-setting, .title-footer-left-two-setting {
      padding-left: 50px;
    }
  }

  .container-footer-right, .container-footer-right-setting {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    position: relative;
  }

  .container-footer-right-setting, .footer-left {
    padding-left: 50px;
  }

  .container-footer-right {
    width: 35%;
    float: right;
    padding-right: 60px;
  }

  /*footer media query*/
  @media screen and (min-width: 1600px) {
    .container-footer-left {
      padding-left: 7.55%;
    }
    .container-footer-right {
      padding-right: 7.55%;
    }
    .container-menu-footer-modern {
      padding-left: 7.55%;
    }
  }

  .line-footer {
    border: 0;
    border-top: 1px solid #fff;
    height: 0;
    width: 100%;
    position: absolute;
    top: 250px;
    margin-top: 0;
    margin-bottom: 0;
  }

}

.layout-inventory-container-car-5 {

  .footer-modern {
    top: 50px;
  }
}

.layout-about-container-5 {

  .footer-modern .container-footer-background {
    margin-top: 0;
  }
}

/*** Template5 ***/


/*** TemplateMain ***/

.layout-about-container-dark {
  .layout-footer {
    top: 0 !important;
  }
}

.layout-footer {
  @include col100;
  position: relative;

  /****** css for container of footer background ******/
  .container-footer-background {
    padding-top: 5px;
    width: 100%;
    height: 330px;
    position: absolute;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;

    &.admin {
      position: unset
    }

    .footer-left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: inherit;
      position: relative;
      //padding-right: 80px;
      float: right;
      width: 40%;
      padding-left: 12%;
      padding-right: 5%;


      h2, h3 {
        margin: 0;
      }

      h2 {
        font-weight: $font-weight-bold;
        color: #4D4F5C;
        font-size: 20px;
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 10px;
        color: #373636;
        font-size: 14px;
      }

      input {
        border-radius: 18px;
        height: 40px;
      }

      .line-description{
        margin-bottom: 10px;
      }

      .content-input {
        position: relative;
        //width: 400px;
        width: 100%;
      }

      .btn-arrow-next.white-arrow {
        .a {
          fill: white;
        }
      }

      .btn-arrow-next.black-arrow {
        .a {
          fill: black;
        }
      }

      .btn-arrow-next {
        @include item-center;
        position: absolute;
        right: 5px;
        top: 3px;
        height: 34px;
        width: 34px;
        border-radius: 18px;
        background-color: #d10b0c;

        .icon-arrow {
          height: 14px;
          width: 14px;
          fill: #ffffff;
        }

        .content-icon-arrow {
          div {
            display: flex;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      .title {
        font-size: 30px;
        color: #4D4F5C;
        font-weight: $font-weight-bold;
      }
    }

    .footer-right {
      display: flex;
      align-items: center;
      height: inherit;
      position: relative;
      float: left;
      width: 60%;

      .title-one, .title-two {
        @include item-center;
        min-width: 300px;
        height: inherit;
        flex-direction: column;
        padding-left: 70px;
        align-items: flex-start;
        margin-top: -30px;
        width: 50%;
        padding-right: 15px;
        word-break: break-word;

        .title {
          font-size: 30px;
          color: #4D4F5C;
          font-weight: $font-weight-bold;
        }

        label {
          font-size: 30px;
          color: #4D4F5C;
          font-weight: $font-weight-bold;
        }

        a {
          color: #373636;
          text-decoration: underline;
        }
      }

      .title-one {
        padding-right: 0;
      }

      .title-two {
        padding-left: 55px;
      }
    }

    .footer-background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.3;
    }

    .footer-bar-bottom {
      position: absolute;
      height: 40px;
      width: 100%;
      bottom: 0;
      background-color: #d10b0c;

      .all-reserved {
        float: left;
        height: inherit;
        @include item-center;
        padding-left: 72px;

        label {
          margin: 0;
          color: #ffffff;
          font-weight: $font-weight-regular;
        }
      }

      .network-social {
        float: right;
        padding-right: 74px;
      }
    }

    .wrapper-instagram, .wrapper-twitter, .wrapper-facebook {
      width: 40px;
      height: 40px;
      float: left;
      @include item-center;

      .icon-instagram, .icon-twitter, .icon-facebook {
        height: 15px;
        fill: #ffffff;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .footer-effect {
      height: 100px;
      position: absolute;
      width: 100%;
    }

    .footer-effect::before {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      content: '';
    }
  }

  .footer-separator {
    height: 13px;
    background-color: #d10b0c;
  }
}

.layout-inventory-container {
  .layout-footer {
    margin-top: 100px;
  }
}

.layout-inventory-container-car, .layout-finance-container {
  .layout-footer {
    top: 0;
    position: inherit;
  }
}

.layout-inventory-container-car {
  .layout-footer {
    padding-top: 150px;
  }
}

.layout-about-container {
  .layout-footer {
    top: 50px;
  }
}

.finance-view-vertical {
  .layout-footer {
    height: 0;
  }
}

.finance-view-wizard {
  .layout-footer {
    top: 350px;
    position: relative;
  }
}

/*** TemplateMain ***/


/*** Media Queries ***/

@media screen and (max-width: 1450px) {

  .layout-footer {
    .container-footer-background {
      height: 300px;
    }
  }
}

@media screen and (max-width: 1400px) {

  .finance-view-wizard .layout-footer {
    top: 250px;
  }
}

@media screen and (max-width: 1360px) {
  .finance-view-wizard .layout-footer {
    top: 220px;
  }
}

@media screen and (max-width: 1200px) {
  .footer-modern {
    .footer-tittle-modern {
      h3 {
        font-size: 28px;
      }
    }

    .footer-sub-tittle-modern {
      label {
        font-size: 28px;
      }
    }

    .container-footer-background {
      .line-footer {
        top: 265px;
      }

      .container-menu-footer-modern {
        top: unset;
        bottom: 10px;
        padding: 0;
        width: 100%;
        @include item-center;

        .container-copyright {
          white-space: nowrap;
          position: absolute;
          left: 50px;
        }

        .container-icon-social {
          padding: 0;
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .finance-view-wizard .layout-footer {
    top: 200px;
  }
}

@media screen and (max-width: 1160px) {

  .footer-modern {
    .container-footer-background-template {
      height: 330px;
    }

    .container-menu-footer-modern-template {
      top: 270px;
    }
  }
}

@media screen and (max-width: 1120px) {
  .footer-modern {
    .container-footer-background-template {
      height: 320px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .footer-modern .footer-tittle-modern label {
    font-size: 14px;
  }
  .footer-modern {
    .container-footer-background {
      .container-tittle-modern {
        .container-footer-left {
          width: 60%;
          padding-left: 50px;

          .title-footer-left-one {
            padding-left: 0;
          }
        }

        .container-footer-right {
          width: 40%;
          padding-right: 50px;
        }
      }
    }

    .col-keyboard {
      width: calc(100% - 34px) !important;

      input {
        border-radius: 2px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .layout-footer {
    .container-footer-background {
      .footer-left {
        .content-input {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {

  .footer-modern {
    .container-footer-background {
      .footer-background {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  .footer-modern {
    .col-keyboard {
      width: 80%;

      input {
        font-size: 12px;
      }
    }

    .btn-subscribe-content {
      .btn-subscribe {
        width: 200px;
      }
    }

    .container-footer-right {
      padding-left: 10px;
    }

    .footer-tittle-modern {
      label {
        font-size: 14px;
      }
    }
  }

  .footer-modern .container-footer-background {
    margin-top: 0;
  }

  .layout-footer {
    .container-footer-background {
      height: 250px;

      .footer-right .title-one, .footer-right .title-two {
        padding-left: 50px;
        min-width: 240px;
        font-size: 15px;

        label {
          font-size: 24px;
        }

        .title {
          font-size: 24px;
        }
      }

      .footer-left {
        padding-left: 0;

        h2 {
          font-size: 24px;
        }

        h3 {
          font-size: 15px;
          padding-top: 10px;
        }

        .title {
          font-size: 24px;
        }

        .line-description {
          font-size: 15px;
        }
      }
    }
  }

  .finance-view-wizard .layout-footer {
    top: 170px;
  }
}

@media screen and (max-width: 992px) {
  .layout-about-container-5 {
    .footer-modern {
      .container-footer-background {

      }
    }
  }
  .footer-modern {
    .container-footer-background {
      height: 320px;

      .footer-background {
        height: 100%;
        object-fit: cover;
        margin-top: 0;
      }

      .container-tittle-modern {
        top: 25px;

        .container-footer-left {
          padding-left: 0;
          margin: 0;
          width: 100%;

          .title-footer-left-one {
            padding-left: 30px;
            min-width: 50%;

            label {
              font-size: 20px;
            }

            div {
              span, a {
                font-size: 17px;
                font-weight: lighter;
                color: #d0d1d4;
                text-decoration: none;
              }
            }
          }

          .title-footer-left-two {
            padding: 0 30px;

            label {
              font-size: 20px;
            }

            div {
              span, a {
                font-size: 17px;
                font-weight: lighter;
                color: #d0d1d4;
              }
            }
          }
        }

        .container-footer-right {
          width: 100%;
          padding: 20px 30px 0 30px;

          .footer-tittle-modern {
            h3 {
              font-size: 20px;
            }

            label {
              font-size: 17px;
              color: #d0d1d4;
            }
          }

          .container-subscribe {
            .col-keyboard {
              width: 100% !important;

              .form-control {
                background: transparent;
                color: #d0d1d4 !important;
                font-size: 17px;
                border-radius: unset;
              }

              .btn-subscribe-content {
                height: 32px;
                width: 32px;
                margin: 1px;
              }
            }
          }
        }
      }

      .line-footer {
        top: unset;
        bottom: 55px;
      }

      .container-menu-footer-modern {
        height: 55px;
        bottom: 0;
        top: unset;
        width: 100%;
        padding: 0 30px;

        .container-copyright {
          padding: 0;
        }

        .container-icon-social {
          padding: 0;
          right: 0;

          .wrapper-twitter {
            padding: 3px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px), handheld and (orientation: landscape) {
  .footer-modern .container-menu-footer-modern .container-icon-social {
    padding-left: 200px;
  }

  .finance-view-wizard {
    .layout-footer {
      top: 130px;
      position: relative;
    }
  }

  .layout-footer {
    .container-footer-background {
      display: flex;
      flex-direction: column;
      height: 450px;

      .footer-left {
        padding: 0 40px 0 40px;
        width: 100%;
        justify-content: flex-start;

        .content-input {
          width: 100%;
        }

        h2 {
          font-size: 26px;
          padding: 0 10px;
        }

        h3 {
          padding: 0 10px;
          margin-bottom: 15px;
        }
      }

      .footer-right {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 40px 0 40px;
        width: 100%;

        .title-one, .title-two {
          margin: 0;
          padding: 20px 0 0 0;
          height: 100px;
          width: 100%;

          label {
            font-size: 26px;
          }
        }
      }

      .footer-background {
        opacity: 0.3;
        width: 100%;
      }

      .footer-bar-bottom {
        height: 54px;
        padding: 0 55px;

        .all-reserved {
          padding-left: 0 !important;
        }

        .network-social {
          height: 100%;
          padding-right: 0 !important;
          @include item-center;
        }
      }

      .wrapper-facebook .icon-facebook, .wrapper-instagram .icon-instagram, .wrapper-twitter .icon-twitter {
        height: 13px;
      }
    }
  }

  .layout-inventory-container .layout-footer {
    top: 150px !important;
  }
}

@media screen and (max-width: 900px) {
  .layout-footer {
    .container-footer-background {

      .footer-right .title-two {
        min-width: 180px;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .footer-modern {

    .container-menu-footer-modern {
      .container-icon-social .icon-social-networks {
        height: 22px;
        width: 22px;
      }

      .container-copyright {
        padding-left: 20px;
      }
    }

    .container-footer-left .title-footer-left-one, .container-footer-left .title-footer-left-two {
      min-width: 50%;
      padding-left: 20px;
    }

    .col-keyboard {
      width: 90%;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-modern {
    .container-footer-background {
      height: 320px;

      .footer-background {
        height: 100%;
        object-fit: cover;
      }

      .container-tittle-modern {
        .container-footer-left {
          padding-left: 0;
          margin: 0;

          .title-footer-left-one {
            padding-left: 30px;

            label {
              font-size: 20px;
            }

            div {
              span, a {
                font-size: 17px;
                font-weight: lighter;
                color: #d0d1d4;
                text-decoration: none;
              }
            }
          }

          .title-footer-left-two {
            padding: 0 30px;

            label {
              font-size: 20px;
            }

            div {
              span, a {
                font-size: 17px;
                font-weight: lighter;
                color: #d0d1d4;
              }
            }
          }
        }

        .container-footer-right {
          width: 100%;
          padding: 20px 30px 0 30px;

          .footer-tittle-modern {
            h3 {
              font-size: 20px;
            }

            label {
              font-size: 17px;
              color: #d0d1d4;
            }
          }

          .container-subscribe {
            .col-keyboard {
              width: 100%;

              .form-control {
                background: transparent;
                color: #d0d1d4 !important;
                font-size: 17px;
                border-radius: unset;
              }
            }
          }
        }
      }

      .line-footer {
        top: unset;
        bottom: 55px;
      }

      .container-menu-footer-modern {
        height: 55px;
        bottom: 0;
        top: unset;
        width: 100%;
        padding: 0 30px;

        .container-copyright {
          padding: 0;
        }

        .container-icon-social {
          padding: 0;

          .wrapper-twitter {
            padding: 3px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .layout-inventory-container-car-5 {
    .footer-modern {
      top: 50px;
    }
  }
}

@media screen and (max-width: 660px) {
  .footer-modern {
    .container-footer-background {
      .container-menu-footer-modern {
        justify-content: flex-end;
      }
    }

    .footer-tittle-modern {
      h3 {
        font-size: 20px;
      }

      label {
        font-size: 12px;
      }
    }

    .container-menu-footer-modern {
      top: 220px;
    }

    .container-footer-left .title-footer-left-one, .container-footer-left .title-footer-left-two {
      label {
        font-size: 20px;
      }

      a, span {
        font-size: 12px;
      }
    }

  }
}

@media screen and (max-width: 620px) {
  .footer-modern {

    .container-menu-footer-modern {
      top: 200px;
    }


    .container-footer-left {
      margin-bottom: 5px;
    }

  }
}

@media screen and (max-width: 600px) {
  .footer-modern {

    .footer-tittle-modern, .footer-sub-tittle-modern {
      h3 {
        font-size: 18px;
      }
    }

    .container-menu-footer-modern .container-icon-social .icon-social-networks {
      height: 20px;
      width: 20px;
    }

    .container-footer-left .title-footer-left-one, .container-footer-left .title-footer-left-two {
      label {
        font-size: 18px;
      }
    }
  }

  .layout-footer {
    .container-footer-background {
      display: flex;
      flex-direction: column;
      height: 520px;

      .footer-left {
        padding: 78px 40px 0 40px;
        width: 100%;
        justify-content: flex-start;

        .content-input {
          width: 100%;
        }

        h2 {
          font-size: 26px;
        }
      }

      .footer-right {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 40px 0 40px;

        .title-one, .title-two {
          margin: 0;
          padding: 30px 0 0 0;
          height: 100px;

          label {
            font-size: 26px;
          }
        }

        .title-two {
          padding: 130px 0 0 0;
        }
      }

      .footer-background {
        width: auto;
        inline-size: -webkit-fill-available;
      }

      .footer-bar-bottom {
        .all-reserved {
          padding-left: 50px;
        }
      }

      .wrapper-facebook .icon-facebook, .wrapper-instagram .icon-instagram, .wrapper-twitter .icon-twitter {
        height: 13px;
      }
    }
  }

  .layout-container-main-b {
    .layout-footer {
      top: 0 !important;
    }
  }
}

@media screen and (max-width: 580px) {
  .footer-modern {
    .container-tittle-modern {
      top: 15px;
    }

    .container-menu-footer-modern {
      top: 190px;
    }

    .line-footer {
      top: 185px;
    }

    .container-footer-left .title-footer-left-one, .container-footer-left .title-footer-left-two {
      label {
        font-size: 18px !important;
      }

      a, span {
        font-size: 14px !important;
      }
    }

    .container-footer-right {
      .footer-tittle-modern {
        h3 {
          font-size: 18px !important;
        }

        label {
          font-size: 14px !important;
        }
      }
    }
  }

  .layout-inventory-container-car .layout-footer {
    padding-top: 130px;
  }
}

@media screen and (max-width: 560px) {
  .footer-modern {
    .line-footer {
      top: 180px;
    }

    .container-tittle-modern {
      top: 10px !important;
    }

    .container-footer-background {
      .footer-background {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .footer-modern {
    .footer-tittle-modern, .footer-sub-tittle-modern {
      h3 {
        font-size: 16px;
      }
    }

    .title-footer-left-two {
      padding-left: 10px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .layout-container-5 .footer-modern .container-footer-background .container-tittle-modern .container-footer-right {
    padding-top: 7px;
  }

  .layout-about-container-5 .footer-modern .container-footer-background .container-tittle-modern .container-footer-right {
    padding-top: 10px;
  }

  .layout-footer .container-footer-background {
    .footer-right {
      .title-one, .title-two {
        label {
          font-size: 24px;
        }

        .title {
          font-size: 24px;
        }

        a, span {
          font-size: 15px;
        }
      }
    }

    .footer-left {
      h2 {
        font-size: 24px;
      }

      .title {
        font-size: 24px;
      }

      .line-description {
        font-size: 15px;
      }
    }

    .footer-bar-bottom {
      .all-reserved {
        padding-left: 35px;
        font-size: 12px;
      }

      .network-social {
        padding-right: 30px;
      }
    }

    .wrapper-facebook, .wrapper-instagram, .wrapper-twitter {
      width: 30px;

      .icon-facebook, .icon-instagram, .icon-twitter {
        height: 11px;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .footer-modern {
    .line-footer {
      top: unset;
      bottom: 55px;
    }

    .container-menu-footer-modern {
      top: unset;
      bottom: 0;
      height: 55px;
      width: 100%;
      padding: 0 30px;

      .container-copyright {
        padding: 0;
      }
    }

    .container-footer-background {
      height: 415px !important;
    }

    .container-tittle-modern {
      .container-footer-left {
        padding-left: 0;
        margin: 0;
        display: inline-block;

        .title-footer-left-one, .title-footer-left-two {
          min-width: unset;
          width: 100%;
          padding: 5px 30px !important;

          label {
            font-size: 17px !important;
          }

          a, span {
            font-size: 15px !important;
          }
        }
      }

      .container-footer-right {
        width: 100%;
        float: right;
        padding: 10px 30px 0 30px;

        .footer-tittle-modern {
          h3 {
            font-size: 17px !important;
          }

          label {
            font-size: 15px !important;
          }
        }
      }

    }
  }

  .layout-footer {
    .footer-right {
      .title-one, .title-two {
        label {
          font-size: 20px !important;
        }

        a, span {
          font-size: 12px;
        }
      }
    }

    .footer-left {
      h2 {
        font-size: 20px !important;
      }

      h3 {
        font-size: 12px !important;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .footer-modern {
    .container-menu-footer-modern .container-copyright .text-copyright {
      font-size: 12px;
    }

    .container-menu-footer-modern .container-icon-social {
      padding: 0 1px 0 0;

      .icon-social-networks {
        height: 16px;
        width: 16px;
      }
    }
  }

  .layout-footer .container-footer-background {
    .footer-bar-bottom {
      .all-reserved {
        font-size: 10px;
      }
    }

    .wrapper-facebook, .wrapper-instagram, .wrapper-twitter {
      width: 25px;

      .icon-facebook, .icon-instagram, .icon-twitter {
        height: 10px;
      }
    }

    .footer-right {
      .title-two {
        padding: 130px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .layout-footer {
    .footer-right {
      .title-one, .title-two {
        label {
          font-size: 17px !important;
        }

        a, span {
          font-size: 11px;
        }
      }
    }

    .footer-left {
      h2 {
        font-size: 17px !important;
      }

      h3 {
        font-size: 11px !important;
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .footer-modern {
    .container-menu-footer-modern .container-copyright .text-copyright {
      font-size: 11px;
    }

  }
}



