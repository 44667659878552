$text-color: #787878;
$light-blue-color-1: #2194F1;
@import "constants";

@mixin item-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin col100() {
  float: left;
  width: 100%;
}

@mixin col33() {
  float: left;
  width: 33.33%;
}

@mixin one-single-line-text() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin simple-line-separator() {
  border: 1px solid #D7DAE2;
  opacity: 1;
  margin: 5px 0;
}

@mixin border-box() {
  padding: 4px 5px 5px 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@mixin btn-disabled() {
  border-color: #bbb;
  background-color: #bbb;
}

@mixin default-popover() {
  overflow: auto;
  max-height: 110px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  border-radius: 2px;
  padding: 5px 0;
}

@mixin classic-button($text-color: #ffffff, $background-color: $green-color, $width: 90px,
$height: 32px, $border: 1px solid $background-color, $font-size: 13px, $font-weight: $font-weight-regular, $border-radius: 4px  ) {
  color: $text-color;
  background-color: $background-color;
  border-radius: $border-radius;
  border: $border;
  height: $height;
  width: $width;
  font-size: $font-size;
  font-family: 'Open Sans', sans-serif;
  font-weight: $font-weight;
  @include item-center;
}

@mixin ellipsis-text($numberOfLines : 4) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
}

@mixin word-break-button() {
  white-space: normal;
  word-break: break-word;
  height: auto;
}

@mixin row-base() {
  float: left;
  width: 100%;
}

@mixin col-50() {
  float: left;
  width: 50%;
}
