@import '../../styles/constants';
@import "src/common/styles/utils";

.dp-button {
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: none;
  cursor: pointer;
  border: none;
}

.dp-button--primary {
  color: #fff;
}

.dp-button--outline {
  border: 1px solid;
  background-color: transparent;
}

.dp-button--no-border {
  color: var(--primary);
  background-color: transparent;
  border: none;
}

.dp-button--accept {
  background-color: $green-color;
}

.dp-button--decline {
  background-color: $button-color-red;
}

.dp-button--normal {
  background-color: $light-blue-color-1;
}

.dp-button--icon {
  height: 20px;
  width: 20px;
}

.dp-button--icon-only {
  width: 40px;
  height: 40px;
  border-radius: 100px !important;
  display: flex;
  gap: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
}

.dp-button--small {
  padding: 6px 12px;
  font-size: 13px;
}

.dp-button--medium {
  padding: 12px 30px;
  font-size: 16px;

}

.icon--small {
  width: 15px;
  height: 15px;
}

.icon--medium {
  width: 25px;
  height: 25px;
}

.icon--large {
  width: 35px;
  height: 35px;
}

.dp-button--large {
  padding: 15px;
  font-size: 20px;
  border-radius: 24px;
}

.dp-button--round {
  border-radius: var(--border-round);
}


.dp-button--text-uppercase {
  text-transform: uppercase;
}

.dp-button--text-lowercase {
  text-transform: lowercase;
}

.dp-button--text-capitalize {
  text-transform: capitalize;
}

.dp-button--disabled {
  cursor: not-allowed !important;
}

.dp-button--disabled,
button:disabled {
  opacity: 1 !important;
}

:root {
  --border-square: 4px;
  --border-round: 14px;
}

.button-icon-center > div {
  @include item-center;
}