@import "src/catalog/common/common";
@import "src/catalog/util/fontTest";
@import "src/common/styles/utils";
@import "src/common/styles/setting";

$font-family: 'Open Sans', sans-serif;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 200;
$arrow-color: #B4B4B4;
$background-dark-color: #000;
$background-dark-color-2: #222;
$border-dark-color: #CACACA;
$border-dark-color-2: #707070;


/****** DARK THEME ******/

.layout-inventory-container-dark {
  .dp-pagination-container {
    .pagination-left, .pagination-right {
      button {
        background-color: $background-dark-color-2 !important;
      }
    }
  }

}

.layout-container-main-c {
  .content-box-get-in-touch-dark {
    .btn-send-content {
      .btn-send {
        //--border-color: #FFF !important;
      }
    }
  }
}

.layout-finance-container-c {
  .finance-btn {
    .btn-submit:focus {
      border: 1px solid !important;
    }
  }
}

.layout-inventory-container-c {
  .layout-car-filter {
    .btn-search-advance:focus {
      border: 1px solid !important;
    }
  }
}

.layout-container-main-b {


  .car-search-container, .banner-title {
    z-index: 102;
  }

  .car-search-container {
    .car-search-box {
      .car-search-row {
        input, .car-search-select {
          padding: 10px 20px;
          font-weight: 600;
          border-color: #333;
          color: rgb(226, 226, 226);
        }

        .layoutBanner-DarkMode {
          border: none;
        }
      }
      .input-search-content-price {
        input {
          padding-left: 35px;
        }
      }
    }
  }
}

.layout-container-main {
  .lay-get-in-touch {
    .get-in-touch-message-box {
      display: flex;
      flex-direction: column;
      width: 100%;

      .container-get-in-touch {
        width: 100%;
      }
    }
  }

  .background {
    z-index: 100;
    position: relative;
  }


  .layout-car {
    z-index: 101;
  }

  .car-separator1, .car-separator2 {
    z-index: 101;
    position: relative;
  }
}

.layout-container-main, .layout-finance-container, .container-home-modern-view-2 {
  .lay-get-in-touch{
    .layout-contact-testimonial{
      display: flex;
      width: 100%;
      justify-content: center;

      .layout-contact-full, .layout-testimonials-full{
        width: 50%;
        display: flex;
        flex-direction: column;
      }
      .layout-contact-hide, .layout-testimonials-hide{
        display: none;
      }
    }
  }
}

.layout-finance {
  .body-image {
    filter: none !important;
  }

  .blur-background {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
  }
}

/*** Home Page ***/
.layout-container-main-c {
  .content-box-get-in-touch-light {
    .section-left, .section-right {
      background-color: #F4F4F4 !important;
    }

    .container-testimonial {
      .section-testimonial {
        background-color: #000 !important;
      }
    }

    .testimonial-content-5, .label-name, .label-social {
      color: #FFF !important;
    }

    .get-in-touch-box {
      background-color: #F4F4F4 !important;
      border-color: $background-dark-color !important;
    }
  }
}

/**** common styles ****/
.layout-finance-container-dark, .layout-inventory-container-dark, .layout-inventory-container-car-dark, .layout-about-container-dark {
  .container-banner-car {
    width: 100%;
    height: 1400px;
    position: fixed;
    z-index: -1;
  }
}

.header-content {
  height: auto;
  overflow: hidden;
  position: absolute;
  width: 100%;

  .background {
    width: 100%;
    object-fit: cover;
  }

}

.layout-inventory-container {

  .header-content {

    .separator-header {
      top: 100px;
    }

    .background {
      height: 100%;
    }
  }
}

.layout-finance-container {

  .header-content {

    .background {
      margin-top: -50px;
    }
  }
}

/*** Vehicle Details ***/
.layout-inventory-container-car-dark {
  .inv-car-view {
    .inv-car-box {
      .car-feature {
        > div > div {
          border: 0 !important;
        }
      }
    }
  }
}

.layout-inventory-container-car-light {
  .inv-car-des, .inv-car-calculator{
    background-color: #f8f8f8 !important;
  }
}

/***** LOCATION *****/

#locationMain, #locationMainEasyNavigation {
  @include col100;
  background-color: #fff;
  padding: 5% 65px;
  position: relative;

  .layout-location-content {
    @include col100;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    z-index: 10;
  }

  .layout-location-title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    h2 {
      font-size: 28px;
      font-weight: $font-weight-bold;
    }
  }

  .layout-location-img {
    position: absolute;
    left: 0;

    img {
      width: 100%;
    }
  }

  .layout-location-left {
    background-color: rgba(255, 255, 255, 0.975);
    border: 1px solid #d2d2d2;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    float: left;
    width: 30%;
    height: 700px;

    h4 {
      margin: 0;
    }

    h1 {
      margin: 0;
    }

    .container-location {
      @include col100;

      .location-header {
        border-bottom: 1px solid #d2d2d2;
        cursor: pointer;
        display: flex;
        padding: 18px;
        width: 100%;

        .title-location {
          color: #7F7F7F;
          font-size: 20px;
          padding: 4px 0 0 11px;
          width: 90%;
          font-weight: 300;
        }

        .icon-message {
          background-color: #d10c0c;
          width: 32px;
          height: 32px;
          border-radius: 24px;
          padding-top: 6px;
          text-align: center;
        }
      }

      .location-description {
        border-bottom: 1px solid #d2d2d2;
        display: none;
        padding: 10px 0 20px 20px;
        @include col100;

        .container-description {
          position: relative;
          @include col100;
          display: flex;

          .icon-position, .icon-telephone, .icon-calendar {
            float: left;
            width: 35px;
            text-align: center;
          }

          .description {
            display: grid;
            float: left;
            padding: 3px 0 20px 10px;
            width: 80%;
            color: #7F7F7F;
          }

          .icon-location {
            width: 18px;
            height: 18px;
            fill: #D10C0C
          }

          .icon-location-content {
            padding-top: 10px;
          }
        }
      }
    }
  }

  #map {
    width: 70% !important;
    float: right;
    height: 700px;
    border-radius: 0 20px 20px 0;
  }

  @mixin btnBase() {
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  #gmapToolTip {
    h4 {
      color: black;
      margin-top: 0;
      margin-bottom: 0.2em;
    }

    .btn {
      @include btnBase;
      margin-top: 0.5em;
      color: white;
      background: black;
    }
  }
}

#locationMainEasyNavigation {
  float: none;
  display: grid;
}

#locationMain, #locationMainEasyNavigation {
  background-repeat: no-repeat;
  background-size: 100% auto;
}

#background-location {
  position: absolute;
  left: -50px;


  filter: blur(10px);
}

.frosted-glass {

  justify-content: center;
  align-items: center;
  display: flex;
  filter: blur(10px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}


/*
.frosted-glass:before{
content: '';
position: absolute;
z-index: 1;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: inherit;
box-shadow: inset 0 0 3000px rgba(255,255,255, 0.5);
filter : blur(10px);

}

*/

/***** LOCATION *****/

.banner-img-container {
  margin-top: -15%;
  position: absolute;
  right: 0;
  z-index: 20;
  width: 40%;

  .car {
    width: 100%;
  }
}

.main-nav {
  .navbar-default {
    background-color: transparent;
  }
}

.popover-public-locations {
  max-height: 256px !important;

  div {
    max-height: 256px !important;

    div {
      max-height: 256px !important;

      div {
        max-height: 256px !important;

        div {
          max-height: 256px !important;
        }
      }
    }
  }
}

.menu-user {
  float: right !important;
  margin-right: 30px;
  margin-left: 30px;

  a {
    padding: 5px !important;
    width: 25px !important;

    img {
      width: 15px;
    }
  }
}


@mixin item-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin container-car-image() {
  height: auto;
  background-color: inherit;
  border-radius: 8px 8px 0 0;
  @include item-center;
  aspect-ratio: 1.33;

  img {
    max-width: 100%;
    border-radius: 7px 7px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin container-car-image-sold() {
  position: relative;
  width: 100%;
  &:before {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    background-color: #D10C0C;
    border-radius: 7px 7px 0 0;
    opacity: 0.5;
  }
  &:after {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    content: 'SOLD';
    color: #FDFDFD;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 900;
  }
}

.layout-car, .layout-car-easy-navigation-b {
  padding: 65px 65px 20px 65px;
  position: relative;
  display: flex;

  .col4 {
    float: left;
    width: 25%;
    padding-right: 30px;
  }

  .col3 {
    float: left;
    width: 33.33%;
    padding-right: 23px;
  }

  .col2 {
    float: left;
    width: 50%;
    padding-right: 20px;
  }

  .col1 {
    @include col100;
  }

  .layout-car-content {
    width: 100%;
    background-color: #fff;
    padding: 20px 40px;
    border: 1px #f0f0f0 solid;
    border-radius: 15px;
    top: -350px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 13px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
  }

  .layout-car-header {
    padding: 0 0 15px 30px;

    h2 {
      font-weight: $font-weight-bold;
      font-size: 26px;
      color: #4D4F5C;
    }
  }

  .car-item {
    border-radius: 7px;
    box-shadow: 0 3px 6px #00000029;
    @include col100;
    background-color: #fff;
    position: relative;
  }

  .container-car-image {
    @include container-car-image;
    max-height: 176.5px;

    img {
      border-radius: 8px 8px 0 0;
    }
  }

  .container-car-image-sold, .container-car-image-sold-carousel {
    @include container-car-image;
    @include container-car-image-sold;
  }

  .container-car-image-sold-carousel {
    border-radius: 8px;

    img {
      border-radius: 8px;
    }

    &:before {
      border-radius: 8px;
    }
  }

  .list-view-item {
    .container-car-image-sold {
      aspect-ratio: auto 4/3;

      &:before {
        border-radius: 7px 0 0 7px;
      }
    }
  }

  .car-item-info {
    padding: 15px 15px;
    @include col100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    flex-grow: 1;
  }

  .car-item-title {
    float: left;
    font-size: 14px;
    color: #272727;
    font-weight: $font-weight-semi-bold;
  }

  .car-item-price {
    float: right;
    color: #0FBE2C;
    font-weight: 300;
    font-size: 14px;
    font-style: oblique;

    .price {
      color: #ff565e;
    }
  }

  .btn-car-info {

    border: 1px #B4B4B4 solid;
    border-radius: 30px;
    font-size: 11px;
    padding: 3px 10px;
    color: #B4B4B4;
    margin-top: 10px;
  }

  .btn-car-content {
    float: left;
    padding-right: 10px;
  }

  .car-carousel {
    .container-car-image {
      border-radius: 8px;
    }
  }
}

.layout-car-easy-navigation-b {
  padding: 105px 65px 20px 65px;
  background-color: #F4F4F4;
  border-radius: 15px;
  top: -10px;

  .layout-car-content-easy-navigation-b {
    width: 100%;
    padding: 20px 40px;
    top: -350px;
    display: flex;
    flex-direction: column;
  }
}

.row-layout-car {
  @include col100;
  padding: 0 15px 15px 30px;
  display: flex;

  .col4 {
    padding-right: 15px;
    display: flex;
    >div{
      display: flex;
      flex-direction: column;
    }
  }

  .col3, .col2 {
    display: flex;
  }
}

.row-car-info {
  @include col100;
  display: flex;
  padding-bottom: 15px;

  .car-item-title {
    text-align: left;
  }

  .car-item-price {
    margin-left: auto;
    text-align: right;
  }
}

.row-car-info-btn {
  @include col100;
  display: flex;

  .btn-car-details {
    float: right;

    .btn-details {
      border-radius: 16px;
      display: inline-block;
      max-width: 100%;
      margin-bottom: 0;
      font-weight: 100;
      padding: 1px 14px;
      color: white !important;
      font-size: 13px;
    }
  }

  .row-car-info-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .carfax-content {
    margin-left: auto;
    display: flex;
    flex-direction: column-reverse;
  }
}

.row-car-more {
  @include col100;

  .text-center {
    .flex-row {
      justify-content: center;
    }
  }
}

.btn-inv-more {
  border: none;
  border-radius: 24px;
  //height: 48px;
  color: white;
  font-weight: $font-weight-semi-bold;
  padding: 12px 30px;
  font-size: 16px;
  @include word-break-button;

  &:hover {
    color: white;
  }
}

.car-separator1, .car-separator2 {
  @include col100;
  background-color: #D10C0C;
  height: 25px;
}

.car-separator2 {
  background-color: #1987B0;
  height: 16px;
}

.content-separator2 {
  @include col100;
  padding-top: 16px;
}

.toggle-button {
  padding-right: 20px;
  cursor: pointer;
}

.toggle-menu {

  .background-menu {
    width: 100%;
    height: 290px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
  }

  ul {
    position: relative;
    text-align: center;
    margin-bottom: 0;
    padding: 20px 0;
  }

  .line-horizontal {
    bottom: 0;
    height: 10px;
    background-color: #D10C0C;
  }

  a {
    color: #1E1E1E !important;
  }
}

.banner-red-social {
  position: absolute;
  margin-top: 80px;
  width: 44px;
  height: 140px;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 15px;
  color: #D9D9D9;
  flex-wrap: wrap;
  z-index: 100;

  i {
    @include col100;
    font-size: 16px;
    padding: 10px 0;
    cursor: pointer;
  }
}

/********  header ***********/

/****** css for Finance* **********/
.layout-finance {
  height: 900px;
  max-width: 100%;
  position: relative;
  color: white;
  z-index: 5;

  .body-components {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .body-image {
    @include col100;
    position: absolute;
    filter: blur(20px);
    height: 100%;

    img {
      width: 100%;
      height: inherit;
    }
  }

  .body-left {
    float: left;
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .body-left-position {
    display: flex;
    flex-direction: column;
    top: unset;
    position: relative;
    padding: 15%;
    width: 100%;

    .text-get-finance {
      font-size: 65px;
      font-weight: bold;
      text-align: start;
      word-break: break-word;
      width: 100%;

      label {
        margin: 0;
        font-weight: $font-weight-bold;
        line-height: 70px;
        text-shadow: 5px 6px 5px rgba(0, 0, 0, 0.16);
        width: 100%;
      }
    }

    .text-three-steps {
      color: #19191b;
      font-weight: lighter;
      text-align: start;
      word-break: break-word;

      label {
        font-weight: $font-weight-regular;
        font-size: 30px;
        color: #4D4F5C;
      }
    }
  }

  .finance-apply {
    height: auto;
    border-radius: 30px;
    margin-top: 30px;
    font-weight: $font-weight-semi-bold;
    text-align: center;
    word-break: break-word;
    @include item-center;

    &:hover {
      cursor: pointer;
    }
  }

  .body-right {
    float: right;
    width: 50%;
    @include item-center;
  }

  .body-right-position {
    top: unset;
    width: 100%;
    position: relative;
    border-left: 1px solid white;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 45px;
    float: left;
  }

  .finance-rectangle {
    background-color: white;
    min-height: 86px;
    margin-bottom: 45px;
    border-radius: 10px;
    display: flex;
    padding: 20px 30px;

    > div > div {
      height: 100%;
      @include item-center;
      justify-content: left;
    }
  }

  .finance-image {
    width: 20%;
    text-align: left;
    padding: 0;
  }

  .finance-image-style {
    width: 30px;
    height: 30px;
  }

  .finance-part {
    width: 80%;
    color: #7f7f7f;
    font-size: 24px;
    padding: 0 0 0 20px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    word-break: break-word;

    &:hover {
      cursor: default;
    }
  }

  .finance-apply-end {
    display: none;
  }


}

/****** css for Finance* **********/
.MuiDialog-root {

  .MuiTypography-root, .MuiTypography-body1, .MuiTypography-body2 {
    font-size: 1.4rem;
  }

  .MuiTypography-subtitle1 {
    font-size: 1.6rem;
  }

  .MuiPickersYear-yearSelected {
    font-size: 1.8rem;
  }
}


/****** css for get in touch ******/
.lay-get-in-touch {
  @include col100;
  padding: 70px 35px 70px 65px;
  position: relative;
  float: left;
  //min-height: 720px;
  @include item-center;
}

.container-get-in-touch {
  height: auto !important;
  float: left;
  width: 100%;
}

.container-form-get-in-touch {
  height: 100%;
  @include col100;
  border-radius: 18px;
  box-shadow: 0 6px 10px #00000029;
  padding: 3% 10% 0 10%;

  .title-get-in-touch {
    font-size: 28px;
    font-weight: $font-weight-bold;
    text-align: start;
    color: #4D4F5C;
    word-break: break-word;
  }
}

.container-label {
  color: #7F7F7F;
  size: 20px;
}

.container-input {
  .dp-text-input{
    border-bottom: 1px solid #C6C6C6 !important;
    box-shadow: none;
  }
  .sample-error-input{
    border-bottom: 1px solid #ff0000 !important;
  }
  .dp-text-input-error-message{
    padding-left: 5px;
  }
}

.container-row-button {
  @include col100;
  padding: 45px 0;
  height: auto;
}

.container-button-get-in-touch {
  text-align: center;
}

.layout-customer-testimonial {
  float: left;
  position: relative;
  width: 100%;
  flex-direction: column;
  line-height: 1.2;

  .testimonials-title {
    display: block;
    text-align: center;
    font-size: 28px;
    padding: 0 20px;
  }

  .container-carousel-testimonials {
    position: relative;
    width: 100%;
  }

  .label-experience {
    padding-right: 7.5%;
    display: -webkit-box;
    height: auto;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .carousel-content-text-3, .carousel-content-text-4, .carousel-content-text-0, .carousel-content-text-1 {
    .testimonial-content {
      display: none;
      color: transparent;
    }
  }

  .testimonial-content {
    padding-top: 7%;
    color: #7F7F7F;
    text-align: left;
    padding-right: 7.5%;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    max-width: 450px;
    -webkit-box-orient: vertical;
  }

  h4 {
    margin-top: 12px !important;
    position: absolute;
    bottom: 40px;
    font-weight: $font-weight-regular;
  }

  .content-up-down {
    position: absolute;
    top: 142px;
    left: 51%;
    z-index: 10;
  }

  div > div > img {
    object-fit: cover;
  }
}

.carousel-content-0 {
  width: 100%;
  position: absolute;
  margin-top: 26px;
  opacity: 0.6;
  z-index: 1;
  padding: 0 12.2%;
  transition: 1s;

  .carousel-content-text-0 {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
    float: right;
    height: 199.28px;
    width: calc(100% - 46.97px);
    border-radius: 18px;
    padding-left: 95px;
    position: relative;

    div > h4 {
      font-size: 20px;
      color: #7F7F7F;
      margin-top: 17px;
    }

    div > p {
      width: 96%;
      height: 110px;
      padding-top: 6%;;
      font-size: 11px;
      color: #B4B4B4;
    }

    .carousel-content-image-0 {
      position: absolute;
      padding-top: 20px;
      left: -46.97px;

      img {
        width: 93.94px;
        height: 93.94px;
        border-radius: 50%;
        background-color: #FFF;
      }
    }
  }
}

.carousel-content-1 {
  width: 100%;
  position: absolute;
  margin-top: 60px;
  opacity: 0.6;
  z-index: 2;
  padding: 0 8.5%;
  transition: 0.5s;
}

.carousel-content-image-1 {
  position: absolute;
  padding-top: 46px;
  left: -53.2px;

  img {
    width: 106.4px;
    height: 106.4px;
    border-radius: 50%;
    background: white;
  }
}


.carousel-content-text-1 {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 199.35px;
  width: calc(100% - 53.2px);
  border-radius: 18px;
  padding-left: 95px;
  position: relative;

  div > h4 {
    font-size: 20px;
    color: #7F7F7F;
    margin-top: 17px;
  }

  div > p {
    width: 96%;
    height: 110px;
    padding-top: 6%;;
    font-size: 11px;
    color: #B4B4B4;
  }
}

.carousel-content-2 {
  width: 100%;
  position: absolute;
  margin-top: 142px;
  z-index: 6;
  padding: 0 5%;
  transition: 0.5s;
}

.carousel-content-image-2 {
  position: absolute;
  padding-top: 38px;
  left: -74px;

  img {
    height: 148px;
    width: 148px;
    border-radius: 50%;
    background: white;
  }
}

.carousel-content-text-2 {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 218px;
  width: calc(100% - 74px);
  border-radius: 18px;
  padding-left: 95px;
  position: relative;

  div > p {
    width: 96%;
    height: 110px;
    padding-top: 50px;
    font-size: 12px;
    color: #B4B4B4;
  }

  div > h4 {
    font-size: 20px;
    font-weight: $font-weight-regular;
    color: #7F7F7F;
    margin-top: 32px;
  }

  .user-name {
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
  }
}


.carousel-content-3 {
  width: 100%;
  position: absolute;
  margin-top: 246px;
  opacity: 0.6;
  z-index: 5;
  padding: 0 8.5%;
  transition: 0.5s;
}

.carousel-content-image-3 {
  position: absolute;
  padding-top: 55px;
  left: -53.2px;

  img {
    width: 106.4px;
    height: 106.4px;
    border-radius: 50%;
    background: white;
  }
}

.carousel-content-text-3 {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 199.35px;
  width: calc(100% - 53.2px);
  border-radius: 18px;
  padding-left: 95px;
  position: relative;

  div > p {
    width: 96%;
    height: 110px;
    padding-top: 47px;
    font-size: 11px;
    color: #B4B4B4;
  }

  div > h4 {
    font-size: 18px;
    color: #7F7F7F;
    margin-top: 17px;
  }
}

.carousel-content-4 {
  width: 100%;
  position: absolute;
  margin-top: 340px;
  opacity: 0.6;
  z-index: 4;
  padding: 0 12.2%;
  transition: 0.5s;
}

.carousel-content-image-4 {
  position: absolute;
  padding-top: 43px;
  left: -46.97px;

  img {
    width: 93.94px;
    height: 93.94px;
    border-radius: 50%;
    background: white;
  }
}

.carousel-content-text-4 {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 170.35px;
  width: calc(100% - 46.97px);
  border-radius: 18px;
  padding-left: 95px;
  position: relative;

  div > p {
    width: 96%;
    height: 105px;
    padding-top: 35px;;
    font-size: 11px;
    color: #B4B4B4;
  }

  div > h4 {
    font-size: 16px;
    color: #7F7F7F;
  }
}

.carousel-content-5 {
  width: 100%;
  position: absolute;
  margin-top: 420px;
  opacity: 0.6;
  z-index: 3;
  transition: 0.5s;
  padding: 0 16%;
}

.carousel-content-image-5 {
  position: absolute;
  padding-top: 40px;
  left: -40.22px;

  img {
    width: 80.44px;
    height: 80.44px;
    border-radius: 50%;
    background: white;
  }
}

.carousel-content-text-5 {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 141.51px;
  width: calc(100% - 40.22px);
  border-radius: 18px;
  padding-left: 95px;
  position: relative;

  div > p {
    width: 96%;
    height: 80px;
    padding-top: 6%;
    font-size: 10px;
    color: #B4B4B4;
  }

  div > h4 {
    font-size: 14px;
    color: #7F7F7F;
    margin-top: 15px;
  }
}

.carouselContainerCenter {
  height: 238px;
  width: 700px;
  margin-left: 146px;
  position: absolute;
  border: 1px solid black;
  margin-top: 15%;

}

.carousel-content-social-network {
  width: 125px;
  padding-right: 15px;
  padding-top: 15px;
  position: absolute;
  float: right;
  bottom: 27px;
  right: 0;

  .icon-facebook {
    color: #D9D9D9;
    width: 8px;
    height: 16px;
  }

  .icon-instagram {
    color: #D9D9D9;
    width: 16px;
    height: 16px;
    padding-left: 25%;
  }

  .icon-twitter {
    color: #D9D9D9;
    width: 16px;
    height: 13px;
    padding-left: 37%;
  }

  .label-social {
    font-weight: $font-weight-regular;
    font-size: 12px;
    color: #7F7F7F;
    margin: 0;
  }
}

.container-carousel-up-first {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 219.35px;
  width: 631.31px;
  border-radius: 10px;
  margin-right: 90px;

  h4 {
    font-size: 13px;
    color: #7F7F7F;
    margin-top: 17px;
  }

  #textCarouselUpFirst {
    width: 439.83px;
    height: 69.12px;
    margin-left: 16%;
    margin-top: 50px;
    font-size: 12px;
    color: #B4B4B4;
  }
}

.container-carousel-up-second {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 199.28px;
  width: 573.55px;
  border-radius: 10px;
  margin-right: 119px;

  #textCarouselUpSecond {
    width: 399.13px;
    height: 62.8px;
    margin-left: 16%;
    margin-top: 50px;
    font-size: 12px;
    color: #B4B4B4;
  }

  h4 {
    font-size: 13px;
    color: #7F7F7F;
    margin-top: 17px;
  }
}

.container-carousel-down-first {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 219.35px;
  width: 631.31px;
  border-radius: 10px;
  margin-right: 90px;

  #textCarouselDownFirst {
    width: 476.69px;
    height: 75px;
    margin-left: 18%;
    margin-top: 50px;
    font-size: 12px;
    color: #B4B4B4;
  }

  h4 {
    font-size: 13px;
    color: #7F7F7F;
    margin-top: 17px;
  }

}

.container-carousel-down-second {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 213.28px;
  width: 573.55px;
  border-radius: 10px;
  margin-right: 119px;

  #textCarouselDownSecond {
    width: 444.13px;
    height: 62.8px;
    margin-left: 16%;
    margin-top: 50px;
    font-size: 12px;
    color: #B4B4B4;
  }

  h4 {
    font-size: 13px;
    color: #7F7F7F;
    margin-top: 17px;
  }
}

.container-carousel-down-third {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 205.28px;
  width: 515.59px;
  border-radius: 10px;
  margin-right: 145px;

  #textCarouselDownThird {
    width: 400.57px;
    height: 50.6px;
    margin-left: 16%;
    margin-top: 50px;
    font-size: 12px;
    color: #B4B4B4;

    h4 {
      font-size: 13px;
      color: #7F7F7F;
      margin-top: 17px;
    }
  }
}

.carousel-center-text {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.12);
  float: right;
  height: 215px;
  width: 685px;
  border-radius: 10px;
  margin-right: 60px;

  #textCarouselCenter {
    width: 476.69px;
    height: 75px;
    margin-left: 18%;
    margin-top: 50px;
    font-size: 12px;
    color: #B4B4B4;
  }

  h4 {
    font-size: 13px;
    color: #7F7F7F;
    margin-top: 17px;
  }
}

.semi-circle-up {
  border-radius: 100px 100px 0 0;
  height: 27px;
  width: 53px;
  position: absolute;
  background: #FDFDFD;
  top: -27px;
  left: 55%;
  border: 1px solid #D10C0C;

  .up {
    margin-top: 8px;
    margin-left: 19px;
    color: #D8D8D8;
  }
}

.semi-circle-up:hover {
  cursor: pointer;

  a i {
    color: #FDFDFD;
  }
}

.semi-circle-down {
  border-radius: 100px 100px 0 0;
  height: 27px;
  width: 53px;
  position: absolute;
  background: #FDFDFD;
  top: 218px;
  left: 55%;
  transform: rotate(-180deg);
  border: 1px solid #D10C0C;

  .down {
    margin-top: 6px;
    margin-left: 19px;
    transform: rotate(0deg);
    color: #D8D8D8;
  }
}

.semi-circle-down:hover {
  cursor: pointer;

  a i {
    color: #FDFDFD;
  }
}

.navbar-default .navbar-nav > .active > a {
  background-color: transparent;
  border: 1px solid #D10C0C;
  color: #D10C0C;
  border-radius: 20px;
}

.navbar-default .navbar-nav > .active > a:hover {
  background-color: transparent;
}

.navbar-default .navbar-nav > a:hover {
  background-color: transparent;
}

.container-button-get-in-touch-right {
  float: right;
  display: none;
  padding-top: 30px;
}

.contain-form-getintouch {
  @include col100;
}

/****** css for contact* **********/

/****** css for container of all footer********/
.layout-footer {
  @include col100;
  position: relative;

  /****** css for container of footer background ******/
  .container-footer-background {
    padding-top: 0;
    width: 100%;
    height: 330px;
    position: absolute;
    overflow: visible;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    .footer-limiter {
      position: absolute;
      height: calc(100% + (100vh - 102px - 55px));
      width: 2px;
      z-index: 150;
      bottom: 0;
      left: 0;
    }

    &.admin {
      position: unset
    }

    .container-title-easy-nav {
      display: flex;
      align-items: baseline;
      width: 100%;
    }

    .footer-left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: inherit;
      position: relative;
      //padding-right: 80px;
      float: right;
      width: 40%;
      padding-left: 12%;
      padding-right: 5%;


      h2, h3 {
        margin: 0;
      }

      h2 {
        font-weight: $font-weight-bold;
        color: #4D4F5C;
        font-size: 20px;
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 10px;
        color: #373636;
        font-size: 14px;
      }

      input {
        border-radius: 18px;
        height: 40px;
      }

      .content-input {
        position: relative;
        //width: 400px;
        width: 100%;
      }

      .btn-arrow-next {
        @include item-center;
        position: absolute;
        right: 5px;
        top: 3px;
        height: 34px;
        width: 34px;
        border-radius: 18px;
        background-color: #d10b0c;

        .icon-arrow {
          height: 14px;
          width: 14px;
          fill: #ffffff;
        }

        .content-icon-arrow {
          div {
            display: flex;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      .title {
        font-size: 30px;
        color: #4D4F5C;
        font-weight: $font-weight-bold;
      }
    }

    .footer-right {
      display: flex;
      align-items: baseline;
      height: inherit;
      position: relative;
      float: left;
      width: 60%;

      .title-one, .title-two {
        @include item-center;
        min-width: 300px;
        height: inherit;
        flex-direction: column;
        padding-left: 70px;
        align-items: flex-start;
        margin-top: -30px;
        width: 50%;
        padding-right: 15px;
        word-break: break-word;

        .title {
          font-size: 30px;
          color: #4D4F5C;
          font-weight: $font-weight-bold;
        }

        label {
          font-size: 30px;
          color: #4D4F5C;
          font-weight: $font-weight-bold;
        }

        a {
          color: #373636;
          text-decoration: underline;
        }
      }

      .title-one {
        padding-right: 0;
      }

      .title-two {
        padding-left: 55px;
      }
    }

    .footer-background {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.3;
    }

    .footer-bar-bottom {
      position: absolute;
      height: 40px;
      width: 100%;
      bottom: 0;
      background-color: #d10b0c;

      .all-reserved {
        float: left;
        height: inherit;
        @include item-center;
        padding-left: 72px;

        label {
          margin: 0;
          color: #ffffff;
          font-weight: $font-weight-regular;
        }
      }

      .network-social {
        float: right;
        padding-right: 74px;
      }
    }

    .wrapper-instagram, .wrapper-twitter, .wrapper-facebook {
      width: 40px;
      height: 40px;
      float: left;
      @include item-center;

      .icon-instagram, .icon-twitter, .icon-facebook {
        height: 15px;
        fill: #ffffff;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .footer-effect {
      height: 100px;
      position: absolute;
      width: 100%;
    }

    .footer-effect::before {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      content: '';
    }
  }

  .footer-separator {
    height: 13px;
    background-color: #d10b0c;
  }
}


.finance-form-wizard, .finance-form-vertical {
  position: relative;
  top: 130px;
  z-index: 100;
  padding: 0 65px;

  .tab-finance {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    float: left;

    .nav-tabs {
      padding: 10px;
      border-bottom: 1px solid #E2E2E2;
      text-align: center;
    }

    .nav-tabs > li {
      margin-bottom: 0;
      display: inline-block;
      float: none;
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
      border: none;
      color: #D10C0C;
    }

    .nav-tabs > li > a, .nav-tabs > li > a:focus, .nav-tabs > li > a:hover {
      border: none;
      background-color: #fff;
      color: #E2E2E2;
    }

    .tab-content {
      padding: 20px 20px 25px 40px;
      @include col100;

      .panel-finance {
        background-color: #fff;
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        margin-bottom: 0;
        padding-bottom: 0;

        .col-sec-sub {
          padding-bottom: 20px;
        }
      }

      .header-applicant {
        background-color: #fff !important;
        color: black;

        h5 {
          font-weight: $font-weight-bold;
          font-size: 24px;
          color: #7F7F7F;
          margin-bottom: 0;
        }

        label {
          font-weight: 300;
          color: #7F7F7F;
          font-size: 14px;
        }
      }

      .label-error {
        color: #D10C0C;
        font-size: 16px;
        font-weight: 300;
      }

      .btn-finance-dp {
        height: 44px;
        @include col100;
        background-color: #fff;
        border-radius: 22px;
        color: #787878;
        padding: 11px 15px;
        border: 1px #D9D9D9 solid;

        i {
          float: right;
          padding-top: 2px;
          font-size: 16px;
        }

        span {
          display: inline-block;
          width: calc(100% - 25px);
          overflow: hidden;
          white-space: nowrap;
          box-sizing: border-box;
          text-overflow: ellipsis;
        }
      }

      .btn-finance-dp:hover {
        text-decoration: none;
        cursor: pointer;
      }

      .finance-input {
        height: 44px;
        border-radius: 22px;
        border: 1px #D9D9D9 solid;
        width: 100%;
        box-shadow: 0 0 0;

        .MuiInput-underline::before {
          border-bottom: none;
        }

        .MuiInput-underline::after {
          border-bottom: none;
        }

        .MuiSvgIcon-root {
          fill: #515151;
        }

        #dateId {
          font-size: 14px;
          margin-top: 5px;
          margin-left: 10px;
          color: #7F7F7F !important;
        }
      }

      .col-finance {
        @include col100;
        padding-bottom: 5px;
      }

      .colR25, .colR50, .colR80, .colR16, .colR75 {
        padding-right: 20px;
      }

      .colR20 {
        width: 20%;
        float: left;
        padding-right: 20px;
      }

      .has-error {
        .btn-finance-dp {
          color: #a94442;
          border: 1px #a94442 solid;
        }
      }

      .finance-input:focus {
        border: 1px solid #D9D9D9;
        box-shadow: 0 0 0;
      }
    }

    .label-required {
      color: #D10C0C;
      font-size: 16px;
      float: left;
      padding-left: 15px;
      width: 100%;
      text-align: end;

      label {
        font-weight: 300;
      }
    }

    .label-error-message {
      color: #D10C0C;
      font-size: 16px;
      float: left;
      padding-left: 15px;
      width: 100%;

      label {
        font-weight: 300;
      }
    }

    .label-finance {
      color: #7F7F7F;

      label {
        font-weight: 300;
      }
    }

  }

  .finance-icon {
    img {
      height: 24px;
      float: left;
    }

    span {
      padding-left: 10px;
      padding-top: 3px;
      float: left;
    }

    .svg {
      float: left;
    }
  }

  .nav-tabs > li > a > img, .nav-tabs > li > a > img:focus, .nav-tabs > li > a > img:hover {
    opacity: 0.3;
  }

  .finance-btn {
    @include col100;

    .btn-primary {
      border-radius: 30px;
      font-weight: $font-weight-semi-bold;
    }

    .btn-previous {
      float: left;
      background-color: #fff;
      color: #D10C0C;
      border: 1px solid #D10C0C;
      border-radius: 20px;
      font-weight: $font-weight-semi-bold;

      &:focus {
        border: 1px solid #00de8a !important;
      }
    }
  }

  .radio-button-main {
    width: 14.2%;
    float: left;
    padding-right: 20px;

    button {
      height: 44px;
      border-radius: 22px;
      box-shadow: 0 1px rgba(0, 0, 0, 0.09);
      color: #7F7F7F;
      font-weight: $font-weight-semi-bold;
    }

    .radio-button {
      background-color: #D10C0C;
      color: white;
      opacity: 1;
    }
  }

  .typeId {
    min-width: 14.2%;
    width: auto;
  }

  .sec-sub-title {
    h3 {
      margin-bottom: 5px;
      margin-top: 0;
      color: #7F7F7F;
      font-weight: $font-weight-bold;
    }

    label {
      margin-bottom: 20px;
      font-weight: 300;
      font-size: 16px;
      color: #7F7F7F;
    }
  }

  .tab-applicant-sent {
    @include col100;
    text-align: center;
    padding: 80px 20px 20px 0;
    min-width: 500px;

    .col100 {
      min-width: 500px;
    }

    .dot-applicant-sent {
      width: 80px;
      height: 80px;
      background-color: #0fbe2c;
      border-radius: 50%;
      margin-left: 47%;

      i {
        color: #fff;
        font-size: 50px;
        padding-top: 15px;
      }
    }

    h3 {
      color: #7F7F7F;
    }

    h5 {
      color: #7F7F7F;
      font-weight: 300;
    }

    .btn-home {
      background-color: #fff;
      color: #0FBE2C;
      border: 1px solid #0FBE2C;
      border-radius: 20px;
      width: 100px;
    }

    .col-print {
      @include col100;
      padding: 20px 0 50px 0;
      color: #E2E2E2;

      .col-50-left {
        float: left;
        width: 50%;
        border-right: 1px solid #E2E2E2;

        i {
          float: right;
          padding-right: 10px;
          font-size: 18px;
        }

        span {
          float: right;
          padding-right: 20px;
        }
      }

      .col-50-right {
        float: left;
        width: 50%;

        i {
          float: left;
          padding-left: 20px;
          padding-right: 10px;
          font-size: 18px;
        }

        span {
          float: left;
        }
      }
    }
  }

  .finance-tab-icon {
    height: 24px;
  }

  .radio-button-select {
    display: none;
    width: 100%;
    padding-right: 10px;
  }

  .content-check {
    @include col100;
    padding-top: 5px;

    .term-condition {
      span {
        font-weight: 300;
      }

      a {
        padding-left: 5px;
        text-decoration: underline;
        font-style: oblique;
        font-weight: $font-weight-regular;
        color: #1987B0;
      }
    }
  }
}

.layout-inventory {
  display: block;
  padding: 0;

  #carCarouselBody {
    margin: 0;
    padding: 0;
  }

  .layout-car-body {
    float: left;
    width: 80%;
    padding-top: 30px;
  }

  .layout-car-bg {
    position: absolute;
  }
}

.layout-car {
  .inventory {
    top: 0;
    float: left;
    padding: 0;
    display: block;

    .car-carousel {
      padding-bottom: 30px !important;
    }
  }

  .layout-car-body {
    .car-item-details {
      display: none;
      position: absolute;
      @include col100;
      top: 0;
      height: 100%;
      background-color: #D10C0C;
      opacity: 0.68;
      border-radius: 7px;
      padding: 0;
      align-items: center;
      justify-content: center;

      .row-details {
        text-align: center;
        justify-content: center;
        @include col100;
        color: #FDFDFD;
        padding: 0;
      }

      .btn-car-content {
        float: none;
        display: inline-block;
      }

      .btn-car-info {
        border: 1px #FDFDFD solid;
        color: #FDFDFD;
      }

      .btn-details {
        background-color: #fff;
        color: #D10C0C;
        border-radius: 20px;
        min-width: 100px;
        font-weight: $font-weight-semi-bold;
      }

      .btn-content-details {
        padding-top: 0;
      }
    }
  }
}

.row-description-item-car {
  label:hover {
    cursor: pointer;
  }
}

.car-carousel {
  @include col100;
}

.layout-car-bg {
  padding: 0 65px 0 65px;
  top: -600px;
  position: relative;
  @include col100;
}

#carCarouselBody {
  .semi-circle-carousel {
    width: 30px;
    height: 60px;
    border-color: #D10C0C;
    border-style: solid;
    background: #fff;
    top: 0;
    @include item-center;

    i {
      color: #D8D8D8;
      font-size: 24px;
      position: relative;
      top: 0;
    }
  }

  .sc-left {
    -moz-border-radius: 60px 0 0 60px;
    -webkit-border-radius: 60px 0 0 60px;
    border-radius: 60px 0 0 60px;
    right: 0;
    border-width: 2px 0 2px 2px;

    i {
      right: 0;
    }
  }

  .sc-right {
    -moz-border-radius: 0 60px 60px 0;
    -webkit-border-radius: 0 60px 60px 0;
    border-radius: 0 60px 60px 0;
    border-width: 2px 2px 2px 0;
    left: 0;

    i {
      left: 0;
    }
  }

  .col-car1 {
    @include col100;
    display: flex;
    padding: 0 10px;
  }

  .col-car2 {
    width: 50%;
    float: left;
    padding-right: 20px;
    display: flex;
  }

  .col-car3 {
    width: 33.33%;
    float: left;
    padding-right: 20px;
    display: flex;
  }

  .col-car4 {
    width: 25%;
    float: left;
    padding-right: 20px;
    display: flex;
  }

  .car-item {
    border: 0;
    border-radius: 11px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;

    .container-car-image {
      height: auto;
      background-color: #ffffff;
      border-radius: 11px;
      @include item-center;

      img {
        max-width: 100%;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }


    .container-car-image-sold-carousel {
      @include container-car-image;
      @include container-car-image-sold;

      img {
        border-radius: 8px;
      }

      &:before {
        border-radius: 8px;
      }
    }
  }

  .car-item-details {
    display: none;
    position: absolute;
    @include col100;
    height: 100%;
    background-color: #D10C0C;
    opacity: 0.68;
    border-radius: 10px;
    padding: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .row-details {
      text-align: center;
      justify-content: center;
      @include col100;
      color: #FDFDFD;

      h5 {
        margin: 0;
      }
    }

    .btn-car-content {
      float: none;
      display: inline-block;
    }

    .btn-car-info {
      border: 1px #FDFDFD solid;
      color: #FDFDFD;
      border-radius: 11px;
    }

    .btn-details {
      background-color: #fff;
      color: #D10C0C;
      border-radius: 20px;
      min-width: 100px;
      font-weight: $font-weight-semi-bold;
    }

    .btn-content-details {
      padding-top: 0;
    }
  }

  .car-item:hover {
    cursor: pointer;

    .car-item-details {
      display: flex;
    }
  }

  .row-carousel {
    padding: 0 30px 0 50px;
    display: flex;
  }

  .carousel-control.left, .carousel-control.right {
    width: 50px;
    opacity: 1;
    padding: 0;
    background: transparent;
    top: 0;
    display: flex;
    align-items: center;
  }

  .carousel-control.left {
    left: 0;
    justify-content: flex-end;
  }

  .carousel-control.right {
    right: 0;
    justify-content: flex-start;
  }

  .carousel-indicators {
    display: none;
  }
}


.footer-gradient {
  background-color: #fff;
  height: 100px;
  position: absolute;
  top: 0;
}

.car-search-keyboard {
  @include col100;

  .col-keyboard {
    @include col100;
    position: relative;

    input {
      border-radius: 44px;
      height: 44px;
      padding-left: 40px;
    }

    .icon-search {
      fill: #7F7F7F;
      width: 16px;
      position: absolute;
      top: 15px;
      z-index: 1;
      left: 15px;
    }
  }

  .col-filter {
    width: 25%;
    padding-right: 30px;
    float: left;

    input {
      border-radius: 44px;
      height: 44px;
      padding-left: 20px;
    }
  }

  .btn-search {
    @include col100;
    padding-top: 10px;

    button {
      width: 100%;
      color: #fff;
      background-color: #D10C0C;
      border-color: #D10C0C;
      border-radius: 44px;
      height: 44px;
    }
  }

  .btn-more {
    width: 40%;
    float: left;
    padding-left: 20px;

    button {
      width: 100%;
      color: #D10C0C;
      background-color: #fff;
      border-radius: 44px;
      height: 44px;
      border: 1px solid #D10C0C;
    }

    .icon-add {
      fill: #D10C0C;
      width: 12px;
    }

    .wrapper-icon {
      display: inline-block;
      padding-right: 5px;
    }
  }

  .col-menu {
    @include col100;
    padding-bottom: 10px;

    .wrapper-icon-menu {
      float: right;
      padding-left: 10px;
    }

    .icon-menu {
      fill: #777777;
      height: 16px;
    }

    label {
      color: #7F7F7F;
      font-weight: $font-weight-semi-bold;
    }

    .icon-list, .icon-grid {
      float: right;
      margin-left: 6px;
      cursor: pointer;
      width: 20px;

      .icon-list-unselected {
        width: 20px;
        fill: #7F7F7F;
      }

      .icon-list-selected {
        width: 20px;
        fill: RED;
      }

      .icon-grid-unselected {
        width: 21px;
        fill: #7F7F7F;
      }

      .icon-grid-selected {
        width: 21px;
        fill: RED;
      }
    }
  }

  .btn-filter {
    width: 50%;
    float: left;
    padding-top: 10px;
    display: none;

    .button-filter {
      width: 100%;
      color: #D10C0C;
      background-color: #FFFFFF;
      border: 1px solid #D10C0C;
      border-radius: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .button-filter:hover {
      cursor: pointer;
    }
  }
}

.separator-header {
  height: 30px;
  background-color: transparent;
  position: absolute;
  @include col100;
  z-index: 200;
}


.inv-car-view {
  position: relative;
  top: 64px;
  width: 100%;
  z-index: 100;

  .inv-car-content {
    padding: 0 65px;
    padding-bottom: 65px;
    @include col100;
  }

  .inv-car-box {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    @include col100;
    margin-bottom: 60px;

    .inv-car-header {
      @include col100;
      padding: 15px 0 10px 15px;
      border-bottom: 0.5px solid #7F7F7F;

      .left {
        float: left;
      }

      .right {
        float: right;
      }

      .image-style {
        height: 16px;
        fill: #E2E2E2;
      }

      .image-style:hover {
        cursor: pointer;
      }

      .wrapper {
        float: left;
        padding-right: 15px;
      }
    }

    .inv-car-body {
      @include col100;
    }

    .row-inv-car {
      @include col100;

      .inv-car-img {
        float: left !important;
        display: flex !important;
        align-items: center !important;
        padding-top: 10px;
        padding-bottom: 15px;

        .carousel-control {
          background-image: none;
        }

        .carousel-indicators {
          display: none;
        }
      }

      .inv-car-info {
        width: 64%;
        float: left;
        padding: 10px 30px 0 30px;

        .car-title {
          float: left;
          color: #7F7F7F;
          font-weight: $font-weight-bold;
          margin-top: 0px;
          font-size: 30px;
        }

        .car-status {
          float: right;
          background-color: #313030;
          height: 38px;
          border-radius: 20px;
          min-width: 100px;
          padding: 12px 0;
          text-align: center;
          font-size: 11px;
          color: #fff;
        }

        .car-contact {
          border-radius: 30px;
          color: #fff;
          font-weight: 600;
        }

        .row-info {
          @include col100;
          display: flex;
          align-self: stretch;
          align-items: center;
          height: auto;

          .info-car-details {
            display: flex;
            align-items: center;

            h3 {
              font-size: 30px;
              font-weight: $font-weight-bold;
              padding-right: 10px;
            }

            .sold-tag {
              background-color: #FFF;
              color: red;
              max-width: 100%;
              padding: 2px 6px;
              border-radius: 4px;
              font-weight: bold;
              font-size: 12px;
              border: 1px solid #0a0c0e;
              margin-left: 10px;
              margin-right: 10px;
            }
          }

          .row-info-price {
            margin-left: auto;
            display: flex;
            height: 35px;
          }

          .price {
            font-size: 30px;
            color: #0FBE2C;
            display: flex;
            height: 35px;
            align-self: stretch;
            align-items: flex-end;
          }

          .price-banner {
            font-size: 32px;
            color: grey;
            text-decoration: line-through;
          }

          .price-old {
            font-size: 18px;
            color: #D10C0C;
            font-weight: 300;
            padding-left: 15px;
            text-decoration: line-through;
          }
        }

        .row-info-details {
          float: left;
          width: 70%;
          padding-top: 10px;

          .col-50 {
            width: 50%;
            float: left;
            padding-right: 20px;
          }

          .dot-color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            float: left;
            margin-left: 10px;
          }
        }

        .row-info-send-message {
          .container-send-message-mobile {
            display: none;
          }

          .container-plus {
            display: none;
          }

          float: left;
          width: 92%;
          padding-top: 10px;

          .get-it-touch {
            font-size: 24px;
            font-weight: $font-weight-bold;
            padding: 5px 0;
          }

          label {
            width: 100%;
            color: #7F7F7F;
            font-size: 15px;
            font-weight: 300;
            padding-left: 15px;
          }

          input:focus {
            outline: none;
          }

          input {
            width: 100%;
            border-radius: 20px;
            border: 1px solid #D9D9D9;
            height: 44px;
            padding-left: 15px;
          }

          .sample-error-input {
            border: 1px solid red !important;
            width: 100%;
          }
          .dp-text-input-error-message{
            padding-left: 15px;
            margin: 0;
          }

          .container-name, .container-email, .container-phone, .container-message {
            float: left;
            width: 50%;
            padding: 0 5px;
          }

          .second-row-send-message {
            @include col100;
            padding: 10px 5px 20px;
          }

          .third-row-send-message {
            float: right;
          }
        }

        .box-info {
          display: flex;
          align-items: center;
          height: 44px;
          border: 1px solid #D9D9D9;
          min-width: 140px;
          border-radius: 22px;
          padding: 8px 10px;
          color: #7F7F7F;
          margin-bottom: 3%;

          .wrapper {
            float: left;
            padding-left: 10px;
            padding-top: 5px;

            svg {
              height: 18px;
              width: 18px;
            }
          }

          .wrapper-set {
            float: left;
            padding-left: 10px;
            padding-top: 3px;
          }

          .image-style-height {
            height: 16px;
            fill: #7F7F7F;
          }

          .image-style-width {
            width: 18px;
            fill: #7F7F7F;
          }
        }

        .label-car-info {
          padding: 0 0 0 10px;
          float: left;
          flex: 2;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .label-color {
          text-align: center;
          margin-bottom: 0;
          padding-left: 8px;
          font-weight: 500;
        }

        .inv-car-foot {
          @include col100;
          margin: 30px 0 0 0;
          border-top: 0.5px solid #7F7F7F;

          .alert {
            margin-bottom: 0;
            margin-top: 20px;
          }
        }
      }


      #carCarouselTwoWebView, #carCarouselTwo {
        width: 17%;
        float: left;

        .container-car-image-carousel {
          img {
            border-radius: 5px;
            width: 95px;
            height: 100%;
            object-fit: cover;
          }
        }

        .carousel-control.left {
          position: relative;
          left: 60%;
          opacity: 1;
          height: 0;
        }

        .carousel-control.right {
          position: absolute;
          right: 60%;
          opacity: 1;
          float: left;
          height: 0;
        }

        .semi-circle-carousel {
          width: 90px;
          height: 45px;
          border-color: #D10C0C;
          border-style: solid;
          background: #fff;
          position: absolute;

          i {
            color: #D8D8D8;
            font-size: 24px;
            position: relative;
            top: 5px;
          }
        }

        .sc-left {
          -moz-border-radius: 0 0 60px 60px;
          -webkit-border-radius: 0 0 60px 60px;
          border-radius: 0 0 60px 60px;
          right: -1px;
          border-width: 1px;

          i {
            right: 0;
          }
        }

        .sc-right {
          -moz-border-radius: 60px 60px 0 0;
          -webkit-border-radius: 60px 60px 0 0;
          border-radius: 60px 60px 0 0;
          border-width: 1px;
          left: -1px;

          i {
            left: 0;
          }
        }

        .carousel-inner > .item {
          -webkit-transition: -webkit-transform .6s ease-in-out;
          -o-transition: -o-transform .6s ease-in-out;
          transition: transform .3s ease-in-out;
        }

        .carousel-inner {
          .container-car-image-carousel {
            margin: 0 0 10px 0;
            background-color: transparent;
            display: block;
          }
        }
      }

      #carCarouselTwoWebView {
        .carousel-control.left {
          align-items: flex-end;
          display: flex;
          top: auto;
          bottom: 0;
          position: absolute;
        }

        .carousel-inner {
          height: 100%;
          width: auto;
          @include item-center;

          > .item {
            width: auto;
            transition: 0.6s ease-in-out top;
            @media all and (transform-3d), (-webkit-transform-3d) {
              transition: 0.6s ease-in-out;
              &.next,
              &.active.right {
                transform: translate3d(0, 100%, 0);
                top: 0;
              }
              &.prev,
              &.active.left {
                transform: translate3d(0, -100%, 0);
                top: 0;
              }
              &.next.left,
              &.prev.right,
              &.active {
                transform: translate3d(0, 0, 0);
                top: 0;
              }
            }
          }

          > .active,
          > .next,
          > .prev {
            display: block;
          }

          > .active {
            top: 0;
          }

          > .next,
          > .prev {
            position: absolute;
            top: 0;
            width: 100%;
          }

          > .next {
            top: 100%;
          }

          > .prev {
            top: -100%;
          }

          > .next.left,
          > .prev.right {
            top: 0;
          }

          > .active.left {
            top: -100%;
          }

          > .active.right {
            top: 100%;
          }
        }
      }

      #carCarouselOne {
        width: 83%;
        float: right;
        display: flex;
        align-items: center;

        .container-car-image {
          height: auto;
          background-color: transparent;
          display: flex;
          justify-content: center;

          .banner-sold-car {
            position: absolute;
            width: inherit;
            background-color: red;
            border-radius: 10px 10px 0 0;
            color: #FFF;
            font-weight: 600;
            padding: 10px;
            font-size: 17px;
          }

          .car-image {
            max-width: 100%;
            border-radius: 10px !important;
            object-fit: contain;
          }

          .car-pagination {
            position: absolute;
            color: #fff;
            bottom: 15px;
            left: 20px;
            font-size: 20px;
          }
        }
      }

      .car-detail-extra {
        float: left;
        padding-right: 20px;

        .btn-car-extra {
          float: left;
          background-color: #fff;
          height: 38px;
          border-radius: 20px;
          min-width: 150px;
          padding: 12px 0;
          text-align: center;
          font-size: 11px;
          font-weight: $font-weight-semi-bold;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .row-inv-car-top {
      padding: 20px 20px 0 20px;
    }

    .inv-car-des {
      @include col100;
      background-color: #F8F8F8;
      padding: 20px 30px 30px 30px;
      border-radius: 0 0 4px 4px;
      margin-top: 15px;

      .car-des {
        width: 50%;
        float: left;
        padding-left: 2%;

        h3 {
          color: #7F7F7F;
          font-size: 26px;
          font-weight: $font-weight-bold;
        }

        label {
          font-weight: normal;
          color: #B4B4B4;
          text-align: justify;
        }

        .inventory-description-wrapper {
          overflow-y: auto;
          padding-right: 40px;
          height: inherit;
          max-height: 730px;
        }
      }

      .car-feature {
        float: right;
        width: 400px;
        margin-right: 100px;
        display: table;

        .row-specifications-one {
          box-sizing: border-box;
          display: table-row;
          vertical-align: inherit;
          border-color: inherit;

          .cell-specifications-right, .cell-specifications-left {
            background-color: #FBFBFB;
          }
        }

        .row-specifications-two {
          box-sizing: border-box;
          display: table-row;
          vertical-align: inherit;
          border-color: inherit;

          .cell-specifications-right, .cell-specifications-left {
            background-color: #FFFFFF;
          }
        }

        @mixin cell-specifications() {
          padding: 8px 0 8px 10px !important;
          width: 200px;
          color: #B4B4B4;
          border: 4px solid #F8F8F8;
        }

        .cell-specifications-not {
          padding: 8px 0 8px 10px !important;
          width: 200px;
          color: #B4B4B4;
          border: 4px solid #F8F8F8;
        }

        .cell-specifications-right {
          @include cell-specifications;
          vertical-align: inherit;
          display: table-cell;
        }

        .cell-specifications-left {
          @include cell-specifications;
          vertical-align: inherit;
          display: table-cell;
        }

        .cell-specifications-not {
          font-style: oblique;
        }
      }
    }

    .inv-car-calculator{
      @include col100;
      margin: 15px 0 0 0;
      background-color: #F8F8F8;
      padding: 20px 30px 30px 30px;
      border-radius: 0 0 4px 4px;

      .container-right-calculate {
        width: 60%;
        border-right: 1px solid #E2E2E2;
        float: left;
        padding-right: 50px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          display: none !important;
          -webkit-appearance: none !important;
          margin: 0
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }

        label {
          width: 100%;
          color: #7F7F7F;
          font-size: 15px;
          font-weight: 300;
          padding-left: 15px;
        }

        input:focus {
          outline: none;
        }

        input {
          width: 100%;
          border-radius: 20px;
          border: 1px solid #D9D9D9;
          height: 44px;
          padding-left: 15px;
        }

        .first-row {
          @include col100;
          padding-bottom: 20px;

          .container-price-vehicle, .container-interest-rate, .container-months {
            float: left;
            width: 33.3%;
            padding: 0 5px;

            input {
              width: 100%;
              background-color: white;
            }
          }

          .container-price-vehicle {
            input {
              //color: #97979787 !important;
            }
          }

          .container-interest-rate {
            float: left;
            width: 33.3%;
            padding: 0 5px;

            input {
              width: 100%;
            }
          }

          .container-months {
            float: left;
            width: 33.3%;
            padding: 0 5px;

            input {
              width: 100%;
            }
          }
        }

        .second-row {
          @include col100;
          padding-bottom: 5px;

          .container-payment-amount {
            float: left;
            width: 33.3%;
            padding: 0 5px;

            input {
              width: 100%;
            }
          }

          .container-amount-for-trade {
            float: left;
            width: 33.3%;
            padding: 0 5px;

            input {
              width: 100%;
            }
          }
        }

        .third-row {
          .container-button-calculate {
            @include col100;
            padding: 25px 5px;

            .btn-calculate {
              color: #fff;
              border-radius: 30px;
              font-weight: 600;
            }

            input {
              width: 100%;
            }
          }
        }


        .fourth-row {
          float: left;
          padding-bottom: 20px;
          width: 33.3%;

          .container-monthly-payment {
            padding: 0 5px;

            input {
              background-color: white;
            }
          }
        }
      }
      .no-finance{
        border-right: none;
      }
      .container-left-apply {
        width: 40%;
        float: right;
        padding: 0 100px 0 50px;

        .container-get-finance {
          width: 100%;
          padding-bottom: 20px;

          label {
            color: #7F7F7F;
            font-size: 30px;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
            padding-left: 0;
          }

          p {
            color: #7F7F7F;
            font-size: 14px;
            font-weight: $font-weight-light;
          }
        }

        .container-apply-online {
          width: 100%;
          padding-bottom: 20px;

          .icon-search {
            height: 35px;
            fill: #7F7F7F;
            width: 10%;
            float: left;
          }

          label {
            color: #7F7F7F;
            font-size: 21px;
            font-weight: $font-weight-regular;
            padding-left: 15px;
          }
        }

        .container-choose-car {
          @include col100;
          padding-bottom: 20px;

          .icon-search {
            height: 35px;
            fill: #7F7F7F;
            width: 10%;
            float: left;
          }

          label {
            color: #7F7F7F;
            font-size: 21px;
            font-weight: 500;
            padding-left: 15px;
          }
        }

        .container-sign-go {
          @include col100;

          .icon-search {
            height: 35px;
            fill: #7F7F7F;
            width: 10%;
            float: left;
          }

          label {
            color: #7F7F7F;
            font-size: 21px;
            font-weight: 500;
            padding-left: 15px;
          }
        }

        .container-button-apply {
          padding: 40px 0 30px 0;
          @include col100;

          .btn-apply {
            color: #fff;
            border-radius: 30px;
            font-weight: 600;
          }
        }
      }
    }

  }

  .car-carousel {
    position: relative;
    top: -15px;
    padding-bottom: 70px;
    margin-bottom: 60px;

    .container-car-image {
      //height: auto;
      //background-color: transparent;
      @include container-car-image;
      border-radius: 8px;
    }

    #carCarouselBody .col-car4 {
      .car-item .container-car-image img {
        border-radius: 10px;
        width: 100%;
      }

      .car-item-details .btn-car-info {
        border-radius: 30px;
        font-size: 11px;
        padding: 3px 10px;
      }

      .car-item-details .btn-car-content {
        float: none;
        display: inline-block;
        padding-right: 10px;
      }

      .container-car-image-sold-carousel {
        @include container-car-image;
        @include container-car-image-sold;

        img {
          border-radius: 8px;
        }

        &:before {
          border-radius: 8px;
        }
      }
    }

  }
}

.layout-inventory-container {
  height: inherit;

  #carCarouselBody {
    margin: 0;
    padding: 0;
  }

  .layout-car {
    padding: 0;
    @include col100;
  }

  .layout-car-body {
    float: left;
    width: 80%;
    padding-top: 50px;
    padding-bottom: 30px;

    .message-not-found {
      background-color: #e5e9ec;
      height: 40px;

      h3 {
        padding-top: 7px;
        margin-left: 2%;
        color: #cbcccc;
      }
    }

    .list-view-item {
      @include col100;
      text-align: center;
      border: 1px solid #E2E2E2;
      border-radius: 10px;

      .custom-image {
        border-radius: 9px 0 0 9px;
        cursor: pointer;
      }

      .detailed-info-container {
        width: 75%;
        float: left;
        height: 100%;
        position: relative;

        .general-info-section {
          width: 33.33%;
          float: left;
          padding-left: 15px;
          margin-bottom: 5px;
          padding-top: 10px;

          .car-description {
            width: 100%;
            display: flex;
            float: left;
            font-size: 30px;
            font-weight: bold;
            text-align: left;
            //color: #1A1A1A;
          }

          .car-price, .price-coming-soon {
            display: flex;
            float: left;
            font-size: 18px;
            font-weight: bold;
            color: #0FBE2C;
            padding-right: 10px;
          }

          .price-coming-soon {
            color: #ff565e;
          }

          .car-mileage {
            display: flex;
            float: left;
            font-weight: bold;
            border-left: 1px solid #E2E2E2;
            padding-left: 10px;

            label {
              width: 100%;
              text-align: right;
              margin: 0;
              font-size: 18px;
            }
          }

          .car-price-mileage {
            position: absolute;
            bottom: 40px;
          }
        }

        .main-mechanical-info-section, .additional-mechanical-info-section {
          width: 33.33%;
          float: left;
          padding-left: 10px;
          padding-top: 20px;

          .sub-title {
            width: 100%;
            display: flex;
            float: left;
            font-size: 12px;
            color: #7F7F7F;
          }

          .sub-title-info {
            width: 100%;
            display: flex;
            float: left;
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
            padding-bottom: 6px;
            //color: #1A1A1A;
          }
        }

        .additional-mechanical-info-section {
          text-align: left;
        }

        .options-section {
          width: 100%;
          float: left;
          border-top: 1px solid #E2E2E2;
          padding: 5px 0;
          position: absolute;
          bottom: 0;
          align-items: center;
          display: flex;

          .apply-option {
            display: flex;
            float: left;
            border-right: 1px solid #E2E2E2;
            padding: 0 15px;
            font-size: 15px;
            color: #7F7F7F;
            cursor: pointer;
            align-items: center;
          }

          .other-option {
            display: flex;
            float: left;
            border-right: 1px solid #E2E2E2;
            padding: 0 10px;
            font-size: 15px;
            color: #7F7F7F;
            cursor: pointer;
            align-items: center;

            a {
              color: #7F7F7F;
            }
          }
        }
      }

      .container-car-image, .container-car-image-sold {
        width: 25%;
        display: flex;
        float: left;
        aspect-ratio: auto 4/3;
        border-radius: 9px 0 0 9px;
      }

      .container-mechanical-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .main-mechanical-info-section {
          width: 50%;
          float: left;
          padding: 10px;

          .sub-title {
            width: 100%;
            display: flex;
            float: left;
            font-size: 8px;
            color: #7F7F7F;
          }

          .sub-title-info {
            width: 100%;
            display: flex;
            float: left;
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            padding-bottom: 6px;
          }
        }

        .additional-mechanical-info-section {
          width: 50%;
          float: left;
          padding: 10px;
          text-align: left;

          .sub-title {
            width: 100%;
            display: flex;
            float: left;
            font-size: 8px;
            color: #7F7F7F;
          }

          .sub-title-info {
            width: 100%;
            display: flex;
            float: left;
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            padding-bottom: 6px;
          }
        }

        .options-section {
          width: 100%;
          float: left;
          padding: 7px 0;
          border-top: 1px solid #E2E2E2;

          .apply-option {
            display: flex;
            float: left;
            padding: 0 15px;
            font-size: 12px;
            color: #7F7F7F;
            cursor: pointer;
            border-right: 1px solid #E2E2E2;
          }

          .other-option {
            display: flex;
            float: left;
            border-right: 1px solid #E2E2E2;
            padding: 0 10px;
            font-size: 12px;
            color: #7F7F7F;
            cursor: pointer;

            a {
              color: #7F7F7F;
            }
          }
        }
      }
    }

  }

  .layout-car-pagination {
    width: 80%;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
    padding-top: 10px;
    padding-bottom: 10px;

    .dp-pagination-container {
      float: right;
    }

    .icon-grid {
      float: right;
      margin-left: 6px;
      cursor: pointer;
      width: 30px;
      margin-top: 5px;
      margin-right: 10px;

      .icon-grid-unselected {
        width: 30px;
        fill: #7F7F7F;
      }

      .icon-grid-selected {
        width: 30px;
        fill: RED;
      }
    }

    .icon-list {
      float: right;
      margin-left: 6px;
      cursor: pointer;
      width: 28px;
      margin-top: 5px;
      margin-right: 10px;

      .icon-list-unselected {
        width: 28px;
        fill: #7F7F7F;
      }

      .icon-list-selected {
        width: 28px;
        fill: RED;
      }
    }
  }

  .layout-car-bg {
    position: relative;
  }

  #layoutCarBg {
    padding-top: 130px;
    top: unset;
  }

  .layout-finance {
    top: 100px;
    @include col100;
  }

  .background {
    margin-top: 0;
  }

}

#id-background {
  height: -webkit-fill-available;
}

.layout-finance-container {

  .finance-form-main {
    #financeForm {
      float: left;
      padding-top: 130px;
      top: unset;
      padding-bottom: 57px;
    }
  }

  #contactMain {
    .get-in-touch-message-box {
      flex: 0 0 50%;
    }
  }
}

@mixin col50() {
  float: left;
  width: 50%;
}

.layout-about {
  @include col100;
  top: 110px;
  position: absolute;

  .goal {
    @include col50;
    padding: 50px 0 50px 5%;

    .box {
      background-color: #fff;
      padding: 30px 50px;
      border-radius: $borderRadiusBox;
      color: #B4B4B4;
      @include col100;

      h3 {
        margin-top: 0;
        color: #4D4F5C;
        font-weight: 900;
        font-size: 32px;
      }

      .operation-left {
        @include col50;

        .day-closed {
          color: #D10C0C;
        }

        .label-day {
          width: 70px;
          float: left;
        }
      }

      .operation-right {
        @include col50;

        .label-location {
          color: #7F7F7F;
          font-weight: 900;
        }

        .label-location-address {
          padding-left: 5px;
        }
      }
    }

    .title {
      color: #fff;
      padding: 20px;

      h1 {
        font-size: 40px;
        font-weight: 900;
      }
    }

    .box-hours-operation {
      display: flex;
      flex-wrap: wrap;

      .title-hours-operations {
        flex: 0 0 100%;
        font-size: 26px;
        padding-bottom: 15px;
      }

      .container-hours-operation, .container-location {
        flex: 0 0 50%;

        span {
          font-weight: 400;
        }
      }

      .container-hours-operation {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .about-testimonial {
    @include col50;

    .layout-customer-testimonial {
      width: 100%;
      height: 230px;

      .container-carousel-testimonials {
        height: 100% !important;
      }

      .carousel-content-0 {
        width: 100%;
        margin-top: 80px;
        padding: 0 40% 0 10%;
      }

      .carousel-content-1 {
        width: 100%;
        margin-top: 55px;
        padding: 0 30% 0 15%;
      }

      .carousel-content-2 {
        width: 100%;
        margin-top: 40px;
        margin-left: 0;
        padding: 0 20%;
      }

      .carousel-content-3 {
        width: 100%;
        margin-top: 60px;
        padding: 0 15% 0 30%;
      }

      .carousel-content-4 {
        margin-top: 80px;
        padding: 0 10% 0 40%;
      }

      .carousel-content-5 {
        display: none;
      }

      .semi-circle-up {
        top: 100px;
        left: 15.5%;
        transform: rotate(270deg);
      }

      .semi-circle-down {
        top: 100px;
        left: 79%;
        transform: rotate(90deg);
        display: block;
      }

      .carousel-content-text-0 {
        height: 100px;
        width: 100%;
        padding-left: 58px;

        div > h4 {
          font-size: 11px;
          margin-top: 0;
          margin-bottom: -14px;
        }

        div > p {
          width: 100%;
          height: 76px;
          padding-top: 5%;
          font-size: 10px;
          overflow: hidden;
        }

        .carousel-title-name {
          height: 2px;
        }
      }

      .carousel-content-text-1 {
        height: 135px;
        width: 100%;
        padding-left: 62px;

        div > h4 {
          font-size: 18px;
        }

        div > p {
          height: 100px;
          padding-top: 6%;
          font-size: 11px;
          overflow: hidden;
        }

        .carousel-title-name {
          height: 20px;
        }
      }

      .carousel-content-text-2 {
        height: 160px;
        width: 100%;
        padding-left: 75px;

        div > p {
          padding-top: 40px;
          font-size: 12px;
          overflow: hidden;
        }

        div > h4 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .carousel-content-text-3 {
        height: 130px;
        width: 100%;
        padding-left: 86px;

        div > p {
          height: 100px;
          padding-top: 30px;
          overflow: hidden;
        }

        div > h4 {
          font-size: 15px;
          color: #7F7F7F;
          margin-top: 5px;
        }

        .carousel-title-name {
          height: 18px;
        }
      }

      .carousel-content-text-4 {
        height: 95px;
        width: 100%;
        padding-left: 49px;

        div > p {
          height: 73px;
          padding-top: 16px;
          font-size: 10px;
          overflow: hidden;
        }

        div > h4 {
          font-size: 12px;
          margin-top: 0;
          margin-bottom: 6px;
        }

        .carousel-title-name {
          height: 2px;
        }
      }

      .carousel-content-image-0 {
        top: -30px;
        padding-top: 0;
        left: -30px;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: white;
        }
      }

      .carousel-content-image-1 {
        top: -30px;
        padding-top: 0;
        left: -30px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: white;
        }
      }

      .carousel-content-image-2 {
        top: -30px;
        left: -30px;
        padding-top: 0;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: white;
        }
      }

      .carousel-content-image-3 {
        padding-top: 30px;
        left: -37.5px;

        img {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          background: white;
        }
      }

      .carousel-content-image-4 {
        padding-top: 20px;
        left: -27.5px;

        img {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background: white;
        }
      }

      .carousel-content-social-network {
        float: right;
        width: 125px;
        padding-right: 10px;
        position: absolute;
        right: 0;
        bottom: 15px;
      }

      .label-experience {
        -webkit-line-clamp: 3;
      }

      .content-up-down {
        top: 35px;
        position: relative;
        left: 0;
      }

      .semi-circle-back, .semi-circle-next {
        margin-top: 70px;
      }
    }
  }

  .item {
    @include col100;
    padding: 15px 0 0 0;
    display: flex;

    .box-item {
      background-color: #fff;
      border-radius: $borderRadiusBox;
      @include col100;
      display: flex;

      .icon-style {
        height: 20px;
        fill: #D10C0C;
      }

      .wrapper {
        float: left;
        padding: 20px;

        .icon-style {
          width: 20px !important;
        }
      }

      .label-value {
        padding: 15px 20px 15px 0;
        margin: 0;
        color: #7F7F7F;
        font-size: 20px;
        font-weight: $font-weight-bold;
        word-break: break-word;
      }
    }

    .box:hover {
      background-color: #D10C0C;
      cursor: pointer;

      .icon-style {
        fill: #fff;
      }

      .label-value {
        color: #fff;
      }
    }

    .row-item {
      @include col100;
      padding-bottom: 15px;

      .col-item-left, .col-item-right {
        height: 100%;

        .box-item {
          height: 100%;
          align-items: center;

          .wrapper {
            > div {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    @mixin col-item-left() {
      float: left;
      width: 50%;
      padding-right: 10px;
    }

    .col-item-left {
      @include col-item-left;
    }

    .col-item-right {
      @include col-item-left;
      padding: 0 0 0 10px;
    }
  }
}

.layout-about-container {
  position: relative;

  .car-carousel {
    top: 0;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background: inherit;

    .container-car-image {
      @include container-car-image;
      border-radius: 8px;
    }

    .container-car-image-sold-carousel {
      @include container-car-image;
      @include container-car-image-sold;
      border-radius: 8px;

      img {
        border-radius: 8px;
      }

      &:before {
        border-radius: 8px;
      }
    }
  }

  .about-info {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-top: 30px;

    .row-info {
      display: inline-block;
      padding: 20px;
    }

    .label-info {
      font-size: 52px;
      color: #D10C0C;
      background-color: transparent;
      width: 100%;
      margin: 0;
    }

    .label-data {
      font-size: 24px;
      font-weight: 300;
      color: #7F7F7F;
    }
  }

  #carCarouselBody .col-car4 {
    .car-item-details .btn-car-content {
      padding-right: 10px;
    }

    .car-item-details .btn-car-info {
      border-radius: 30px;
      padding: 3px 10px;
    }
  }

  .banner-red-social {
    bottom: 250px;
  }

  .about-background{
    position: relative;
    padding: 100px 50px 50px 50px;
    @include item-center;
    img{
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      height: 100%;
    }
    .about-us-goal{
      position: relative;
      z-index: 2;
    }
    
    @media screen and (max-width: 768px) {
      padding: 70px 20px 20px 20px;
    }
  }

  .carousel-dealer-images-container, .testimonials-container{
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px 130px;


  }

  .dealer-image-item{
    width: 100%;
    aspect-ratio: calc(100/65);
    border-radius: 10px;
  }

  // testimonial item
  .testimonial-header {
    text-align: left;
    padding: 20px 130px;
  }
  .section-testimonial {
    border-radius: 20px;
    height: 90%;
    width: 100%;
    padding: 25px;
  }

  .row-customer {
    height: 100%;
    display: grid;
    grid-gap: 0;
    grid-template-rows: 4fr 1fr;
    grid-template-columns: 1fr 4fr;
    justify-content: center;
    align-items: start;
    justify-items: start;
    padding: 0;

    .item-a {
      grid-row: 1/2;
      grid-column: 1/3;
      width: 100%;
      height: 100%;
    }

    .item-b {
      grid-row: 2/3;
      grid-column: 1/2;
      align-self: center;
    }

    .item-c {
      grid-row: 2/3;
      grid-column: 2/3;
      padding-right: 10px;
      align-self: center;
      padding-left: 5px;
    }

    .testimonial-content-5 {
      font-family: 'Inter', sans-serif;
      margin: auto;
    }
    .testimonial-content-5.item-a {
      font-family: 'Inter', sans-serif;
      font-size: 1vw;
      font-weight: $font-weight-regular;
      .text-container {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        @media screen and (max-width: 1200px) {
          -webkit-line-clamp: 5;
          line-clamp: 5;
        }
        @media screen and (max-width: 1024px) {
          -webkit-line-clamp: 4;
          line-clamp: 4;
        }
        @media screen and (max-width: 430px) {
          -webkit-line-clamp: 6;
          line-clamp: 6;
        }
      }
    }
    .item-c {
       font-weight: $font-weight-semi-bold;
     }
  }


    .content-image {
    max-width: 108px;
    max-height: 108px;
    margin: auto;

    img {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      @media screen and (max-width: 1200px) {
        height: 40px;
        width: 40px;
      }
      @media screen and (max-width: 1024px) {
        height: 35px;
        width: 35px;
      }
    }
  }


  .container-label-name {
    .label-name {
      font-family: 'Inter', sans-serif;
      font-size: 1vw;
      font-weight: $font-weight-semi-bold;
      margin: 0;
      padding-right: 0;
    }
  }

  .container-label-social-network {
    .label-social {
      font-family: 'Inter', sans-serif;
      font-size: 0.9vw;
      font-weight: $font-weight-regular;
      color: #CACACA;
      text-transform: capitalize;
    }
  }

  .container-label-info {
    .label-info {
      background-color: transparent;
      font-size: 11px;
      font-weight: 200;
    }
  }

  @media screen and (max-width: 1024px){
    .carousel-dealer-images-container, .testimonials-container {
      padding: 30px 40px 20px 40px;
    }
    .testimonial-header {
      padding: 10px 40px 0px 40px;
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 15px;
        margin: 0;
      }
    }
    .testimonials-container {
      .row-customer {
        .item-a {
          font-size: 15px;
        }
      }
    }
    .container-label-name {
      text-align: left;
      .label-name {
        font-size: 14px;
      }

      .label-social {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .section-testimonial {
      padding: 20px;
    }
    .carousel-dealer-images-container, .testimonials-container {
      padding: 20px;
    }
    .testimonial-header {
      padding: 20px;
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 15px;
      }
    }
    .testimonials-container {
      .row-customer {
        grid-template-columns: 1fr 6fr;
        .item-a {
          font-size: 15px;
        }
      }
    }
    .container-label-name {
      text-align: left;
      .label-name {
        font-size: 14px;
      }

      .label-social {
        font-size: 14px;
      }
    }
  }
}

#carCarouselBody {
  .container-car-image {
    img {
      width: 100%;
    }
  }
}

.finance-form-vertical {
  position: relative;
  float: left;

  .view-vertical {
    .tab-content {
      border-bottom: 1px solid #E2E2E2;
    }

    .content-check {
      padding: 20px 0 20px 55px;
    }

    .finance-btn {
      padding: 0 0 40px 0;
      text-align: center;

      .flex-row {
        justify-content: center;

        .btn-submit {
          padding: 12px 30px;
          float: none;
          font-weight: $font-weight-semi-bold;
        }
      }
    }
  }
}

.finance-view-vertical {
  position: relative;
  @include col100;
  //top: 200px;
}

.layout-car-filter {
  float: left;
  width: 20%;
  padding: 60px 0 0 30px;
}

@mixin btn-search-advance() {
  border-radius: 30px;
  color: #ffffff;
  font-weight: $font-weight-semi-bold;
}

@mixin car-row-filter() {
  @include col100;
  padding: 10px 5px 0 0;

  .btn-search-advance {
    @include btn-search-advance;
  }

  .btn-clean-filter {
    @include btn-search-advance;

    &:focus {
      border: 1px solid !important;
    }
  }

  .input-advance-search::placeholder {
    color: #8b8b8b;
    font-weight: $font-weight-bold;
  }
}

.car-search-advance {
  @include col100;

  .car-row-filter, .car-row-filter-condition {
    @include col100;
    padding: 10px 5px 0 0;
    @mixin btn-search-advance() {
      width: 100%;
      border-radius: 22px;
      height: 44px;
      color: #ffffff;
      font-weight: $font-weight-semi-bold;
    }

    .btn-clean-filter {
      @include btn-search-advance;

      &:focus {
        border: 1px solid !important;
      }
    }

    .input-advance-search::placeholder {
      color: #8b8b8b;
      font-weight: $font-weight-bold;
    }

  }

  .car-row-clean-filters {
    @include car-row-filter;
    width: 49%;
    margin-right: 2%;

    .btn-clean-filter {
      width: 100%;
      letter-spacing: normal;
    }
  }

  .car-row-apply-filters {
    @include car-row-filter;
    width: 49%;
    padding-bottom: 20px;

    .btn-search {
      width: 100%;
    }

    .btn-search-advance {
      width: 100%;
      letter-spacing: normal;
    }
  }

  .input-advance-search {
    width: 100%;
    border-radius: 22px;
    padding: 10px 20px;
    height: 44px;
    border: 1px solid #bfbfbf;
    outline: none;
  }

  .car-box-dp {
    border-radius: 22px;
    padding: 10px 20px;
    height: 44px;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    border-left: 1px solid #bfbfbf;
    border-top: 1px solid #bfbfbf;
    font-weight: 600;

    .title {
      color: #8b8b8b;
      margin: 0;
      font-weight: $font-weight-semi-bold;
    }

    i {
      float: right;
      color: #8b8b8b;
      font-size: 16px;
      padding-top: 3px;
    }

    i:hover {
      cursor: pointer;
    }

    .number-selected {
      float: right;
      color: #8b8b8b;
      margin: 0;
      font-weight: $font-weight-regular;
      padding-right: 5px;
    }
  }

  .car-box-dp:hover {
    cursor: pointer;
  }

  .car-box-collapse {
    border-bottom: none;
    border-radius: 22px 22px 0 0;
  }

  .car-box-detail {
    border-radius: 0 0 22px 22px;
    border-top: none;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    border-left: 1px solid #bfbfbf;

    .dp-select-options-list {
      max-height: 148px;
      overflow: auto;
    }

    .dp-select-clear-button {
      padding-top: 0px;
    }

    .title {
      color: #8b8b8b;
    }
  }

  .content-check, .content-radio {
    @include col100;
  }

  .car-box-scroll {
    overflow: auto;
    @include col100;
    height: 175px;
  }

  .car-box-detail-empty {
    border-radius: 0 0 22px 22px;
    padding: 0 10px 10px 20px;
    height: 40px;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    border-left: 1px solid #bfbfbf;

    .title {
      color: #8b8b8b;
    }
  }

  .car-box-scroll-empty {
    overflow: auto;
    @include col100;
    height: 30px;
  }

  .car-input-number-from, .car-input-number-to {
    width: 35%;
    position: relative;

    input {
      border-radius: 22px;
      height: 44px;
      padding-left: 14px;
    }

    i {
      position: absolute;
      top: 15px;
      left: 15px;
      color: #8b8b8b;
    }

    .prices {
      padding-left: 5px;
      position: absolute;
      top: 13px;
      color: #979797;
      float: left;
    }

    .k-miles {
      padding-left: 5px;
      position: absolute;
      top: 13px;
      color: #979797;
      float: right;
      right: 10px;
    }
  }

  .car-input-number-from {
    float: left;
  }

  .car-input-number-to {
    float: right;
  }

  .car-label-number-from, .car-label-number-to {
    float: left;
    margin: 0;
    padding-top: 10px;
    color: #8b8b8b;
    font-weight: $font-weight-semi-bold;
  }

  .car-label-number-from {
    width: 20%;
  }

  .car-label-number-to {
    width: 10%;
    text-align: center;
  }
}

.layout-car-pagination {
  float: right;
  padding-right: 30px;
  margin-bottom: 30px;
}

/****** css for contact* **********/

/****** css for get in touch ******/

.layout-dealer-carousel {
  @include col100;
  position: relative;
  margin: 20px 0;

  .carousel-content-0 {
    padding: 4% 45% 4% 15%;
    margin-top: 0;
  }

  .carousel-content-1 {
    padding: 2% 35% 2% 20%;
    margin-top: 0;
  }

  .carousel-content-2 {
    padding: 0 25%;
    margin-top: 0;
  }

  .carousel-content-3 {
    padding: 2% 20% 2% 35%;
    margin-top: 0;
  }

  .carousel-content-4 {
    padding: 4% 15% 4% 45%;
    margin-top: 0;
  }

  .content-back, .content-back-0 {
    width: 25%;
  }

  .content-next, .content-next-0 {
    width: 25%;
    left: 75%;
  }

  .content-image {
    height: 100%;
    width: 100%;

    .react-player__preview {
      border-radius: 20px;
    }

  }

  img {
    width: 100%;
    height: 100%;
    border-radius: $borderRadiusBox;
    object-fit: cover;
  }

  .semi-circle-up {
    left: 15.2%;
    top: 45%;
    z-index: 100;
    transform: rotate(270deg);
  }

  .semi-circle-down {
    left: 78.5%;
    top: 45%;
    z-index: 100;
    transform: rotate(90deg);
  }

  .dealer-media-only-0 {
    padding: 2% 35% 2% 20% !important;
    opacity: 0.6;
  }

  .dealer-media-only-1 {
    padding: 0 25% !important;
    opacity: 1;
  }
}

.player-background {
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: absolute;
  top: 0;
  opacity: 0.3;

}

.player-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 150px 150px 50px 150px;
  z-index: 3000;
  top: 0;
}

.player-close {
  position: absolute;
  top: 135px;
  right: 100px;
  font-size: 32px;
  color: #fff;
  z-index: 4000;
  cursor: pointer;
  width: 50px;
  height: 50px;

}

.horizontal-line {
  width: 25px;
  height: 2px;
  background-color: #FFFFFF;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  transform: rotate(45deg);
}

.vertical-line {
  width: 2px;
  height: 25px;
  background-color: #FFFFFF;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  transform: rotate(45deg);
}

.content-next-back {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  @include item-center;
}

.content-back, .content-back-0 {
  width: 20%;
  justify-content: flex-end;
  z-index: 10;
}

.content-next, .content-next-0 {
  left: 80%;
  width: 20%;
  z-index: 10;
  justify-content: flex-start;
}

@mixin semi-circle-next() {
  cursor: pointer;
  border-radius: 0 100px 100px 0;
  height: 54px;
  width: 27px;
  background: #FDFDFD;
  border: 1px solid #D10C0C;
  @include item-center;

  a:hover {
    cursor: pointer;
  }
}

.semi-circle-next {
  @include semi-circle-next;
}

.semi-circle-back {
  @include semi-circle-next;
  cursor: pointer;
  transform: rotate(180deg);

  a:hover {
    cursor: pointer;
  }
}

.col-menu-advanced {
  @include col100;
  padding-top: 12px;

  label {
    color: #7F7F7F;
    font-weight: $font-weight-semi-bold;
  }
}

.finance-message-error {
  padding-left: 55px !important;
}

.container-car-image-carousel:hover {
  cursor: pointer;
}

.container-car-image-carousel-selected {
  border-radius: 8px;
  border: 2px solid blue;
}

.backgroundLoader {
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 10000;
  left: 0;
  top: 0;
  opacity: 6;
}

#loader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10000;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: adjust-hue 2s linear infinite;
  animation: adjust-hue 2s linear infinite;
}

@-webkit-keyframes adjust-hue {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes adjust-hue {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.stop-scrolling {
  height: 100%;
  overflow: hidden;
  padding-right: 15px;
}

.popover-box-template {
  float: left;
  width: 100%;
  height: 200px;
  overflow: auto;
  padding: 30px;
  position: relative;
}

.popover-header-template {
  height: 140px;
  overflow: auto;

  label {
    color: #7F7F7F;

  }
}

.modal-box-header {
  position: absolute;
  height: 30px;
  right: -50px;
  top: -15px;

  .player-close {
    top: 0;
    right: 0;
  }

  .horizontal-line {
    background-color: #000;
    height: 3px;
  }

  .vertical-line {
    background-color: #000;
    width: 3px;
  }
}

.modal-box {
  align-items: center;
  justify-content: center;
  display: flex !important;

  .modal-content {
    border-radius: 18px;
    border: 0;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.16);
  }

  .modal-body {
    min-height: 250px;
    padding: 30px;

    .modal-box-body {
      height: 190px;
      overflow: auto;

      label {
        color: #7F7F7F;
      }
    }
  }
}

.content-alert-danger {
  position: absolute;
  width: 100%;

  .alert-danger {
    margin: 0;
  }
}

.btn-copy-finance {
  display: none;
  height: 30px;
  float: right;
  border-radius: 5px;
  color: #fff;
  margin-right: 20px;
}

.car-item-details .row-details {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #CACACA;
  padding-bottom: 15px;
}

/****** Easy Navigation B **********/
.layout-container-main-b {


  .layout-car {
    padding: 0 65px 20px 65px;
    background-color: #f4f4f4;
    border-radius: 40px;

    .layout-car-content {
      position: relative;
      top: 0;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      background-color: #f4f4f4;
      padding-top: 100px;
    }
  }

  .row-car-more {
    padding-top: 30px;
  }

  .layout-finance {
    float: left;
    width: 100%;
  }
}

/****** Easy Navigation C **********/
.layout-container-main-c {
  .body-customer-section {
    .container-testimonial .section-testimonial {
      border-radius: 20px;
    }

    .section-left {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .section-right {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
    }
  }

  .layout-get-in-touch {
    .get-in-touch-form .input-area textarea {
      resize: none;
    }

    .btn-send-content .btn-send {
      border-radius: 30px;
      font-weight: 600;
    }
  }
}

.layout-container-main-c, .layout-about-container-c .layout-about {
  .container-testimonial-general {
    .container-bar-progress {
      padding-top: 25px;
      width: 100%;
      @include item-center;

      .section-left {
        width: 25px;
        float: left;
        transform: rotate(270deg);

        &:hover {
          cursor: pointer;
        }
      }

      .bar-progress {
        float: left;
        width: calc(80% - 50px);
        height: 25px;
        padding: 0 10px;
        @include item-center;
      }

      .section-right {
        width: 25px;
        float: right;
        transform: rotate(270deg);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.carfax-content {
  float: right;
  z-index: 10;
  position: relative;

  a {
    width: max-content;
    display: flex;
    font-size: 12px;
    color: inherit;
  }

  img {
    width: 80px !important;
    height: auto;
  }
}

.layout-finance-container-c {
  .layout-get-in-touch .btn-send-content .btn-send {
    border-radius: 30px;
  }
}

/****** Media Queries* **********/

@media screen and (max-width: 1750px) {

  /******* css Vehicles **********/
  .inv-car-view .inv-car-box {
    .row-inv-car {
      .inv-car-info {
        .box-info {
          height: 40px;
        }

        .row-info-send-message {
          input {
            height: 40px;
          }
        }
      }
    }

    .inv-car-calculator {
      .container-right-calculate {
        input {
          height: 40px;
        }
      }
    }
  }

  /******* css Vehicles **********/


}

@media screen and (max-width: 1670px) {
  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-control.left {
      left: 63%;
    }

    .carousel-control.right {
      right: 63%;
    }

    .carousel-inner {
      .container-car-image-carousel {
        height: 72px;

        img {
          width: 85.5px
        }
      }
    }
  }
  /******* css Vehicles **********/
}

@media screen and (max-width: 1585px) {
  /*******css for inventory **********/
  .row-car-info-btn {
    .btn-car-details {
      display: none;
    }
  }
  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-control.left {
      left: 65%;
    }

    .carousel-control.right {
      right: 65%;
    }

    .carousel-inner {
      .container-car-image-carousel {
        height: 72px;

        img {
          width: 90px;
          top: 6px;
        }
      }
    }
  }

  .inv-car-view .inv-car-box {
    .row-inv-car {
      .inv-car-info {
        .box-info {
          height: 36px;
        }

        .row-info-send-message {
          input {
            height: 36px;
          }
        }
      }
    }

    .inv-car-calculator {
      .container-right-calculate {
        input {
          height: 36px;
        }
      }
    }
  }
  /******* css Vehicles **********/
}


@media screen and (max-width: 1510px) {

  .layout-car {

    .car-item-price {
      font-size: 14px;
    }

    .car-item-title {
      font-size: 12px;
    }
  }

  /****** css for Finance **********/

  /****** css for Finance* **********/

  .layout-inventory {
    .car-carousel {
      top: -780px;
    }

    .layout-car-bg {
      top: -560px;
    }

    .layout-car-content {
      top: 0;
    }
  }

  .layout-about .goal .box {
    padding: 20px 30px;

    h3 {
      font-size: 24px;
    }

    span {
      font-size: 11px;
    }
  }

  .layout-about-container .banner-red-social {
    bottom: 200px;
  }
}

@media screen and (max-width: 1500px) {

  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-control.right {
      right: 67%;
    }

    .carousel-control.left {
      left: 67%;
    }

    .carousel-inner .container-car-image-carousel {
      height: 70px;
    }
  }
  /******* css Vehicles **********/
}

@media screen and (max-width: 1490px) {
  .container-form-get-in-touch {
    .title-get-in-touch {
      font-size: 72px;
    }
  }

  .inv-car-view .inv-car-box {
    .row-inv-car {
      .inv-car-info {
        .row-info-details {
          width: 85%;
        }

        .inv-car-foot {
          margin: 20px 0 0 0;
        }

        .box-info {
          height: 32px;
        }

        .row-info-send-message {
          input {
            height: 32px;
          }
        }

        .row-info {
          h3 {
            font-size: 25px;
          }

          .price {
            font-size: 27px;
          }
        }
      }
    }

    .inv-car-calculator {
      .container-right-calculate {
        input {
          height: 32px;
        }
      }

      .container-left-apply {
        .container-apply-online {
          label {
            font-size: 19px;
          }

          .icon-search {
            height: 30px
          }
        }

        .container-choose-car {
          label {
            font-size: 19px;
          }

          .icon-search {
            height: 30px
          }
        }

        .container-sign-go {
          label {
            font-size: 19px;
          }

          .icon-search {
            height: 30px
          }
        }

        .container-get-finance {
          label {
            font-size: 25px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView .carousel-inner .container-car-image-carousel {
    height: 68px;
  }
}

@media screen and (max-width: 1480px) {


  .layout-inventory-container .layout-car-body .list-view-item {
    .detailed-info-container {
      .general-info-section {
        .car-description {
          font-size: 20px;
        }

        .car-price, .car-mileage label, .price-coming-soon {
          font-size: 14px;
        }
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        padding-top: 10px;

        .sub-title-info {
          font-size: 15px;
        }
      }

      .options-section {
        .apply-option, .other-option {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {

  /****** css for header* **********/


  .layout-car {

    .car-item-price {
      font-size: 14px;
    }

    .layout-car-content {
      padding: 0 25px 30px 25px;
      top: -300px;
    }

    .layout-car-header {
      padding: 0 0 15px 30px;
    }

  }


  /****** css for header* **********/

  .layout-inventory-container {
    .layout-car-content {
      top: 0;
      padding: 0;
    }
  }

  /**************** css for vehicles ********/
  .row-info {
    h3 {
      font-size: 26px;
    }

    .price {
      font-size: 28px;
    }
  }

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-inner .container-car-image-carousel {
      height: 65px;
    }
  }


  .layout-dealer-carousel {
    margin-top: 0;
  }

  .layout-about {
    top: 100px;

    .goal {
      padding-top: 30px;
    }

    .item .box-item .wrapper {
      padding: 15px;
    }

    .item .box-item .label-value {
      padding: 10px 15px 10px 0;
    }

    .about-testimonial .layout-customer-testimonial .semi-circle-up {
      left: 14.3%;
    }

    .about-testimonial .layout-customer-testimonial .semi-circle-down {
      left: 78.2%;
    }
  }

  .layout-about-container .banner-red-social {
    bottom: 130px;
  }


}

@media screen and (max-width: 1400px) {
  /****** css for Finance **********/

  /*******down the logo dealer******/

  /****** css for Finance* **********/

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-control.left {
      left: 69%;
    }

    .carousel-control.right {
      right: 69%;
    }
  }

  .carousel-content-text-3 div > h4 {
    font-size: 14px;
  }

  .carousel-content-text-4 div > h4 {
    font-size: 12px;
  }
}

@media screen and (max-width: 1380px) {

  #layCar {
    .layout-car-content {
      top: -260px;
    }
  }

  .layout-car {

    .car-item-price {
      font-size: 13px;
    }

  }

  .layout-inventory-container {
    .layout-car-bg {
      padding: 0 30px;
    }
  }

  .inv-car-view .inv-car-box {
    .row-inv-car-top {
      padding: 10px 20px 5px 20px;
    }

    .inv-car-des {
      .car-feature {
        padding-right: 4%;
      }
    }

    .row-inv-car {
      .inv-car-info {
        .box-info {
          height: 28px;

          .image-style-width {
            width: 14px;
          }
        }

        .row-info-send-message {
          input {
            height: 28px;
          }
        }

        .row-info-details .dot-color {
          width: 18px;
          height: 18px;
        }
      }
    }

    .inv-car-calculator {
      .container-right-calculate {
        input {
          height: 28px;
        }
      }
    }
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView .carousel-inner .container-car-image-carousel {
    height: 62px;
  }

  .layout-container-main-b {
    #layCar {
      .layout-car-content {
        top: 0;
      }
    }
  }
}


@media screen and (max-width: 1360px) {

  .layout-about {
    top: 80px;
  }

  .layout-about .about-testimonial .layout-customer-testimonial .semi-circle-up {
    left: 14%;
  }

  .player-wrapper {
    padding: 110px 150px 50px 150px;
  }

  .player-close {
    top: 95px;
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView .carousel-inner .container-car-image-carousel {
    height: 56px;

    img {
      width: 87px;
    }
  }

  .layout-about-container {
    .layout-about {
      .goal {
        width: 50%;
      }

      .about-testimonial {
        width: 50%;
      }
    }
  }


}

@media screen and (max-width: 1320px) {

  #layCar {
    .layout-car-content {
      top: -240px;
    }
  }

  .layout-car {

    .car-item-price {
      font-size: 13px;
    }

    .col4 {
      padding-right: 20px;
    }

  }

  /****** css for Finance **********/

  /****** css for Finance* **********/

}

@media screen and (max-width: 1300px) {
  /****** css for Finance **********/

  /****** css for Finance* **********/

  .inv-car-view .inv-car-box {
    .row-inv-car {
      .inv-car-info {
        .row-info-details {
          padding-top: 5px;
          font-size: 12px;

          .dot-color {
            width: 15px;
            height: 15px;
          }
        }

        .inv-car-foot {
          margin: 12px 0 0 0;

          .alert {
            margin-top: 10px;
          }
        }

        .box-info {
          height: 24px;
        }

        .row-info-send-message {
          padding-top: 0;

          .get-it-touch {
            padding: 2px 0;
            font-size: 19px;
          }

          input {
            height: 24px;
          }

          label {
            font-size: 13px;
            margin-bottom: 3px;
          }
        }

        .row-info {
          h3 {
            font-size: 22px;
          }

          .price {
            font-size: 24px;
          }
        }
      }
    }

    .inv-car-calculator {
      .container-right-calculate {
        input {
          height: 24px;
        }
      }

      .container-left-apply {
        .container-apply-online {
          label {
            font-size: 17px;
            padding-left: 5px;
          }

          .icon-search {
            height: 26px
          }
        }

        .container-choose-car {
          label {
            font-size: 17px;
            padding-left: 5px;
          }

          .icon-search {
            height: 26px
          }
        }

        .container-sign-go {
          label {
            font-size: 17px;
            padding-left: 5px;
          }

          .icon-search {
            height: 26px
          }
        }

        .container-get-finance {
          label {
            font-size: 23px;
          }

          p {
            font-size: 11px;
          }
        }
      }
    }
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .semi-circle-carousel {
      width: 84px;
      height: 42px;
    }
  }

  .banner-img-container {
    margin-top: -16%;
  }

  .layout-container-main-b {


    .layout-car {
      top: -100px;
    }

  }
}

@media screen and (max-width: 1290px) {
  .container-form-get-in-touch {
    .title-get-in-touch {
      font-size: 71px;
    }
  }

  .layout-inventory {
    .car-carousel {
      top: -620px;
    }

    .layout-car-bg {
      top: -450px;
    }
  }

  /**************** css for vehicles ********/
  .row-info {
    h3 {
      font-size: 23px;
    }

    .price {
      font-size: 25px;
    }
  }
  /**************** css for vehicles ********/


}

@media screen and (max-width: 1260px) {

  .layout-car {

    .car-item-price {
      font-size: 12px;
    }

    .car-item-title {
      font-size: 12px;
    }

    .btn-car-info {
      font-size: 9px;
    }

    .layout-car-content {
      top: -238px;
    }

    .inventory {
      top: 0;
    }

    .layout-car-header {
      padding: 0 0 15px 30px;
    }

    .car-carousel {
      top: -560px;
    }

    .layout-car-bg {
      top: -300px;
    }

  }


  .lay-get-in-touch {
    padding: 70px 65px;
  }

  .layout-inventory-container {
    .layout-car .layout-car-bg {
      top: 60px;
    }
  }

  .inv-car-view .inv-car-box .row-inv-car {
    #carCarouselTwoWebView {
      .carousel-inner {
        .container-car-image-carousel {
          margin: 0 0 5px 0;
          height: 56px;
        }
      }
    }
  }

  .carousel-content-text-3, .carousel-content-text-4, .carousel-content-text-0, .carousel-content-text-1 {
    .testimonial-content {
      color: white;
    }
  }


}

@media screen and (max-width: 1250px) {
  .layout-container-main, .layout-finance-container, .container-home-modern-view-2 {
    .lay-get-in-touch {
      .layout-contact-testimonial{
        flex-direction: column;
        .layout-contact-full, .layout-testimonials-full{
          width: 100%;
        }
      }
      .get-in-touch-message-box {
        width: inherit;
      }
    }
  }


  .carousel-content-social-network {
    width: 150px;
  }

  .container-get-in-touch {
    width: 100%;
  }

  .container-form-get-in-touch {
    height: 85%;
    padding-left: 8%;
    padding-right: 8%;

    .title-get-in-touch {
      font-size: 46px;
    }
  }

  .semi-circle-up {
    display: none;
  }

  .semi-circle-down {
    top: 90px;
    transform: rotate(90deg);
    display: block;
  }

  .layout-customer-testimonial .content-up-down {
    top: 90px;
    left: 81.8%;
  }

  .container-button-get-in-touch-right {
    display: block;
  }

  .layout-customer-testimonial {
    width: 100%;
    height: auto;
    padding-top: 70px;

    .container-carousel-testimonials {
      height: 380px !important;
    }

    .carousel-content-5 {
      display: none;
    }

    .carousel-content-4 {
      margin-top: 134px;
      padding-left: 55%;
      padding-right: 6%;
    }


    .carousel-content-image-4 {
      position: absolute;
      padding-top: 27px;
      left: -42px;

      img {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-4 {
      height: 125.48px;
      width: 90%;
      padding-left: 49px;

      div > p {
        height: 73px;
        padding-top: 16px;
        font-size: 10px;
        overflow: hidden;
      }

      div > h4 {
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 6px;
      }

      .carousel-title-name {
        height: 2px;
      }
    }

    .carousel-content-3 {
      width: 90%;
      margin-top: 100px;
      padding-right: 0.5%;
      padding-left: 25%;
    }

    .carousel-content-image-3 {
      position: absolute;
      padding-top: 34px;
      left: -55px;

      img {
        width: 113.3px;
        height: 113.3px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-3 {
      height: 177.58px;
      width: 71%;
      padding-left: 86px;

      div > p {
        height: 100px;
        padding-top: 30px;
        overflow: hidden;
      }

      div > h4 {
        font-size: 15px;
        color: #7F7F7F;
        margin-top: 5px;
      }

      .carousel-title-name {
        height: 18px;
      }
    }

    .carousel-content-2 {
      width: 75%;
      margin-top: 79px;
      margin-left: 13%;
    }

    .carousel-content-image-2 {
      position: absolute;
      padding-top: 40px;
      left: -64px;

      img {
        height: 132.16px;
        width: 132.16px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-2 {
      height: 222px;
      width: 86%;
      padding-left: 95px;

      div > p {
        height: 110px;
        padding-top: 50px;
        font-size: 12px;
        overflow: hidden;
      }
    }

    .carousel-content-1 {
      width: 69%;
      margin-top: 103px;
      padding-right: 9%;
      padding-left: 1.8%;
    }

    .carousel-content-image-1 {
      position: absolute;
      padding-top: 34px;
      left: -49px;

      img {
        width: 113.3px;
        height: 113.3px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-1 {
      height: 177px;
      width: 72%;
      padding-left: 62px;

      div > h4 {
        font-size: 18px;
      }

      div > p {
        height: 100px;
        padding-top: 6%;
        font-size: 11px;
        overflow: hidden;
      }

      .carousel-title-name {
        height: 20px;
      }
    }

    .carousel-content-0 {
      width: 81%;
      margin-top: 133px;
      padding-right: 19%;
      padding-left: 14%;
    }

    .carousel-content-image-0 {
      position: absolute;
      padding-top: 24px;
      left: -40px;

      img {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-0 {
      height: 125.48px;
      width: 100%;
      padding-left: 58px;

      div > h4 {
        font-size: 11px;
        margin-top: 0;
        margin-bottom: -14px;
      }

      div > p {
        width: 100%;
        height: 76px;
        padding-top: 5%;
        font-size: 10px;
        overflow: hidden;
      }

      .carousel-title-name {
        height: 2px;
      }
    }
  }

  .layout-about {
    .about-testimonial {
      .layout-customer-testimonial {
        padding-top: 0;
      }
    }
  }

  .lay-get-in-touch {
    display: block;
  }

  /************ css for vehicles ******/
  .car-search-advance {
    .car-row-apply-filters {
      button {
        width: 100%;
      }
    }
  }
  /************ css for vehicles ******/

  .inv-car-view {
    .inv-car-content {
      .inv-car-box {
        .inv-car-des {
          .car-des {
            width: 50%;
          }

          .car-feature {
            margin-right: 0;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1200px) {

  .inv-car-view .inv-car-box .row-inv-car {
    #carCarouselTwoWebView {
      .carousel-control.left {
        left: 73%;
      }

      .carousel-control.right {
        right: 73%;
      }

      .carousel-inner .container-car-image-carousel {
        height: 53px;

        img {
          width: 84px
        }
      }
    }
  }
  /**************** css for vehicles ********/

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .row-info {
    h3 {
      font-size: 19px;
    }

    .price {
      font-size: 20px;
    }

    .price-old {
      font-size: 14px;
    }
  }

  .inv-car-view .inv-car-box .inv-car-calculator {
    margin: 15px 0 0 0;

    .container-right-calculate {
      label {
        font-size: 13px;
      }
    }
  }

  .inv-car-view .inv-car-box .inv-car-calculator .container-left-apply .container-sign-go .container-icon .icon-search {
    height: 25px;
    width: 25px;
  }

  #layCar .layout-car-content {
    top: -260px;
  }

  .layout-inventory-container .layout-car-body .list-view-item {
    .detailed-info-container {
      .main-mechanical-info-section, .additional-mechanical-info-section {
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 1190px) {

  .layout-inventory {
    .car-carousel {
      top: -570px;
    }

    .layout-car-bg {
      top: -350px;
    }
  }

  .layout-inventory-container .layout-car-bg {
    padding: 0 30px;
  }

  .layout-car .col3 {
    padding-right: 15px;
  }

  .layout-car-filter {
    width: 25%;
    padding: 30px 0 0 20px;
  }

  .layout-inventory-container {
    .layout-car-body, .layout-car-pagination {
      width: 75%;
    }

  }

  .car-search-advance .car-input-number-from input, .car-search-advance .car-input-number-to input {
    height: 40px;
  }


  #layCar .layout-car-content {
    top: -220px;
  }

}

@media screen and (max-width: 1160px) {


  /****** css for Finance **********/

  /****** css for Finance* **********/

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-control.right {
      right: 71%;
    }

    .carousel-control.left {
      left: 71%;
    }

    .semi-circle-carousel {
      width: 76px;
      height: 38px;
    }

    .carousel-inner .container-car-image-carousel {
      img {
        width: 80px;
      }
    }
  }


}

@media screen and (max-width: 1140px) {

  .layout-inventory {
    .car-carousel {
      top: -540px;
    }

    .layout-car-bg {
      top: -330px;
    }
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .row-info-details {
    width: 95%;
  }

  .inv-car-view .inv-car-box .inv-car-calculator {
    .container-right-calculate {
      label {
        font-size: 11.5px;
      }
    }
  }

  .inv-car-view .inv-car-box .inv-car-calculator .container-left-apply .container-sign-go .container-icon .icon-search {
    height: 20px;
    width: 20px;
  }

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView .carousel-inner .container-car-image-carousel {
    height: 49px;

    img {
      width: 79px;
    }
  }


}

@media screen and (max-width: 1100px) {


  .layout-car .inventory {
    top: 40px;
  }

  .layout-inventory-container {
    .layout-car .inventory {
      top: -20px;
    }
  }

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwoWebView {
    .carousel-inner .container-car-image-carousel {
      height: 48px;

      img {
        width: 78px
      }
    }
  }


  .layout-inventory-container .layout-car-body .list-view-item {
    .detailed-info-container {
      .general-info-section {
        padding: 10px;

        .car-description {
          font-size: 16px;
        }

        .car-price, .car-mileage label, .price-coming-soon {
          font-size: 12px;
        }
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        padding-top: 10px;

        .sub-title-info {
          font-size: 12px;
        }

        .sub-title {
          font-size: 8px;
        }
      }

      .options-section {
        .apply-option, .other-option {
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 1090px) {

  #layCar .layout-car-content {
    top: -168px;
  }
  /****** css for Finance **********/

  .layout-finance .body-left-position .text-three-steps {
    font-size: 35px;
  }
  /****** css for Finance* **********/

  .layout-inventory {
    .car-carousel {
      top: -510px;
    }

    .layout-car-bg {
      top: -320px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .inv-car-view .inv-car-box .row-inv-car .inv-car-info {
    .car-status {
      padding: 9px 0;
      height: 30px;
    }

    .car-title {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 1050px) {

  /****** css for header* **********/


  /****** css for header* **********/

  .layout-inventory {
    .car-carousel {
      top: -490px;
      left: 0;
    }

    .layout-car-bg {
      top: -310px;

      .layout-car-content {
        top: 0;
      }
    }
  }

  .inv-car-view .inv-car-box .row-inv-car {
    #carCarouselTwoWebView {
      .carousel-control.left {
        left: 75%;
      }

      .carousel-control.right {
        right: 75%;
      }

      .carousel-inner .container-car-image-carousel {
        height: 45px;

        img {
          width: 75px;
        }
      }
    }
  }

}

@media screen and (max-width: 1040px) {

  .finance-form-vertical, .finance-form-wizard {
    .tab-finance {
      .tab-content {
        .colR25 {
          width: 25%;
        }

        .colR16 {
          width: 16.66%;
        }
      }
    }
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (max-width: 1024px) {

  .layout-inventory-container .layout-car {

    .layout-car-content {
      top: -60px;
    }

    .layout-car-header {
      padding: 0 0 12px 24px;

      h2 {
        font-size: 30px;
      }
    }

    .car-item-title {
      font-size: 11px;
    }

    .car-item-price {
      font-size: 10px;
    }


  }

  .row-layout-car {
    padding-left: 20px;

    .col2 {
      padding-right: 15px;
    }
  }


  /****** css for location* **********/

  #locationMain {
    .layout-location-left {
      .container-location {

        .location-description .container-description .description {
          width: 100%;
          padding-left: 20px;
        }

        .location-description {
          font-size: 20px;
        }

        .location-header .icon-message {
          margin-top: 8px;
        }

        .location-header .title-location {
          font-size: 29px;
        }
      }
    }
  }


  /****** css for location* **********/

  /****** css for Finance open **********/
  .layout-finance {
    height: auto;

    .body-left-position {
      .text-get-finance {
        font-size: 51px;
      }

      .text-three-steps {
        font-size: 30px;
      }
    }

    .body-right-position {
      padding: 95px 15% 75px;
    }

    .finance-rectangle {
      margin-bottom: 25px
    }

    .finance-image-style {
      width: 36px;
      height: 36px;
    }

    .finance-part {
      font-size: 23px;
    }

  }

  /****** css for Finance finish **********/

  /****** css for container of all footer********/

  .layout-inventory {
    padding: 0;

    .car-carousel {
      top: -510px;
    }

    .layout-car-bg {
      top: -290px;
    }
  }

  .finance-form-vertical, .finance-form-wizard {
    top: 100px;

    .tab-finance {
      .tab-content {
        padding: 20px 10px 40px 20px;

        .colR25, .colR50, .colR20, .colR80, .colR16, .colR75 {
          padding-right: 10px;
        }

        .panel-finance {
          padding: 0;
        }
      }
    }

    .radio-button-main {
      padding-right: 10px;

      button {
        font-size: 12px;
        font-weight: $font-weight-semi-bold;
      }
    }

    .finance-btn {
      padding-right: 10px;
      padding-left: 0;
    }
  }

  .layout-car-filter {
    width: 30%;
    padding: 30px 0 0 20px;
  }

  .layout-inventory-container {
    .layout-car-body, .layout-car-pagination {
      width: 70%;
    }

    .layout-car {
      .car-item-info {
        padding: 10px;

        .row-car-info {

          .car-item-title {
            font-size: 16px;
          }
        }

        .row-car-info-btn {
          height: 25px;

          .row-car-info-tags {
            align-items: flex-start;
          }
        }

        .row-car-info-footer {
          display: flex;
          height: 44px;

          .carfax-content {
            display: flex;
            flex-direction: column-reverse;

            img {
              width: 60px !important;
            }
          }

          .car-item-price {
            display: flex;
            flex-direction: column-reverse;
            margin-left: auto;

            label {
              margin-bottom: 0;
              font-size: 16px;
              text-align: right;
            }
          }
        }
      }

      .btn-car-content {
        padding-right: 5px;
      }

      .layout-car-bg {
        padding: 0 15px;
        top: 50px;
      }
    }

  }

  .inv-car-view {
    .inv-car-content {
      padding: 0 30px;
      padding-bottom: 30px;
    }

    .inv-car-body {
      .row-inv-car.row-inv-car-top {
        margin-top: 20px;
      }
    }

    .inv-car-box .row-inv-car {
      #carCarouselOne, #carCarouselTwo {
        width: 100%;
      }


      #carCarouselTwo {
        margin-top: 0;

        .carousel-control.left {
          left: -33px;
          top: 38px;
          position: absolute;
        }

        .carousel-control.right {
          right: -30px;
          top: 39px;
        }

        .carousel-inner {
          margin-top: 20px;
          padding-top: 0;
        }

        .sc-left {
          border-radius: 0 0 60px 60px;
          border-width: 1px;
          right: 0;

          i {
            right: 0;
          }
        }

        .sc-right {
          border-radius: 60px 60px 0 0;
          border-width: 1px;
          left: 0;

          i {
            right: 0;
          }
        }

        .carousel-inner .container-car-image-carousel {
          margin: 0 2.5px 0 2.5px;
          height: 64px;
          display: inline-block;

          img {
            width: 75px;
          }
        }

        .semi-circle-carousel {
          width: 56px;
          height: 28px;

          i {
            font-size: 20px;
            top: -5px;
          }

          transform: rotate(90deg);
        }
      }

      .inv-car-info {
        padding: 0 10px 0 10px;
        width: 82%;

        .car-title {
          h3 {
            font-size: 20px;
          }
        }

        .car-status {
          height: 30px;
          padding: 9px 0;
        }
      }

      .inv-car-img {
        width: 100%;
        display: block !important;
        padding: 20px 10px 0 10px;
      }
    }
  }

  .inv-car-view .inv-car-box {
    .row-inv-car {
      .car-detail-extra {
        height: 34px;
      }

      .inv-car-info {
        .box-info {
          height: 30px;
        }

        .row-info-send-message {
          input {
            height: 30px;
          }
        }
      }
    }

    .inv-car-calculator {
      .container-right-calculate {
        width: 70%;
        padding-right: 10px;

        input {
          height: 30px;
        }
      }

      .container-left-apply {
        width: 30%;
        padding: 0 0 0 30px;
      }

    }
  }
  .car-search-advance {
    .car-row-apply-filters {
      width: 49%;
      display: flex;
      justify-content: center;
      padding-top: 10px;

      .btn {
        width: 100%;
      }

      padding-bottom: 20px;
    }
  }

  #layCar .layout-car-content {
    top: -280px;
  }

  .layout-car .layout-car-content {
    .layout-car-body {
      .car-item-info {
        .row-car-info {

          .car-item-title {
            font-size: 16px;
          }
        }

        .row-car-info-btn {
          height: 25px;

          .row-car-info-tags {
            align-items: flex-start;
          }
        }

        .row-car-info-footer {
          display: flex;
          height: 44px;

          .carfax-content {
            display: flex;
            flex-direction: column-reverse;

            img {
              width: 60px !important;
            }
          }

          .car-item-price {
            display: flex;
            flex-direction: column-reverse;
            margin-left: auto;

            label {
              margin-bottom: 0;
              font-size: 16px;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .layout-customer-testimonial {
    .testimonial-content {
      padding-top: 11%;
    }
  }

  .inv-car-view {
    .inv-car-content {
      .inv-car-box {
        .inv-car-des {
          .car-feature {
            width: 50%;
            padding-right: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  .layout-customer-testimonial .content-up-down {
    left: 81.5%;
  }

  .layout-container-main-b {


    .layout-car {
      top: -200px;
    }

    #layCar .layout-car-content {
      padding-top: 100px;
    }

    #locationMain {
      top: -100px;
    }
  }

  .layout-inventory-container .layout-car-body .list-view-item {
    .container-car-image, .container-car-image-sold {
      width: 35% !important;
    }

    .detailed-info-container {
      width: 65%;

      .general-info-section {
        padding: 5px;

        .car-description {
          font-size: 13px;
        }

        .car-price, .car-mileage label, .price-coming-soon {
          font-size: 9px;
        }

        .car-price-mileage {
          //bottom: 30px;
        }
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        .sub-title-info {
          font-size: 9px;
        }
      }

      .options-section {
        height: auto;
        bottom: 6px;

        .apply-option, .other-option {
          font-size: 9px;
        }
      }
    }
  }

  .finance-rectangle {
    .finance-image {
      width: 25%;
    }

    .finance-part {
      width: 75%;
    }
  }

}

@media screen and (max-width: 1010px) {
  /**************** css for vehicles ********/
  .inv-car-view .inv-car-box .row-inv-car {
    #carCarouselTwo {
      .carousel-control.left, .carousel-control.right {
        top: 34px;
      }

      .carousel-inner .container-car-image-carousel {
        height: 56px;

        img {
          width: 66.5px;
        }
      }

      .semi-circle-carousel {
        width: 50px;
        height: 25px;
      }
    }
  }
  /**************** css for vehicles ********/
}

@media screen and (max-width: 992px) {

  .car-search-advance {
    .car-row-apply-filters {
      .flex-row {
        justify-content: flex-start;

        .btn {
          width: auto;
          letter-spacing: normal;
        }
      }
    }

    .car-row-clean-filters {
      .flex-row {
        justify-content: flex-end;

        .btn {
          width: auto;
          letter-spacing: normal;
        }
      }
    }
  }

  .finance-form-wizard {
    padding: 0 20px;

    .tab-finance .nav-tabs {
      padding: 20px 10px 10px 10px;

      li {
        padding-right: 10px;

        a {
          padding: 0;
          font-size: 12px;
        }
      }
    }

    .finance-tab-icon {
      height: 18px;
    }

    .finance-icon span {
      padding-left: 5px;
      padding-top: 2px;
    }
  }

  .finance-form-vertical {
    padding: 0 20px;
  }

  #layCar .layout-car-content {
    top: -240px;
  }
  .about-testimonial {
    width: 100%;

    .layout-customer-testimonial {
      .carousel-content-0, .carousel-content-1, .carousel-content-2, .carousel-content-3, .carousel-content-4 {
        margin: 0;
        width: 100%;
      }

      .carousel-content-0 {
        padding: 50px 40% 0 10%;
      }

      .carousel-content-1 {
        padding: 25px 30% 0 15%;
      }

      .carousel-content-2 {
        padding: 0 20%;
      }

      .carousel-content-3 {
        padding: 25px 15% 0 30%;
      }

      .carousel-content-4 {
        padding: 50px 10% 0 40%;
      }

      .carousel-content-text-1, .carousel-content-text-2, .carousel-content-text-3, .carousel-content-text-4 {
        width: 100%;
      }

      .carousel-content-image-0 {
        padding-top: 0;
      }

      .carousel-content-image-1 {
        padding-top: 4px;
      }

      .carousel-content-image-2 {
        padding-top: 20px;
      }

      .semi-circle-back, .semi-circle-next {
        margin-top: unset;
      }
    }
  }

  .layout-customer-testimonial {
    .semi-circle-back {
      display: none;
    }
  }

  .layout-container-main, .layout-finance-container, .container-home-modern-view-2 {
    .lay-get-in-touch {
      .layout-contact-testimonial{
        flex-direction: column;
        padding-bottom: 70px;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .layout-inventory {
    #carCarouselBody .left {
      left: 0;
    }

    #carCarouselBody .right {
      right: 0;
    }
  }


  .inv-car-view {
    .inv-car-box {
      .row-inv-car {
        .car-detail-extra {
          .btn-car-extra {
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .inv-car-info {
          .box-info {
            height: 24px;
          }

          .row-info-send-message {
            input {
              height: 26px;
            }

            .get-it-touch {
              font-size: 19px;
            }

            .second-row-send-message {
              padding: 3px 0 12px 5px;
            }

            label {
              font-size: 11.5px;
            }
          }
        }

      }
    }
  }
}

@media screen and (max-width: 940px) {

  .inv-car-view .inv-car-box .row-inv-car {
    .inv-car-info {
      .car-title {
        font-size: 18px;
      }

      .car-status {
        height: 26px;
        padding: 7px 0;
      }
    }
  }


  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo .carousel-control.left {
    left: -28px;
  }
  .layout-customer-testimonial h4 {
    bottom: 40px;
  }
  .layout-about-container {
    #carCarousel {
      //display: none;
    }

    .layout-about .goal {
      width: 95%;
    }

    .layout-dealer-carousel {
      margin-top: 0;
    }

    .layout-customer-testimonial {
      margin-top: 20px;
      padding-top: 50px;
    }

  }

  .carousel-content-social-network {
    bottom: 20px;
  }
}

@media screen and (max-width: 900px) {


  /****** css for Finance open **********/
  .layout-finance {

    .body-left-position {
      .text-get-finance {
        font-size: 69px;
      }

      .text-three-steps {
        font-size: 25px;
      }
    }

  }

  /****** css for Finance finish **********/

  .layout-inventory {
    .car-carousel {
      top: -460px;
    }

    .layout-car-bg {
      top: -260px;
    }
  }

  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-inner .container-car-image-carousel {
      height: 52px;

      img {
        width: 61.75px;
      }
    }
  }

  /******* css Vehicles **********/
}

@media screen and (max-width: 880px) {
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-control.left {
      left: -20px;
    }

    .carousel-inner {
      .container-car-image-carousel {
        margin: 0 1px 0 1px;
      }
    }
  }
}

@media screen and (max-width: 860px) {

  /****** css for Finance open **********/

  /****** css for Finance finish **********/

  .layout-inventory {
    .car-carousel {
      top: -430px;
    }

    .layout-car-bg {
      top: -240px;
    }
  }

  .layout-car-filter {
    padding: 30px 0 0 10px;
  }

  .inv-car-view {
    .inv-car-content {
      padding: 0 20px;
      padding-bottom: 20px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  #layCar .layout-car-content {
    padding: 0 5px 20px 5px;
  }
}

@media (min-width: 850px) and (max-width: 992px) {
  #layCar .layout-car-content {
    padding: 0 5px 20px 5px;
  }
}


@media screen and (max-width: 850px) {

  #layCar .layout-car-content {
    padding: 0 5px 20px 5px;
    top: -206px;
  }

  .layout-car .layout-car-header {
    padding: 0 0 15px 30px;
  }

  /****** css for Finance open **********/

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-inner .container-car-image-carousel {
      img {
        width: 60px;
      }
    }
  }


}

@media (min-width: 840px) and (max-width: 860px) {
  #layCar .layout-car-content {
    padding: 0 5px 20px 5px;
    top: -168px;
  }
}

@media screen and (max-width: 840px) {

  /****** css for Finance open **********/


  /****** css for Finance finish **********/
  .layout-inventory {
    .car-carousel {
      top: -460px;
    }

    .layout-car-bg {
      top: -190px;
    }
  }

}

@media screen and (max-width: 820px) {


  /****** css for header* **********/
  .layout-car {
    padding: 120px 50px 20px 50px;
  }
  /****** css for header* **********/

  /****** css for Finance open **********/

  /****** css for Finance finish **********/

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info {
    .row-info-details {
      padding-top: 3px;
    }

    .row-info .col-50 {
      padding-right: 10px;
    }

    .box-info {
      min-width: 100px;
      height: 22px;
      font-size: 11px;
    }
  }

  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car {
    #carCarouselTwo {
      .semi-circle-carousel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 24px;

        i {
          top: -2px;
        }
      }

      .carousel-control.right, .carousel-control.left {
        top: 32px;
      }

      .carousel-inner .container-car-image-carousel {
        height: 50px;

        img {
          width: 62px;
        }
      }
    }
  }

  /******* css Vehicles **********/

  .container-row-button {
    display: block;
    padding-top: 50px;
    height: auto;
  }

  .lay-get-in-touch {
    height: auto;
  }

  .row-car-info {
    padding-bottom: 0;
  }

  .row-car-info-btn .btn-car-content {
    padding-right: 5px;
  }

  .layout-container-main-c {
    .layout-car {
      padding: 30px 50px 20px 50px;
    }
  }
}

@media screen and (max-width: 800px) {

  /****** css for header* **********/

  /****** css for header* **********/

  /****** css for Finance open **********/

  /****** css for Finance finish **********/

  /****** css for footer  ********/

  .inv-car-view .inv-car-box .row-inv-car {
    .car-detail-extra {
      padding-right: 10px;
    }

    .container-car-image-carousel {
      img {
        border-radius: 5px;
      }
    }

    #carCarouselTwo {
      .container-car-image-carousel {
        img {
          width: 80px;
        }
      }

      .container-car-image-carousel {
        height: 75px;
      }
    }
  }

  .carousel-content-text-2 div > h4 {
    font-size: 16px;
  }


  .layout-customer-testimonial .testimonial-content {
    text-align: center;
  }


}


@media screen and (max-width: 780px) {
  .layout-container-main {
    .chat-container {
      width: 100%;
      margin: auto 0 20px auto;
      height: 82%;
      display: flex;
      flex-direction: column;
      justify-content: end;

      .chat-content {
        border-radius: 0 0 0 0;
        width: 100%;
        height: 100%;
        margin: 5px 0 auto auto;

        .chat {
          height: calc(100% - 22px);

          .chat-box-popup-footer {
            width: 92%;
            height: 15%;
            grid-gap: 10px;
            margin-bottom: 0;

            input {
              width: 93%;
            }

            aside {
              width: 29px;
              height: 29px;
              margin-right: 5px;
            }

            .icon-smile {
              width: 21px;
              height: 21px;
              padding-top: 4px;
              cursor: pointer;
            }
          }

          .chat-body {
            height: 130%;
            margin-bottom: 20%;

            .container-first-registered > div {
              font-size: 10px;
              margin: 6px 15px;
            }
          }

          .container-messages-dealer {
            width: 70%;
            font-size: 14px;
          }

          .container-messages-client {
            div {
              width: 70%;
              font-size: 14px;
            }
          }

          .data-input {
            font-size: 14px;
            width: 70%;
            padding: 11px;
            height: 40px;
          }

          .data-input-big-area {
            font-size: 14px;
            width: 70%;
            height: 64px;
          }

          .btn-send {
            width: 25%;
            height: 40px;
            font-size: 14px;
          }

          .title-chat {
            height: 43px;
            width: 100%;
            border-radius: 0 0 0 0;

            label {
              color: #FFFFFF;
              font-size: 18px;
              font-weight: bold;
            }

            a {
              display: none;
            }
          }
        }
      }
    }

    .chat-popup-message {
      margin: 100% 8px 0 auto;
    }

    .btn-chat-popup {
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .layout-inventory-container-car {
    .inv-car-view {
      top: 5px;

      .car-carousel {
        padding-bottom: 0;
        top: 0;
        margin-top: 35px;
      }

      .inv-car-content {
        .inv-car-box {
          margin-bottom: 0px;
        }
      }
    }
  }
  .car-search-container .car-search-box .car-search-row {
    .car-search-input-content {
      width: 100%;
    }
  }
}

/* tablets and phones */
@media screen and (max-width: 992px) {

  .layout-about .item .row-item {
    width: 100%;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;

    .col-item-left {
      width: 100%;
    }

    .col-item-right {
      width: 100%;
      padding: 0;
    }
  }

  .layout-about .about-testimonial .layout-customer-testimonial {
    width: 100%;
    height: 200px;
  }

  .layout-about-container {
    .layout-about {
      //top: 50px;
      .goal {
        width: 50%;
      }

      .about-testimonial {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 992px), handheld and (orientation: landscape) {

  #layCar .layout-car-content {
    padding: 0 10px 20px 10px;
    top: -230px;
  }

  /*******Footer Menu by toggle button (media 768)******/

  /***invisible menu list navbar*********/
  .menu-footer-right .navbar-default {
    display: none;
  }

  /*****end menu footer (media 768) *****/


  .banner-red-social {
    display: none;
  }

  /****** css for header* **********/


  /****** css for Finance open **********/

  /****** css for Finance finish **********/

  /********* css for location ***********/

  #locationMain {
    .layout-location-left {
      width: 100%;
      border-radius: 20px 20px 0 0;
      height: auto;

      .container-location {
        .location-description {
          .container-description {
            .icon-position, .icon-telephone, .icon-calendar {
              width: auto;
            }
          }
        }
      }
    }

    #map {
      width: 100% !important;
      border-radius: 0 0 20px 20px;
      height: 600px !important;
    }
  }
  /********* css for location ***********/

  .layout-inventory {
    .layout-car-content {
      padding: 0;
    }
  }

  #locationMain {
    padding: 30px 20px;
  }

  .lay-get-in-touch {
    padding: 70px 20px 0 20px;
  }

  .toggle-menu {
    background: #ffffff;

    #aboutUs {
      background-color: #fff;
    }
  }

  .finance-form-vertical, .finance-form-wizard {
    .tab-finance {
      .tab-content {
        .colR25, .colR50, .colR20, .colR80, .colR16 {
          width: 50%;
        }

        .col100 {
          display: flex;
          flex-wrap: wrap;
        }

        .radio-button-select {
          display: block;
        }
      }
    }

    .radio-button-main {
      display: none;
    }
  }

  .layout-inventory-container {
    .layout-car .layout-car-content {
      padding: 0;
    }
  }

  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-control.left {
      top: 36px;
    }

    .carousel-control.right {
      right: -17px;
      top: 36px;
    }

    .semi-circle-carousel {
      width: 59px;
      height: 29px;

      i {
        top: -2px;
      }
    }

    .carousel-inner .container-car-image-carousel {
      height: 60.8px;

      img {
        width: 69px;
      }
    }
  }

  .inv-car-view {
    .inv-car-box {
      .inv-car-calculator {
        .container-right-calculate {
          width: 75%;
        }

        .container-left-apply {
          width: 25%;
          padding: 0 0 0 15px;

          .container-get-finance {
            label {
              font-size: 21px;
            }

            p {
              font-size: 10px;
            }
          }
        }
      }
    }

    .car-carousel {
      .title-carousel {
        color: #7F7F7F;
        padding: 0 40px;
        font-size: 22px;
        font-weight: 700;
      }

    }

    .row-inv-car {
      .inv-car-info {
        .row-info-details {
          .row-info-details-line {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .col-49 {
              width: 49%;
            }
          }
        }
      }
    }
  }

  .layout-about-container {
    .layout-customer-testimonial {
      .container-carousel-testimonials {
        height: 222px !important;
      }
    }
  }

  .modal-box {
    .modal-dialog {
      width: 80%;
    }

    .modal-box-header {
      right: -10px;
      top: -50px;
    }
  }

  .layout-about {
    top: 60px;

    .goal {
      width: 100% !important;
      padding: 15px;

      .box {
        border-radius: 10px !important;
        padding: 20px 25px;
        box-shadow: 0 10px 30px #0000001A;

        h3 {
          font-weight: 700;
          font-size: 20px;
          color: #272727;
        }

        span {
          font-size: 14px;
          color: #272727;
        }

        .container-hours-operation, .container-location {
          flex: 0 0 100%;
        }

        .container-location {
          padding-top: 20px;
        }
      }

      .item {
        .row-item {
          display: flex;
          flex-direction: column;

          .col-item-left {
            width: 100%;
            padding: 0 0 10px 0;

            label {
              color: #272727;
              font-size: 20px;
            }
          }

          .col-item-right {
            width: 100%;
            padding: 0;

            label {
              color: #272727;
              font-size: 20px;
            }
          }

          .box-item {
            border-radius: 10px !important;
            padding: 0 10px;
            box-shadow: 0 10px 30px #0000001A;
          }
        }
      }
    }
  }

  .content-separator {
    .car-separator1 {
      height: 14px;
    }

    .content-separator2 {
      padding-top: 7px;

      .car-separator2 {
        height: 7px;
      }
    }
  }

  .about-testimonial, .lay-get-in-touch {
    .layout-dealer-carousel {
      margin-top: 32px;

      .carousel-content-0 {
        padding: 4% 45% 4% 15px;
      }

      .carousel-content-1 {
        padding: 2% 35% 2% 35px;
      }

      .carousel-content-2 {
        padding: 2px 55px;
      }

      .carousel-content-3 {
        padding: 2% 35px 2% 35%;
      }

      .carousel-content-4 {
        padding: 4% 15px 4% 45%;
      }

      .content-back {
        width: 55px;
      }

      .content-next {
        width: 55px;
        left: unset;
      }
    }
  }

  .layout-car-filter {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  .layout-inventory-container .layout-car-body {
    width: 100%;
    padding-top: 20px;
  }

  .car-search-keyboard .btn-filter {
    width: 100%;
    display: block;
  }

  .layout-inventory-container .layout-car-body .list-view-item {
    .container-car-image, .container-car-image-sold {
      width: 50% !important;
    }

    .detailed-info-container {
      width: 50%;
      height: auto;
      aspect-ratio: auto 4/2.82;
      border-bottom: 1px solid #E2E2E2;

      .general-info-section {
        position: absolute;
        padding: 10px;
        width: 100%;
        height: 100%;

        .car-description {
          font-size: 16px;
        }

        .car-price, .car-mileage label, .price-coming-soon {
          font-size: 12px;
        }

        .car-price-mileage {
          bottom: 26px;
        }
      }

      .additional-mechanical-info-section {
        padding-left: 60px;
        width: 42.33%;
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        padding-top: 60px;

        .sub-title-info {
          font-size: 14px;
        }
      }
    }

    .custom-image {
      border-radius: 9px 0 0 0;
    }
  }

  .row-layout-car {
    padding-right: 5px;
  }

  .layout-car-body-list .row-layout-car {
    padding-right: 20px;
  }

  .layout-car, .layout-car-easy-navigation-b {
    .list-view-item .container-car-image-sold:before {
      border-radius: 7px 0 0 0;
    }
  }

  .layout-customer-testimonial {
    padding-top: 70px;
  }

  .container-mechanical-info .options-section {
    div {
      height: 100%;
      align-items: center;
    }
  }

  .layout-footer {
    .container-footer-background {
      display: flex;
      height: auto;

      .container-title-easy-nav {
        flex-direction: column;
      }

      .footer-left {
        padding: 0px 40px;
        width: 100%;
        justify-content: flex-start;
        height: auto;

        .content-input {
          width: 100%;
          // margin-top: 10px;
          margin-bottom: 89px;

          ::placeholder {
            color: currentColor;
          }
        }

        h2 {
          font-size: 26px;
          padding: 0 10px;
        }

        h3 {
          padding: 0 10px;
          margin-bottom: 15px;
        }
      }

      .footer-right {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 40px 20px 40px;
        height: auto;
        width: 100%;

        .title-one, .title-two {
          margin: 0;
          height: auto;
          padding: 20px 0 0 0;
          width: 100%;

          label {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  .layout-about-container-5 {
    .layout-about {
      .layout-about-box {
        .goal {
          [class="box"] {
            padding-left: 0;
            padding-right: 0;
          }

          .item {
            .row-item {
              .col-item-left, .col-item-right {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 760px) {
  .layout-customer-testimonial {
    .container-carousel-testimonials {
      padding-top: 94px;
      height: 400px;
    }
  }

  .carousel-content-0 {
    margin-top: 103px;
  }

  .carousel-content-1 {
    margin-top: 73px;
  }

  .carousel-content-2 {
    margin-top: 40px;
  }

  .carousel-content-3 {
    margin-top: 70px;
  }

  .carousel-content-4 {
    margin-top: 103px;
  }

  .layout-customer-testimonial .content-up-down {
    left: calc(-68px + (100vw - 40px) * 0.75 + 13%);
  }

  .carousel-content-social-network {
    padding-top: 5px;
  }

  .layout-customer-testimonial {
    .testimonial-content {
      padding-top: 15%;
    }
  }

  .lay-get-in-touch {
    .layout-customer-testimonial {
      .carousel-content-0 {
        margin-top: 83px;
      }

      .carousel-content-1 {
        margin-top: 53px;
      }

      .carousel-content-2 {
        margin-top: 29px;
      }

      .carousel-content-3 {
        margin-top: 50px;
      }

      .carousel-content-4 {
        margin-top: 84px;
      }
    }
  }

  .lay-get-in-touch .layout-customer-testimonial .carousel-content-0 .carousel-content-text-0 .carousel-content-social-network {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    bottom: 10px;
  }

  .lay-get-in-touch .layout-customer-testimonial .carousel-content-1 .carousel-content-text-1 .carousel-content-social-network,
  .lay-get-in-touch .layout-customer-testimonial .carousel-content-3 .carousel-content-text-3 .carousel-content-social-network,
  .lay-get-in-touch .layout-customer-testimonial .carousel-content-4 .carousel-content-text-4 .carousel-content-social-network,
  .lay-get-in-touch .layout-customer-testimonial .carousel-content-1 .carousel-content-text-1 .carousel-content-social-network {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    bottom: 15px;
  }

  .lay-get-in-touch .layout-customer-testimonial .carousel-content-1 .carousel-content-text-1 .carousel-content-social-network {
    bottom: 10px;
  }

  .layout-customer-testimonial .carousel-content-text-1 div > h4, .layout-customer-testimonial .carousel-content-text-3 div > h4 {
    font-size: 12px;
  }

  .layout-customer-testimonial {
    img {
      object-fit: cover;
    }

    .carousel-content-0 {
      padding: 50px 40% 0 15px !important;

      img {
        display: none;
      }
    }

    .carousel-content-1 {
      padding: 26px 30% 0 35px !important;

      img {
        display: none;
      }
    }

    .carousel-content-2 {
      padding: 0 55px !important;

      .carousel-content-text-2 {
        position: relative;
        padding: 75px 30px 0 30px;

        .carousel-content-image-2 {
          position: absolute;
          right: 0;
          left: unset;
          display: flex;
          justify-content: center;
          padding-top: 0;
          top: -65px;
          width: 100%;
          padding-left: 0;
        }

        .testimonial-content {
          padding-top: 0;
          padding-right: 0;
          height: 80px;

          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          display: -webkit-box;
        }

        .content-username {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          bottom: 35px;
          left: 0;
          padding-bottom: 5px;

          h4 {
            margin: 0;
            position: relative;
            bottom: 0;
          }
        }

        .carousel-content-social-network {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 0;
          bottom: 15px;
        }
      }
    }

    .carousel-content-3 {
      padding: 25px 35px 0 30% !important;

      img {
        display: none;
      }
    }

    .carousel-content-4 {
      padding: 50px 15px 0 40% !important;

      img {
        display: none;
      }
    }

    .content-back {
      width: 55px;
    }

    .content-next {
      width: 55px;
      left: unset;
    }
  }

  .layout-customer-testimonial {
    position: relative;
    @include col100;

    .carousel-content-1 {
      width: 100%;
      padding-left: 10%;
      padding-right: 15%;
      left: 0;
    }

    .carousel-content-image-1 {
      padding-top: 23px;
      left: -37px;

      img {
        width: 82.6px;
        height: 82.6px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-1 {
      height: 186.67px;
      width: 100%;
      padding-left: 62px;

      div > p {
        overflow: hidden;
      }
    }

    .carousel-content-2 {
      width: 100%;
      height: 70%;
      margin-left: 0;
      padding: 0 10% 0 15%;
    }

    .carousel-content-image-2 {
      padding-top: 22px;
      padding-left: 15px;

      img {
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-2 {
      height: 234.34px;
      width: 100%;
      padding-left: 50px;

      div > p {
        overflow: hidden;
        padding-top: 25px;
      }
    }

    .carousel-content-3 {
      width: 100%;
      padding-right: 5%;
      padding-left: 25%;
    }

    .carousel-content-image-3 {
      margin-top: 30px;
      margin-left: 13.5%;

      img {
        width: 82.6px;
        height: 82.6px;
        border-radius: 50%;
        background: white;
      }
    }

    .carousel-content-text-3 {
      height: 186.67px;
      width: 213.29px;

      div > p {
        overflow: hidden;
      }
    }

    .carousel-content-5 {
      display: none
    }
  }

  .layout-customer-testimonial {
    .carousel-content-0 {
      margin-top: 73px;
    }

    .carousel-content-1 {
      margin-top: 43px;
    }

    .carousel-content-2 {
      margin-top: 19px;
    }

    .carousel-content-3 {
      margin-top: 40px;
    }

    .carousel-content-4 {
      margin-top: 74px;
    }
  }

  .semi-circle-up {
    top: 76px;
    transform: rotate(270deg);
    display: block;
  }

  .semi-circle-down {
    top: 76px;
    left: 87.5%;
    transform: rotate(90deg);
    display: block;
  }

  .lay-get-in-touch {
    .semi-circle-up {
      left: 2.5%;
    }
  }

  .lay-get-in-touch {
    #nextT {
      left: 15px;
    }

    #backT {
      left: calc(-108px + 100vw)
    }
  }

  .layout-about-container .about-testimonial .layout-customer-testimonial {
    .container-carousel-testimonials {
      height: 380px !important;
    }
    .semi-circle-back, .semi-circle-next {
      margin-top: 160px;
      display: flex;
    }
  }
}

@media screen and (max-width: 750px) {
  /******* css Vehicles **********/
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-control.left {
      left: -14px;
      top: 33px;
    }

    .carousel-control.right {
      top: 33px;
    }

    .carousel-inner .container-car-image-carousel {
      height: 56px;

      img {
        width: 66.5px;
      }
    }
  }
  /******* css Vehicles **********/

  .layout-inventory-container {
    .layout-car .layout-car-bg {
      padding: 0 8px;
    }
  }

}

@media screen and (max-width: 740px) {

  /****** css for header* **********/

  /****** css for header* **********/

  /****** css for Finance open **********/

  /****** css for Finance finish **********/

  .inv-car-view .inv-car-box {
    .row-inv-car {
      .inv-car-info {
        .car-title {
          font-size: 14px;
        }
      }

      .car-detail-extra {
        padding-right: 5px;
      }
    }

    .row-inv-car-top {
      padding: 0 10px 0 10px;
    }
  }

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo .carousel-inner {
    margin-bottom: 10px;
  }

  .layout-inventory-container .layout-car-body .list-view-item .detailed-info-container {
    aspect-ratio: auto 4/2.82;
  }

}

@media screen and (max-width: 720px) {

  /****** css for header* **********/

  /****** css for header* **********/


  /****** css for Finance open **********/

  #carCarouselBody {
    padding: 0;
  }

  /****** css for Finance finish **********/

  .inv-car-view .inv-car-box .inv-car-calculator {
    height: auto;
    padding: 10px 15px 0 15px;

    .container-left-apply {
      .container-apply-online, .container-choose-car, .container-get-finance {
        padding-bottom: 5px;
      }

      .container-button-apply {
        padding: 10px 0 30px 0;
      }

      .container-apply-online, .container-choose-car, .container-sign-go {
        label {
          font-size: 15px;
          padding-left: 10px;
        }
      }
    }
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-control.right, .carousel-control.left {
      top: 34px;
    }

    .semi-circle-carousel {
      width: 50px;
      height: 25px;
    }

    .carousel-inner .container-car-image-carousel {
      img {
        width: 63px;
      }
    }
  }
}

@media screen and (max-width: 700px) {

  /****** css for header* **********/

  /****** css for header* **********/

  /****** css for Finance open **********/

  /****** css for Finance finish **********/

  .layout-inventory {
    #carCarouselBody {
      padding: 0;
    }

    .layout-car-bg {
      top: -230px;
    }
  }

  .inv-car-view {
    top: 90px;

    .row-inv-car {
      flex-direction: column;

      .inv-car-info {
        .inv-car-foot {
          .row-info-send-message {
            align-items: center;
            padding: 10px 0;

            .get-in-touch-header {
              display: flex;
              align-items: center;
              width: 100%;
              margin-left: 15px;
              padding-top: 10px;
              padding-bottom: 20px;
            }

            .container-plus {
              margin-right: 10px;
            }

            label {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .inv-car-box .row-inv-car {
      .inv-car-img, .inv-car-info {
        width: 100%;
      }

      #carCarouselTwo {
        .semi-circle-carousel {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 47px;
        }

        .carousel-control.left {
          left: -33px;
          top: 48px;
        }

        .carousel-control.right {
          right: -33px;
          top: 48px;
        }

        .carousel-inner .container-car-image-carousel {
          height: 104px;
          padding: 0 1px 0 1px;

          img {
            width: 123.5px;
          }
        }
      }

      .car-detail-extra {
        .btn-car-extra {
          min-width: 130px;
          font-size: 100%;
        }
      }

      .inv-car-info {
        padding: 0 15px;
        .row-info-send-message {
          width: 100%;

          .container-send-message-mobile {
            padding-left: 1%;
          }

          .row-email, .row-name, .row-message {
            padding: 1.5% 0;
            @include col100;
          }

          .row-btn-send {
            @include col100;
            @include item-center;
            padding: 3% 0 5% 0;

          }

          label {
            font-size: 14px;
            margin-bottom: 5px;
          }

          input {
            height: 36px;
          }

          .container-plus {
            width: 10%;
            position: relative;
            display: flex;
            float: left;
            //padding: 10px 0 0 10px;
            .container-icon {
              height: 30px;
              width: 30px;

              svg:hover {
                cursor: pointer;
              }
            }
          }

          .container-send-message-mobile {
            display: block;
          }

          .get-it-touch {
            font-size: 30px;
            width: 88%;
            float: right;
          }

          .first-row-send-message, .second-row-send-message, .third-row-send-message {
            display: none;
          }
        }

        .row-info-details {
          width: 100%;
          padding-top: 1.5%;
        }

        .row-info {
          h3 {
            font-size: 30px;
          }

          .price {
            font-size: 35px;
          }

          .price-old {
            font-size: 24px;
          }
        }

        .inv-car-foot {
          .row-info {
            width: 50%;

            .col-50 {
              padding-right: 10px;
            }
          }
        }

        .car-contact {
          float: right;
        }

        .box-info {
          font-size: 17px;
          height: 36px;
        }

        .car-title {
          font-size: 22px;
          margin: 0 0 10px 0;
        }
      }
    }

    .inv-car-box {
      border-radius: 10px;

      .inv-car-calculator{
        padding: 15px;

        .container-right-calculate {
          width: 100%;
          padding-right: 0;
          border-right: 0;

          .first-row {
            padding-bottom: 0;

            .container-price-vehicle, .container-interest-rate, .container-months {
              width: 100%;
              padding: 1.5% 0;

              label {
                font-size: 14px;
              }

              input {
                height: 36px;
              }
            }
          }

          .second-row {
            .container-payment-amount, .container-amount-for-trade {
              width: 100%;
              padding: 1.5% 0;

              label {
                font-size: 14px;
              }

              input {
                height: 36px;
              }
            }

            .container-button-calculate {
              @include col100;
              padding: 4% 0;
              display: flex;
              justify-content: center;
            }
          }

          .third-row {
            .flex-row {
              justify-content: center;
            }
          }

          .fourth-row {
            width: 100%;

            .container-monthly-payment {
              width: 100%;
              padding: 1.5% 0;

              label {
                font-size: 14px;
              }

              input {
                height: 36px;
              }
            }
          }
        }

        .container-left-apply {
          width: 100%;
          padding: 0;
          text-align: center;

          .container-apply-online, .container-choose-car, .container-sign-go {
            padding-bottom: 2%;
            @include item-center;

            label {
              font-size: 20px;
            }

            .container-icon {
              width: 30px;

              .icon-search {
                width: 30px;
                height: 30px;
              }
            }
          }

          .container-get-finance {
            label {
              font-size: 30px;
            }

            p {
              font-size: 14px;
            }
          }

          .container-button-apply {
            .flex-row {
              justify-content: center;
            }
          }
        }
      }

      .row-inv-car {
        .car-detail-extra {
          width: 33%;
          padding: 0 1% 0 1%;

          .btn-car-extra {
            width: 95%;
            height: 36px;
          }
        }
      }

      .row-inv-car-top {
        padding: 10px 0 10px 2%;
      }

      .inv-car-des {
        .car-des, .car-feature {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .row-info {
    padding-top: 50px;
  }

  .car-search-advance {
    .car-row-clean-filters {
      margin-right: 0;
    }
  }

  .inv-car-view {
    .inv-car-content {
      .inv-car-box {
        .inv-car-des {
          .car-des {
            width: 100%;
          }

          .car-feature {
            width: 100%;
          }
        }
      }
    }
  }

  .carousel-content-text-2 {
    padding-left: 70px;
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .row-info-details .dot-color {
    width: 20px;
    height: 20px;
  }

  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo .carousel-inner {
    margin-bottom: 0;
  }


  .layout-inventory-container-car-c {
    .inv-car-view .inv-car-box .inv-car-des .car-des .inventory-description-wrapper {
      height: inherit;
    }
  }

}

@media screen and (max-width: 680px) {

  .finance-form-wizard {
    .tab-finance .nav-tabs {
      display: none;
    }
  }

}

@media screen and (max-width: 690px) {
  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-control.right {
          right: -21px;
        }

        .carousel-control.left {
          left: -18px;
        }

        .carousel-inner .container-car-image-carousel {
          height: 104px;

          img {
            width: 115px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 670px) {

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-control.left, .carousel-control.right {
          top: 45px;
        }

        .carousel-inner .container-car-image-carousel {
          height: 96px;

          img {
            width: 114px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 660px) {

  .layout-finance {

    .body-left-position {
      .text-three-steps {
        font-size: 25px;
      }
    }

    .finance-part {
      padding-top: 33px;
    }
  }

  .layout-car-filter {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  .layout-inventory-container {

    .layout-car-body {
      width: 100%;
      padding-top: 20px;
    }

    .layout-car-pagination {
      width: 100%;
    }

  }

  .car-search-advance .car-label-number-from {
    width: 20%;
  }

  .car-search-advance .car-input-number-from, .car-search-advance .car-input-number-to {
    width: 35%;
    padding-bottom: 0;
  }

  .car-search-keyboard {
    .btn-search {
      width: 50%;
      padding-left: 10px;
    }

    .btn-filter {
      width: 100%;
      display: block;
    }

  }

  .car-search-advance {
    .car-row-clean-filters {
      width: 50%;
      padding-right: 10px;
      padding-top: 15px;
    }

    .car-row-apply-filters {
      padding-top: 15px;
      width: 50%;
      padding-left: 10px;
    }
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-inner .container-car-image-carousel {
      img {
        width: 105px;
      }
    }
  }
}

@media screen and (max-width: 640px) {

  .layout-finance {

    .body-left-position {
      .text-three-steps {
        font-size: 25px;
      }
    }

    .finance-part {
      padding-top: 33px;
    }

    .finance-image-style {
      width: 35px;
      height: 35px;
    }

  }


  .inv-car-view .inv-car-box .row-inv-car-top {
    padding: 10px 10px 0 10px;
  }

}

@media screen and (max-width: 620px) {

  .layout-finance {
    .body-left-position {
      .text-three-steps {
        font-size: 25px;
      }
    }

    .finance-part {
      padding-top: 33px;
    }
  }

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-control.left {
          top: 42px;
          left: -20px;
        }

        .carousel-control.right {
          top: 42px;
        }

        .carousel-inner .container-car-image-carousel {
          height: 88px;

          img {
            width: 100px;
          }
        }

        .semi-circle-carousel {
          width: 80px;
          height: 40px;
        }
      }
    }
  }

  .layout-container-main-c {
    .row-layout-car {
      padding-left: 5px;
    }
  }
}

/* phones */
@media screen and (max-width: 600px) {


  #layCar .layout-car-content {
    top: -230px;
  }

  #locationMain {
    padding-top: 0;
    margin-top: 5%;
  }

  .container-form-get-in-touch {
    height: 100%;
    padding-bottom: 0;
  }

  #layoutAboutContainer {
    .layout-customer-testimonial {
      .content-up-down {
        left: 0;
        width: 100%;
      }
    }

    #nextT {
      left: 0;
    }

    #backT {
      left: unset;
    }
  }

  .container-get-in-touch {
    width: 100%;
    float: left;
    height: 450px
  }

  .carousel-content-social-network {
    float: left;
  }

  /****** css for get in touch ******/

  .layout-finance {

    .body-components {
      @include item-center;
      flex-direction: column;
      padding: 80px 0;
    }

    .body-left {
      @include col100;
      text-align: center;
    }

    .body-left-position {
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      text-align: center;

      .text-get-finance {
        font-size: 50px;
        text-align: center;
      }

      .text-three-steps {
        font-size: 25px;
        text-align: center;
      }

      .finance-apply {
        display: none;
      }
    }

    .finance-apply {
      text-align: center;
      position: relative;
      z-index: 100;
    }

    .body-right {
      float: right;
      width: 100%;
      flex-direction: column;

      .finance-apply-end {
        display: block;
      }
    }

    .body-right-position {
      position: relative;
      padding: 60px 20px 0 10px;
      display: flex;
    }

    .finance-rectangle {
      background-color: white;
      width: 33.33%;
      margin-bottom: 0;
      margin-left: 10px;
      border-radius: 10px;
      display: block;
      height: auto;
      min-height: 170px;
      padding: 30px 20px;
    }

    .finance-image {
      text-align: center;
      width: 100%;
      padding-bottom: 30px;
      @include item-center;

      > div > div {
        @include item-center;
      }
    }

    .finance-image-style {
      height: 40px;
      width: auto;
    }

    .finance-part {
      color: #7f7f7f;
      font-size: 15px;
      padding: 0;
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    .body-image {
      img {
        width: 100%;
      }
    }
  }

  .layout-inventory {
    .car-carousel {
      top: -560px;
    }

    .layout-car-bg {
      top: -330px;
    }
  }

  .finance-form-wizard {
    margin: 0 25px;
  }

  .panel-finance .colR25, .panel-finance .colR16, .panel-finance .col25,
  .panel-finance .col16, .panel-finance .col50, .panel-finance .colR50,
  .panel-finance .colR80, .panel-finance .col20 {
    width: 100%;
  }

  .finance-form-vertical, .finance-form-5 .finance-form-wizard {
    padding: 0 10px 10px 10px;
    overflow: hidden;
  }

  .finance-form-wizard {
    margin: 0 10px;
    padding: 0 10px 10px 10px;
    overflow: hidden;
  }


  .inv-car-view .inv-car-box .row-inv-car .car-detail-extra {
    padding-right: 1px;

    .btn-car-extra {
      min-width: 110px;
    }
  }

  .layout-container-main-b {

    .layout-car {
      top: -45px;
    }

    #locationMain {
      top: -50px;
    }
  }

  .layout-container-main-b {
    .layout-footer {
      top: 0 !important;
    }
  }

  .layout-container-main {
    .container-sections {
      .container-banner-car {
        .layout-car {
          padding: 6vw 3vw;

          .layout-car-content {
            padding: 0 !important;

            .row-layout-car {
              padding-left: 0;
              padding-right: 0;
              justify-content: space-around;

              .col2 {
                padding: 0;
                width: 46%;
              }
            }

            .row-car-more {
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 580px) {


  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          height: 80px;

          img {
            width: 95px;
          }
        }

        .carousel-control.left, .carousel-control.right {
          top: 41px;
        }

        .semi-circle-carousel {
          width: 74px;
          height: 38px;
        }
      }
    }
  }

}

@media screen and (max-width: 560px) {

  .inv-car-view .inv-car-box .row-inv-car {
    .car-detail-extra {
      .btn-car-extra {
        font-size: 92%;
      }
    }

    .inv-car-info {
      .row-info {
        h3 {
          font-size: 26px;
        }

        .price-old {
          font-size: 18px;
          padding-left: 5px;
        }

        .price {
          font-size: 28px;
        }
      }

      .row-info-send-message {
        .get-it-touch {
          font-size: 26px;
        }
      }
    }
  }

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          img {
            width: 91px;
          }
        }

        .carousel-control.left {
          left: -17px;
        }

        .carousel-control.right {
          right: -17px;
        }
      }
    }
  }

  .container-form-get-in-touch .title-get-in-touch {
    font-size: 40px;
  }

  //Media query advanced search

}

@media screen and (max-width: 540px) {


  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          img {
            width: 90px;
          }
        }
      }

      .label-color {
        font-size: 15px;
      }
    }
  }

  .semi-circle-down {
    left: 86.5%;
  }
}

@media screen and (max-width: 530px) {

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          height: 78px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {


  #layout-about-container {
    .row-carousel {
      padding: 0 40px 0 40px;
    }

    .carousel-control {
      top: 23%;
    }
  }
  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-control.left {
          left: -11px;
          top: 38px;
        }

        .carousel-control.right {
          right: -11px;
          top: 38px;
        }

        .carousel-inner .container-car-image-carousel {
          height: 72px;

          img {
            width: 82px;
          }
        }

        .semi-circle-carousel {
          width: 70px;
          height: 36px;
        }
      }
    }
  }

}

@media screen and (max-width: 510px) {
  .layout-customer-testimonial {
    .carousel-content-2 {
      .carousel-content-text-2 {
        padding: 65px 20px 0 20px !important;

        .carousel-content-image-2 {
          top: -45px !important;

          img {
            height: 90px;
            width: 90px;
          }
        }

        .testimonial-content {
          -webkit-line-clamp: 5;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {

  .inv-car-view .inv-car-box .row-inv-car .car-detail-extra {
    padding-right: 5px;
  }

  .inv-car-view .inv-car-box .row-inv-car .car-detail-extra .btn-car-extra {
    min-width: 80px;
    font-size: 90%;
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo {
    .carousel-inner .container-car-image-carousel {
      img {
        width: 80px;
      }
    }
  }

  .layout-inventory-container-car {
    #carCarouselBody {

      .row-carousel {
        padding: 0 22px 0 40px;
      }

      .carousel-control {
        width: 8vw;

        .semi-circle-carousel {
          width: 6vw;
          height: 14vw;
          max-height: 60px;
          min-height: 55px;
        }
      }

      .carousel-inner {
        .row-carousel {
          display: flex;
          width: 85vw;
          justify-content: space-around;
          margin: auto;
          padding: 0;

          .col-car2 {
            padding: 0;
            width: 49%;
          }
        }
      }
    }
  }

  .carousel-content-text-2 {
    padding-left: 48px;
  }

  .lay-get-in-touch {

    .semi-circle-down {
      left: 85.2%;
    }
  }

  .modal-box {
    .modal-dialog {
      width: 100%;
      padding: 0 10px;
    }
  }

  .container-form-get-in-touch .title-get-in-touch {
    font-size: 38px;
  }
}

@media screen and (max-width: 480px) {

  #locationMain {
    .layout-location-left {
      .container-location {

        .location-description {
          font-size: 15px;

          .container-description .description {
            padding-left: 10px;
            padding-bottom: 5px;
          }
        }

        .location-header .title-location {
          font-size: 20px;
        }
      }
    }

    #map {
      height: 350px !important;
    }
  }

  /* Home page - TemplateMain Mobile */


  .layout-car .layout-car-content {
    .layout-car-body {
      .row-layout-car {
        .row-car-info {

          .car-item-title {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .row-car-info-btn {
          height: 25px;

          .row-car-info-tags {
            align-items: flex-start;
          }
        }

        .row-car-info-footer {
          display: flex;
          height: 44px;

          .carfax-content {
            display: flex;
            flex-direction: column-reverse;

            img {
              width: 56px !important;
            }
          }

          .car-item-price {
            display: flex;
            flex-direction: column-reverse;
            margin-left: auto;

            label {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: bold;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .layout-container-main .layout-car {
    padding: 0 10px 20px 10px;
    margin-top: 140px;

    .layout-car-content {
      padding-top: 0 !important;

      .layout-car-header {
        padding: 0 0 0 10px;

        h2 {
          font-size: 17px;
        }
      }
    }

    .banner-img-container {
      margin-top: -14%;
      width: 50%;
    }
  }

  .finance-form-vertical, .finance-form-wizard {
    .tab-finance {
      .tab-content {
        .colR25, .colR50, .colR20, .colR80, .colR16, .colR75 {
          width: 100%;
        }
      }
    }
  }

  .finance-form-wizard {
    .finance-btn {
      padding-top: 10px;

      .btn-previous {
        width: 49%;
        margin-right: 1%;
      }

      .btn-primary {
        width: 49%;
        margin-left: 1%;
      }

      button {
        font-size: 12px;
      }
    }
  }

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          height: 70px;

          img {
            width: 75px;
          }
        }

        .semi-circle-carousel {
          width: 66px;
          height: 34px;
        }
      }
    }
  }

  .layout-car .btn-car-content {
    padding-right: 5px;
  }

  .layout-inventory-container .layout-car {
    margin-bottom: 100px;
  }

  .layout-container-main-b {


    .layout-car {
      padding: 120px 10px 20px 10px;
      margin-top: 0;
    }
  }

  .layout-container-main, .layout-container-5 {
    .lay-get-in-touch {
      .get-in-touch-message-box {
        .container-get-in-touch {
          .container-form-get-in-touch {
            .form-get-in-touch {
              form {
                .form-first-row {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  gap: 30px;

                  .form-first-row-element, .form-first-row-second-element {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-control.left {
          left: -10px;
        }

        .carousel-control.right {
          right: -10px
        }
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .layout-inventory-container {

    .layout-car-bg {
      padding: 0 10px;
    }

    .row-layout-car {
      padding: 10px 5px 10px 20px;
    }
  }

  .layout-finance .finance-image-style {
    height: 32px;
  }

  .row-layout-car {
    padding: 5px;
  }

  .row-car-more {
    padding-top: 20px;
  }

  .layout-car {
    .car-item-title {
      font-size: 14px;
      font-weight: normal;
    }

    .car-item-price {
      font-size: 14px;

      label {
        font-weight: normal;
        font-style: normal;
      }
    }
  }

  .inv-car-view .inv-car-box .row-inv-car {
    .inv-car-info {
      .row-info {
        h3 {
          font-size: 22px;
        }

        .price-old {
          font-size: 16px;
          padding-left: 4px;
        }

        .price {
          font-size: 24px;
        }
      }

      .row-info-send-message {
        .get-it-touch {
          font-size: 22px;
        }
      }
    }
  }
  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          img {
            width: 73px;
          }
        }
      }
    }
  }

  .layout-customer-testimonial h4 {
    bottom: 44px;
    font-size: 15px;
  }

  .lay-get-in-touch {
    height: 100%;
    padding-top: 30px;

    .semi-circle-up {
      left: 5%;
    }
  }

  .lay-get-in-touch .semi-circle-up {
    left: 3%;
  }

  .lay-get-in-touch .semi-circle-down {
    left: 84.2%;
  }
}

@media screen and (max-width: 450px) {

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          img {
            width: 70px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {

  .inv-car-view {
    .inv-car-content {
      padding: 0 10px;
    }

    .car-carousel {
      margin-top: 55px !important;
    }
  }
  .lay-get-in-touch .semi-circle-down {
    left: 83.2%;
  }
}

@media screen and (max-width: 430px) {

}

@media screen and (max-width: 420px) {

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .car-title {
    font-size: 20px;
    margin: 0;
  }

  .inv-car-view .inv-car-box .inv-car-des {
    padding: 15px 15px 15px 10px;
  }

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          height: 56px;

          img {
            width: 66.5px;
          }
        }

        .carousel-control.left {
          top: 35px;
          left: -14px;
        }

        .carousel-control.right {
          top: 35px;
          right: -14px;
        }

        .semi-circle-carousel {
          width: 52px;
          height: 27px;
        }
      }
    }
  }

  .lay-get-in-touch .semi-circle-up {
    left: 3.8%;
  }

  .layout-finance {
    .finance-apply {
      text-align: center;
      left: unset;
      right: 0;
      z-index: 100;
      margin-top: 0;
    }

    .finance-apply-end {
      width: 100%;
      padding: 30px;
    }

    .body-right .finance-apply-end {
      @include item-center;
    }
  }

  .layout-car-filter {
    padding: 10px 10px 0 10px;
  }

  .layout-inventory-container {
    .row-layout-car {
      padding: 10px 0 10px 10px;

      .col2 {
        padding-right: 10px;
      }
    }

    .layout-car-body {
      padding-top: 10px;
    }
  }

  #locationMain {
    padding-top: 0;
    margin-top: 5%;
    display: flex;
    flex-direction: column;

    .layout-location-left {
      width: 100%;
      height: auto;
    }

    #map {
      width: 100% !important;
      height: 500px !important;
    }
  }
}

@media screen and (max-width: 410px) {

}

@media screen and (max-width: 400px) {

  /****** css for header* **********/


  .carousel-content-1 {
    height: auto;
    margin-top: 83px;
  }

  .carousel-content-2 {
    height: auto;
    margin-top: 59px;
  }

  .carousel-content-3 {
    height: auto;
    margin-top: 80px;
  }

  .semi-circle-down {
    left: 87%;
  }

  .semi-circle-up {
    left: 4.5%;
  }

  /****** css for get in touch ******/

  .finance-form-wizard .finance-btn .btn-primary, .finance-form-wizard .finance-btn .btn-previous {
    font-size: 10px;
  }

  .layout-inventory-container .layout-finance .body-right-position {
    padding: 0 2% 0 4%;
  }

  .layout-car {
    .car-item-title {
      font-size: 14px;
    }

    .car-item-price {
      font-size: 14px;
    }
  }

  .inv-car-view .inv-car-box .row-inv-car {
    .inv-car-info {
      .row-info {
        h3 {
          font-size: 20px;
        }

        .price-old {
          font-size: 14px;
        }

        .price {
          font-size: 22px;
        }
      }

      .row-info-send-message {
        .get-it-touch {
          font-size: 20px;
        }
      }
    }
  }

  .lay-get-in-touch .semi-circle-up {
    left: 3.3%;
  }

  .lay-get-in-touch .semi-circle-down {
    left: 86.7%;
  }

  .lay-get-in-touch {
    .layout-customer-testimonial {
      .carousel-content-0 {
        margin-top: 58px;
      }

      .carousel-content-1 {
        margin-top: 28px;
      }

      .carousel-content-2 {
        margin-top: 4px;
      }

      .carousel-content-3 {
        margin-top: 25px;
      }

      .carousel-content-4 {
        margin-top: 59px;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo .carousel-inner .container-car-image-carousel {
    img {
      width: 64px;
    }
  }

  .about-testimonial .layout-dealer-carousel, .layout-customer-testimonial {
    .content-next .semi-circle-next, .content-back .semi-circle-back {
      height: 40px;
      width: 20px;
    }
  }
}

@media screen and (max-width: 380px) {

  .layout-inventory-container .layout-finance .finance-part {
    font-size: 16px;
  }

  .inv-car-view .inv-car-box .row-inv-car .car-detail-extra {
    padding-right: 1px;
  }

  .inv-car-view .inv-car-box .row-inv-car .car-detail-extra .btn-car-extra {
    min-width: 70px;
    font-size: 80%;
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .car-title {
    font-size: 18px;
    margin-top: 5px;
  }

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          height: 55px;

          img {
            width: 60px;
          }
        }

        .carousel-control.left {
          top: 34px;
          left: -12px;
        }

        .carousel-control.right {
          top: 34px;
          right: -12px;
        }

        .semi-circle-carousel {
          width: 49px;
          height: 25px;
        }
      }
    }
  }

  .lay-get-in-touch .semi-circle-up {
    left: 4.5%;
  }

  .lay-get-in-touch .semi-circle-down {
    left: 80%;
  }
}

@media screen and (max-width: 360px) {

  .layout-inventory-container .layout-finance .finance-part {
    font-size: 14px;
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .car-title {
    font-size: 18px;
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .car-title {
    font-size: 16px;
  }

  .inv-car-view .inv-car-box .row-inv-car {
    .inv-car-info {
      .row-info {
        h3 {
          font-size: 16px;
        }

        .price-old {
          font-size: 12px;
        }

        .price {
          font-size: 18px;
        }
      }

      .row-info-send-message {
        .get-it-touch {
          font-size: 18px;
        }
      }
    }
  }
  .inv-car-view .inv-car-box .row-inv-car #carCarouselTwo .carousel-inner .container-car-image-carousel img {
    width: 57px;
  }

  .lay-get-in-touch .semi-circle-up {
    left: 4.5%;
  }

  .lay-get-in-touch .semi-circle-down {
    left: 79%;
  }

  .footer-bar-bottom {
    padding: 0 40px !important;
  }
}

@media screen and (max-width: 340px) {

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .row-info .price-old {
    font-size: 14px;
  }

  .inv-car-view .inv-car-box .row-inv-car .inv-car-info .car-title {
    font-size: 14px;
  }

  .inv-car-view {
    .inv-car-box .row-inv-car {
      #carCarouselTwo {
        .carousel-inner .container-car-image-carousel {
          height: 52px;

          img {
            width: 51px;
          }
        }

        .carousel-control.left {
          top: 32px;
          left: -8px;
        }

        .carousel-control.right {
          top: 32px;
          right: -8px;
        }

        .semi-circle-carousel {
          width: 46px;
          height: 24px;
        }
      }
    }
  }

  .lay-get-in-touch .semi-circle-up {
    left: 1%;
  }
}

/****** Media Queries* **********/

@media (min-width: 1250px) {
  .lay-get-in-touch {
    display: -webkit-inline-box;
  }
}

// Car cards for mobile

@media (max-width: 768px) {
  .layout-container-main {
    .container-sections {
      .container-banner-car {
        .layout-car {
          .row-layout-car {
            .col2 {
              .container-car-image {
                img {
                  border-radius: 7px 7px 0 0;
                }
              }

              .car-item-info {
                padding: 5px 11px;

                .row-car-info {
                  height: auto;

                  .car-item-title {
                    font-size: 15px;
                  }
                }

                .row-car-info-btn {
                  .row-car-info-tags {
                    .btn-car-content {
                      .btn-car-info {
                        margin-top: 0;
                      }
                    }
                  }
                }

                .car-item-price {
                  font-size: 12px;
                  text-align: left;
                }

                .row-car-info-footer {
                  height: auto;
                }
              }

            }
          }

        }
      }
    }
  }
}
