.dp-select-dropdown{
  position: relative;
  .select{
    //float: left;
    //width: calc(25% - 180px);
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    height: 34px;
    align-items: center;
    display: flex;
    padding-left: 10px;
    padding-right: 30px;
    position: relative;
    &:hover{
      cursor: pointer;
      label{
        cursor: pointer;
      }
    }
  }
  .container-label{
    width: 100%;
    display: flex;
    label{
      margin: 0;
      font-weight: 400;
      font-size: 13px;
    }
  }
  .container-icon{
    position: absolute;
    right: 10px;
    .triangle-down{
      width: 15px;
    }
  }
  //.has-error{
  //  margin-bottom: 10px;
  //}
  .help-block{
    position: absolute;
    top: 36px;
    font-size: 9px;
  }
  .select-disabled{
    cursor: not-allowed !important;
    background-color: #f5f6fa;
    label{
      cursor: not-allowed !important;
    }
  }
  .popover-for-select{
    overflow: auto;
    max-height: 110px;
    box-shadow: 0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12);
    border-radius: 2px;
    padding: 5px 0;
  }
  .popover-body-for-select{
    padding: 0;
    margin: 0;
    color: #979797;
    z-index: 100;
    position: relative;
    .option-label-for-select{
      font-size: 13px;
      font-weight: 400;
    }
    .container-option-for-select, .container-option-for-select-checked{
      padding: 0 10px;
      &:hover{
        background-color: #EFEFEF;
        cursor: pointer;
        label{
          cursor: pointer;
        }
      }
    }
    .container-option-for-select-checked{
      background-color: #3B86FF;
      &:hover{
        background-color: #3B86FF;
      }
      color: white;
      label{
        color: white;
      }
    }
  }
}

