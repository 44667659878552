@import "src/common/styles/constants";

.dp-icon {
  display: inline-block;
  height: 25px;
  width: 25px;

  //svg {
  //  height: 18px;
  //  width: 18px;
  //}
}

.dp-icon.edit-icon {
  fill: $light-blue-color-1
}

.dp-icon.inactive-user-icon {
  .a {
    fill: $button-color-red
  }
}

.dp-icon.active-user-icon {
  .a {
    fill: $green-color
  }
}

.dp-icon.delete-icon {
  fill: $button-color-red
}

.dp-icon.inactive-badge {
  svg{
    height: 20px;
    width: 60px;
    .a{
      fill: $button-color-red;
    }
  }
}

.dp-icon.save-icon {
  .a {
    fill: $green-color
  }
}

.dp-icon.arrow-back-icon {
  .a {
    fill: $light-blue-color-2
  }
}

.dp-icon.arrow-next-icon {
  .a {
    fill: $light-blue-color-2
  }
}

.dp-icon.close-icon{
  width: 32px;
}