@import "../../../common/styles/constants";
.container-days-of-week{
  display: flex;
  gap: 3px;
  border: 1px solid $button-not-selected-background;
  padding: 3px;
  border-radius: 10px;

  .day-of-week{
    background-color: $button-not-selected-background;
    color: #0B1524;
    height: 40px;
    padding: 5px;
    width: 40px;
    border: none;
    border-radius: 8px;
  }
  .day-selected{
    background-color: #2A5AFA;
    color: #EFEFEF;
  }
  .day-disabled{
    background-color: #bebebe;
    cursor: not-allowed;
  }
  font-size: 14px;
}