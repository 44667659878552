@import "src/common/styles/utils";
@import "src/catalog/common/common";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght$100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght$100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght$100;700&display=swap');

$font-family: 'Open Sans', sans-serif;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 200;
$title-color: #272727;
$height-input-mobile-view: 36px;
$arrow-color: #CACACA;
$background-dark-color: #000;
$background-dark-color-2: #222;
$border-dark-color: #CACACA;

/***************** HEADER ******************/
//todo: here only style for HEADER

@mixin one-single-line-text() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin item-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popover-scroll::-webkit-scrollbar {
  width: 8px; /* Tamaño del scroll en vertical */
  height: 8px; /* Tamaño del scroll en horizontal */
  //display: none;  /* Ocultar scroll */
}

.container-testimonial-mobile {
  display: none;
}

.container-testimonial-web-tablet {
  display: block
}

.dp-pagination-container {
  float: right;
  position: relative;

  .popover-pagination-box {
    float: left;
    width: 100%;
    padding: 10px 10px 5px 10px;
    color: #979797;
    text-align: center;
    position: relative;

    .items-by-page {
      .flex-row {
        button {
          padding: 0;
          width: 100%;
        }
      }
    }

    .items-by-page {
      padding-bottom: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .pagination-left {
    float: left;
    border-radius: 15px;

    .btn-page-group {
      background-color: #FFF;
      border: 1px solid #D7DAE2;
      border-radius: 17px;
      padding: 5px 10px;
      height: 34px;

      &:focus {
        border: 1px solid #D7DAE2 !important;
      }

      .icon-container {
        float: left;

        .icon-menu-list, .icon-unfold {
          height: 10px;
        }

        svg {
          fill: #A4AFB7;
        }
      }

      .label-page-selected {
        float: left;
        padding: 0 5px 0 5px;
        color: #787878;
      }
    }
  }

  .pagination-right {
    svg {
      fill: #A4AFB7;
      width: 12px;
    }

    float: right;
    padding-left: 10px;

    .btn-back {
      border-radius: 17px 0 0 17px;
      background-color: #FFF;
      border: 1px solid #D7DAE2;
      padding: 7px 12px;
      height: 34px;

      &:focus {
        border: 1px solid #D7DAE2 !important;
      }
    }

    .btn-center {
      background-color: #FFF;
      height: 34px;
      border: 1px solid #D7DAE2;
      padding: 7px 10px 7px 7px;

      .label-page-selected {
        float: left;
        padding: 0 5px 0 5px;
        color: #787878;
      }

      .label-page-off {
        padding: 0 5px 0 0;
        color: #787878;
        font-weight: 300;
      }

      &:focus {
        border: 1px solid #D7DAE2 !important;
      }
    }

    .btn-next {
      border-radius: 0 17px 17px 0;
      background-color: #FFF;
      border: 1px solid #D7DAE2;
      padding: 7px 12px;
      height: 34px;

      &:focus {
        border: 1px solid #D7DAE2 !important;
      }
    }
  }
}

/***************** HEADER ******************/

/***************** INVENTORY ******************/
//todo: here only style for INVENTORY
.footer-modern {
  .container-footer-background {
    .footer-limiter {
      position: absolute;
      height: calc(100% + (100vh - 207px));
      width: 2px;
      z-index: 150;
      bottom: 0;
      left: 0;
    }
  }
}

.layout-container-5 {
  background-color: #F4F4F4;
  @include row-base;
  //display: flex;
  justify-content: center;
}

.finance-apply-modern-view {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.container-home-modern-view-2,
.container-home-modern-view-1,
.container-inventory-modern-view-1,
.container-inventory-modern-view-3,
.container-home-modern-view-3 {
  .layout-finance5 {
    .finance-apply-modern-view {
      /* Use the common styles for .finance-apply2 here */
      @extend .finance-apply-modern-view;
    }
  }
}

.container-home-modern-view-2, .container-inventory-modern-view-2 {
  .layout-finance5 .finance-apply-modern-view {
    .btn {
      /* Common styles for .btn inside .finance-apply */
      width: auto;
      padding: 12px 30px;
      font-weight: 600 !important;
      font-size: 16px !important;
    }
    .btn-finance-mv2 {
      font-weight: 600;
    }
  }
}
.container-home-modern-view-2 {
  .car-carousel-template5, .layout-finance5 {

    .inventoryButton {
      padding-bottom: 20px;

      .btn {
        width: auto;
        color: #fff;
        padding: 12px 30px;
        font-weight: 600 !important;
        font-size: 16px !important;
      }
    }

    .finance-apply {
      padding-bottom: 20px;

      .btn-finance {
        width: auto;
        padding: 12px 30px;
        font-weight: 600 !important;
        font-size: 16px !important;
      }
    }
  }

  .lay-get-in-touch {
    .get-in-touch-message-box {
      .container-row-button {
        .container-button-get-in-touch {
          .btn-inv-more {
            width: auto;
            padding: 12px 30px;
            font-weight: 600 !important;
            font-size: 16px !important;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.btn-finance-mv2 {
  border-radius: 4px;
  font-weight: 600;
}

.container-home-modern-view-3 {

  .banner-container-5 .car-search-container {
    bottom: -100px;
  }

  .container-car-search-input {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 100;
    left: 0;

    .car-search-input {
      display: flex;
      width: 48%;
      height: 100%;
      margin: 0 auto;
      padding: 0px 0px 0px 0px;
      background-color: #fff;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 13px 0 rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      justify-content: center;

      input:focus {
        outline: none;
      }

      .input-container {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .car-search-icon {
        width: 5%;
      }

      .container-button {
        width: 25%;
        padding: 3px 3px 3px 0;

        .flex-row{
          height: 100%;
        }
      }
    }

    .form-control-search {
      width: calc(100% - 150px);
      height: 20px;
      font-style: normal;
      color: #0a0c0e;
      margin-top: 0;
      padding: 6px 12px;
      border: 0;
      float: left;
      font-size: 18px;
      font-weight: 300;
    }

    .car-search-icon {
      display: flex;
      float: left;
      color: #0a0c0e;
      margin-top: 0;
      align-items: center;

      img {
        width: 18px;
      }
    }

    .container-button {
      float: right;
      height: 100%;

      .button-input {
        height: 100%;
        width: 100%;
        border-radius: 5px;
        color: #ffffff;
      }
    }
  }

}

.car-item-details {
  @include row-base;
  padding: 15px 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  .btn-car-info {
    border: 1px #cacaca solid;
    border-radius: 3px;
    font-size: 11px;
    padding: 3px 8px;
    color: #B4B4B4;
    font-weight: $font-weight-light;
  }

  .row-item-price {
    padding-bottom: 0 !important;
    margin-bottom: 0;
  }

  .row-details {
    @include row-base;
    display: flex;
    color: #CACACA;
    padding-bottom: 5px;

    .car-description-inventory {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      height: 34px;
      margin: 0 auto;
      line-height: 1.5;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      text-align: left;
      font-weight: $font-weight-regular;
      color: #4D4D4D;
    }
  }

  .btn-details {
    background-color: #fff;
    color: #D10C0C;
    border-radius: 20px;
    min-width: 100px;
    font-weight: $font-weight-semi-bold;
  }

  .btn-content-details {
    padding-top: 20px;
  }
}

.btn-car-content {
  float: left;
  padding-right: 5px;
}

.label-title {
  color: #4D4D4D;
  font-size: 18px;
  font-weight: $font-weight-semi-bold;
  margin: 0;
}

@mixin object-fit-cover() {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin car-image() {
  @include item-center;
  aspect-ratio: 4/3;
  &:hover {
    cursor: pointer;
  }
  img {
    @include object-fit-cover;
  }
}

.car-item {
  width: 100%;

  .car-image-sold {
    @include car-image;
    position: relative;

    &:before {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      content: '';
      background-color: #D10C0C;
      opacity: 0.5;
    }

    &:after {
      display: flex;
      position: absolute;
      height: 100%;
      width: 100%;
      content: 'SOLD';
      color: #FDFDFD;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: 900;
    }
  }

  .car-image {
    @include car-image;
    max-height: 198px;
  }
}

#carCarouselBodyTemplate5 {
  .car-item {
    //--@include car-image;
    display: flex;
  }
}

.car-item-old-price {
  float: right;
  color: #FF3200;
  font-weight: 300;
  font-size: 12px;
  text-decoration: line-through;
  padding-top: 6px;
  padding-right: 8px;
}

@mixin car-item-price() {
  color: #00AA2B;
  font-weight: 400;
  font-size: 18px;
  padding-top: 5px;
}

.car-item-price-soon {
  @include car-item-price;
  color: #ff565e;
}

.car-details {
  font-size: 12px;
  text-align: center;
}

.catalog-section-title {
  font-size: 32px;
  color: #515151;
  font-weight: bold;
}

.car-carousel-template5 {
  @include row-base;
  padding-top: 104px;

  .inventory-title {
    @include row-base;
    justify-content: center;
    display: flex;

    label {
      font-size: 28px;
      color: #515151;
    }
  }

  .carCarouselMobile {
    margin-top: 65px;
    position: relative;
  }

  .inventoryButton {
    @include row-base;
    text-align: center;
    padding-top: 10px;

    .flex-row {
      justify-content: center;

      .btn {
        letter-spacing: normal;
        border-radius: 4px;
        border: none;
      }
    }

    button {
      width: auto;
      padding: 12px 30px;
      font-weight: normal;
      font-size: 16px !important;
    }

    button:hover {
      cursor: pointer;
    }
    .btn-inventory-mv2 {
      font-weight: 600;
      border-radius: 4px;
    }
  }
}

.col-car4 {
  width: 25%;
  float: left;
  padding-right: 20px;
}

#carCarouselBodyTemplate5 {
  padding-top: 45px;
  padding-bottom: 25px;
  @include row-base;

  .semi-circle-carousel {
    width: 30px;
    height: 60px;
    border-color: #D10C0C;
    border-style: solid;
    background: #fff;
    top: 90px;
    position: absolute;

    i {
      color: #D8D8D8;
      font-size: 24px;
      margin-top: 13px;
      position: relative;
      top: 0;
    }
  }

  .sc-left {
    -moz-border-radius: 60px 0 0 60px;
    -webkit-border-radius: 60px 0 0 60px;
    border-radius: 60px 0 0 60px;
    right: 0;
    border-width: 2px 0 2px 2px;
    width: 10%;
    height: 10%;

    i {
      right: 0;
    }
  }

  .sc-right {
    -moz-border-radius: 0 60px 60px 0;
    -webkit-border-radius: 0 60px 60px 0;
    border-radius: 0 60px 60px 0;
    border-width: 2px 2px 2px 0;
    left: 0;
    width: 10%;
    height: 10%;

    i {
      left: 0;
    }
  }

  .col-car {
    width: 25%;
    float: left;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
  }

  .car-item {
    border: 0;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 0 0 10px 10px;
    @include row-base;

    .container-car-image {
      img {
        border-radius: 10px;
      }
    }
  }

  .car-item:hover {
    .car-item-details {
      //display: block;
    }
  }

  .row-carousel {
    padding: 0 45px 0 65px;
    display: flex;
    flex-wrap: wrap;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-control {
    bottom: -20px;
    top: inherit;
    background-image: none;
    margin: 0 10% 0 23%;

  }
}

/* Carousel media query*/
@media screen and (min-width: 1600px) {
  #carCarouselBodyTemplate5 .row-carousel {
    padding: 0 6.51% 0 7.55%;
  }
}

.pagination-carousel {
  display: flex;
  justify-content: center;
  padding: 0 26% 2% 26%;
  @include row-base;
}

.carousel-bar {
  width: 80%;
  border: 2px solid #2ede0d;
}

.page-indicator {
  border: 2px solid;
}

.col-item-price {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  label {
    margin: 0;
    float: right;
  }
}

.layout-inventory-container {
  .separator-header-5 {
    height: 30px;
  }

  .layout-finance5 {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 40px;

    .finance-header {
      padding: 40px 0;
    }
  }
}

.container-inventory-modern-view-2 {
  .layout-finance5 {
    .finance-apply {
      .btn {
        width: auto;
        height: auto;
        padding: 12px 30px;
        float: none;
        font-weight: 600 !important;
        font-size: 16px !important;
      }
    }
  }
}

.container-header-top-modern-view-2 {
  height: 50px;
  position: fixed;
  width: 100%;
  background: #1b62c3;
  z-index: 300;

  .container-texts {
    @include item-center;
    height: 100%;
    color: #FFF;
    font-size: 16px;

    .link-here {
      padding-left: 4px;
      color: #FFF;
      text-decoration: underline;
    }
  }
}

/***************** INVENTORY ******************/

/***************** FINANCE ******************/

//todo: here only style for FINANCE

.layout-finance5 {
  @include row-base;
  padding-top: 100px;
  background-color: #FFFFFF;
  //margin-top: 50px;
}

.finance-header {
  text-align: center;
  padding: 20px 0;

  h1 {
    color: #515151;
    margin: 0;
    font-size: 28px;
    font-weight: $font-weight-bold;
    padding: 0 10px;
  }
}

.finance-body, .step-effect {
  @include row-base;
  padding: 0 25px 0 65px;
  position: relative;
  display: flex;
  overflow: hidden;

  .finance-box {
    border: 1px solid #bababa;
    background-color: #ffffff;
    position: relative;
    float: left;
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .finance-step-content {
    float: left;
    width: 33.33%;
    padding-right: 40px;
    position: relative;
    display: flex;
  }

  .finance-body-step {
    padding: 50px 50px 0px 50px;
    flex: 1;
    min-height: 273px;
  }

  .finance-icon-step {
    height: 40px;
    fill: #515151;
    width: 40px;
  }

  .finance-label {
    label {
      font-size: 24px;
      color: #515151;
      font-weight: $font-weight-bold;
      padding-top: 10px;
      word-break: break-word;
    }
  }

  .finance-des {
    padding-top: 10px;
    height: 130px;

    label {
      color: #515151;
      font-weight: $font-weight-light;
      word-break: break-word;
      margin: 0;
    }
  }

  .finance-image, .finance-image-dark {
    max-width: 100%;
    position: relative;
    height: 330px;
  }

  .finance-image::before {
    display: block;
    position: absolute;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #fff 100%);
    height: 100%;
    width: 100%;
    content: '';
  }
  .finance-image-dark::before {
    display: block;
    position: absolute;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0, #222 100%);
    height: 100%;
    width: 100%;
    content: '';
  }
}

/*Finance media query*/
@media screen and (min-width: 1600px) {
  .finance-body, .step-effect {
    padding: 0 5.40% 0 7.55%;
  }
}

.step-effect {
  display: none;
  position: relative;
}


.layout-finance5 > .finance-apply {
  @include row-base;
  text-align: center;
  padding-top: 50px;

  .btn-finance {
    color: #ffffff;
    width: auto;
    padding: 12px 30px;
    font-weight: 300 !important;
    font-size: 16px !important;
    white-space: normal;
  }

  .btn-finance:hover {
    cursor: pointer;
  }
}

.finance-indicators {
  @include row-base;
  display: none;
  padding: 20px 50px 0 50px;

  .slide-back, .slide-next {
    float: left;
    width: 15%;
  }

  .bar-progress {
    float: left;
    width: 70%;
    padding-top: 10px;
  }

  .finance-icon-back, .finance-icon-next {
    width: 20px;
  }

  .finance-icon-next {
    float: right;
  }

  .finance-icon-back:hover, .finance-icon-next:hover {
    cursor: pointer;
  }
}

.slider-bar-total {
  height: 2px;
  background-color: #9f9f9f;
  position: relative;
  padding: 0;
  float: left;
  width: 100%;
}

.slider-bar-current {
  height: 5px;
  background-color: #BC1B1B;
  position: relative;
  bottom: 3px;
  transition: 0.8s;
}

.separator-header-5 {
  height: 50px;
  background-color: transparent;
  position: absolute;
  top: 165px;
  @include row-base;
  z-index: 100;
}

.finance-form-5 {
  @include row-base;
  padding-top: 105px;
  position: relative;
  background-color: #F4F4F4;
  padding-bottom: 50px;

  .finance-form-wizard, .finance-form-vertical {
    position: relative;
    top: 0;
    z-index: 100;
    padding: 0 65px;

    .radio-button-main {
      width: 14.2%;
      float: left;
      padding-right: 20px;

      .radio-button {
        opacity: 1;
      }

      button {
        height: 44px;
        font-weight: 500;
        box-shadow: none;
      }
    }

    .typeId {
      min-width: 14.2%;
      width: auto;
    }

    .content-check {
      padding: 20px 0 20px 55px;
      float: left;
      width: 100%;

      .term-condition {
        span {
          font-weight: 300;
        }

        a {
          padding-left: 5px;
          text-decoration: underline;
          font-style: oblique;
          font-weight: $font-weight-regular;
          color: #1987B0
        }
      }

      > div > div {
        label {
          .term-condition {
            span, a {
              font-weight: $font-weight-semi-bold;
            }
          }
        }
      }

    }

    .tab-finance {
      background-color: #fff;
      border-radius: 0;
      //box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 -1px 1px 0 rgba(0,0,0,0.1);
      box-shadow: none;
      border: 1px solid #9F9F9F;
      float: left;

      .btn-finance-dp {
        height: 44px;
        @include row-base;
        background-color: #fff;
        border-radius: 5px !important;
        color: #787878;
        padding: 11px 15px;
        border: 1px #e3e3e3 solid;
      }

      .finance-input {
        border-radius: 5px !important;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }

      .finance-btn {
        .btn-primary, .btn-previous {
          border-radius: 5px !important;
        }

        button {
          height: 40px;
        }

        .btn-primary {
          width: auto;
          height: auto;
          padding: 12px 30px;
          float: none;
          font-weight: normal;
          font-size: 16px !important;
        }
        .btn-finance-mv2 {
          font-weight: 600;
        }

      }

      .radio-button-main {
        button {
          border-radius: 5px !important;
          border: 1px #e3e3e3 solid;
          font-weight: $font-weight-semi-bold;
        }
      }

      .radio-button-select {
        display: none;
        width: 100%;
        padding-right: 10px;
      }

      .finance-input {
        height: 44px;
        border: 1px #D9D9D9 solid;
        width: 100%;
        box-shadow: 0 0 0;

        #dateId {
          font-size: 14px;
          margin-top: 5px;
          margin-left: 10px;
          color: #7F7F7F !important;
        }
      }

      .label-finance {
        color: #7F7F7F;

        label {
          font-weight: $font-weight-semi-bold;
        }
      }

      .label-error {
        color: #D10C0C;
        font-size: 16px;
        font-weight: 300;
      }

      .tab-content {
        padding: 20px 20px 40px 40px;
        float: left;
        width: 100%;
        border-bottom: 1px solid #E2E2E2;

        .colR25 {
          padding-right: 20px;
        }

        .col-finance {
          float: left;
          width: 100%;
          padding-bottom: 10px;
        }

        .btn-finance-dp {
          i {
            float: right;
            padding-top: 2px;
            font-size: 16px;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .panel-finance {
          background-color: #fff;
          border: none;
          box-shadow: none;
          -webkit-box-shadow: none;
          margin-bottom: 0;
          padding-bottom: 0;

          .col-sec-sub {
            padding-bottom: 20px;

            .sec-sub-title {
              h3 {
                margin-bottom: 5px;
                margin-top: 0;
                color: #7F7F7F;
                font-weight: $font-weight-bold;
              }

              label {
                margin-bottom: 20px;
                font-weight: 300;
                font-size: 16px;
                color: #7F7F7F;
              }
            }
          }
        }
      }
    }
  }

  .finance-form-modern-view-2 {
    .tab-finance {
      .finance-btn {
        .btn-primary {
          width: auto;
          height: auto;
          padding: 12px 30px;
          float: none;
          font-weight: 600 !important;
          font-size: 16px !important;
        }
      }
    }
  }

  .finance-form-vertical {
    .view-vertical {
      .finance-btn {
        display: flex;
        padding: 0 0 40px 55px;
        text-align: center;
        float: left;
        width: 100%;
      }
    }
  }

  .finance-form-wizard {
    .colR16, .colR50, .colR25, .colR75 {
      padding-right: 20px;
    }

    .tab-finance {
      .btn-submit {
        width: 200px !important;
      }

      .label-required {
        color: #D10C0C;
        font-size: 16px;
        float: left;
        padding-left: 15px;
        width: 100%;
        text-align: end;
      }

      .finance-btn {
        float: left;
        width: 100%;
        padding: 30px 40px 0 15px;

        .content-check {
          padding: 10px 0 10px 0;
        }

        .btn-previous {
          background-color: #fff;
          font-weight: $font-weight-semi-bold;
        }

        .btn-primary {
          float: right;
          width: 150px;
        }

        .label-error-message {
          color: #D10C0C;
          font-size: 16px;
          float: left;
          padding-left: 15px;
          width: 100%;
        }
      }

      .nav-tabs {
        padding: 10px;
        border-bottom: 1px solid #E2E2E2;
        text-align: center;

        > li {
          margin-bottom: 0;
          display: inline-block;
          float: none;

          > a {
            border: none;

            .finance-icon {
              > div {
                float: left;
              }

              span {
                padding-left: 10px;
                padding-top: 3px;
                float: left;
              }
            }
          }
        }
      }

      .tab-content {
        border-bottom: none;
      }
    }

  }

  .icon-uncheck, .icon-check {
    fill: #CACACA;
    width: 24px;
    height: 24px;
  }

  .finance-tab-icon {
    height: 24px;
  }
}

.layout-finance-container {
  .form-control {
    background-color: #ffffff;
  }

  .lay-get-in-touch {
    .container-get-in-touch {
      .form-get-in-touch {
        .container-button-get-in-touch {
          .btn-inv-more {
            width: auto;
            padding: 12px 30px;
            font-size: 16px !important;
            font-weight: 600 !important;
            border-radius: 30px;
          }
        }
      }

      .form-get-in-touch.mv2 {
        .container-button-get-in-touch {
          .btn-inv-more {
            border-radius: 4px;
          }
        }
      }
    }
  }
}

/***************** FINANCE ******************/

/***************** LOCATION ******************/

//todo: here only style for LOCATION

.layout-location {
  @include row-base;
  background-color: #fff;
  padding: 5% 65px;
  //display: flex;
  position: relative;

  .layout-location-title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    h2 {
      font-size: 28px;
      font-weight: $font-weight-bold;
      color: #515151;
    }
  }

  .layout-location-content {
    @include row-base;
    z-index: 10;
  }

  .layout-location-img {
    position: absolute;
    left: 0;

    img {
      width: 100%;
    }
  }

  .layout-location-left {
    background-color: rgba(255, 255, 255, 0.975);
    border: 1px solid #d2d2d2;
    float: left;
    width: 30%;
    height: 700px;

    h4 {
      margin: 0;
    }

    h1 {
      margin: 0;
    }

    .container-location {
      @include row-base;

      .location-header {
        border-bottom: 1px solid #d2d2d2;
        cursor: pointer;
        display: flex;
        padding: 18px;
        width: 100%;

        .title-location {
          color: #515151;
          font-size: 20px;
          padding: 4px 0 0 11px;
          width: 90%;
          font-weight: $font-weight-bold;
        }

        .icon-message {
          background-color: #d10c0c;
          width: 32px;
          height: 32px;
          border-radius: 24px;
          padding-top: 4px;
          text-align: center;
        }
      }

      .location-description {
        border-bottom: 1px solid #d2d2d2;
        display: none;
        padding: 10px 0 20px 20px;
        @include row-base;

        .container-description {
          position: relative;
          @include row-base;
          display: flex;

          .icon-position, .icon-telephone, .icon-calendar {
            width: 35px;
            text-align: center;
          }

          .description {
            display: grid;
            float: left;
            padding: 3px 0 20px 10px;
            width: 50%;
            color: #7F7F7F;
          }

          .icon-location {
            width: 18px;
            height: 18px;
            fill: #D10C0C
          }

          .icon-location-content {
            padding-top: 10px;
          }
        }
      }
    }
  }

  #map {
    width: 70% !important;
    float: right;
    height: 700px;
    border-top: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
  }

  @mixin btnBase() {
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  #gmapToolTip {
    h4 {
      color: black;
      margin-top: 0;
      margin-bottom: 0.2em;
    }

    .btn {
      @include btnBase;
      margin-top: 0.5em;
      color: white;
      background: black;
    }
  }
}

/* Carousel media query*/

@media screen and (min-width: 1600px) {
  .layout-location {
    padding: 5% 7.55%;
  }
}

/***************** LOCATION ******************/

/***************** CUSTOMER ******************/

//todo: here only style for CUSTOMER
.container-testimonial {
  @include row-base;
  float: none;
  padding: 4% 0 4% 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  .testimonial-header {
    h1 {
      font-size: 28px;
      font-weight: $font-weight-bold;
      color: #515151;
    }
  }

  .container-bar-progress {
    justify-content: center;
    display: flex;
  }

  .bar-progress {
    width: 50%;
    padding-top: 4%;
    display: flex;
    justify-content: center;

    .slider-bar-total {
      background-color: #9f9f9f;
      position: relative;
      float: left;
      width: 100%;
      padding: 0 0;
    }
  }

  .separator-testimonial {
    height: 8px;
    background-color: #BC1B1B;
    margin: 0 5% 4% 5%;
    display: none;
  }
}

/*Separator testimonial media query*/

.testimonial-header {
  width: 100%;
  text-align: center;
  padding-bottom: 0;
}

.body-customer-section {
  width: 100%;
  display: flex;
  height: 32vw;
  max-height: 490px;
  align-items: center;
  padding: 0 7.55%;

  .section-left {
    float: left;
    height: 100%;
    max-height: 490px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .slide-left:hover {
      cursor: pointer;
    }
  }

  .container-testimonial {
    width: 100%;
    padding: 0;
    height: 32vw;
    max-height: 490px;

    .section-testimonial {
      height: 32vw;
      max-height: 449px;
      max-width: 449px;
      transition: 0.8s;
      position: absolute;
      margin: auto;
      width: calc(90% / 3);

      img {
        border-radius: 50%;
      }
    }

    .active {
      display: block;
    }

    .carousel-testimonial {
      @include row-base;
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
    }
  }

  .quotation-mark-up {
    display: block;
    margin-top: -47px;
    z-index: 200;
    position: absolute;
  }

  .quotation-mark-down {
    display: block;
    position: relative;
    float: right;
    margin-top: -70px;
  }

  .section-right {
    float: right;
    height: 32vw;
    max-height: 490px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .slide-right:hover {
      cursor: pointer;
    }
  }

  .testimonial-icon {
    width: 50px;

    .a {
      stroke: inherit;
    }

    .a, .d {
      fill: inherit;
    }
  }

  .row-customer {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1.24fr 2.39fr;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0;

    .quotation-marks {
      color: #BC1B1B;
      font-size: 2.6vw;
      font-family: normal normal bold Oswald;
    }

    .testimonial-content-5 {
      font-size: 0.9vw;
      font-weight: $font-weight-regular;
      margin: auto;
      width: 78%;
      max-height: 100%;
      height: fit-content;
    }

    .col-info {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 2%;

      .col-image {

        .content-image {
          border-radius: 50%;
          overflow: hidden;
          height: 5.6vw;
          width: 5.6vw;
          max-width: 108px;
          max-height: 108px;
          margin: auto;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .container-label-name {
        .label-name {
          font-weight: $font-weight-semi-bold;
          margin: 0;
          padding-right: 0;
          // font-size: 0.73vw;
        }
      }

      .container-label-social-network {
        .label-social {
          font-weight: $font-weight-semi-bold;
          color: #CACACA;
          font-size: 0.62vw;
        }
      }
    }


    .container-label-info {
      .label-info {
        background-color: transparent;
        font-size: 11px;
        font-weight: 200;
      }
    }

  }
}

@media screen and (min-width: 1600px) {
  .body-customer-section {
    .row-customer {
      .col-info {
        .container-label-social-network {
          .label-social {
            font-size: 12px;
          }
        }

        .container-label-name {
          .label-name {
            font-size: 14px;
          }
        }
      }

      .quotation-marks {
        font-size: 50px;
      }

      .testimonial-content-5 {
        font-size: 17px;
      }
    }
  }
  .container-testimonial .separator-testimonial {
    margin: 0 7.55% 4% 7.5%;
  }
}

.slide-back, .slide-next {
  float: left;
  width: 15%;
}


/***************** CUSTOMER ******************/

/***************** CONTACT ******************/

//todo: here only style for CONTACT


/***************** CONTACT ******************/

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn-pagination-content {
    padding-right: 7px;
    float: left;

    a:hover {
      text-decoration: none;
    }
  }

  .btn-pagination {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #686868;
    padding: 0 4px 1px 4px;
    font-size: 18px;
    color: #686868;
  }

  .btn-pagination:hover {
    cursor: pointer;
  }

  button:focus {
    border-bottom: 1px solid #686868 !important;
    //background-color: #0f5d84;
    //background-color: #fff;
    //color: #E2E2E2;
  }

  .pagination-left, .pagination-right {
    //float: left;
    //padding-top: 5px;
    i {
      color: #686868;
    }

    i:hover {
      cursor: pointer;
    }
  }

  .pagination-left {
    padding-right: 7px;
  }
}

.car-item-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  width: 100%;
}

.layout-inventory-5 {
  @include row-base;
  padding-top: 55px;
  z-index: 10;
  position: relative;

  .layout-inventory-body {
    @include row-base;
    background-color: #F4F4F4;
    padding: 30px 45px 30px 65px;
  }

  .row-inventory {
    @include row-base;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .car-item {
      width: 20%;
      float: left;
      padding-right: 20px;
      padding-bottom: 20px;
      display: flex;
    }

    .car-item-box {
      @include row-base;
      background-color: #ffffff;
      border-radius: 0 0 10px 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .list-view-item {
      @include row-base;
      text-align: center;
      //height: 184px;
      border: 1px solid #E2E2E2;
      border-radius: 7px 7px 7px 7px;
      margin-bottom: 20px;
      background-color: white;

      .custom-image {
        border-radius: 7px 0 0 7px;
        cursor: pointer;
      }

      .detailed-info-container {
        width: 75%;
        float: left;
        height: 100%;
        position: relative;

        .general-info-section {
          width: 33.33%;
          float: left;
          padding: 10px 20px;
          height: calc(100% - 40px);
          position: relative;

          .car-description {
            width: 100%;
            display: flex;
            float: left;
            font-size: 28px;
            font-weight: bold;
            text-align: left;
          }

          .car-price, .price-coming-soon {
            display: flex;
            float: left;
            font-size: 18px;
            font-weight: bold;
            color: #0FBE2C;
            padding-right: 10px;
          }

          .price-coming-soon {
            color: #ff565e;
          }

          .car-mileage {
            display: flex;
            float: left;
            font-weight: bold;
            border-left: 1px solid #E2E2E2;
            padding-left: 10px;

            label {
              width: 100%;
              text-align: right;
              margin: 0;
              font-size: 18px;
            }
          }

          .car-price-mileage {
            position: absolute;
            bottom: 10px;
          }
        }

        .main-mechanical-info-section, .additional-mechanical-info-section {
          width: 33.33%;
          float: left;
          padding-left: 10px;
          padding-top: 20px;

          .sub-title {
            width: 100%;
            display: flex;
            float: left;
            font-size: 12px;
            color: #7F7F7F;
          }

          .sub-title-info {
            width: 100%;
            display: flex;
            float: left;
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
            padding-bottom: 10px;
          }
        }

        .additional-mechanical-info-section {
          text-align: left;
        }

        .options-section {
          width: 100%;
          float: left;
          border-top: 1px solid #E2E2E2;
          align-items: center;
          display: flex;

          .apply-option {
            display: flex;
            float: left;
            border-right: 1px solid #E2E2E2;
            font-size: 15px;
            color: #7F7F7F;
            cursor: pointer;

            height: 40px;
            @include item-center;
            width: auto;
            padding: 0 15px;
          }

          .other-option {
            display: flex;
            float: left;
            border-right: 1px solid #E2E2E2;
            font-size: 15px;
            color: #7F7F7F;
            cursor: pointer;

            height: 40px;
            @include item-center;
            width: auto;
            padding: 0 10px;

            a {
              color: #7F7F7F;
            }
          }
        }
      }

      .container-car-image, .container-car-image-sold {
        width: 25%;
        display: flex;
        float: left;
        aspect-ratio: auto 4/3;
        height: 100%;
        border-radius: 7px 0 0 7px;
        border-right: 1px solid;
      }

      .container-car-image-sold {
        position: relative;

        &:before {
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
          content: '';
          background-color: #D10C0C;
          opacity: 0.5;
        }

        &:after {
          display: flex;
          position: absolute;
          height: 100%;
          width: 100%;
          content: 'SOLD';
          color: #FDFDFD;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          font-weight: 900;
        }
      }
    }
  }

  .row-filter-cyl-tit-trim-price, .row-filter-color-condition {
    @include row-base;
    padding-bottom: 15px;
  }

  .row-filter-conditions, .row-filter-year-miles-price, .row-filter-condition-cyl-mobile, .row-filter-color-mobile, .row-filter-title-trim-mobile {
    display: none;
  }

  .row-filter {
    @include row-base;
    padding-bottom: 15px;
  }

  .col-inv-filter {
    float: left;
    width: calc(100% - 360px);

    .col-keyword {
      float: left;
      width: 30%;
      position: relative;

      .icon-search {
        height: 16px;
        fill: #e3e3e3;
        position: absolute;
        top: 13px;
        right: 13px;
      }

      input {
        height: 40px;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }

      input:focus {
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }
    }

    .icon-filter {
      height: 16px;
      fill: #ffffff;
    }

    .btn-filter {
      float: left;
      width: 45px;
      margin: 10px;
      cursor: pointer;
    }
  }

  .col-inv-pagination {
    float: right;
    //width: 30%;
    width: 360px;
    //padding-right: 20px;
    .layout-car-pagination {
      width: auto;
      margin-bottom: 0;
      padding-right: 20px;
    }
  }

  .filter-box {
    @include row-base;
    margin-bottom: 40px;
    background-color: #ffffff;
    padding: 30px 10px 15px 30px;
    border: 1px solid #e3e3e3;

    .col-filter-select {
      float: left;
      width: 25%;
      padding-right: 20px;
      position: relative;

      .p-abs {
        width: calc(100% - 20px);
        border-top: none;
        margin-top: -2px;
      }

      &:hover {
        cursor: pointer;
      }

      .advancedSearch-select-deployed {
        border-bottom: none;
      }
    }

    .filter-select {
      height: 44px;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      padding: 10px 20px;

      label {
        margin: 0;
        color: #979797;
        font-weight: 300;
      }

      .number-selected {
        float: right;
        color: #8b8b8b;
        margin: 0;
        font-weight: $font-weight-regular;
        padding-right: 5px;
      }

      .icon-expand-more {
        float: right;
        transform: rotate(180deg);

        svg {
          fill: #CACACA;
          height: 8px;
        }
      }

    }

    .label-filter {
      position: absolute;
      top: -5px;

      label {
        margin: 0;
        font-size: 12px;
        color: #979797;
        font-weight: $font-weight-semi-bold;
      }
    }

    .filter-number {
      display: flex;
      padding-top: 12px;

      .input-filter {
        float: left;
        width: 45%;
        position: relative;

        #priceFrom,
        #priceTo {
          padding-left: 20px;
        }

        input {
          height: 32px;
          border: 1px solid #e3e3e3;
          box-shadow: 0 0 0;
        }

        input:focus {
          border: 1px solid #e3e3e3;
          box-shadow: 0 0 0;
        }

        .label-miles, .label-price {
          position: absolute;
          top: 6px;
          margin: 0;
          right: 10px;
          color: #979797;
          font-weight: 300;
        }

        .label-price {
          right: inherit;
          left: 5px;
          top: 7px;
        }
      }

      .input-filter-price {
        input {
          padding-left: 20px;
        }
      }

      .label-to {
        float: left;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          color: #979797;
          font-weight: 300;
          margin: 0;
        }
      }
    }

    .filter-input {
      height: 44px;

      input {
        height: 44px;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }

      input:focus {
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }
    }

    .row-filter-display {
      float: left;
      width: 25%;
      padding-right: 20px;
      position: relative;
    }

    .row-filter-color-condition {
      .row-filter-display {
        float: left;
        width: 25%;
        padding-right: 20px;
        position: relative;
      }
    }
  }

  .popover-header {
    label {
      font-weight: 300;
    }

    &:hover {
      cursor: pointer;
    }

    .icon-expand-more {
      float: right;

      svg {
        fill: $arrow-color;
        height: 8px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .row-filter-button {
    @include row-base;
    padding-bottom: 15px;
    padding-right: 10px;

    .btn-search {
      text-align: inherit;
      padding-top: 10px;
      width: 50%;
      float: right;

      .btn-search-inventory {
        width: 120px;
        font-weight: normal;
        border-radius: 5px;
        letter-spacing: normal;
      }
    }

    .btn-clear {
      text-align: end;
      padding-top: 10px;
      width: 50%;
      float: left;
      padding-right: 10px;

      .flex-row {
        justify-content: flex-end;
      }

      .btn-clear-inventory {
        width: 120px;
        border-radius: 5px;
        font-weight: normal;
        letter-spacing: normal;
      }
    }
  }

  .layout-inventory-body-vl {
    padding: 30px 65px 30px 65px;

    .col-inv-pagination {
      .layout-car-pagination {
        padding-right: 0;
      }
    }
  }
}

/*Inventory media query*/

@media screen and (min-width: 1600px) {
  .layout-inventory-5 .layout-inventory-body {
    padding: 30px 6.55% 30px 7.55%;
  }

  .layout-inventory-5 .layout-inventory-body-vl {
    padding: 30px 7.55% 30px 7.55%;
  }
}

.see-more-text {
  color: #2194f1;
  float: right;

  &:hover {
    cursor: pointer;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #979797;
  font-weight: 300;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #979797;
  font-weight: 300;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #979797;
  font-weight: 300;
}

:-moz-placeholder { /* Firefox 18- */
  color: #979797;
  font-weight: 300;
}

@mixin btn-outline() {
  outline: none;
  box-shadow: none;
}

.layout-inventory-container-car-5 {

  .inv-car-info {
    width: 50%;
    float: right;
    padding: 30px 20px 20px 30px;

    .car-details-header {
      .car-details-row {
        @include row-base();
        display: flex;
        align-items: center;
        padding: 7px 0 7px 0;
        justify-content: space-between;

        .info-car-details {
          display: flex;
          align-items: center;

          .left {
            float: left;
            margin: 7px;

            div {
              div {
                display: flex;
              }
            }
          }

          .sold-tag {
            background-color: #FFF;
            color: red;
            max-width: 100%;
            padding: 2px 6px;
            border-radius: 4px;
            font-weight: bold;
            font-size: 12px;
            border: 1px solid #0a0c0e;
          }

          .image-style {
            height: 16px;
            fill: #707070;
          }

          .image-style:hover {
            cursor: pointer;
          }
        }

        .label-make-model {
          float: left;
          margin: 0 10px 0;
          font-weight: $font-weight-bold;
          color: #515151;
        }
      }

      .price-banner {
        font-size: 24px;
        color: grey;
        text-decoration: line-through;
      }

      .label-suggested-price {
        float: right;
        margin: 0;
        font-size: 24px;
        color: #0FBE2C;
      }

      .label-retail-price {
        float: right;
        color: #BC1B1B;
        text-decoration: line-through;
        padding-left: 5px;
        padding-top: 6px;
      }

      .label-value-info {
        margin: 0 10px 0 0;
        padding: 5px 10px;
        border: 1px solid #CACACA;
        color: #515151;
        border-radius: 3px;
        font-weight: $font-weight-semi-bold;
      }

      .opaque-label {
        opacity: 0.5;
      }
    }

    .car-details-body {
      @include row-base;
      padding-top: 15px;
      display: flex;
      flex-direction: column;

      .btn-car-tab {
        float: left;
        background-color: transparent;
        border: 0;
        font-size: 24px;
        padding: 10px 20px;
        color: #a7a7a7;
        font-weight: $font-weight-bold;
        width: 40%;
        text-align: left;
        border-bottom: 1px solid #a7a7a7;
      }

      .btn-print-car {
        background-color: transparent;
        height: 55px;
        float: left;
        width: 20%;
        border-bottom: 1px solid #a7a7a7;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .btn-car-tab:focus {
        @include btn-outline();
        border-bottom: 1px solid #a7a7a7 !important;
      }

      .btn-tab-active, .btn-tab-active:focus {
        @include btn-outline();
        color: #707070;
        border-bottom: 1px solid transparent !important;
        border-left: 1px solid #a7a7a7 !important;
        border-top: 1px solid #a7a7a7 !important;
        border-right: 1px solid #a7a7a7 !important;
      }

      .separator-tab {
        float: right;
        width: 20%;
        height: 1px;
        background-color: #a7a7a7;
        margin-top: 55px;
      }

      .car-feature {
        padding-top: 20px;

        .cell-feature {
          border: 0;
          padding: 0 5px 0 0;
          float: left;
          width: 45%;
        }

        .cell-value {
          width: 55%;
          float: right;
          border: 0;
          padding: 0 0 0 5px;
        }

        .row-specifications-one {
          display: table-row;
          width: 100%;
          float: left;

          span {
            @include row-base;
            background-color: #F4F4F4;
            padding: 5px 0 5px 15px;
          }
        }

        .row-specifications-two {
          display: table-row;
          width: 100%;
          float: left;

          span {
            @include row-base;
            background-color: #FFFFFF;
            padding: 5px 0 5px 15px;
          }
        }
      }

      .car-description {
        padding-top: 20px;

        p {
          margin: 0;
        }
      }

      @mixin input-value() {
        height: 44px;
        border-radius: 5px;
        border: 1px #D9D9D9 solid;
        width: 100%;
        box-shadow: 0 0 0;
        background-color: #ffffff;
      }

      .col-value-left {
        @include col-50;
        padding-right: 5px;
        position: relative;

        input {
          @include input-value;
        }

        .label-display {
          color: #4D4D4D;
          font-size: 13px;
        }
      }

      .col-value-right {
        @include col-50;
        padding-left: 5px;
        position: relative;

        input {
          @include input-value;
        }

        .label-display {
          color: #4D4D4D;
          font-size: 13px;
        }
      }

      .icon-symbol {
        position: absolute;
        left: 10px;
        top: 37px;
        color: #4D4D4D;
      }

      .input-symbol {
        padding-left: 20px !important;
      }

      .row-calculate {
        @include row-base;
        padding-bottom: 15px;
      }

      .calculate-body {
        @include row-base;
        padding-top: 20px;
      }

      .btn-calculate {
        margin-top: 24px;
        float: right;
        width: 100%;
        height: 44px;
        background-color: #BC1B1B;
        color: #ffffff;
        border: 1px solid #BC1B1B;
        border-radius: 5px;
      }

      .box-monthly-payment {
        @include input-value;
        padding: 11px;
      }

      .row-monthly-payment {
        @include row-base;
        padding-top: 20px;
      }

    }
  }

  .inv-car-image {
    width: 50%;
    float: right;
    padding: 30px;

    .col-car-image5 {
      @include item-center;
      float: left;
      width: 20%;
      padding-right: 10px;
      aspect-ratio: 4/3.409;

      img {
        @include object-fit-cover;
      }
    }

    .col-car-image4 {
      @include item-center;
      float: left;
      width: 25%;
      padding-right: 10px;
      aspect-ratio: 4/3.409;

      img {
        @include object-fit-cover;
      }
    }

    .col-car-image5:hover, .col-car-image4:hover {
      cursor: pointer;
    }

    .arrow-left, .arrow-right {
      @include item-center;
      height: 100%;
      background-color: #fff;
    }

    .arrow-left > div, .arrow-right > div {
      @include item-center;
    }

    .icon-arrow {
      width: 32px;
    }

    .carousel-control {
      background-color: #ffffff;
      width: 50px;
      opacity: 1;
    }

    .carousel-inner {
      padding: 0 50px;
    }

    .row-carousel {
      @include row-base;
      padding-left: 10px;
    }

    .banner-sold-car {
      position: absolute;
      width: 100%;
      background-color: red;
      border-radius: 5px 5px 0 0;
      color: #FFF;
      font-weight: 600;
      padding: 10px;
      font-size: 17px;
    }

    .car-image-main {
      border-radius: 5px;
      @include object-fit-cover;

      .carousel-inner {
        padding: 0;
      }
    }

    .carousel {
      padding-top: 10px;
    }
  }

  .inv-car-view {
    position: relative;
    @include row-base();
    padding: 50px 70px;
    background-color: #F4F4F4;
    top: 55px;

    .inv-car-box {
      background-color: #fff;
      @include row-base;
      border: 1px solid #CACACA;
      border-radius: 0;
      box-shadow: none;

      .inv-car-image {
        .layout-get-in-touch {
          .get-in-touch-box {
            .get-in-touch-form {
              .btn-row {
                display: flex;
                justify-content: center;
              }
            }
          }
        }

        .container-get-in-touch {
          .container-form-get-in-touch {
            .form-get-in-touch {
              .container-row-button {
                .btn-inv-more {
                  width: auto;
                  padding: 12px 30px;
                  font-size: 16px !important;
                  font-weight: 300 !important;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  .inv-car-view {
    .inv-car-box {
      .inv-car-image {
        .layout-get-in-touch-modern-view-2 {
          .get-in-touch-message-box {
            .container-get-in-touch {
              .container-form-get-in-touch {
                .form-get-in-touch {
                  .container-button-get-in-touch {
                    .btn-inv-more {
                      width: auto;
                      padding: 12px 30px;
                      font-size: 16px !important;
                      font-weight: 600 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

.print-option {
  height: 25px;
  width: 25px;
}

.print-option:hover {
  cursor: pointer;
}

@mixin object-fit-contain() {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.finance-message-error {
  padding-left: 55px !important;
}

.layout-about-container-5 {

  .layout-about {
    @include row-base;
    padding: 105px 50px 50px 50px;
    background-color: #F4F4F4;
    position: relative;
    top: 0;

    .goal {
      padding: 0 0 0 30px;
      width: 50% !important;

      .box {
        padding: 20px;

        span {
          color: #4D4D4D;
          font-size: 14px;
          font-weight: $font-weight-semi-bold;
        }

        .goal-title {
          color: #43425D;
          font-size: 80px;
        }

        .goal-title-template-main {
          color: #43425D;
          font-size: 36px;
          font-weight: $font-weight-semi-bold;
        }

        .span-template-main {
          color: #4D4D4D;
          font-size: 16px;
          font-weight: normal;
        }

        .additional-text {
          font-weight: $font-weight-regular;
        }

        .title-hours-operations {
          font-size: 26px;
        }

      }

      .box-operation {
        border: 1px solid #CACACA;
        padding: 20px;
        border-radius: 15px;

        h3 {
          color: #515151;
          font-weight: $font-weight-bold;
        }
      }

      .box-hours-operation {
        border-radius: 10px;
        border: 1px solid #CACACA !important;
      }
    }

    .item {
      padding: 15px 0 10px 0;

      .box-item {
        border: 1px solid #CACACA;

        .label-value {
          color: #515151;
          //font-size: 14px;
        }
      }
    }
  }

  .layout-about-box {
    @include row-base;
    background-color: #fff;
    border: 1px solid #CACACA;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;

    .about-testimonial {
      float: left;
      width: 50%;

      .layout-dealer-carousel {
        float: left;
        width: 100%;
        position: relative;
        margin: 0px;
        padding: 0px 50px 0px 0px;
      }
    }

    .goal {
      float: left;
      width: 50%;

      .item {
        float: left;
        width: 100%;

        .row-item {
          float: left;
          width: 100%;
          padding-bottom: 15px;
          display: flex;

          @mixin box-item-general() {
            border-radius: 10px;
            float: left;
            width: 100%;
            display: flex;
            .wrapper {
              float: left;
              padding: 20px;

              svg {
                width: 20px !important;
                height: 20px;
              }
            }
            .label-value {
              padding: 15px 0;
              margin: 0;
              font-size: 20px;
            }
          }

          .col-item-left {
            float: left;
            width: 50%;
            padding-right: 10px;

            .box-item {
              @include box-item-general;
              padding-right: 20px;
              display: flex;
              align-items: center;
              height: 100%;
              border: 1px solid #CACACA !important;
            }
          }

          .col-item-right {
            float: left;
            width: 50%;
            padding-left: 0;

            .box-item {
              @include box-item-general;
              padding-right: 20px;
              height: 100%;
              display: flex;
              align-items: center;
              border: 1px solid #CACACA !important;
            }
          }
        }
      }

      .item-template-main {
        width: 100%;

        .row-item-template-main {
          width: 100%;
          padding-bottom: 15px;
          display: flex;

          @mixin box-item-general() {
            border-radius: 10px;
            float: left;
            width: 100%;
            display: flex;
            .wrapper {
              float: left;
              padding: 10px;

              svg {
                width: 12px !important;
                height: 12px;
              }
            }
            .label-value {
              //padding: 15px 0;
              margin: 0;
              font-size: 16px;
            }
          }

          .col-item-left {
            padding-right: 10px;

            .box-item {
              @include box-item-general;
              padding-right: 20px;
              align-items: center;
              height: 100%;
              border: 1px solid #CACACA !important;
            }
          }
        }
      }

      .box-operation {
        color: #B4B4B4;
        float: left;
        width: 100%;
        background-color: #fff;

        h3 {
          margin-top: 0;
        }
      }
    }

    .testimonials-section {
      width: 100%;
      padding-top: 60px;
    }
  }

  .layout-dealer-carousel {
    margin: 20px 0 40px 0;

    .car-image-gallery {
      float: left;
      width: 100%;

      .content-image {
        width: 10%;
        float: left;
        padding-right: 5px;
        height: auto;
        padding-bottom: 5px;
        aspect-ratio: 85/71;

        .react-player__preview {
          border-radius: 3px;
        }
      }

      .content-image-template-main {
        width: 16.6%;
        float: left;
        padding: 6px;
        aspect-ratio: 1/1;

        .react-player__preview {
          border-radius: 3px;
        }
      }

      img {
        @include object-fit-cover;
        border-radius: 1px;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .car-image-selected {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      aspect-ratio: 20/9; //CAR_IMAGE_HEIGHT_PERCENT => 0.45

      img {
        @include object-fit-cover;
        border-radius: 1px;
        padding: 0px 10px 0px 6px;
      }
    }
  }

  .body-customer-section {
    .section-left, .section-right {
      height: 100%;
      justify-content: center;
      padding: 0;
    }

    .container-testimonial {
      padding: 0 15px;
      width: 100%;
    }

    .testimonial-icon {
      width: 40px;
    }

    .row-customer .label-experience {
      padding-right: 30px;
    }
  }

  .container-testimonial .bar-progress .slider-bar-total {
    width: 100%;
  }
}

/* About media query*/

@media screen and (min-width: 1600px) {
  .layout-about-container-5 .layout-about {
    padding: 105px 7.55% 50px 7.55%;
  }
}


.backgroundLoader {
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 10000;
  left: 0;
  top: 0;
  opacity: 6;
}

#loader {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10000;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: adjust-hue 2s linear infinite;
  animation: adjust-hue 2s linear infinite;
}

@-webkit-keyframes adjust-hue {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes adjust-hue {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-alert-danger {
  position: absolute;
  width: 100%;
  bottom: 40px;
  top: unset;

  .alert-danger {
    margin: 0;
  }

  .alert-success {
    margin: 0;
  }
}

.btn-copy-finance {
  display: none;
  height: 30px;
  float: right;
  border-radius: 5px;
  color: #fff;
  margin-right: 20px;
}

/***************** MEDIA QUERY ******************/

@media screen and (max-width: 1900px) {
  .layout-inventory-5 .row-inventory .car-item {
    width: 25%;
  }


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 60px;
    word-break: break-word;
  }
}


@media screen and (max-width: 1600px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 62px;
  }
}

@media screen and (max-width: 1580px) {
  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 60px;
  }
}

@media screen and (max-width: 1520px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 58px;
  }
}

@media screen and (max-width: 1460px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 56px;
  }
}

@media screen and (max-width: 1450px) {
  .layout-about .item .box-item .label-value {
    font-size: 14px;
    padding: 15px 0;
  }
}

@media screen and (max-width: 1420px) {
  .car-item-details {
    .label-title {
      font-size: 16px;
      text-align: center;
    }
  }


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 54px;
  }
}

@media screen and (max-width: 1400px) {

  .finance-form-5 {
    padding-top: 90px;
    padding-bottom: 40px;
  }

  .layout-inventory-container-car-5 .inv-car-view {
    top: 50px;
  }

  .layout-inventory-5 {
    padding-top: 50px;
  }


  .layout-about-container-5 {
    .layout-about {
      padding-top: 100px;
    }
  }
}

@media screen and (max-width: 1340px) {


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 1280px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 48px;
  }

  .layout-about-container-5 {
    .layout-about {
      .layout-about-box {
        .goal {
          .box {
            .main-title {
              font-size: 60px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {

  .footer-modern .container-menu-footer-modern-template .container-icon-social-setting {
    padding-left: 150px;
  }
  .container-home-modern-view-3 {
    .banner-container-5 {
      .car-search-container {
        .container-car-search-input {
          .car-search-input {
            .input-container {

              .form-control-search {
                width: 100%;
              }
            }

            .car-search-icon {
              width: 8%;
            }

            .container-button {
              width: 25%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 46px;
  }
}

@media screen and (max-width: 1220px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 44px;
  }

  .layout-inventory-5 .row-inventory .list-view-item {
    .detailed-info-container {
      .general-info-section {
        .car-description {
          font-size: 20px;
        }

        .car-price, .car-mileage label, .price-coming-soon {
          font-size: 16px;
        }
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        .sub-title-info {
          font-size: 15px;
        }

        .sub-title {
          font-size: 11px;
        }
      }

      .options-section {
        .apply-option, .other-option, .carfax-content a {
          font-size: 11px;
        }
      }
    }
  }
}


@media screen and (max-width: 1200px) {

  #carCarouselBodyTemplate5 {
    .col-car {
      width: 33.33%;
    }

    .carousel-control {
      bottom: -17px;
    }
  }

  .car-carousel-template5 {
    .inventory-title {
      label {
        font-size: 22px;
      }
    }
  }


  .layout-inventory-container-car-5 {
    .inv-car-view {
      padding: 20px 20px;
    }

    .inv-car-image {
      padding: 20px 20px 20px 0;

      .carousel-inner {
        padding: 0 30px;
      }

      .carousel-control {
        width: 30px;
      }

      .icon-arrow {
        width: 24px;
      }
    }

    .inv-car-info {
      padding: 20px 20px 20px 20px;
    }
  }

  .layout-about-container-5 .layout-about {
    padding: 65px 15px 15px 15px;
  }

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 45px;
  }
}

@media screen and (max-width: 1180px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 44px;
  }
}

@media screen and (max-width: 1120px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 1100px) {

  .finance-body, .step-effect {
    .finance-label {
      label {
        font-size: 18px;
      }
    }

    .finance-des {
      label {
        font-size: 12px;
      }
    }
  }

  .finance-form-5 {
    //padding-top: 115px;
    .finance-form-vertical, .finance-form-wizard {
      padding: 0 20px;
    }
  }


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 41px;
  }

  .layout-inventory-5 .row-inventory .list-view-item {
    .detailed-info-container {
      display: flex;
      flex-wrap: wrap;

      .general-info-section {
        width: 100%;
        display: flex;
        padding: 10px;
        height: auto;

        .car-description {
          font-size: 18px;
          padding-right: 10px;
        }

        .car-price-mileage {
          position: relative;
          display: flex;
          bottom: unset;
          align-items: center;

          .car-price, .car-mileage, .price-coming-soon {
            @include one-single-line-text;
            margin: 0;
          }
        }

        .car-price, .car-mileage label, .price-coming-soon {
          font-size: 12px;
        }
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        padding-top: 0;
        width: 45.33%;

        .sub-title-info {
          font-size: 13px;
        }

        .sub-title {
          font-size: 9px;
        }
      }

      .options-section {
        bottom: 0;

        .apply-option, .other-option, .carfax-content a {
          font-size: 11px;
        }

        > div {
          height: 100% !important;
          align-items: center !important;
          padding: 5px 15px !important;
        }
      }
    }
  }

  .layout-inventory-5 {
    .layout-inventory-body {
      padding: 30px 10px 30px 30px;
    }

    .layout-inventory-body-vl {
      padding: 30px;
    }
  }

  .layout-finance5 {
    .finance-body {
      padding: 0 0 0 40px;
    }
  }
}

@media screen and (max-width: 1080px) {

  .layout-about-container-5 {
    .layout-about {
      .layout-about-box {
        .goal {
          .box {
            .goal-title {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 39px;
  }

  .car-item-details {
    .label-title {
      font-size: 14px;
    }
  }

}

@media screen and (max-width: 1040px) {

  .finance-form-5 {
    .panel-finance {
      .colR25 {
        width: 25%;
      }

      .colR16 {
        width: 16.66%;
      }

    }
  }


  .layout-inventory-5 {
    padding-top: 45px;
  }


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 38px;
  }
}

@media screen and (max-width: 1024px) {


  .layout-location {
    .layout-location-left {
      width: 40%;
      height: 500px;
    }

    #map {
      width: 60% !important;
      height: 500px;
    }
  }

  .body-customer-section {
    .testimonial-icon {
      width: 35px;
    }

  }


  .finance-body, .step-effect {
    padding: 0 30px 0 40px;

    .finance-step-content {
      padding-right: 10px;
    }

    .finance-body-step {
      padding: 30px 30px 0px 30px;
      z-index: 10;
      position: relative;
      min-height: 180px;
    }

    .finance-icon-step {
      height: 30px;
    }

    .finance-label {
      label {
        font-size: 14px;
        padding-top: 0;
      }
    }

    .finance-des {
      height: 100px;
      label {
        font-size: 11.5px;
      }
    }

    .finance-image, .finance-image-dark{
      height: 260px;
    }
  }

  .layout-location {
    padding: 5% 40px;
  }

  .car-carousel-template5 {
    padding-top: 140px;
  }

  .layout-inventory-5 .row-inventory .car-item {
    width: 33.33%;

    .car-item-box {
      .car-item-details {
        .row-details {
          justify-content: flex-start;

          .label-title {
            text-align: left;
            font-size: 15px;
            font-weight: bold;
          }

          .btn-car-content {
            .btn-car-info {
              font-size: 10px;
            }
          }

          .carfax-content {
            img {
              width: 56px !important;
            }
          }

          .col-item-price {
            label {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .layout-inventory-5 .layout-inventory-body {
    //--padding: 30px 15px 30px 35px;
  }

  .car-item-details {
    .btn-car-info {
      font-size: 9.5px;
      margin: 0;
      padding: 3px;
    }

    .row-details {
      padding-bottom: 5px;
    }

    .label-title {
      font-size: 18px;
    }
  }

  .layout-inventory-5 {
    .col-inv-pagination {
      width: 100%;
      justify-content: center;
      display: flex;

      .layout-car-pagination {
        width: 100%;
      }
    }

    .filter-box .filter-number .input-filter .label-price {
      left: 10px;
    }

    .col-inv-filter {
      width: 100%;
    }
  }

  .finance-form-5 {
    padding-top: 80px;
    padding-bottom: 35px;

    .finance-form-vertical, .finance-form-wizard {
      .tab-finance {
        .tab-content {
          padding: 20px 10px 40px 20px;

          .colR25, .colR50, .colR20, .colR80, .colR16, .colR75 {
            padding-right: 10px;
          }

          .panel-finance {
            padding: 0;
          }
        }
      }

      .radio-button-main {
        padding-right: 10px;

        button {
          font-size: 12px;
        }
      }

      .finance-btn {
        padding-right: 10px;
        padding-left: 0;
      }
    }
  }

  .pagination-container {
    .btn-pagination {
      font-size: 29px;
    }

    .pagination-left {
      padding-right: 15px;
    }

    .pagination-right {
      padding-left: 8px;
    }
  }

  .layout-inventory-5 {
    .filter-box {
      .col-filter-select {
        width: 33.3%;
      }

      .car-search-input-content {
        display: none;
      }

      .row-filter-conditions {
        display: block;
        float: left;
        width: 100%;
        padding-top: 5px;

        .row-filter-conditions-option {
          float: left;
          width: 50%;
          padding-right: 20px;
        }
      }

      .row-filter-year-miles-price {
        display: block;
        @include row-base;
        padding-bottom: 20px;

        .row-filter-ymp {
          width: 33.33%;
          position: relative;
          float: left;
          padding-right: 20px;
        }
      }

      .row-filter-cyl-tit-trim-price, .row-filter-display {
        display: none;
      }

      .filter-number {
        padding-top: 15px;
      }

      .row-filter-color-condition {
        .row-filter-display {
          display: none;
        }
      }
    }
  }

  .layout-inventory-container-car-5 {
    .inv-car-view {
      top: 45px;
    }
  }

  .layout-inventory-5 {
    padding-top: 45px;
  }

  .layout-about-container-5 {
    .layout-dealer-carousel {
      margin: 0 0 20px 0;

      .car-image-selected {
        width: 100%;
        padding: 0 10px;
      }

      .car-image-gallery {
        width: 100%;
        padding-top: 10px;
        padding-left: 10px;

        .content-image {
          width: 20%;
        }
      }
    }

    .layout-about {
      padding: 65px 0px 0px 0px;
    }

    .layout-about-box {
      padding: 0px;

      .about-testimonial {
        width: 100%;
      }

      .about-us-goal {
        width: 100%;
        margin: 40px 120px 60px 120px;

        .box-template-5 {
          margin: 0px 10px;
        }
      }

      .carousel-dealer-images-container{
        display: flex;
        width: 100%;
        height: auto;
        padding: 30px 40px 20px 40px;

        .dealer-image-item{
          width: 100%;
          aspect-ratio: calc(100/65);
          //border-radius: 10px;
        }
      }

    }

    .layout-about .goal {
      padding: 0 0 0 20px;

      .box .operation-left {
        width: 100%;
      }

      .box .operation-right {
        width: 100%;
        padding-top: 10px;
      }
    }

    .body-customer-section {
      .container-testimonial {
        padding: 0 10px 10px 10px;
        height: 300px;
      }

      .section-left, .section-right {
        height: 300px;
      }
    }
  }

  #carCarouselBodyTemplate5 .row-carousel {
    padding: 0 20px 0 40px;
  }

  .container-home-modern-view-2 {
    .container-header-top-modern-view-2 {
      height: 45px;
    }


  }

  .layout-inventory-5 .row-inventory .list-view-item {
    .container-car-image, .container-car-image-sold {
      width: 35% !important;
    }

    .detailed-info-container {
      width: 65%;
      flex-direction: column;
      justify-content: space-between;

      .general-info-section {
        padding: 5px;

        .car-description {
          font-size: 20px;
        }

      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        .sub-title-info {
          font-size: 14px;
        }
      }

      .options-section {
        height: auto;

        .apply-option, .other-option, .carfax-content a {
          font-size: 12px;
        }
      }
    }
  }

  .layout-finance5 .finance-body {
    padding: 0 20px 0 30px;
  }
}

@media screen and (max-width: 992px) {

  .finance-form-5 {
    .finance-form-wizard {
      .finance-icon {
        span {
          padding-left: 5px;
          padding-top: 2px;
        }
      }
    }
  }

  #location5 {
    .layout-location-content {
      .layout-location-left {
        width: 100%;
        height: auto;
      }

      #map {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .body-customer-section {
    .testimonial-icon {
      width: 35px;
    }
  }

  .finance-body, .step-effect {
    .finance-des {
      label {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media screen and (max-width: 950px) {

  .finance-form-5 {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  .body-customer-section {
    height: auto;
  }
  #carCarouselBodyTemplate5 {
    padding-top: 10px;

    .row-carousel {
      padding: 40px 10px 0 20px;
    }

    .col-car {
      padding-right: 10px;
      padding-bottom: 10px;
    }

    .carousel-control {
      bottom: -15px;
    }
  }

  .finance-form-5 {
    .finance-form-wizard {
      .tab-finance .nav-tabs {
        padding: 20px 10px 10px 10px;

        li {
          padding-right: 10px;

          a {
            padding: 0;
            font-size: 12px;
          }
        }
      }

      .finance-tab-icon {
        height: 18px;
      }

      .finance-icon span {
        padding-left: 5px;
        padding-top: 2px;
      }
    }
  }

  .layout-inventory-container-car-5 {
    .inv-car-info .car-details-body {
      .btn-car-tab {
        font-size: 18px;
      }

      .btn-print-car {
        height: 46px;
      }

      .separator-tab {
        margin-top: 46px;
      }
    }
  }

  .print-option {
    height: 20px;
    width: 24px;
  }

  .finance-body {
    padding: 0 10px 0 20px;
  }

  .layout-location {
    padding: 5% 20px;
  }


}

@media screen and (max-width: 860px) {

  .layout-container-5 {
    .car-carousel-template5 {
      padding: 380px 10px 0 10px;
    }
  }
  .container-home-modern-view-3 {
    .car-carousel-template5 {
      padding-top: 140px;
    }
  }

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 48px;
  }

  .car-item-details {
    .label-title {
      font-size: 16px;
    }
  }


  .layout-about-container-5 {
    .body-customer-section .row-customer .container-label-name {
      bottom: 15%;
    }
  }
}

@media screen and (max-width: 820px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 46px;
  }
}

@media screen and (max-width: 800px) {


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 52px;
  }

}

@media screen and (max-width: 780px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 44px;
  }

}

@media screen and (max-width: 768px) {
  .container-testimonial-mobile {
    display: block;
  }
  .container-testimonial-web-tablet {
    display: none;
  }

  .body-customer-section {
    .section-left {
      height: 100%;
    }

    .container-testimonial {
      width: 100%;
      height: 338px;
    }

    .section-right {
      height: 100%;
    }
  }


  #carCarouselBodyTemplate5 {
    .col-car {
      width: 50%;
    }

    .carousel-control {
      bottom: -13px;
    }
  }

  .layout-inventory-5 .layout-inventory-body {
    padding: 30px 10px 30px 20px;
  }

  .layout-inventory-5 .row-inventory .car-item {
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .car-details {
    font-size: 11px;
    margin: 0;
  }

  .layout-inventory-5 {
    //padding-top: 84px;
  }

  .finance-form-5 {
    .finance-form-vertical, .finance-form-wizard {
      .tab-finance {
        .tab-content {
          .colR25, .colR50, .colR20, .colR80, .colR16 {
            width: 50%;
          }

          .col100 {
            display: flex;
            flex-wrap: wrap;
          }

          .radio-button-select {
            display: block;
          }
        }
      }

      .radio-button-main {
        display: none;
      }
    }

    .finance-form-vertical {
      .view-vertical {
        .finance-btn, .content-check {
          padding-left: 20px;
        }
      }
    }
  }

  .layout-finance-container, .layout-inventory-container {
    .footer-modern .container-footer-background {
      margin-top: 0;

      .container-tittle-modern {
        .container-footer-right {
          width: 100%;
          padding: 20px 30px 20px 30px;
        }
      }

      .line-footer {
        top: 252px;
        bottom: 55px;
      }
    }
  }

  .layout-inventory-container-car-5 {
    .inv-car-view {
      .inv-car-box {
        .inv-car-info {
          .car-details-body {
            .row-base {
              margin-top: 44px;

              .car-description {
                padding-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  .pagination-container {
    .btn-pagination {
      font-size: 23px;
    }
  }

  .car-item-details {
    .label-title {
      font-size: 14px;
    }
  }

  .container-testimonial .separator-testimonial {
    display: none;
  }

  .layout-inventory-container-car-5 {
    .inv-car-view {
      top: 40px;
    }

    .inv-car-info {
      width: 100%;

      .car-details-body {
        padding-bottom: 20px;
      }
    }

    .inv-car-image {
      width: 100%;
      padding: 20px;
    }
  }

  .layout-inventory-5 {
    padding-top: 40px;
  }

  .layout-about-container-5 {
    .layout-about {
      padding: 70px 0px 0 0px !important;

      .about-testimonial, .goal {
        width: 100%;
      }

      .item .col-item-left {
        width: 100%;
        padding: 0 0 10px 0;
      }

      .item .col-item-right {
        width: 100%;
        padding: 0;
      }

      .layout-about-box {
        padding: 20px 30px 30px 30px;
        border: none !important;

        .about-testimonial {
          .layout-dealer-carousel {
            margin: 0;
            padding: 0px;

            .carousel {
              float: left;
              padding-top: 10px;

              .carousel-indicators {
                visibility: hidden;
              }

              .carousel-inner {
                padding: 0 30px;

                .active {
                  padding: 0;
                }

                .item {
                  .row-carousel {
                    .car-image {
                      border-radius: 0 !important;
                    }
                  }
                }
              }

              .carousel-control {
                width: 30px;
                opacity: 1;

                .arrow-right, .arrow-left {
                  padding-top: 10px;
                  @include item-center;
                  height: 100%;
                  background-color: #fff;
                  opacity: 1;

                  div {
                    @include item-center;

                    svg {
                      width: 24px;
                      transform: rotate(270deg);
                    }
                  }
                }
              }
            }


            .col-car-image4 {
              @include item-center;
              float: left;
              width: 25%;
              padding-right: 10px;

              img {
                @include object-fit-cover;
              }
            }


            .col-car-image5:hover, .col-car-image4:hover {
              cursor: pointer;
            }

            .col-car-image5 {
              @include item-center;
              float: left;
              width: 20%;
              padding: 0 5px;

              img {
                @include object-fit-cover;
              }
            }

            .car-image-selected {
              padding: 0;
              margin: 0;

              img {
                border-radius: unset;
                padding: 0px 6px 0px 6px;
              }
            }
          }
        }

        .about-us-goal {
          width: 100%;
          margin: 0px;
          padding: 0px;

          .box-template-5 {
            margin: 0 5px;
          }
        }

        .goal {
          padding: 0 10px;
          width: 100% !important;

          .box {
            padding: 15px;

            .goal-title {
              color: $title-color;
              font-weight: 700;
              font-size: 20px;
            }

            span {
              color: $title-color;
              font-weight: lighter;
            }
          }

          .item {
            .row-item {
              label {
                color: $title-color;
              }

              .col-item-left {
                width: 100%;
                padding: 0 0 10px 0;
              }

              .col-item-right {
                width: 100%;
                padding: 0;
              }

              .box-item {
                border-radius: unset;
                height: auto;
                padding-right: 10px;

                .wrapper {
                  padding: 15px 20px;
                }

                .label-value {
                  font-size: 20px;
                  padding: 12px 0;
                }
              }
            }
          }

          .box-operation {
            border-radius: unset;
            padding: 10px 20px;

            h3 {
              color: $title-color;
              font-size: 20px;
            }
          }
        }

      }

      .layout-dealer-carousel {
        .car-image-gallery {
          padding: 10px 0 0 0px;
        }
      }
    }

    .container-testimonial {

      .quotation-mark-up, .quotation-mark-down {
        width: 68px;
        height: 61px;
        object-fit: cover;
      }

      .quotation-mark-up {
        margin-top: -22px;
      }

      .quotation-mark-down {
        margin-top: -38px;
      }
    }
  }

  @mixin container-testimonials() {
    padding: 35px 10px 35px;
    .testimonial-header {
      padding-bottom: 50px;

      h1 {
        margin: 0;
      }
    }
    .body-customer-section {
      justify-content: center;
    }
    .container-bar-progress {
      padding-top: 25px;
      width: 100%;
      @include item-center;

      .section-left {
        display: none;

        &:hover {
          cursor: pointer;
        }
      }

      .bar-progress {
        float: left;
        width: calc(80% - 50px);
        height: 25px;
        padding: 0 10px;
        @include item-center;

        .slider-bar-total {
          width: 100%;

          .slider-bar-current {
            height: 6px !important;
            bottom: 3px !important;
          }
        }
      }

      .section-right {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .layout-container-5, .layout-inventory-container {

    .car-carousel-template5 {

      .carCarouselMobile {
        margin-top: 50px;
        @include item-center;

        .car-item-previous {
          top: unset;
          position: absolute;
          width: 70%;
          margin: 0 30% 0 0;

          .car-item-box {
            .car-item-details {
              height: 165px;

              .row-details {
                padding-bottom: 0;
              }

              .row-item-price {
                float: left;

                .col-item-price {
                  padding-top: 0;
                  padding-bottom: 2px;

                  label {
                    float: left;
                    padding-top: 0;
                  }
                }
              }
            }
          }
        }

        .car-item-active {
          width: calc(100% - 60px);
          margin: 0 30px;

          .car-item-box {
            .car-item-details {
              height: 165px;

              .row-details {
                padding-bottom: 0;
              }

              .row-item-price {
                float: left;

                .col-item-price {
                  padding-top: 0;
                  padding-bottom: 2px;

                  label {
                    float: left;
                    padding-top: 0;
                  }
                }
              }
            }
          }
        }

        .car-item-next {
          top: unset;
          position: absolute;
          width: 70%;
          margin: 0 0 0 30%;

          .car-item-box {
            .car-image {
              height: 165px;
            }

            .car-item-details {
              .row-details {
                padding-bottom: 0;
              }

              .row-item-price {
                float: left;

                .col-item-price {
                  padding-top: 0;
                  padding-bottom: 2px;

                  label {
                    float: left;
                    padding-top: 0;
                  }
                }
              }
            }
          }
        }
      }

      .car-item {
        float: left;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        transition: 0.6s;
        background-color: #fff;
        border-radius: 5px;

        .car-item-details {
          .row-details {
            .label-title {
              font-size: 17px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
            }
          }

          padding: 10px 15px 15px;
        }
      }

      /*.car-item-previous{
        position: absolute;
        top: 10px;
        margin: 0 50% 0 5%;
        z-index: 10;
        width: 45%;
      }

      .car-item-next{
        position: absolute;
        z-index: 50;
        top: 10px;
        margin: 0 5% 0 53%;
        width: 42%;
      }*/

      .car-item-active {
        z-index: 100;
        position: relative;
      }

      .car-details {
        font-size: 10px;
      }

      .btn-car-content {
        font-size: 10px;
      }

      .row-title {
        color: #4D4D4D;
      }

      .pagination-carousel {
        padding: 30px 0;
        position: relative;
        margin-top: 30px;

        .slider-bar-total {
          width: calc(80% - 70px);
          margin: 0 10px;
        }

        .icon-arrow {
          width: unset;
          height: unset;
        }

        .wrapper-previous {
          width: 25px;
          position: absolute;
          left: 10%;
          top: 20px;
          transform: rotate(270deg);
        }

        .wrapper-next {
          position: absolute;
          width: 25px;
          right: 9.5%;
          top: 20px;
          transform: rotate(270deg);
        }
      }

    }

    .layout-finance5 {
      .finance-body {
        display: flex;
        align-items: center;
        padding: 0 20px 0 20px;

        .finance-body-step {
          padding: 20px 30px 0 30px;
          min-height: 160px;

          .finance-des{
            height: 90px;
          }
        }

        .finance-image, .finance-image-dark{
          height: 340px;
        }

        .finance-step-content {
          padding-right: 0;
        }

        .finance-icon-step {
          height: 24px;
        }

        .finance-label label {
          font-size: 12px;
          margin: 0;
        }
      }

      .finance-box {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.18);
      }

      .current-step-0 {
        div {
          .finance-box {
            .finance-image {
              margin: 0;
            }
          }
        }

        .step-0 {
          height: 100%;
          z-index: 100;
          position: relative;
          transition: 0.5s;
          width: calc(100% - 80px);
          top: unset;
        }

        .step-1 {
          height: 75%;
          position: absolute;
          z-index: 50;
          width: 45%;
          top: unset;
          transition: 0.5s;
          right: 60px;
        }

        .step-2 {
          height: 60%;
          position: absolute;
          z-index: 10;
          width: 35%;
          top: unset;
          transition: 0.5s;
          right: 20px;

          .finance-des label {
            font-size: 8px;
          }
        }
      }

      .current-step-1 {
        .finance-image, .finance-image-dark{
          height: 250px ;
        }
        .step-0 {
          height: 83%;
          z-index: 10;
          position: absolute;
          width: 40%;
          top: unset;
          transition: 0.5s;
        }

        .step-1 {
          height: 100%;
          position: relative;
          z-index: 100;
          left: 15%;
          width: 70%;
          transition: 0.5s;
        }

        .step-2 {
          height: 83%;
          position: absolute;
          z-index: 10;
          width: 40%;
          top: unset;
          transition: 0.5s;
          right: 20px;
        }
      }

      .current-step-2 {
        .step-0 {
          height: 60%;
          position: absolute;
          z-index: 10;
          width: 35%;
          top: unset;
          transition: 0.5s;

          .finance-des label {
            font-size: 8px;
          }
        }

        .step-1 {
          height: 75%;
          position: absolute;
          z-index: 50;
          left: 60px;
          width: 45%;
          top: unset;
          transition: 0.5s;
        }

        .step-2 {
          height: 100%;
          z-index: 100;
          position: relative;
          width: calc(100% - 80px);
          margin-left: 80px;
          float: right;
          transition: 0.5s;
        }
      }

      .finance-apply {
        display: none;
      }

      .finance-indicators {
        display: block;
        @include item-center;
        width: 100%;
        padding: 25px 10px 0 10px;

        .slide-back, .slide-next {
          width: 25px;
          float: left;
          transform: rotate(270deg);

          svg {
            width: unset;
          }
        }

        .bar-progress {
          float: left;
          width: calc(80% - 50px);
          height: 25px;
          padding: 0 10px;
          @include item-center;
        }
      }
    }
  }

  .layout-finance-container {
    .container-testimonial-general {
      @include container-testimonials;
    }
  }

  .layout-container-main {
    .container-testimonial-general {
      @include container-testimonials;
    }
  }

  .container-home-modern-view-2 {
    .container-header-top-modern-view-2 {
      height: 40px;
    }


  }


  .layout-inventory-5 .row-inventory .list-view-item {
    .container-car-image, .container-car-image-sold {
      width: 50% !important;
      border-radius: 7px 0 0 0 !important;
      height: auto;
      border-bottom: 1px solid;
    }

    .detailed-info-container {
      width: 50%;
      height: auto;
      aspect-ratio: auto 4/3;
      border-bottom: 1px solid #E2E2E2;

      .general-info-section {
        padding: 10px;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        .car-description {
          font-size: 16px;
        }
      }

      .main-mechanical-info-section, .additional-mechanical-info-section {
        .sub-title-info {
          font-size: 14px;
        }
      }
    }

    .custom-image {
      border-radius: 7px 0 0 0;
    }
  }

  .container-mechanical-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > div:first-child {
      width: 100%;
    }

    .main-mechanical-info-section {
      width: 50%;
      float: left;
      padding: 10px;

      .sub-title {
        width: 100%;
        display: flex;
        float: left;
        font-size: 8px;
        color: #7F7F7F;
      }

      .sub-title-info {
        width: 100%;
        display: flex;
        float: left;
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        padding-bottom: 6px;
      }
    }

    .additional-mechanical-info-section {
      width: 50%;
      float: left;
      padding: 10px;
      text-align: left;

      .sub-title {
        width: 100%;
        display: flex;
        float: left;
        font-size: 8px;
        color: #7F7F7F;
      }

      .sub-title-info {
        width: 100%;
        display: flex;
        float: left;
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        padding-bottom: 6px;
      }
    }

    .options-section {
      width: 100%;
      float: left;
      border-top: 1px solid #E2E2E2;

      .apply-option {
        display: flex;
        float: left;
        padding: 7px 15px;
        font-size: 12px;
        color: #7F7F7F;
        cursor: pointer;
        border-right: 1px solid
      }

      .other-option {
        display: flex;
        float: left;
        border-right: 1px solid #E2E2E2;
        padding: 7px 10px;
        font-size: 12px;
        color: #7F7F7F;
        cursor: pointer;

        a {
          color: #7F7F7F;
        }

        .carfax-content a {
          font-size: 12px;
        }
      }

      > div {
        height: 100% !important;
        align-items: center !important;
        padding: 5px 15px !important;
      }
    }
  }

  .layout-inventory-5 .layout-inventory-body-vl {
    padding: 30px 20px;
  }

  .layout-container-5 {
    .chat-container {
      width: 100%;
      margin: auto 0 20px auto;
      height: 82%;
      display: flex;
      flex-direction: column;
      justify-content: end;

      .chat-content {
        margin: auto 0 auto auto;
        border-radius: 0 0 0 0;
        width: 100%;
        height: 100%;
        margin-top: 5px;

        .chat {
          height: calc(100% - 22px);

          .chat-box-popup-footer {
            width: 92%;
            height: 15%;
            grid-gap: 10px;
            margin-bottom: 0;

            input {
              width: 93%;
            }

            aside {
              width: 29px;
              height: 29px;
              margin-right: 5px;
            }

            .icon-smile {
              width: 21px;
              height: 21px;
              padding-top: 4px;
              cursor: pointer;
            }
          }

          .chat-body {
            height: 130%;
            margin-bottom: 20%;

            .container-first-registered > div {
              font-size: 10px;
              margin: 6px 15px;
            }
          }

          .container-messages-dealer {
            width: 70%;
            font-size: 14px;
          }

          .data-input {
            font-size: 14px;
            width: 70%;
            padding: 11px;
            height: 40px;
          }

          .data-input-big-area {
            height: 64px;
          }

          .btn-send {
            width: 25%;
            height: 40px;
            font-size: 14px;
          }

          .title-chat {
            height: 43px;
            width: 100%;
            border-radius: 0 0 0 0;

            label {
              color: #FFFFFF;
              font-size: 18px;
              font-weight: bold;
            }

            a {
              display: none;
            }
          }
        }
      }
    }

    .chat-popup-message {
      margin: 100% 8px 0 auto;
    }

    .btn-chat-popup {
      margin-right: 8px;
    }
  }

  .container-testimonial-general {
    height: auto;

    .testimonial-header {
      height: 50px;
      padding: 0;
      margin-bottom: 30px;
      text-align: center;
      width: 100%;

      h1 {
        font-size: 26px;
      }
    }

    .body-customer-section {
      height: 82vw;
      padding: 0;

      .container-testimonial {
        width: 100%;
        height: 82vw;
        padding: 0;

        .carousel-testimonial {
          .section-testimonial {
            width: 82%;
            height: 100%;

            .row-customer {
              padding-top: 0;
              padding: 0px 0px 20px 0px;

              .quotation-marks {
                font-size: 73px;
                position: relative;
                top: 30px;
              }

              .testimonial-content-5 {
                font-size: 3.15vw;
              }

              .col-info {
                .col-image {
                  .box-image {
                    @include item-center;

                    .content-image {
                      height: 18vw;
                      width: 18vw;
                      img {
                        height: 100%;
                        width: 100%;
                      }
                    }
                  }
                }

                div {
                  .container-label-name {
                    label {
                      font-size: 18px;
                      font-weight: bold;
                    }
                  }

                  .container-label-social-network {
                    label {
                      font-size: 16px;
                      color: #809FB8;
                    }
                  }
                }

              }
            }
          }
        }
      }

      .section-left {
        margin-left: 10px;
      }

      .section-right {
        margin-right: 10px;
      }
    }

    .container-bar-progress {
      padding-top: 25px;
      width: 100%;
      @include item-center;

      .bar-progress {
        float: left;
        width: calc(90% - 50px);
        height: 25px;
        padding: 0 10px;
        @include item-center;
      }
    }
  }

  .body-customer-section {
    .testimonial-icon {
      width: 40px;
    }
  }

  @media screen and(min-width: 674px) {
    .container-testimonial-general {
      .body-customer-section {
        width: 674px;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 700px) {


  .body-customer-section {
    .testimonial-icon {
      width: 40px;
    }

  }

  .layout-inventory-5 {
    padding-top: 50px;
  }

  .layout-inventory-container-car-5 {
    .inv-car-view {
      top: 50px;
    }
  }

  .finance-form-5 {
    padding-top: 70px;
    padding-bottom: 20px;
  }

  .container-home-modern-view-2 {
    .container-header-top-modern-view-2 {
      height: 50px;
    }


  }


}

@media screen and (max-width: 680px) {

  .finance-body, .step-effect {
    .finance-label label {
      font-size: 12px;
    }

    .finance-icon-step {
      height: 24px;
    }
  }

  .layout-inventory-5 .row-inventory .car-item {
    width: 50%;
  }

  .layout-inventory-5 .col-inv-filter .col-keyboard {
    width: 60%;
  }

  .finance-form-5 {
    .finance-form-wizard {
      .tab-finance .nav-tabs {
        display: none;
      }
    }
  }

  .car-item-details {
    .label-title {
      font-size: 18px;
    }
  }

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 640px) {

  .body-customer-section .row-customer .label-experience {
    padding-bottom: 0;
    font-size: 12px;
    font-weight: lighter;
    padding-right: 0;
  }

  .container-label-name {
    text-align: center;
  }
  .container-label-info {
    text-align: center;
  }
  .layout-inventory-5 {
    .row-filter {
      padding-bottom: 0;
    }

    .filter-box {
      .row-filter {
        .col-filter-select {
          width: 100%;
          padding-bottom: 15px;
        }
      }

      .row-filter-color-condition {
        padding-bottom: 15px;

        #condition {
          display: none;
        }

        .col-filter-select {
          width: 50%;
        }
      }

      .row-filter-year-miles-price {
        padding-bottom: 0;

        .row-filter-ymp {
          width: 100%;
          padding-bottom: 15px;
        }
      }

      .row-filter-color-condition {
        display: none;
      }

      .row-filter-condition-cyl-mobile, .row-filter-color-mobile, .row-filter-title-trim-mobile {
        display: block;
        @include row-base;

        .col-filter-select {
          width: 50%;
          padding-bottom: 15px;
        }
      }

      .filter-number {
        padding-top: 18px;
      }

      .row-filter-conditions {
        padding-top: 0;
      }

      .filter-select {
        padding: 10px 10px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container-testimonial-general {
    .testimonial-header {
      h1 {
        font-size: 4.35vw;
      }
    }

    .body-customer-section {
      height: 82vw;

      .container-testimonial {
        .carousel-testimonial {
          .section-testimonial {
            .row-customer {
              .quotation-marks {
                font-size: 12.1vw;

              }
            }
          }

        }
      }
    }
  }


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 44px;
  }

  #carCarouselBodyTemplate5 {
    display: none;

    .col-car {
      width: 100%;
    }
  }

  .car-carousel-template5 {
    .car-item {
      width: 50%;
      margin: 0 25%;
      float: left;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transition: 0.5s;
      background-color: #fff;
      border-radius: 5px;
    }

    .car-item-previous {
      position: absolute;
      top: 10px;
      margin: 0 50% 0 5%;
      z-index: 10;
      width: 45%;
    }

    .car-item-next {
      position: absolute;
      z-index: 50;
      top: 10px;
      margin: 0 5% 0 53%;
      width: 42%;
    }

    .car-item-active {
      z-index: 100;
      position: relative;
    }

    .car-details {
      font-size: 10px;
    }

    .btn-car-content {
      font-size: 10px;
    }

    .row-title {
      color: #4D4D4D;
    }

    .pagination-carousel {
      padding: 30px 20%;
      position: relative;
      margin-top: 30px;

      .icon-arrow {
        width: 20px;
        height: 20px;
      }

      .wrapper-previous {
        position: absolute;
        left: 15%;
        top: 20px;
      }

      .wrapper-next {
        position: absolute;
        right: 15%;
        top: 20px;

      }
    }
  }

  .col-item-price {
    .car-item-old-price {
      padding-top: 3px;
    }

    .car-item-price {
      font-size: 16px;
    }
  }

  .label-title {
    font-size: 12px;
    width: 100%;
  }

  .car-carousel-template5 {
    .inventory-title {
      label {
        font-size: 20px;
      }
    }

    .car-details {
      display: none;
    }
  }

  .layout-finance5 {
    padding-top: 10px;
  }

  .car-item-details {
    .btn-car-info {
      font-size: 10px;
      padding: 1px 3px;
    }

    .row-details {
      padding-bottom: 5px;
    }
  }

  .finance-form-5 .finance-form-vertical, .finance-form-5 .finance-form-wizard {
    padding: 0 10px;
  }

  .finance-form-5 {
    .finance-form-wizard {
      padding: 0;
      margin: 0 10px;
    }
  }

  .car-item-details {
    .label-title {
      font-size: 16px;
    }
  }

  .layout-inventory-container-car-5 {
    .inv-car-view {
      padding: 5px;
    }

    .inv-car-image {
      padding: 5px;
    }

    .inv-car-info {
      padding: 10px 20px 20px 20px;
    }
  }

  .container-home-modern-view-2 {


    .lay-get-in-touch {
      height: 100%;
    }
  }

  .container-home-modern-view-3 {
    .banner-container-5 {
      .car-search-container {
        .container-car-search-input {
          .car-search-input {
            .car-search-icon {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {

  .finance-body {
    .finance-label label {
      font-size: 10px;
    }

    .finance-icon-step {
      height: 18px;
    }
  }

  .layout-inventory-5 {

    .layout-inventory-body {
      padding: 30px 5px 30px 10px;
    }

    .layout-inventory-body-vl {
      padding: 30px 10px;
    }

    .col-inv-filter {
      width: 100%;

      .col-keyboard {
        width: 75%;
      }

      .btn-filter {
        float: right;
        margin-right: 0;
        margin-left: 0;

        div > div {
          float: right;
        }
      }
    }

    .filter-box {
      padding: 30px 10px 15px 15px;

      .col-filter-select {
        padding-right: 5px;

        .p-abs {
          width: calc(100% - 5px);
        }

      }

      .row-filter-year-miles-price .row-filter-ymp {
        padding-right: 5px;
      }
    }
  }

  .layout-inventory-5 .row-inventory .car-item {
    padding-right: 5px;
  }


  .layout-inventory-5 .row-inventory .list-view-item {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 560px) {
  #carCarouselBodyTemplate5 {
    .col-car {
      width: 80%;
    }
  }
}

@media screen and (max-width: 540px) {
  .container-testimonial-general {
    .row-customer {
      .testimonial-content-5 {
        font-size: 3.15vw !important;
      }

      .container-label-name {
        label {
          font-size: 3.4vw !important;
        }
      }

      .container-label-social-network {
        label {
          font-size: 2.9vw !important;
        }
      }
    }
  }
  .car-item-details {
    .label-title {
      font-size: 14px;
    }
  }

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 40px;
  }
}

@media screen and (max-width: 520px) {

  .finance-body {
    .finance-body-step {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 500px) {


  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 38px;
  }

  .layout-inventory-container-car-5 {
    .inv-car-info {
      .car-details-header {
        .label-make-model {
          font-size: 20px;
        }

        .label-suggested-price {
          font-size: 20px;
        }

        .label-retail-price {
          padding-top: 5px;
          font-size: 10px;
        }

        .label-value-info {
          padding: 4px 10px;
          font-size: 11px;
          margin: 0 5px 0 0;
        }
      }
    }
  }

  .layout-about-container-5 {
    .body-customer-section {
      .row-customer {
        .col-image {
          .box-image {
            padding: 5% 25%;
          }

          .box-background {
            width: 40%;
            margin: 0 30%;
          }
        }
      }

      .testimonial-icon {
        width: 40px;
      }
    }
  }

  .finance-body{
    .finance-image, .finance-image-dark{
      height: 180px !important;
    }
  }
  .current-step-1 {
    .finance-image, .finance-image-dark{
      height: 160px !important;
    }
    .step-0 {
      height: 90% !important;
    }
    .step-2 {
      height: 90% !important;
    }
  }
}

@media screen and (max-width: 480px) {

  .layout-inventory-container .layout-finance5 {
    .finance-body {
      padding: 0 30px 0 30px;

      .finance-body-step {
        padding: 30px;
      }

      .finance-step-content {
        padding-right: 0;
      }

      .finance-icon-step {
        height: 24px;
      }

      .finance-label label {
        font-size: 12px;
        margin: 0;
      }
    }

    .finance-box {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.18);
    }

    .finance-apply {
      display: none;
    }
  }

  .layout-inventory-5 .row-inventory .car-item {
    width: 50%;
  }

  .layout-inventory-5 {
    padding-top: 40px;
  }

  .layout-inventory-5 {
    .layout-inventory-body {
      .row-inventory {
        .car-item {
          .car-item-box {
            .car-item-details {
              .row-details {
                .label-title {
                  font-size: 12px;
                }

                .btn-car-content {
                  .btn-car-info {
                    font-size: 8px;
                  }
                }

                .carfax-content {
                  img {
                    width: 56px !important;
                  }
                }

                .col-item-price {
                  label {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .finance-form-5 {
    .finance-form-vertical, .finance-form-wizard {
      .tab-finance {
        .tab-content {
          .colR25, .colR50, .colR20, .colR80, .colR16, .colR75 {
            width: 100%;
          }
        }
      }
    }

    .finance-form-wizard {
      .finance-btn {
        padding-top: 10px;

        .btn-previous {
          width: 49%;
          margin-right: 1%;
        }

        .btn-primary {
          width: 49%;
          margin-left: 1%;
        }

        button {
          font-size: 12px;
        }
      }
    }
  }

  .layout-inventory-5 .filter-box {
    padding: 30px 5px 15px 10px;
  }

  .car-item-details {
    .label-title {
      font-size: 18px;
    }
  }

  .layout-inventory-container-car-5 {
    .inv-car-info {
      padding: 10px 15px 15px 15px;

      .car-details-body {
        padding-bottom: 10px;
      }
    }

    .inv-car-view {
      top: 40px;
    }
  }

  .layout-about-container-5 .layout-about {
    padding: 45px 5px 5px 5px;
  }

  .container-home-modern-view-2 {
    .container-header-top-modern-view-2 {
      height: 40px;
    }


  }

  .layout-inventory-container {
    .layout-finance5 {
      .finance-indicators {
        .slide-back, .slide-next{
          transform: rotate(270deg);
        }
      }
    }
  }

  .container-testimonial-general {
    height: auto;

    .testimonial-header {
      margin-bottom: 30px;
      text-align: center;
      width: 100%;

      h1 {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .body-customer-section {
      height: 82vw;
      padding: 0;

      .container-testimonial {
        width: 100%;
        height: 82vw;
        padding: 0;

        .carousel-testimonial {
          .section-testimonial {
            width: 82%;
            height: 100%;

            .row-customer {
              padding: 0px 0px 20px 0px;
              box-shadow: 0px 4px #F4F4F4;

              .quotation-marks {
                font-size: 73px;
                position: relative;
                top: 30px;
              }

              .col-info {
                .col-image {
                  .box-image {
                    @include item-center;

                    .content-image {
                      height: 18vw;
                      width: 18vw;
                      img {
                        height: 100%;
                        width: 100%;
                      }
                    }
                  }
                }

                div {
                  .container-label-name {
                    label {
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }

                  .container-label-social-network {
                    label {
                      font-size: 10px;
                      color: #809FB8;
                    }
                  }
                }

              }
            }
          }
        }
      }

      .section-left {
        margin-left: 10px;
      }

      .section-right {
        margin-right: 10px;
      }
    }

    .container-bar-progress {
      padding-top: 25px;
      width: 100%;
      @include item-center;

      .bar-progress {
        float: left;
        width: calc(80% - 50px);
        height: 25px;
        padding: 0 10px;
        @include item-center;
      }
    }
  }

  .body-customer-section {
    .testimonial-icon {
      width: 40px;
    }
  }
}

@media screen and (max-width: 460px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 440px) {
  .car-carousel-template5 {
    .car-item {
      width: 70%;
      margin: 0 15%;
    }

    .car-item-previous {
      margin: 0 35% 0 5%;
      width: 60%;
    }

    .car-item-next {
      margin: 0 5% 0 35%;
      width: 60%;
    }
  }


}

@media screen and (max-width: 420px) {

  .body-customer-section {
    .testimonial-icon {
      width: 40px;
    }
  }

  .layout-location .layout-location-content {
    float: left;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }

  .layout-location {
    .layout-location-left {
      width: 100%;
      height: auto;
    }

    #map {
      width: 100% !important;
      height: 500px;
    }
  }
}

@media screen and (max-width: 400px) {


  .layout-inventory-container-car-5 {
    .inv-car-info {
      .car-details-body {
        .col-value-left, .col-value-right {
          width: 100%;
          padding: 0 0 10px 0;
        }

        .row-calculate {
          padding-bottom: 0;
        }

        .btn-calculate {
          margin-top: 10px;
        }

        .row-monthly-payment {
          padding-top: 10px;
        }

        .car-feature .row-specifications-one span, .car-feature .row-specifications-two span {
          padding: 5px 0 5px 5px;
          font-size: 12px;
        }

        .btn-car-tab {
          font-size: 16px;
          padding: 10px;
        }

        .btn-print-car {
          height: 43px;
        }

        .separator-tab {
          margin-top: 43px;
        }
      }

      .car-details-header {
        .label-make-model, .label-suggested-price {
          font-size: 16px;
        }

        .label-retail-price {
          padding-top: 3px;
          font-size: 8px;
        }

        .label-value-info {
          padding: 3px 10px;
          font-size: 10px;
        }
      }
    }

    .inv-car-view {
      top: 35px;
    }

  }

  .layout-inventory-5 {
    padding-top: 33px;
  }

  .layout-about-container-5 {
    .layout-about {
      padding-top: 40px;
    }

    .body-customer-section {
      .row-customer {
        .col-image .box-background {
          width: 50%;
          margin: 0 25%;
        }

        .col-image .box-image {
          padding: 5% 15%;
        }
      }
    }

    .layout-about-box {
      padding: 15px 5px 20px 5px;
    }
  }

  .layout-inventory-5 .row-filter-button {
    padding-right: 5px;
  }

  .popover-box {
    padding: 10px 5px 10px 5px;
  }

  .popover-header {
    padding: 0 3px 5px 3px;
  }

  .container-home-modern-view-2 {
    .container-header-top-modern-view-2 {
      height: 33px;
    }


  }


}

@media screen and (max-width: 380px) {
  .layout-inventory-5 .filter-box .filter-select {
    padding: 10px 4px;
  }

  .layout-inventory-5 .row-filter-button .btn-clear {
    padding-right: 5px;
  }

  .layout-about-container-5 {
    .goal {
      .item {
        .row-item {
          .box-item {
            height: auto !important;
          }
        }
      }
    }
  }

  .layout-container-5 {
    .car-carousel-template5 {
      .car-item {
        .car-item-details {
          .row-details {
            .btn-car-content {
              .btn-car-info {
                @include one-single-line-text;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
  }
  .finance-body{
    .finance-image, .finance-image-dark{
      height: 160px !important;
    }
  }
  .current-step-1 {
    .finance-image, .finance-image-dark{
      height: 130px !important;
    }
    .step-0 {
      height: 90% !important;
    }
    .step-2 {
      height: 90% !important;
    }
  }
}

@media screen and (max-width: 360px) {

  .finance-body {
    padding: 0 30px 0 30px;

    .finance-body-step {
      padding: 20px;
    }

    .finance-label label {
      font-size: 11px;
    }
  }

  .current-step-0 {
    .step-0 {
      width: 75%;
    }

    .step-1 {
      width: 45%;
    }

    .step-2 {
      width: 38%;
    }
  }

  .current-step-1 {
    .step-0 {
      width: 45%;
    }

    .step-1 {
      left: 12.5%;
      width: 75%;
    }

    .step-2 {
      width: 45%;
    }
  }

  .current-step-2 {
    .step-2 {
      width: 75%;
    }
  }

  .layout-inventory-5 {
    .filter-box {
      .filter-select {
        label {
          font-size: 12px;
        }

        i {
          font-size: 16px;
          padding-top: 2px;
        }
      }

      .filter-input input {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 340px) {


  .layout-inventory-5 {
    .row-filter-button {
      .btn-search .btn-search-inventory, .btn-clear .btn-clear-inventory {
        width: 115px;
      }
    }

    .filter-box {
      .filter-select {
        label {
          font-size: 12px;
        }

        i {
          font-size: 16px;
          padding-top: 2px;
        }
      }

      .filter-input input {
        font-size: 12px;
      }
    }

    .popover-header label {
      font-size: 12px;
    }
  }

  .popover-box {
    padding: 10px 2px 10px 5px;
  }

  .popover-header {
    padding: 0 3px 5px 0;

    i {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 320px) {

  .layout-about-container-5 .layout-about .goal .box .goal-title {
    font-size: 26px;
  }

  .about-testimonial {
    .layout-dealer-carousel {
      .carousel {
        .carousel-inner {
          padding: 0 15px !important;
        }

        .carousel-control {
          width: 15px !important;

          .arrow-right, .arrow-left {
            div {
              @include item-center;

              svg {
                width: 18px !important;
              }
            }
          }
        }
      }
    }
  }
}

#bannerSeparator {
  height: 18px;
  background-color: transparent;
  position: absolute;
  top: 160px;
  z-index: 200;
  width: 10px;
}

#templateViewPrevious {
  .layout-container-5 , .layout-finance-container{
    .lay-get-in-touch {
      .form-get-in-touch {
        .container-button-get-in-touch {
          .btn-inv-more {
            border-radius: 4px;
          }
        }
      }
    }
  }
  .view-previous-vehicles {
    #layoutInventory {
      .icon-list, .icon-grid {
        float: right;
        margin-left: 6px;
        cursor: pointer;
        width: 28px;
        margin-top: 5px;
        margin-right: 10px;
      }

      .icon-grid {
        width: 30px;
      }
    }
  }
}
