/**** LOGIN *****/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght$0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght$0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght$0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght$0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght$100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght$0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.account-confirmation {
  .background {
    width: 100vw;
    height: 40vw;
    background-color: #F5F6FA;
    z-index: -11;
    position: absolute;
  }
  .half-background {
    width: 100vw;
    height: 40vw;
    z-index: -10;
    position: absolute;
    margin-top: 420px;
    background: pink linear-gradient(180deg, #43425D, #005294);
  }
  .header {
    height: 56px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 2px 6px rgba(0,0,0,0.04);
    opacity: 1;
  }
  .dealer-logo-img {
    height: 100%;
    padding: 7px;
    margin-left: 120px;
  }
  .title {
    color: #005294;
    font-size: 28px;
    font-weight: 100;
    //font-family: 'Open Sans', sans-serif;
    text-align: center;
    margin-top: 80px;
  }
  .sub-title {
    text-align: center;
    font-weight: 600;
    color: #787878;
    //font-family: 'Open Sans', sans-serif;
  }
  .form-content, .form-recover-password, .form-change-password {
    background-color: white;
    width: 40vw;
    float: left;
    height: auto;
    margin-left: calc(50% - 20vw);
    border-radius: 5px;
    margin-top: 40px;
    box-shadow: 0 2px 20px rgba(157, 157, 157, 0.15);
    padding: 30px 50px;
    margin-bottom: 160px;
    label {
      display: table-cell;
      color: #A4AFB7;
      font-weight: lighter;
      //font-family: "'Open Sans', sans-serif";
      font-size: 10px;
      padding-left: 13px;
    }
    input, &:focus {
      border: 1px solid rgb(164, 175, 183);
      border-radius: 4px;
      padding: 7px 13px;
      font-size: 12px;
      color: #6d6f7a !important;
      font-weight: lighter;
      //font-family: 'Open Sans', sans-serif;
      margin-top: 5px;
      width: 100%;
      outline: none;
    }
    .label-forgot-password{
      float: right;
      margin-top: 10px;
      color: #a2d3fa;
      cursor: pointer;
    }
  }

  .form-recover-password{
    width: 34vw;
    display: table;
    margin: 80px auto 0 calc(50% - 14vw);

    .recover-password-img{
      text-align: center;
    }
    .text-forgot-password{
      margin-top: 30px;
      color: #a8a9b0;
      font-weight: lighter;
      //font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      p{
        margin: 0;
        span{
          color: #545763;
          font-weight: 900;
        }
      }
    }
    .label-send-again{
      margin-top: 15px;
      color: #57aef4;
      cursor: pointer;
    }
  }

  .form-change-password{
    margin-top: 20px;
    margin-left: calc(50% - 25vw);
    width: 50vw;
  }

  .text-center{
    text-align: center;
    float: left;
    width: 100%;
  }
  .btn-start, .btn-login, .btn-back-login {
    background-color: #2194F1;
    border-radius: 3px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 12px;
    padding: 8px 18px;
    margin-top: 40px;
  }

  .btn-login{
    margin-top: 16px;
    width: 130px;
  }

  .btn-back-login{
    margin-top: 30px;
  }

  .requirements-box {
    color: #787878;
    font-size: 15px;
    margin-top: 20px;
    p {
      margin: 0 0 2px 0;
      display: table;
    }
    img {
      width: 19px;
      margin-right: 4px;
    }
  }
}

#id-btn-login{
  float: right;
  border-radius: 4px;
}

#id-form-content{
  margin-top: 25px;
  padding: 0 0 20px;
}

.id-form-content-reset{
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  border-bottom: 1px solid rgb(245, 245, 245);
  padding: 17px 25px;
  color: rgb(112, 112, 112);
}

.id-form-content-email{
  padding: 17px 25px;
  color: rgb(209, 215, 219);
}

.new-password-recover{
  display: flow-root;
}

.confirm-password-recover{
  margin-top: 20px;
}

.input-new-password-recover{
  position: relative;
}

.img-new-password-recover{
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 24px;
  height: 24px;
}

.img-password-recover{
  position: absolute;
  bottom: 5px;
  right: 6px;
  width: 21px;
  height: 21px;
}

.img-new-password-recover-new{
  position: absolute;
  bottom: 1px;
  right: 3%;
  width: 25px;
  height: 25px;
}

.box-new-password-recover{
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.form-group-login{
  color: rgb(0, 82, 160);
  text-align: left;
  padding-left: 16px;
  cursor: pointer;
  font: normal 13px/22px 'Poppins', sans-serif;
  position: relative;
  display: flex;
}

.form-content-password{
  margin-top: 25px;
}

.form-forgot-password{
  display: table-cell;
  color: rgb(164, 175, 183);
  font-weight: lighter;
  //font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  padding-left: 13px;
  padding-top: 13px;
  cursor: pointer;
}

.form-forgot-password :hover{
  color: #2194F1;
}

.form-btn-sign-in{
  padding-right: 20px;
  width: 100%;
  text-align: right;
  border-top: 1px solid rgb(245, 245, 245);
}

.btn-form-content-reset{
  padding-right: 20px;
  width: 100%;
  text-align: right;
  border-top: 1px solid rgb(245, 245, 245);
}

.icon-arrow-green{
  width: 17px;
  padding-top: 4px;
  margin-right: 7px;
}

.p-review-check{
  display: flex;
}

.logo-godealergo{
  width: 237px;
  display: block;
  margin: 60px auto;
}

.message-back-change-password{
  display: block;
  text-align-last: center;
  color: green;
  font-size: 20px;
  margin: 60px auto 60px auto;
}

.message-error, .message-general-error{
  font-size: 12px;
  color: red;
  position: relative;
  top: 1px;
  left: 16px;
}

.message-general-error{
  left: 0;
  top: -10px;
}

.form-container{
  float: left;
  width: 100%;
  display: flex;
  .form-content{
    margin: auto;
  }

}

/**********media queries ************************/
@media screen and (max-width: 768px) {
  .account-confirmation{
    .header {
      display: flex;
      justify-content: center;

      img {
        margin-left: 0;
      }
    }
    .form-content{
      padding: 20px;
      width: 90%;
      float: none;
      margin-left: calc(30% - 25vw);
    }
  }

  .logo-godealergo {
    margin: 40px auto;
  }

  #id-form-content {
    margin-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .account-confirmation{
    .header {
      display: flex;
      justify-content: center;

      img {
        margin-left: 0;
      }
    }
    .form-content{
      padding: 20px;
      width: 90%;
      float: none;
      margin-left: calc(30% - 25vw);
    }
  }

  .logo-godealergo {
    margin: 30px auto;
  }
}

//Backdrop styles
.backdrop{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.5);
}

.burger-button{
  width: 20px;
  height: auto;
}

.exit-button{
  fill: #4D4F5C;
  width: 15px;
  height: auto;
}

.label-popup-chat{
  border-radius: 4px;
  margin-left: 15px;
  margin-bottom: 5px;
  color: black ;
  ::placeholder {
    @extend .gray-color;
  }
}

.btn-send {
  @extend .flex-row;
  @extend .label-text-chat;
  @extend .g-5;
  width: 59px;
  height: 26px;
  background: #D10C0C no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  justify-content: center;
  border: none;
  color: white !important;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.chat-popup-message{
  width: 194px;
  height: 42px;
  background: #FFFFFF no-repeat padding-box;
  box-shadow: 0 3px 10px #00000029;
  border-radius: 10px 10px 0 10px;
  opacity: 1;
  padding: 13px 11px;
  margin: 0 0 0 auto;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0;
  color: #454545;
}

.mt-6{
  margin-top: 6px;
}

.btn-minimize {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: white;
}

.chat-container{
  background-color: transparent;
  width: 262px;
  position: fixed;
  right: 0;
  z-index: 1000;
  margin-right: 26px;
  bottom: 60px;
  @extend .flex-column;
  @extend .g-9;
}

.btn-chat-popup{
  padding-top: 5px;
  width: 36px;
  height: 36px;
  border: 1px solid #FFFFFF;
  background: #D10C0C;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px #00000029;
  border-radius: 10px 0 10px 10px;
  opacity: 1;
  align-self: flex-end;
  i{
    color: white;
    font-size: 20px;
  }
}

.btn-chat-popup:active {
  transform: rotate(180deg);
}

.chat-content{
  width: 262px;
  height: 328px;
  background: #FFFFFF no-repeat padding-box;
  box-shadow: 0 3px 10px #00000029;
  border-radius: 10px 10px 0 10px;
  opacity: 1;

  .chat{
    @extend .flex-column;
    height: 328px;
    .title-chat{
      width: 262px;
      height: 27px;
      background: #D10C0C no-repeat padding-box;
      border-radius: 10px 10px 0 0;
      opacity: 1;
      align-items: center;
      padding: 12px;
      justify-content: space-between;
      @extend .flex-row;
      @extend .label-text-chat;
      label{
        color: white;
        margin: 0;
        font-weight: 400;
      }
    }

    .chat-body{
      margin-top:9px;
      height: 360px;
      @extend .flex-column;
    }
  }
}

.label-text-chat{
  color:#454545;
  font-size: 9px;
}

.data-input{
  padding: 9px 6px;
  width: 176px;
  height: 26px;
  background: #F2F3F5 no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 5px;
  outline: none;
  border: 0;
  @extend .label-text-chat;
  @extend .g-5;
  ::placeholder{
    @extend .gray-color;
    padding:9px;
  }
}

.data-input-big-area{
  @extend .data-input;
  height: 57px;
  resize: none;
}
.gray-color {
  color:#A4AFB7
}

.g-9{
  gap:9px;
}

.g-5{
  gap:5px;
}

.flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-box-popup-footer{
  box-sizing: border-box;
  width: 232px;
  height: 26px;
  background: #F2F3F5 no-repeat ;
  border-radius: 4px;
  opacity: 1;
  align-self: center;
  margin: auto auto 10px;
  @extend .flex-row;
  input{
    margin: 0px;
    width:191px;
  }
  aside{
    @extend .flex-center;
    width: 20px;
    height: 20px;
    background: 0% 0% no-repeat padding-box padding-box rgb(209, 12, 12);
    border-radius: 4px;
    margin: 0 auto;
    margin-right: 3px;
    cursor: pointer
  }
}

.icon-send{
  width: 11px;
  height: 11px;
}

.icon-popup{
  width: 17px;
  height: 17px;
}

.icon-close{
  width: 23px;
  height: 23px;
}

.icon-smile{
  width: 14px;
  height: 14px;
  padding-top: 4px;
  cursor: pointer;
}

.flex-center{
  align-items: center;
  display: flex;
  justify-content: center;
}

.container-messages-dealer{
  padding: 8px;
  background: #F2F3F5 no-repeat padding-box;
  border-radius: 5px 5px 0 5px;
  opacity: 1;
  width: 191px;
  margin: 0 15px 5px auto;
  @extend .label-text-chat;
  @extend .mt-6;
}

.container-messages-client {
  @extend .flex-row;
  @extend .gray-color;
  font-size: 9px;
  padding-right: 15px;
  div{
    @extend .container-messages-dealer;
    background: #D10C0C no-repeat padding-box;
    border-radius: 5px 5px 5px 0;
    margin: 0 auto 0 15px;
    color: white;
  }
}

.container-first-registered{
  @extend .flex-column;
  .form-client{
    @extend .flex-column;
  }
}

.container-first-registered > div{
  @extend .label-text-chat;
  margin: 6px 15px;
}

.catalog-inventory-pagination {
  .pagination-left{
    .btn-page-group {
      border-radius: 17px;
    }
  }
  .pagination-right{
    .btn-back {
      border-radius: 17px 0 0 17px;
    }

    .btn-next {
      border-radius: 0 17px 17px 0;
    }
  }
}
