@import "src/common/styles/utils";

$arrow-color: #CACACA;
$font-weight-semi-bold: 600;
$height-input-mobile-view: 36px;
$title-color: #272727;

/***************** BANNER Template 5 ******************/

.banner-container-5 {
  @include row-base;
  position: relative;
  padding-top: 55px;

  .car-search-container, .car-search-container-settings, .car-search-container-settings-easy-navigation {
    position: absolute;
    bottom: -80px;
    width: 100%;
    padding: 0 65px;

    .car-search-box {
      background-color: white;
      padding: 40px 40px 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.18);

      .car-search-row {
        .car-search-select-content-model {
          .car-search-select {
            word-break: break-all;
            .icon-expand-more {
              float: right;

              svg {
                fill: $arrow-color;
                height: 8px;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }

          .popover-box {
            .popover-header {
              .icon-expand-more {
                float: right;

                svg {
                  fill: $arrow-color;
                  height: 8px;
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .btn-search-content {
        .btn-search-car-content {
          width: auto;

          .btn-search-car {
            width: auto;
            padding: 12px 30px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .car-search-container-settings-easy-navigation {
    z-index: 100;
    bottom: -90px;

    .car-search-box {
      border-radius: 15px;
    }
  }

  .form-control-search {
    width: 90%;
    height: 40px;
    padding: 6px 12px;
    border: 0;
    float: left;
    font-size: 18px;
    font-weight: 300;
    font-style: oblique;
  }

  .car-search-icon {
    float: right;
    color: #d4d4d4;
    margin-top: 10px;

    img {
      width: 24px;
    }
  }

  .btn-search-car:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .banner-title {
    float: right;
    width: 30vw;
    text-align: center;
    position: absolute;
    top: 165px;
    right: 0;
    margin-right: 7.55%;

    h1 {
      color: white;
      font-size: 3.4vw;
      font-weight: $font-weight-bold;
      text-align: right;
      text-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
      word-break: break-word;
    }
  }

  .banner-title-setting, .banner-title-setting-easy-navigation, .banner-title-setting-modern-view, .banner-title-setting-modern-view-3 {
    float: right;
    width: 50%;
    text-align: center;
    position: absolute;
    top: 120px;
    right: 0;

    h1 {
      color: white;
      font-size: 70px;
      font-weight: $font-weight-bold;
      text-align: right;
      padding-right: 40px;
      text-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
      word-break: break-word;
    }
  }

  .banner-title-setting-easy-navigation {
    width: 100%;
    top: 420px;
    height: auto;

    h1 {
      text-align: right;
    }
  }

  .banner-title-setting-modern-view {
    /*top: 320px;
    left: 7.55%;*/
    top: 120px;
    left: 0;
    margin-left: 65px;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: left;
      word-break: break-word;
    }
  }

  .banner-title-setting-modern-view-3 {
    width: 100%;
    top: 200px;

    >div{
      width: 100%;
      @include item-center;
      height: 150px;
    }

    h1 {
      @include item-center;
      font-size: 4vw;
      font-weight: 500;
      padding: 0;
      width: 60%;
      text-align: center;
    }
  }

  .btn-apply-content {
    width: 10%;
    position: absolute;
    right: 0;
    padding-top: 30px;

    .btn-apply {
      float: right;
      background-color: #BC1B1B;
      color: #ffffff;
      border-radius: 5px;
      width: 150px;
      font-weight: $font-weight-semi-bold;
    }
  }

  .btn-apply-content-settings {
    right: 0;
  }

  .btn-apply-content-settings, .btn-apply-content-settings-modern-view {
    position: absolute;
    top: 15vw;

    .btn-apply-settings {
      letter-spacing: normal;
      color: #ffffff;
      border-radius: 5px;
      width: auto;
      padding: 12px 30px;
      font-size: 16px;
      font-weight: normal;
    }

    .flex-row {
      float: right;
    }
  }

  .btn-apply-content-settings-modern-view {
    width: 92.45%;
    position: static;
    margin-top: 30px;

    .btn-apply-settings {
      float: left;
      font-weight: 600;
    }
  }

  .background {
    width: 100%;
    object-fit: cover;
  }

  .car-search-row.second-row {
    display: flex;
    gap: 20px;
  }




  .col-keyboard, .col-keyboard-easy-navigation {
    position: relative;

    input {
      border-radius: 3px;
      padding-right: 40px;
      height: inherit;
      border: 1px solid #e3e3e3;
      box-shadow: 0 0 0;
      background-color: #ffffff;
    }

    input:focus {
      border: 1px solid #e3e3e3;
      box-shadow: 0 0 0;
    }

    .icon-search {
      fill: #7F7F7F;
      width: 16px;
      position: absolute;
      top: 15px;
      z-index: 1;
      right: 20px;
    }
  }

  .col-keyboard-easy-navigation {
    input {
      border-radius: 20px;
    }
  }

  .car-search-select-content, .car-search-select-content-make, .car-search-select-content-model,
  .car-search-input-content-cel {
    width: 100%;
    position: relative;

    .layoutBanner-select-deployed {
      border-bottom: none;
      border-radius: 3px 3px 0 0;
    }
    .layoutBanner-detail {
      border-radius: 0 0 3px 3px;
      border-top: none;
    }

  }

  .car-search-input-content {
    width: 33%;
    float: left;
    position: relative;
  }

  .car-search-select, .car-search-select-easy-navigation {
    height: inherit;
    border-radius: 3px;
    border: 1px solid #e3e3e3;
    padding: 0 20px;

    label {
      color: #4D4F5C;
      font-weight: 300;
    }

    i {
      color: #4D4F5C;
      float: right;
      font-size: 18px;
    }

    .number-selected {
      float: right;
      color: #8b8b8b;
      margin: 0;
      font-weight: 400;
      padding-right: 5px;
    }
  }

  .car-search-select-easy-navigation {
    border-radius: 20px;
  }

  .btn-search-content {
    width: 100%;
    height: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .btn-search-car-content-easy-navigation {
    width: 12%;
  }

  .btn-search-car-easy-navigation {
    border-radius: 5px;
  }

  .label-banner-template {
    color: #979797;
    font-size: 11px;
    text-align: left;
    font-weight: $font-weight-regular;;
  }

  .input-search-content, .input-search-content-price, .input-search-content-easy-navigation {
    float: left;
    width: 45%;
    position: relative;

    input {
      height: inherit;
      color: #4D4F5C;
      border: 1px solid #e3e3e3;
      box-shadow: 0 0 0;
    }

    input:focus {
      border: 1px solid #e3e3e3;
      box-shadow: 0 0 0;
    }
  }

  .input-search-content-easy-navigation {
    input {
      border-radius: 20px;
    }
  }

  .text-fields {
    height: 16px;
    @include row-base;
    position: absolute;
    top: -5px;

    label {
      color: #4D4F5C;
      font-size: 11px;
      text-align: left;
      font-weight: $font-weight-regular;
    }
  }

  .fields-boxes {
    height: 28px;
    width: 100%;
    padding-top: 16px;
  }

  .label-to {
    width: 10%;
    text-align: center;
    float: left;

    label {
      color: #4D4F5C;
      font-size: 11px;
      text-align: left;
      font-weight: $font-weight-regular;
      margin-top: 12px;
    }
  }

  .k-miles, .prices {
    color: #4D4F5C;
    float: left;
    position: absolute;
    top: 12px;
    font-weight: 100;
    margin: 0 15px 0 15px;
  }

  .prices {
    left: 5px;
  }

  .k-miles {
    right: 10px;
  }

  .input-search-content-price {
    input {
      padding-left: 35px;
    }
  }

  .container-car-search-input {
    position: absolute;
    top: -260px;
    width: 100%;
    height: 60px;
    z-index: 100;
    left: 0;

    .car-search-input {
      width: 50%;
      margin: 0 auto;
      padding: 2px 5px 2px 25px;
      background-color: #fff;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 13px 0 rgba(0, 0, 0, 0.12);
      border-radius: 3px;

      input:focus {
        outline: none;
      }
    }

    .form-control-search {
      width: 75%;
      height: 20px;
      font-style: normal;
      color: #0a0c0e;
      margin-top: 8px;
    }

    .car-search-icon {
      float: left;
      color: #0a0c0e;
      margin-top: 5px;

      img {
        width: 18px;
      }
    }

    .container-button {
      float: right;
      height: 100%;

      .button-input {
        height: 100%;
        width: 120px;
        letter-spacing: normal;
        border-radius: 5px;
        color: #ffffff;
      }
    }
  }

  .car-search-container-settings {
    padding: 0 40px;
  }

}

.layout-container-5{
  .banner-container-5{
    .car-search {
      .layoutBanner-detail {
        border-top: none;
        height: 145px;
      }
    }
    .btn-search-car, .btn-search-car-easy-navigation {
      border-radius: 3px;
      @include row-base;
      font-weight: normal;
      letter-spacing: normal;
      padding: 10px;
      text-align: center;
      font-size: 14px;
      color: white;
    }
    .btn-search-car-easy-navigation{
      font-weight: 400;
    }
    .btn-search-car-mv2 {
      font-weight: 600;
      border-radius: 3px;
    }
  }
}

.layout-container-main-b {
  .banner-container-5 {
    .car-search-container {
      .car-search-box {
        .car-search-select-content, .car-search-select-content-make, .car-search-select-content-model,
        .car-search-input-content-cel {
          width: 100%;
          position: relative;

          .layoutBanner-select-deployed {
            border-bottom: none;
            border-radius: 22px 22px 0 0;
          }
          .layoutBanner-detail {
            border-radius: 0 0 22px 22px;
            border-top: none;
          }

        }
        .btn-search-content {
          .btn-search-car-content {
            .btn-search-car {
              padding: 12px 30px;
              width: 100%;
              font-weight: 600;
              border-radius: 30px;
              text-align: center;
              letter-spacing: normal;
              color: #fff;
              margin-top: 10px;
              cursor: pointer;
            }
          }
        }
        .dp-select-paper {
          height: auto;
          .dp-select-options-list {
            max-height: 148px;
            overflow: auto;
          }
        }
      }
    }
  }
}

.banner-separator {
  height: 50px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.popover-box {
  @include row-base;
  height: 200px;
  padding: 10px 0 10px 20px;
  color: #4D4F5C;
  position: relative;

  .popover-box-scroll {
    float: left;
    width: 100%;
    overflow: auto;
    height: 89%;
  }

  .header {
    @include row-base;
  }

  .separator {
    @include row-base;
    border: 1px solid #8c7f7f;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .checked {
    @include row-base;

    .title {
      font-size: 14px;
      color: #8c7f7f;
      font-weight: $font-weight-bold;
    }
  }

  .notChecked {
    @include row-base;

    .title {
      font-size: 14px;
      color: #8c7f7f;
      font-weight: $font-weight-bold;
    }
  }

}

.clearFilters {
  @include row-base;
  text-align: right;

  label {
    font-size: 11px;
    text-decoration: underline;
    color: #8c7f7f;
  }

  label:hover {
    cursor: pointer;
  }
}

.popover-header {
  padding: 0 20px 5px 3px;

  label {
    font-weight: $font-weight-regular;
    font-size: 14px;
  }

  i {
    float: right;
    font-size: 16px;
    padding-top: 3px;
  }
}

.icon-uncheck, .icon-check {
  fill: #CACACA;
  height: 24px;
}

.carfax-content {
  a {
    width: max-content;
    display: flex;
    color: inherit;
  }

  img {
    width: 80px !important;
    height: auto !important;
  }
}

.other-option {
  .carfax-content {
    a {
      font-size: 15px;
    }
  }
}

/***************** BANNER Template 5  modern view 2 ******************/
.container-home-modern-view-2 {
  .banner-container-5 {
    .banner-title {
      top: 8vw;
      width: 40vw;

      h1 {
        font-size: 60px;
      }
    }

    .car-search-container {
      .car-search-box {
        border-radius: 20px;
      }
    }

    .banner-title {
      margin-left: 7%;
      margin-right: 0;
      left: 0;
      display: flex;
      flex-direction: column;

      h1 {
        text-align: left;
        word-break: break-word;
      }

      .btn-apply-content {
        padding-top: 30px;
        margin-left: 110px;
        left: 0;

        .btn-apply {
          float: right;
          background-color: #BC1B1B;
          color: #ffffff;
          border-radius: 5px;
          width: 150px;
          font-weight: $font-weight-semi-bold;
        }
      }

      .btn-apply-content-settings-modern-view {
        position: static;
        margin-top: 30px;
      }
    }
  }
}

.container-home-modern-view-2 {
  .banner-container-5 {
    .banner-title {
      .btn-apply-content-settings-modern-view {
        display: flex;
        .btn-apply-settings {
          width: auto;
          height: auto;
          padding: 12px 30px;
          font-size: 16px !important;
          font-weight: 600 !important;
        }
      }
    }

    .car-search-container {
      .car-search-box {
        .btn-search-content {
          .btn-search-car-content {
            .btn-search-car {
              width: auto;
              height: auto;
              padding: 12px 30px;
              font-size: 16px !important;
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}

/***************** BANNER Template 5  modern view 3 ******************/
.container-home-modern-view-3 {
  .banner-container-5 {
    .banner-title {
      width: 100%;
      margin: 0;
      top: 180px;

      > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;

        h1 {
          width: 45%;
          display: flex;
          text-align: center;
          font-size: 4vw;
          font-weight: 500;
          justify-content: center;
          margin: 0;
          line-height: 1;
        }
      }

    }

    .car-search-container {
      .car-search-box {
        #containerButtons {
          @include item-center;
          flex-direction: row-reverse;
          padding-top: 30px;

          .btn-close-filter {
            float: left;
            width: 100%;
            padding: 10px;
            text-align: center;
            font-size: 16px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }

          .btn-search-content {
            width: unset;
          }

          .btn-search-car-content {
            width: 200px;
            background-color: unset;
            .flex-row{
              height: 100%;
            }
          }
        }
      }
    }

    .container-car-search-input{
      top: -295px;
    }
  }
  .car-carousel-template5{
    padding-top: 125px !important;
  }
}


/*************************************************** BANNER Template Main ***************************************************************************/

.layout-container-main-b {
  .banner-container-5 {
    z-index: inherit !important;

    .banner-title {
      top: 10vw;
      h1 {
        word-break: break-word;
        padding: 0 120px;
      }
    }
  }
}

.layout-container-main {
  .banner-container {
    z-index: 102;
    .car-search {
      .layoutBanner-detail {
        border-radius: 0 0 22px 22px;
        border-top: none;
      }

      .dp-select-paper {
        .dp-select-options-list {
          max-height: 148px;
          overflow: auto;
        }
      }
      .layoutBanner-select-deployed{
        border-radius: 22px 22px 0px 0px;
      }
    }
  }
}

.banner-container {
  @include col100;
  position: relative;
  padding-top: 180px;

  .car-search {
    width: 100%;

    .car-search-input {
      width: 40%;
      margin: 0 auto;
      padding: 10px 25px;
      height: 60px;
      background-color: #fff;
      border-radius: 100px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 13px 0 rgba(0, 0, 0, 0.12);

      input:focus {
        outline: none;
      }
    }

    .col-keyboard {
      position: relative;

      input {
        border-radius: 30px;
        padding-right: 40px;
        height: inherit;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
        background-color: #ffffff;
      }

      input:focus {
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }

      .icon-search {
        fill: #7F7F7F;
        width: 16px;
        position: absolute;
        top: 15px;
        z-index: 1;
        right: 20px;
      }
    }

    @mixin row-base() {
      float: left;
      width: 100%;
    }

    .car-search-container, .car-search-container-settings {
      width: auto;
      margin: 0 3vw;

      .car-search-box {
        background-color: white;
        padding: 40px 40px 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 20px 0 rgba(0, 0, 0, 0.18);
        border-radius: 30px;

        .car-search-row {
          .car-search-select-content-model {
            .car-search-select {
              .icon-expand-more {
                float: right;

                svg {
                  fill: $arrow-color;
                  height: 8px;
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }

            .popover-box {
              .popover-header {
                .icon-expand-more {
                  float: right;

                  svg {
                    fill: $arrow-color;
                    height: 8px;
                  }

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .car-search-row.second-row {
      display: flex;
      gap: 20px;
    }

    .btn-search-content {
      @include row-base;
      height: inherit;
      position: relative;
      display: flex;
      justify-content: center;
    }

    .car-search-select-content, .car-search-select-content-make, .car-search-select-content-model,
    .car-search-input-content-cel {
      position: relative;

      .layoutBanner-select-deployed {
        border-bottom: none;
      }
    }

    .car-search-input-content {
      width: 33%;
      position: relative;
    }

    .car-search-select {
      height: inherit;
      border-radius: 30px;
      border: 1px solid #e3e3e3;
      padding: 0px 20px;
      word-break: break-all;

      label {
        color: #4D4F5C;
        font-weight: 300;
      }

      i {
        color: #4D4F5C;
        float: right;
        font-size: 18px;
      }

      .number-selected {
        float: right;
        color: #8b8b8b;
        margin: 0;
        font-weight: 400;
        padding-right: 5px;
      }
    }

    .input-search-content, .input-search-content-price {
      float: left;
      width: 45%;
      position: relative;

      input {
        height: inherit;
        color: #4D4F5C;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
        border-radius: 30px;
      }

      input:focus {
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 0;
      }
    }

    .text-fields {
      height: 16px;
      @include row-base;
      position: absolute;
      top: -5px;

      label {
        color: #4D4F5C;
        font-size: 11px;
        text-align: left;
        font-weight: 400;
        margin-left: 20px;
      }
    }

    .fields-boxes {
      height: 28px;
      width: 100%;
      padding-top: 16px;
    }

    .label-to {
      width: 10%;
      text-align: center;
      float: left;

      label {
        color: #4D4F5C;
        font-size: 11px;
        text-align: left;
        font-weight: 400;
        margin-top: 12px;
      }
    }

    .prices {
      color: #4D4F5C;
      float: left;
      position: absolute;
      top: 12px;
      font-weight: 100;
      margin: 0 15px 0 15px;
    }

    .k-miles {
      color: #4D4F5C;
      float: left;
      position: absolute;
      top: 12px;
      font-weight: 100;
      margin: 0 15px 0 15px;
    }

    .prices {
      left: 5px;
    }

    .k-miles {
      right: 10px;
    }

    .input-search-content-price {
      input {
        padding-left: 30px;
      }
    }

    .popover-box {
      @include row-base;
      height: 200px;
      padding: 10px 0 10px 20px;
      color: #4D4F5C;
      position: relative;

      .popover-box-scroll {
        float: left;
        width: 100%;
        overflow: auto;
        height: 76%;
      }

      .header {
        @include row-base;
      }

      .separator {
        @include row-base;
        border: 1px solid #8c7f7f;
        margin-bottom: 15px;
        margin-top: 15px;
      }

      .checked {
        @include row-base;

        .title {
          font-size: 14px;
          color: #8c7f7f;
          font-weight: 700;
        }
      }

      .notChecked {
        @include row-base;

        .title {
          font-size: 14px;
          color: #8c7f7f;
          font-weight: 700;
        }
      }

      .popover-header {
        padding: 0 20px 5px 3px;

        label {
          font-weight: 400;
          font-size: 14px;
        }

        i {
          float: right;
          font-size: 16px;
          padding-top: 3px;
        }
      }
    }
  }

  .form-control-search {
    width: 90%;
    height: 40px;
    padding: 6px 12px;
    border: 0;
    float: left;
    font-size: 18px;
    font-weight: 300;
    font-style: oblique;
    color: #979797;
  }

  .car-search-icon-preview {
    margin-top: 0 !important;
  }

  .car-search-icon {
    float: right;
    color: #d4d4d4;
    margin-top: 10px;

    img {
      width: 24px;
    }
  }

  .show-options-wrapper {
    width: 100%;
    height: auto;
    float: left;
    position: relative;
  }

  .btn-show-options {
    color: #fff;
    text-align: center;
    height: 30px;
    border-radius: 0 0 20px 20px;
    width: 120px;
    padding-top: 5px;
    letter-spacing: normal;
    font-weight: 600;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 13px 0 rgba(0, 0, 0, 0.12);
    text-decoration: none;
    cursor: pointer;
    margin: 0 auto;
    display: block;



  }

  .btn-search-car {
    border-radius: 30px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    letter-spacing: normal;
    color: #fff;
    margin-top: 10px;
    cursor: pointer;
  }

  .banner-content-img {
    @include col100;
    display: flex;
    position: relative;
  }

  .banner-title {
    float: left;
    width: 50%;
    padding: 20px 100px 0 130px;

    h1 {
      color: white;
      font-size: 60px;
      font-weight: $font-weight-bold;
      text-shadow: 5px 6px 5px rgba(0, 0, 0, 0.16);
      width: 400px;
      word-break: break-word;
    }
  }

}

/****** Easy Navigation B **********/

.layout-container-main-b {
  .banner-container-5 {
    z-index: 10;

    .car-search-container {
      .car-search-box {
        border-radius: 20px;

        .layoutBanner-select-deployed {
          border-radius: 22px 22px 0 0 ;
        }
      }
    }

    .col-keyboard input, .car-search-select, .input-search-content input, .input-search-content-price input {
      border-radius: 22px;
    }

    .banner-title {
      @include item-center;
      align-items: flex-end;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      bottom: 200px;

      h1 {
        font-size: 3.5vw;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}

.banner-container .banner-title h1 {
  font-size: 4vw;
}


.car-search-row.first-row-grid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 44px;
  gap: 20px;
  width: 100%;
}

.car-search-row{
  .input-container, .dp-select-container {
    height: 44px;
  }
}


/********************BANNER Media queries********************/

@media screen and (min-width: 1600px) {
  .container-home-modern-view-2 {
    .banner-container-5 {
      .banner-title {
        h1 {
          font-size: 60px;
        }
      }
    }
  }
  .banner-container-5 {
    .btn-apply-content-settings, .btn-apply-content-settings-modern-view {
      top: 300px;
    }

    .banner-title {
      top: 140px;
      width: 35vw;

      h1 {
        font-size: 75px;
      }
    }

    .car-search-container, .car-search-container-settings {
      padding: 0 7.55%;
    }
  }
  .banner-container .banner-title h1 {
    font-size: 75px;
    width: 100%;
  }
  .layout-container-main-b .banner-container-5 .banner-title h1 {
    font-size: 75px;
  }
  .container-home-modern-view-3 {
    .banner-container-5 {
      .banner-title {
        top: 180px;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .container-home-modern-view-1 .banner-container-5 .banner-title{
    margin-right: 65px;
  }
}

@media screen and (max-width: 1600px) {
  .layout-container-main-b {
    .banner-container-5 {

      .car-search-container {
        bottom: 30px;
      }

      .banner-title {
        bottom: 300px;
      }
    }

    .layout-car {
      top: -100px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .layout-container-main-b {
    .banner-container-5 {
      z-index: inherit;

      .car-search-container {
        bottom: 45px;
      }

      .banner-title {
        bottom: 350px;
      }
    }

    .layout-car {
      top: -150px;
    }
  }
  .container-home-modern-view-1 {
    .banner-container-5 {
      .banner-title {
        top: 10vh;
      }
    }
  }

  .container-home-modern-view-2 {
    .banner-container-5 {
      .banner-title {
        h1 {
          font-size: 50px;
        }
      }
    }
  }
  .container-home-modern-view-3 {
    .banner-container-5 {
      .banner-title {
        top: 165px;
      }
      .car-search-container{
        bottom: -90px !important;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .layout-container-main-b {
    .banner-container-5 {
      z-index: inherit;

      .car-search-container {
        bottom: 15vw;
      }

      .banner-title {
        height: 10vw;
      }
    }
  }
  .layout-container-5 {
    .banner-container-5 {
      .banner-title {
        top: 10vw;
      }
    }
  }
  .container-home-modern-view-3 {
    .banner-container-5 {
      .banner-title {
        top: 17vw;
      }
    }
  }
  .container-home-modern-view-2 {
    .banner-container-5 {
      .banner-title h1{
        font-size: 40px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .banner-container-5 .car-search-container {
    padding: 0 20px;
  }
  .layout-container-5 {
    .banner-container-5 {
      .banner-title {
        top: 15vw;
      }
    }
  }
  .container-home-modern-view-2 {
    .banner-container-5 {
      .banner-title {
        h1 {
          font-size: 35px;
        }
      }
    }
  }
  .container-home-modern-view-3{
   .banner-container-5 {
     .banner-title{
       top: 17vw;
     }
   }
  }
}

@media screen and (max-width: 860px) {
  .banner-container-5 {
    .btn-apply-content-settings, .btn-apply-content-settings-modern-view {
      top: 25vw;
    }

    padding-top: 40px;

    .car-search-container, .car-search-container-settings {
      bottom: -100px;
    }

    .btn-search-car {
      font-size: 10px;
    }

    .text-fields {
      height: inherit;
      position: relative;
      top: 0;
    }
    .input-container, .dp-select-container {
      height: 36px;
    }
    .car-search-row.first-row-grid {
      display: grid;
      grid-column-gap: 10px;
      grid-row-gap: 0;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 36px 10px 36px 5px 36px;
    }
    .car-search-row.second-row {
      flex-direction: column;
      gap: 10px;
    }
  }
  .layout-container-5 {
    .banner-container-5 {
      .banner-title {
        top: 10vw;
      }
      .car-search-container {
        top: 80%;
        padding: 0 10px;
        display: block;

        .car-search-box {
          padding: 10px 10px 20px 10px;

          input {
            border-radius: 0;
          }
          .car-search-row {
            .col-keyboard {
              padding: 0;
              width: 100%;

              div > div > svg {
                right: 15px;
                top: 10px;
              }

              input {
                height: $height-input-mobile-view;
                padding: 6px 10px;
              }
            }

            .car-search-select-content-model {
              .layoutBanner-select-deployed {
                border-bottom: none;
              }

              .car-search-select {
                padding: 0px 10px 0 10px;
                height: $height-input-mobile-view;

                .values-label {
                  @include one-single-line-text;
                  width: calc(100% - 42px);
                }

                .icon-expand-more {
                  float: right;

                  svg {
                    fill: $arrow-color;
                    height: 8px;
                  }
                }
              }

              .title-top {
                padding-left: 10px;
                font-size: 12px;
                color: $title-color;
              }
            }

            #selectedModels, #selectedConditions {
              padding-right: 0;

              .dp-select-paper {
                width: 100%;
              }
            }

            .car-search-input-content {
              display: flex;
              width: 100%;
              height: 36px;
              padding-right: 0 !important;
              padding-bottom: 0px;

              .text-fields {
                width: 100px;
                display: flex;
                align-items: center;

                label {
                  margin: 0;
                  font-size: 14px;
                  font-weight: lighter;
                }
              }

              .fields-boxes {
                margin: 0;
                padding: 0;
                .label-to {
                  @include item-center;
                  height: 100%;

                  label {
                    margin: 0;
                    font-size: 14px;
                  }
                }

                .input-search-content, .input-search-content-price {
                  width: calc(50% - 15px);

                  input {
                    height: $height-input-mobile-view;
                  }

                  .k-miles {
                    margin: 0;
                    top: 8px;
                  }

                  #milesFrom, #milesTo {
                    padding: 6px 20px 6px 12px;
                  }
                }

                .input-search-content-price {
                  input {
                    padding-left: 20px;
                  }

                  .prices {
                    left: 0;
                    margin: 0 10px;
                    top: 8px;
                  }
                }

                .label-to {
                  width: 30px;
                }
              }
            }

            .btn-search-content {
              margin-top: 0;
              .btn-search-car-content {
                padding-right: 0;

                a {
                  margin: 0;
                  @include item-center;
                  font-size: 14px;
                }
              }
            }

          }
        }
      }
    }
  }
  .container-home-modern-view-2 {
    .banner-container-5 {
      padding-top: 40px;
    }
  }
  .container-home-modern-view-3 {
    .banner-container-5 {
      .car-search-container {
        top: 55%;
      }

      .banner-title {
        top: 16vw;

        h1 {
          padding-top: 45px;
        }
      }

      .car-search-container {
        .container-car-search-input {
          top: 10vw;

          .car-search-input {
            width: 70%;

            .input-container {
              .form-control-search {
                -webkit-user-select: text;
                user-select: text;
                padding: 0 12px 0 12px;
              }
            }
          }
        }

        .car-search-box #containerButtons .btn-search-content{
          padding: 0;
        }
      }
    }
    .car-carousel-template5{
      padding-top: 170px !important;
    }
  }
  .layout-container-main-b {
    .banner-container-5 {
      height: 92vw;
      .input-container, .dp-select-container {
        height: 44px;
      }
    }

    .car-search-container {
      .car-search-box {
        .car-search-row.first-row-grid{
          display: grid;
          grid-column-gap: 20px;
          grid-row-gap: 0;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 44px 15px 44px 15px 44px;
        }
        .car-search-row.second-row {
          flex-direction: column;
          gap: 10px;
          padding: 0;
        }
      }
      .car-search-input-content {
        width: 100%;
      }
    }
  }
  .banner-container {
    .car-search {
      .car-search-container {
        .car-search-box {
          gap: 30px;
          padding: 65px 40px 30px 40px;

          .car-search-row.first-row-grid{
            display: grid;
            grid-column-gap: 20px;
            grid-row-gap: 0;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 44px 15px 44px 15px 44px;
          }
          .car-search-row.second-row {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }
        .car-search-input-content {
          width: 100%;
        }
      }
    }

    .banner-content-img {
      .banner-title {
        padding-left: 5vw;

        h1 {
          font-size: 7vw;
        }
      }
    }
  }
  .banner-container {
    padding-top: 50px;

    .car-search {
      padding: 80px 0 0;

      .car-search-input {
        width: auto;
        margin-left: 3vw;
        margin-right: 3vw;
      }
    }
  }

  .dp-text-input-container{
    grid-column: 1 / span 2;
  }
  #selectedMakes, #selectedModels{
    grid-row-start: 3;
  }

  #selectedBodies, #selectedConditions{
    grid-row-start: 5;
  }
}

@media screen and (max-width: 768px) {
  .layout-container-5,.container-home-modern-view-2{
    .banner-container-5{
      .banner-title{
        .btn-apply-content-settings-modern-view{
          display: none;
        }
      }
    }
  }
  .layout-container-main-b {
    .banner-container-5 {
      height: 900px;

      .banner-title {
        height: calc(170px - 10vw);
        width: 100%;
        top: 10vw;

        h1 {
          text-align: center;
          font-size: 6vw;
          padding: 0;
        }
      }
    }
  }
  .car-search-container {
    .car-search-box {
      .car-search-row {
        .car-search-select-content-model {
          .title-top {
            color: rgb(150, 150, 150) !important;
          }
        }
      }
    }
  }
  .banner-container-5 {
    .car-search-container {
      .container-car-search-input {
        .car-search-input {
          width: 90% !important;
          margin-top: 20px;
          padding-left: 10px;
        }

        .input-container {
          .form-control-search {
            font-size: 15px;
          }
        }
      }
    }

    .banner-title {
      .btn-apply-content-settings-modern-view,
      .btn-apply-content-settings {
        display: none;
      }
    }
  }
  .banner-container {
    .banner-content-img {
      justify-content: center;

      .banner-title {
        padding: 0;
        width: 100%;

        h1 {
          text-align: center;
          margin-right: auto;
          margin-left: auto;
          width: auto;
        }
      }
    }
  }


  .container-home-modern-view-3{
    .banner-container-5{
      .banner-title > div h1{
        padding-top: 0;
        margin-top: 10px;
        font-size: 50px;
        width: 90%;
        justify-content: center;
      }
      .car-search-container{
        top: 70%;
        .container-car-search-input{
          top: 60px;

          .car-search-input{
            height: 80%;
          }
        }
        .car-search-box{
          #containerButtons{
            .btn-search-car-content{
              display: flex;
              justify-content: center;
            }
            #btn-close-mv{
              display: none;
            }
          }
        }
      }
    }
    .car-carousel-template5{
      padding-top: 280px !important;
    }
  }


  .layout-container-5 {
    .banner-container-5 {
      .banner-title {
        top: 25vw;
      }
    }
  }
  .container-home-modern-view-3 {
    .banner-container-5 {
      .banner-title {
        top: 15vw;
      }
    }
  }
  .container-home-modern-view-2{
    .banner-container-5{
      .banner-title{
        top: 20vw;
        width: 50vw;
      }
    }
  }
}

@media screen and (max-width: 510px){
  .container-home-modern-view-2{
    .banner-container-5{
      .banner-title h1{
          font-size: 30px;
      }
    }
  }
}


@media screen and (max-width: 480px) {
  .layout-container-main {
    .banner-container {
      .car-search {
        padding-top: 5vw
      }

      .banner-content-img {
        .banner-title {
          h1 {
            font-size: 6vw;
          }
        }
      }
    }
  }
  .layout-container-main-b {
    .banner-container-5 {
      .banner-title {
        height: calc(170px - 10vw);
        width: 100%;
        top: 16vw;
        padding: 0 8vw;

        h1 {
          text-align: center;
          font-size: calc(1.5rem + 5vw);
          padding: 0;
        }
      }
    }
  }
  .layout-container-5 {
    .banner-container-5 {
      .banner-title {
        top: 25vw;
        padding: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        h1 {
          margin-left: 0;
          margin-right: 0;
          font-size: 5vw;
          text-align: center;
        }
      }
    }
  }

  .container-home-modern-view-2{
    .banner-container-5{
      .banner-title{
        padding: 0 10px 0 10px;
      }
    }
  }

  .container-home-modern-view-3 {
    .banner-container-5 {
      .banner-title {
        top: 15vw;
      }
      .car-search-container .car-search-box{
        margin-top: 80px;
      }
    }
    .car-carousel-template5{
      padding-top: 285px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .layout-container-main-b {
    .banner-container-5 {
      .banner-title {
        top: 25vw;
      }

      .car-search-container {
        bottom: 27vw;

        .car-search-box {
          padding: 10px 10px 40px 10px;

          .car-search-row {
            padding: 0;

            .col-keyboard {
              padding-top: 0;
              padding-right: 0;

              div {
                div {
                  svg {
                    top: 15px;
                    right: 20px;
                  }
                }
              }
            }

            .car-search-select-content-model {
              padding-right: 0;
            }

            .car-search-input-content, .reduce-padding-right {
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }
  .container-home-modern-view-3{
    .banner-container-5 .banner-title{
      top: 70px;

      >div h1{
        font-size: 30px;
        justify-content: center;
      }
    }

    .banner-container-5 .car-search-container{
      top: 60%
    }
    .car-carousel-template5{
      padding-top: 300px !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .container-home-modern-view-2{
    .banner-container-5 .banner-title{
      top: 20vw;
    }
  }

  .container-home-modern-view-3{
    .banner-container-5 .banner-title{
      top: 40px;

      >div h1{
        font-size: 30px;
        justify-content: center;
      }
    }

    .banner-container-5 .car-search-container{
      top: 40%;

      .car-search-box{
        margin-top: 80px;
      }
    }
    .car-carousel-template5{
      padding-top: 340px !important;
    }
  }

  .layout-container-main-b{
    .banner-container-5 .car-search-container{
      bottom: 30vw;

      .car-search-box{
        .first-row-grid{
          grid-column-gap: 10px;

          .car-search-select-content-model .car-search-select{
            font-size: 11px;
            padding: 10px 15px;

            .dp-select-arrow{
              width: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px){
  .container-home-modern-view-3{
    .banner-container-5 .banner-title{
      top: 50px;
    }
    .car-carousel-template5{
      padding-top: 360px !important;
    }
  }
}
