.about-us-goal{
  width: 50%;
  .box{
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    h3, >div{
      font-family: 'Inter', sans-serif;
      font-size: 32px;
      font-weight: bold;
    }
    .general-title{
      margin-top: 0;
    }
    .value-description{
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      padding-bottom: 75px;
    }

    .values-container{
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      gap: 10px;
      .box-item{
        height: auto;
        display: flex;
        align-items: center;
        padding: 8px 10px;
        border-radius: 5px;
        .wrapper{
          display: flex;
          padding-right: 8px;
          >div{
            display: flex;
            svg{
              width: 14px;
              max-height: 18px;
            }
          }
        }
        .value-text{
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-weight: bold;
          line-height: normal;
        }
      }
    }
  }

  .box-template-5{
    border-radius: 0;
    margin: 0px 0px 0px 50px;
    padding: 0px;
    .values-container{
      .box-item{
        border-radius: 1px;
      }
    }
    .general-title{
      margin-top: 0;
    }

  }

  @media screen and  (max-width: 768px) {
    width: 100%;
    margin: 0px;
    .box{
      padding: 20px;
      .general-title{
        margin-top: 0;
        font-size: 22px;
      }
      .value-description{
        font-size: 15px;
        padding-bottom: 20px;
      }
      .values-container{
        .box-item{
          .value-text{
            font-size: 14px;
          }
        }
      }
    }
    .box-template-5{
      padding: 20px 0;
      margin: 0 0 0 10px;
    }
  }
}
