@import "src/common/styles/utils";

/* TODO: FAW = for all widths
- height of header FAW
  - in mobile and tablet, media queries
- paddings of left and right FAW
- dimensions of logo FAW
- dimensions of items FAW (template 5?)

- .header-content is a problem, it should not be in this file, since that is for the background image at the top
- pull styles for sidebar drawer here
*/

.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;

  .menu-header {
    height: 55px;
    @include item-center;

    // left side - logo
    .header-left {
      width: 20%;
      padding-left: 65px;

      .img {
        width: auto;

        &:hover {
          cursor: pointer;
        }
      }
    }

    // right side - menu
    .header-right {
      width: 80%;
      padding-right: 50px;
      display: flex;
      justify-content: flex-end;

      .navbar-default {
        @include item-center;
        background-color: transparent;
        border: 0;
        margin: 0;

        .container-fluid {
          padding: 0;

          .navbar-nav {
            @include item-center;

            .menu-header-li {
              cursor: pointer;
              min-width: 70px !important;
              width: 90px;

              a {
                padding: 5px !important;
                display: flex !important;
                align-items: center;
                justify-content: center;
              }
            }

            .menu-location {
              margin-right: 20px;
              width: 135px !important;

              &.menu-location-main a {
                border-radius: 20px;
              }

              a {
                padding: 5px !important;

                i {
                  float: right;
                  padding: 3px;
                }

                span {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      &.header-right-5 {
        .menu-header-li {

          #finance, #inventory, #location, #contact, #home, #aboutUs {
            background-color: transparent;
            border-right: 1px solid #646464;
            height: 17px;
          }

          #aboutUs {
            border-right: none;
          }
        }
      }

    }

    // toggle button section
    .header-toggle-button {
      display: none;
    }

  }
}

/* Admin preview */
.template-view {
  .header-container {
    .menu-header {
      .header-right {
        display: flex;
        padding-right: 20px;
        .menu-header-li, .menu-location {
          font-size: 0.9em;
        }
        .menu-location {
          margin-right: 15px !important;
        }
      }
      .header-left {
        padding-left: 20px;
      }
    }
  }
}

/* Dark theme */
.container-dark {
  .header-container {
    #toggle-button {
      svg {
        fill: #FFF;
      }
    }
  }
}

/* Media queries */
@media screen and (min-width: 1600px) {
  .header-container .menu-header {
    .header-left {
      padding-left: 7%;
    }

    .header-right {
      padding-right: 6%;

      .menu-header-li {
        font-size: 16px;
      }

      .menu-header-li {
        width: 110px !important;
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .header-container .menu-header .header-right .menu-header-li {
    width: 84px !important;
  }
}

// tablet view from here
@media screen and (max-width: 991px) {
  .header-container {

    .menu-header {
      height: 50px;

      .header-left {
        padding-left: 20px;
      }

      .header-right {
        display: none !important;
      }
    }

    .header-toggle-button {
      display: block !important;
      width: 80%;

      .toggle-button {
        padding: 10px 20px;
      }
    }

  }

}

@media screen and (max-width: 768px) {
  .header-container .menu-header .header-left {
    padding-left: 20px;
  }
}

@media screen and (max-width: 375px) {
  .header-container .menu-header {
    height: 40px;
  }
}

//-------------------------------------------------------

// side drawer
.side-drawer .side-drawer-body {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .nav-item {
    list-style: none;

    .location-item {
      border-radius: 18px;
      padding: 5px;
      border: 1px solid #707070;
      width: 80%;

      span{
        font-size: 18px;
        margin-left: 10px;
      }

      i{
        float: right;
        margin-right: 5px;
        margin-top: 5px;
      }
    }

    a{
      font-size: 18px;
      color: #4D4F5C;
      text-decoration: none;
      background-color: transparent !important;
    }
  }
}