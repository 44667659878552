@import "../../styles/utils";

.generic-carousel-container{
  .elements-container{
    width: 100%;
    height: auto;
    display: flex;
  }

  .control-back, .control-next, .control-center, .bar-progress{
    display: flex;
  }
  .control-back, .control-next{
    border-radius: 10px;

    .arrow-back-container, .arrow-next-container {
      @include item-center;
      height: 40px;
      width: 40px;
    }

    .arrow-back, .arrow-next {
      display: flex;
      color: black;
      width: 50%;
      >div{
        display: flex;
      }

      .icon-next, .icon-back{
        width: 100%;
        height: 100%;
      }
    }

    .arrow-back{
      transform: rotate(180deg);
    }
  }
  .control-center{
    width: 100%;
    justify-content: center;

    .bar-progress{
      width: 80%;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }

  .control-back :hover, .control-next :hover{
    cursor: pointer;
    opacity: 1;
    border: #809FB8 .5px solid;
    border-radius: 10px;

    .arrow-next, .arrow-back{
      width: 55%;
      border: none;

      >div{
        border: none;

        .icon-back, .icon-next{
          border: none;
        }
      }
    }
  }

  .item{
    transition: all .5s ease-in;
    -moz-transition: all .5s ease-in;
    -webkit-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
  }

  .segments-container{
    height: 2px;
    display: flex;
    justify-content: center;
    width: 100%;

    .segment{
      display: flex;
      width: 70px;
      margin: 0 8px;
      @media screen and (max-width: 1024px) { // tablet
        margin: 0 4px;
      }
      @media screen and (max-width: 767px) { // mobile
        width: inherit;
        margin: 0 2px;
      }
    }
  }
}


