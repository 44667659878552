@import "utils";

.table-rol{
  .container-actions{
    display: none;
  }
  .td-actions{
    height: 50px;
    min-width: 90px;
  }
  .th-user{
    padding-left: 20px;
    width: 25%;
  }

  .td-user{
    padding: 15px 0 15px 20px;
    width: 25%;
  }

  .tr-customer{
    .img-view{
      fill: #4AD991;
      width: 14px;
    }
    .img-delete{
      fill: #FF6565;
      width: 14px;
    }
    &:hover{
      .container-actions{
        display: flex;
      }
    }
  }
}

.row-rol{
  .form-setting-button{
    width: 70px;
    padding-left: 20px;
  }

  .btn-general-message-dp-customer{
    display: flex;
    align-items: center;
  }

  .icon-arrow-content{
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.modal-backdrop{
  background: #6A6C6C 0 0 no-repeat padding-box;
  opacity: 1;
}

.rol-modal-body{
  width: 100%;
  padding: 20px;
  height: 480px;

  .left, .right{
    width: 50%;
    float: left;
  }

  .rol-label{
    padding-bottom: 10px;
    label{
      color: #787878;
      font-size: 10px;
      font-weight: 300;
    }
  }

  .input-rol-name{
    padding-bottom: 10px;
    .form-control{
      border-color: #EFEFEF;
      box-shadow: none;
    }
  }

  .input-rol-desc{
    .form-control{
      border-color: #EFEFEF;
      box-shadow: none;
      height: 100px;
      resize: none;
      color: #787878;
    }
  }

  .rol-title{
    label{
      color: #787878;
      font-size: 21px;
    }
  }

  .rol-permission{
    color: #787878;
    .title{
      color: #787878;
      margin-bottom: 0;
      font-size: 13px;
      font-weight: 400;
    }
    .sub-title{
      color: #787878;
      font-size: 10px;
      font-weight: 300;
    }
  }

  .permission-list{
    color: #787878;
    font-weight: 400;
  }

  .right{
    padding: 0 0 0 20px;
    .container-check{
      margin-left: 18px;
    }

    .content-check{
      padding-bottom: 3px;
    }
  }

  .permission-all-container{
    border-bottom: 1px solid #00000027;
  }

  .permission-container{
    padding-top: 10px;
    max-height: 300px;
    overflow: auto;
    .label-check-image-main{
      width: 340px;
    }
  }

  .permission-selected{
    float: left;
    width: 100%;
    color: #787878;
    font-size: 13px;
    font-weight: 400;
  }

  .user-modal-pie{
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .rol-permission-container{
    max-height: 180px;
    margin-top: 20px;
    overflow: auto;
  }

  .left{
    .has-error{
      border-color: #FF6565;
    }
  }

}

.modal-deactivate-rol{
  .modal-dialog{
    width: 600px;
  }

  .modal-content{
    box-shadow: 0 0 30px #00000029;
  }

  .btn-delete {
    width: 120px !important;
  }

  .btn-delete-absolute {
    background-color: #ff565e !important;
    border: 1px solid #ff565e !important;
    width: 120px !important;
  }

  .panel-right {
    padding: 0 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

.label-error{
  color: #FF6565;
}

.truncate-desc{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
}

.input-disabled, .classInputNewCar{
  .fa-dollar{
    position: absolute;
    top: 12px;
    left: 14px;
  }
  input.form-control{
    padding: 0 25px !important;
    padding-right: 12px !important;
  }
}

.setting-template-header{
  @include col100;
}

.table-templates{
  @include col100;
  .title-name-template{
    padding-left: 20px;
    width: 25%;
  }

  .row-templates{
    height: 50px;
    .customer-actions{
      position: absolute;
      right: 50px;
      top: 0;
      .icon-edit, .icon-copy, .icon-view, .icon-delete{
        width: 16px;
        margin-left: 15px;
        fill: #707070;
      }
      .icon-edit{
        width: 18px;
        margin-top: 3px;
      }
      .icon-view{
        width: 20px;
        margin-top: 3px;
        g .a{
          fill: #707070;
        }
      }
      .icon-delete{
        margin-top: 4px;
        fill: #FF6565;
      }
      .template-actions{
        display: flex;
        position: relative;
        top: 12px;
      }
      .btn-select{
        top: 8.5px;
        position: relative;
        margin-left: 15px;
        background-color: #FFFFFF;
        border-color: $light-blue-color-1;
        color: $light-blue-color-1;
      }
    }
    &:hover{
      .customer-actions{
        display: flex;
      }
    }
    .container-pill{
      float: left;
      width: 85px;
    }
    .pill-box{
      width: auto;
      margin-top: 0;
    }
    .title-name-ellipse{
      float: left;
      //width: calc(100% - 105px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .container-actions{
    position: relative;
  }
}

.templates-container{
  @include col100;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px 0 10px;
  .template-box{
    @include col33;
    padding: 0 10px 20px 10px;
    .col-image{
      height: 100%;
      display: flex;
      &:hover{
        cursor: pointer;
      }
    }
    img{
      width: 100%;
      border-radius: 5px;
    }

    .template-selected{
      border: 4px solid $light-blue-color-1;
      border-radius: 4px;
    }
  }
}

.modal-new-template, .modal-edit-template{
  .panel-container{
    padding: 0;
    .panel-container-body .container-button{
      padding: 20px 20px 0 20px;
      flex-direction: column;
      align-items: flex-end;
      input{
        //--width: 250px;
      }
      .button-save{
        border-color: $light-blue-color-1;
      }
      .button-save-disabled{
        @include btn-disabled;
      }
    }
  }
  .modal-dialog{
    height: 530px;
    width: 920px;
  }

  .templates-header{
    @include col100;
    padding: 0 20px;
    border-bottom: 1px solid #DDDDDD;
    h3{
      margin: 15px 0;
      color: $text-color;
      font-family: "Open Sans";
      font-weight: bold;
      font-size: 15px;
    }
  }

}

.modal-edit-template{
  .modal-dialog{
    height: 160px;
    width: 430px;
  }

  .panel-container{
    .panel-container-body .container-button{

      .button-save{
        margin-top: 10px;
      }
    }
  }
}