//--------------------part public------------------
.f11w1 {
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  min-width: 320px;
}

.header {
  color: #ffffff;
  background-color: #fbfbfb;
  padding: 0;
  margin: 0;
  .container-fluid:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid {
    .leftHeader {
      width: 25%;
    }
  }
  .site-name {
    font-style: italic;
    color: #e60000;
    h1 {
      cursor: pointer;
      margin: 20px 0 0 0;
      font-size: 36px;
      font-weight: 600;
    }
    a {
      cursor: pointer;
      text-decoration: none;
      color: #e60000;
    }
  }
}

#titleHead {
  width : 25%;
  margin-top: -15px;
  padding: 0;
}

.menuHead {
  width: 75%;
  margin-top: -15px;
  padding: 0;
}

#home :hover {
  color: #e60000;
  cursor:pointer;
}

#about :hover { //todo: check if 'about' is a used id
  color: #e60000;
  cursor:pointer;
}

#testimonial :hover {
  color: #e60000;
  cursor:pointer;
}


#location :hover {
  color: #e60000;
  cursor:pointer;
}

#contact :hover {
  color: #e60000;
  cursor:pointer;
}

#financed :hover {
  color: #e60000;
  cursor:pointer;
}

#inventory :hover {
  color: #e60000;
  cursor:pointer;
}

#facebook {
  background-color: transparent;
}

#facebook :hover {
  color: #e60000;
  cursor:pointer;
}

#googleplus {
  background-color: transparent;
}

#googleplus :hover {
  color: #e60000;
  cursor:pointer;
}

@mixin btnBase(){
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

#btnLogin ,#btnLoginR{
  @include btnBase;
  padding: 5px 10px;
  background-color: #e60000;
  color: white;
  margin-top: 10px;
  margin-left: 0;
}

#loginBar, #loginBarR {
  display: inline-block;
  float: right;
}

#socialAndLogin{
  display: inline-block;
  float: right;
  height: 0;
  visibility: hidden;
  z-index: 1;
}

#socialAndLoginR{
  display: inline-block;
  float: right;
  width: 20%;
}

#socialLinks {
  display: inline;
  .navbar-nav {
    margin-left: 50%;
  }
}

#mainHeaderBody {
  padding-top: 15px;
}

.main-nav {
  .navbar-default {
    border: none;
    color: #ffffff; /* #6d7480 */
    font-size: 13px;
    margin: 14px 0 10px 0;
    .navbar-nav {
      width: 80%;
      .menuHeader :hover{
        cursor: pointer;
        text-decoration: none;
        color: #e60000;
      }
    }
    #socialLinks {
      .navbar-nav {
        width: 75%;
      }
    }
    .navbar-nav>li {
      //--width: 14% ;
    }
    .navbar-nav>.active>a{
      background-color: transparent;
      color: #e60000;
    }
    .navbar-nav>.active>li>a{
      background-color: transparent;
      color: #e60000;
    }

  }
}

#typeahead-box {
  color: white;
  background-color: #575A5D;
  height: auto;
  position: relative;
  .ic-tokens {
    padding: 4px 6px;
  }
  .ic-tokeninput-list {
    display: none;
  }
  .ic-tokeninput-is-open .ic-tokeninput-list {
    display: block;
  }
  .ic-tokeninput-input {
    max-width: 40px;
    border: 0 none;
    background-color: transparent;
    color: white;
  }
  .ic-tokeninput-input:focus {
    outline: 0;
  }
  .ic-token {
    border: 1px solid #C1C7CF;
    padding: 3px 8px;
    margin-right: 6px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .ic-token-delete-button {
    margin-right: 5px;
    cursor: pointer;
    position: absolute;
  }
  .tokenizer-values-list {
    position: absolute;
  }
  .ic-token-label{
    margin-left: 15px;
  }
}

.typeahead-combobox {
  border-bottom: 1px solid #aaa;
  padding: 2px 8px;
  font-weight: bold;
}
.typeahead-combobox:focus {
  outline: none;
}

#inventorySearchBox ,#inventorySearchBoxHeader {
  margin-bottom: 20px;
  h3 {
    margin-top: 5px;
    font-weight: 700;
  }
  .form-control {
    @include btnBase;
    color: white;
    background-color: #575A5D;
  }
  .input-disabled {
    background-color: #3c3f42;
  }
  label {
    color: white;
  }

  #searchOr {
    margin: 8px 0;
    text-align: center;
    font-weight: bold;
  }

  #simpleSearchBox {
    background-color: black;
  }

  #main-panel {
    margin-bottom: 0;
    div:first-child {
      @include btnBase;
    }
    @include btnBase;
    background-color: #2D3134;
    .panel-heading {
      background-color: #14181c; //rgba(0,0,0,0.3);
      color: white;
    }
  }
  #main-panel-header {
    margin-bottom: 0;
    div:first-child {
      @include btnBase;
    }
    @include btnBase;
    background-color: #2D3134;
    .panel-heading {
      background-color: #14181c; //rgba(0,0,0,0.3);
      color: white;
    }
  }
  #main-panel-body {
    margin-bottom: 0;
    div:first-child {
      @include btnBase;
    }
    @include btnBase;
    background-color: #2D3134;
    .panel-heading {
      background-color: #14181c; //rgba(0,0,0,0.3);
      color: white;
    }
  }
  #panfooter {
    border-top: 1px solid #ddd;
  }

  button {
    @include btnBase;
    width: 100%;
    height: 34px;
    color: #721f1f;
    background-color: white;
    font-weight: 600;
  }
}

.banner {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  margin-top: 76px;
  .banner-imgContainer {
    width: inherit;
    height: 580px;
    overflow: hidden;
  }
  .banner-image {
    width: 100%;
    height: auto;
    background-attachment: fixed;
    background-size: cover;
  }
  #banRow {
    top: 0;
    height: 100%;
    width: 33%;
    min-width: 320px;
    max-width: 445px;
    left: 5%;
    position: absolute;
    .col-md-4 {
      top: 5%;
    }
    .col-md-12 {
      top: 5%;
    }
  }

  #banRow2 {
    min-width: 320px;
    max-width: 1300px;
    width: 100%;
    left:1%;
    height: auto;
    max-height: inherit;
    position: absolute;
    padding-left: 35%;
    top:30%;
    .col-md-8 {
      .bannerCaption {
        h1 {
          font-size: 46px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
  }

  .panel-body {
    margin-bottom : -8px;
  }
}

#simpleSearchForm {
  margin-top: -10px;
  padding-bottom: 8px;
}

#advancedSearchForm .form-group {
  margin-top: -5px;
}

#inventoryFormGroup {
  margin-bottom: 5px;
}

.inline-flex {
  display: inline-flex;
}

.ic-tokeninput-button {
  display: none;
}

#carSel {
  background-color: transparent;
  margin-top: 30px;
  margin-bottom: 50px;
  #carSelList {
    padding: 0 50px;
  }
  .left {
    background-image: none;
    width: 50px;
    padding-right: 10px;
    i{
      top: 70px;
      color: black;
      font-size: 64px;
      position: absolute;
      right: 10px;
    }
  }
  .right {
    background-image: none;
    width: 50px;
    padding-left: 10px;
    i{
      top: 70px;
      color: black;
      font-size: 64px;
      position: absolute;
      left: 10px;
    }
  }

  .carousel-inner{
    .left{
      width: inherit;
    }
    .right{
      width: inherit;
    }
  }
}

#carLstItem {
  border: 1px solid #f2f2f2;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  img {
    display: block;
    margin: 0 auto;
  }
  .carImg {
    //height: 224px;
    //width: 300px;
  }
  #carDescription {
    color: black;
    font-weight: 600;
  }
  .btn {
    @include btnBase;
    background-color: #e60000;
    width: 120px;
    height: 33px;
    margin-top: 4px;
  }
}

.carItemOptions , .carViewOptions{
  margin-top: 15px;
  font-size: 10px;

  .carItemOption {
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 5px 0;
    img {
      max-width: 15px;
      margin-right: 5px;
    }
    div {
      display: inline-block;
      margin: 1px;
    }
  }

  .carItemOption:last-child {
    border-right: none;
  }

}

#cars {
  color: black;
  #cars-title {
    margin-top: 0;
  }
  #carsButtons {
    margin-top: 50px;
  }
}

.section {
  background-color: #ffffff;
  padding: 95px 0 0 0;
}

#btnLeft {
  @include btnBase;
  width: 18%;
  height: 49px;
  background-color: #14181c;
  font-weight: 600;
  color: #87898b;
  padding: 15px;
}

#btnCenter {
  @include btnBase;
  width: 18%;
  height: 49px;
  background-color: #e30a17;
  color: white;
  font-weight: 600;
  padding: 15px;
  z-index: 1;
}

#btnRight {
  @include btnBase;
  width: 18%;
  height: 49px;
  background-color: #14181c;
  font-weight: 600;
  color: #87898b;
  padding: 15px;
}

.btn-arrow-right {
  @include btnBase;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
  &:after { /* make two squares (before and after), looking similar to the button */
    content: "";
    position: absolute;
    top: 7px; /* move it down because of rounded corners */
    width: 35px; /* same as height */
    height: 35px; /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    border-left-color: transparent; /* hide left border */
    border-bottom-color: transparent; /* hide bottom border */
    border-radius: 0 4px 0 0; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    right: -17px;
    z-index: 1;

  }
}

.btn-arrow-left {
  @include btnBase;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
  &:before { /* make two squares (before and after), looking similar to the button */
    content: "";
    position: absolute;
    top: 7px; /* move it down because of rounded corners */
    width: 35px; /* same as height */
    height: 35px; /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    border-left-color: transparent; /* hide left border */
    border-bottom-color: transparent; /* hide bottom border */
    border-radius: 0 4px 0 0; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0 4px 0 0;
    -moz-border-radius: 0 4px 0 0;
    transform: rotate(225deg); /* rotate left arrow squares 225 deg to point left */
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    left: -17px;
    z-index: 1;
  }
}

#btnInv {
  @include btnBase;
  width: 25%;
  height: 50px;
  background-color: #e60000;
  color: white;
  font-weight: 700;
  padding: 15px 0;
}

#gmapToolTip {
  h4 {
    color: black;
    margin-top: 0em;
    margin-bottom: 0.2em;
  }
  .btn {
    @include btnBase;
    margin-top: 0.5em;
    color: white;
    background: black;
  }
}

.gooMap {
  width: 100%;
  height: 800px;
}

#btnLocLeft, #btnLocRight {
  @include btnBase;
  width: 100%;
  color: white;
  margin-bottom: 0.4em;
}

#btnLocLeft {
  background-color: #e60000;
}

#btnLocRight {
  background-color: #0fcde1;
}

.whoWeAreModal{

  font-size: small;
  p{
    color: #bbb;
  }
  h3{
    color:white;
    margin-top: 0.3em;
  }
  .carousel-indicators {
    visibility: hidden;
  }
  .modal-content{
    background-color:black;
  }
  .modal-dialog{
    border-color: white;
    border-style:solid;
  }

  ul{
    font-size: 14px;
    color: #bbb;
  }
}

#idBtnLink {
  margin-left: -10px;
  z-index: 1;
}

#layLocation{
  float: left;
  width: 100%;
  background-color: #ffffff;
  padding: 5% 0 0 0 !important;

  .layLocationHeader{
    padding: 1% 20%;
    background-color: black;
    color: white;

    h4{
      margin: 0;
    }

    h1{
      margin: 0;
    }
  }

  #map{
    width: 100%;
    height: 700px;
  }

  .btnBase{
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  #gmapToolTip {
    h4 {
      color: black;
      margin-top: 0em;
      margin-bottom: 0.2em;
    }
    .btn {
      @include btnBase;
      margin-top: 0.5em;
      color: white;
      background: black;
    }
  }
}

#aboutUs {
  //background-color: black;
  p{
    color: #bbb;
  }
  p:first-child {
    margin-top: 2.5em;
  }
  h3 {
    color: white;
    margin-top: 0.3em;
  }
  #whoButtons {
    margin-top: 1.5em;
  }
  #whoImage {
    padding-right: 0;
  }
  .carousel-indicators {
    visibility: hidden;
  }
}

#whoText {
  width: 52%;
  margin-left: 13%;
}

#testimonialItem {
  margin-left : 15%;
}

.btnLink {
  font-size: 13px;
  margin-top: -2.2px;
  padding: 0 10px;
  z-index: 1;
}

#layoutBlog {
  .container-fluid {
    padding-top: 0.8em;
    padding-bottom: 1em;
    margin-bottom: 1.5em;
    width: 100%;
    height: 5%;
    color:black;
    font-weight: 800;
  }

  background-color: #f0f2f3;
  width: 100%;
  height: 100%;

  .carousel-indicators li {
    background-color: #721f1f;
    border-color: #721f1f;
  }

  .carousel-indicators .active {
    background-color: white;
  }

}

#layTestimonialHeader {
  margin-left: 15%;
}

#carBlog {
  background-color: inherit;
  .carousel-indicators {
    background-color: inherit;
    bottom: -3em;
  }
  .left {
    background-image: none;
  }
  .right {
    background-image: none;
  }
}

#linesep{
  margin-top: 70px;
  #circlesep{
    font-size: 20px;
    color:black;
  }
  .col-md-offset-1{
    margin-left: 12%;
  }
  .col-md-4{
    padding: 0;
  }
  .col-md-2 {
    width: 10%;
    padding: 0;
  }
  hr {
    margin-top: 10px;
    border-color: black;
    width: 100%;
    border-top-width: 2px;
  }
}

#blogSecCon {
  margin-top: 2.5em;
  h3 {
    font-weight: 800;
    color: black;
  }
}

#blogThiCon {
  margin-bottom: 100px;
  input {
    @include btnBase;
    width: 35%;
  }
  button {
    @include btnBase;
    color: white;
    background-color: black;
    width: 10%;
  }
}

#msgBox {
  input {
    @include btnBase;
    color: white;
  }
  #mainPanelMsgBox {
    div:first-child {
      @include btnBase;
    }
    @include btnBase;
    background-color: rgba(0, 0, 0, 0.5);
    .input {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
    }
  }
}

#panelbodyMsgBox {
  padding: 14px;
}

.textBox{
  padding: 0;
  margin-top: 0;
}

.formGroup {
  margin-bottom: 14px;
}

#getInTouchTextArea {
  @include btnBase;
  background-color: rgba(0, 0, 0, 0.3);
}

#getInTouchBtn {
  button {
    @include btnBase;
    width: 100%;
    height: 50px;
    color: white;
    background-color: #e60000;
  }
}

#lgetInTouch {
  width: 100%;
  color: #fff;
  min-width: 320px;
  position: absolute;
  .banner-imgContainer {
    width: inherit;
    overflow: hidden;
    background-color: #6d7480;
  }
  .banner-image2 {
    width: 100%;
    height: auto;
  }
  #banRowl {
    top: 5%;
    width: 100%;
    min-width: 320px;
    max-width: 1308px;
    left: 1%;
    position: absolute;
    margin-top: -12px;
  }
}

.controlsLayout {
  max-width: 290px;
  margin: 0 auto;
  width: 100%;
}

#idMsgBox {
  max-width: 675px;
  max-height: 271px;
}

#idReferencePie {
  display: none;
  color: #2D3134;
}

#footerGetInTouch {
  margin-top: 43px;
  margin-bottom: 0;
}

#btnGetInTouch01, #btnGetInTouch02, #btnGetInTouch03, #btnGetInTouch04 {
  @include btnBase;
  width: 125px;
  height: 53px;
  color: white;
  font-weight: 300;
  margin-left: 5px;
  margin-right: 5px;
}

#btnGetInTouch01 {
  background-color: #1b78c7;
}

#btnGetInTouch02 {
  background-color: #00bff3;
}

#btnGetInTouch03 {
  background-color: #da421f;
}

#btnGetInTouch04 {
  background-color: #ffb400;
}

#idAddressPie {
  margin-bottom: 0;
}

#piePage {
  color: #2D3134;
  padding-top: 5%;
  h5 {
    margin-bottom: 10px;
  }
}

.footerGetInTouch , .piePage{
  float: left;
  width: 100%;
  text-align: center;
}

.footerGetInTouch{
  padding: 5% 0 1% 0;
}

.piePage{
  padding: 0 0 3% 0;
}

.containerFooter{
  float: left;
  width: 100%;
}

#invCarView {
  margin-top: 76px;
  width: 90%;
  margin-left: 5%;
}

.rectangleM {
  width: 4px;
  height: 30px;
  background: #B41D1C;
  margin-top: 15px;
}

.rectangleL {
  width: 4px;
  height: 45px;
  background: #B41D1C;
  margin-top: 15px;
}

.rectangleD {
  width: 4px;
  height: 30px;
  background: #B41D1C;
}

.rectangleS {
  width: 4px;
  height: 25px;
  background: #B41D1C;
  margin-top: 15px;
}

.rectangleLeft {
  width: 4px;
  height: 30px;
  background: #B41D1C;
  margin-top: 15px;
  float: right;
}

#containerTitleCar {
  width:95%;
  margin-left: -10px;
}

#titleCar {
  width: 90%;
  font-weight: 600;
  color: black;
  margin-top: 10px;
  margin-bottom: 0;
}

#btnPrice{
  @include btnBase;
  padding: 5px 10px;
  background-color: #E90101;
  color: white;
  margin-top: 10px;
  margin-left: 0;
  font-size: 20px;
  font-weight: 600;
  width: 125px;
}

.idBellow {
  font-size: 10.5px;
}

#idCarouselLeft {
  width: 66.7%;
}
#idCarouselRight {
  width: 33.3%;
  #idDescription {
    width: 100%;
    display:block;
    color: #151515;
    margin-top:9px;
    font-weight: 700;
  }

  #carViewDescription {
    margin-left: -10px;
    margin-top: -5px;
    height: 50px;
  }
}

.description {
  font-size: 85%;
}

#carCarouselOne ,#carCarouselTwo, #carViewSel {
  .carousel-indicators {
    visibility: hidden;
  }
}

.carLetterColor {
  color: #721f1f;
  font-size: 15px;
}

#tableCar {
  width: 90%;
  font-size: 11px;
  font-weight: 600;
  color: #404040;
  margin-top: 20px;
  tr {
    height: 30px;
  }
}

.rowTable {
  background: #EAEAEA;
}

.carViewOptions {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  color: #202020;
  width: 100%;
}

.icon-letter {
  height: 25px;
  padding: 4px;
  width: 100%;
}

.icon-letter-color {
  height: 25px;
  padding: 4px;
  width: 100%;
  background: #F8F8F8;
}

#idCall {
  width: 100%;
  margin-top: 50px;
  #idImageCar {
    background: #939393;
    height: 62px;
  }
  #idImgPhone {
    width: 48px;
    margin-left: -9px;
    margin-top: 5px;
  }

  #btnCall{
    @include btnBase;
    padding: 10px 0;
    background-color: #6A6A6A;
    color: white;
    margin-left: 0;
    font-size: 18px;
    font-weight: 600;
    height : 62px;
  }

  #btnCallUser{
    @include btnBase;
    padding: 5px 10px;
    background-color: #E90101;
    color: white;
    margin-left: 0;
    font-size: 20px;
    font-weight: 600;
    width:100%;
  }
}

#idTitleCall {
  margin-top: 18px;
  margin-left: -15px;
  width : 90%;
  color: #1D1D1D;
  font-weight: 600;
  padding: 0;
}

#idRectangleM {
  padding: 0;
  width: 10px;
}

#idOverView {
  padding: 5px;
  width: 95%;
  margin-top: 5px;
}

.idCarOther {
  color :#2E2E2E;
  font-weight: 700;
}

.rectangleWidth {
  width: 38%;
}

#idViewMore {
  margin-top: 10px;
  text-align: center;
  width: 24%;
  padding: 0;
}


.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

#imgIconCar {
  max-width: 48px;
}

.carouselPrev, .carouselNext {
  margin-top: 30px;
  font-size: 30px;
}

#carViewSel {
  background-color: transparent;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-top: 15px;
  #carViewList {
    padding: 0 60px;
  }
  .left {
    left: -60px;
    background-image: none;
    .glyphicon {
      top: 90px;
      background-color: black;
      color: black;
      transform: rotate(45deg);
    }
  }
  .right {
    right: -60px;
    background-image: none;
    .glyphicon {
      top: 90px;
      background-color: black;
      color: black;
      transform: rotate(45deg);
    }
  }

  .carouselPrev, .carouselNext{
    font-size: 50px;
    margin-top: 80px;
  }
}

.btnLinkDes {
  font-size: 12px;
  margin-top: -2.2px;
  padding: 0 10px;
  cursor: pointer;
  z-index: 1;
}

#itemLeft {
  //width: 22%;
}

#itemRight {
  position: relative;
  background-color: white;
}

#btnInvCar {
  @include btnBase;
  width: 25%;
  height: 33px;
  background-color: #e60000;
  color: white;
  font-weight: 700;
  padding: 7px 0;
}

#iconCar {
  padding-top: 15px;
}

#inventoryList {
  .btn {
    @include btnBase;
    background-color: #e60000;
  }
  .text-left:first-of-type {
    color: #14181c;
    font-weight: 800;
  }
  i {
    margin-left: 15px;
  }
  img {
    max-width: 100%;
  }
}

#photoGrid {
  text-align: center;
  .btn {
    @include btnBase;
    background-color: #e60000;
  }
  #blockTitle {
    color: #14181c;
    font-weight: 800;
  }
  i {
    margin-left: 15px;
  }
  img {
    max-width: 200px;
  }
  #perMonth, #priceGrid {
    margin-top: -4%;
  }
  #btnViewGrid {
    margin-top: 4%;
  }
}

#btnInvCarGrid {
  @include btnBase;
  width: 60%;
  height: 35px;
  background-color: #e60000;
  color: white;
  font-weight: 700;
  padding: 8px 0;
}
#inventoryFinances:hover{
  border:5px solid black;
}
#inventoryFinances {
  h1, h2 {
    color: white;
  }
  button {
    @include btnBase;
    color: #721f1f;
  }
  height: 500px;
  position: relative;
  border: 1px solid #bcffb0;
  background-color: #e60000;
  .finance-bg {
    width: inherit;
    height: inherit;
    position: relative;
    overflow: visible;
    img {
      max-width: 350px;
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }
  }
  .financeData1 {
    left: 0;
    top: 0;
    position: absolute;
    align-content: center;
    width: 100%;
    height: 170px;
    padding-top: 10px;
    .text-center {
      margin-top: 10px;
    }
  }
  .financeData2 {
    left: 23%;
    top: 85%;
    width: inherit;
    position: absolute;
    align-content: center;
    cursor: pointer;
  }

  a:hover{
    text-decoration: none;
  }
}

#callUsToday {
  float: right;
  margin-right: 3%;
}

#inventoryView {
  padding-top: 25px;
  padding-bottom: 25px;
  select {
    border: 0;
    outline: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 700;
  }
  #invBt1, #invBt2 {
    border: 0;
    outline: 0;
  }
}

#inventoryViewRightUp{
  display: none;
  height : 0
}

#inventorySearchBoxHeader {
  float: left;
  width: 30%;
  margin-left: 10%;
  margin-right: 10%;
  height: 88px;
  background-color: #2D3134;
  min-width: 280px;
}
#inventorySearchBoxHeader .panel-heading {
  background-color: #14181c;
  color: white;
  border: none;
}

#simpleSearchForm {
  margin-top: -10px;
  padding-bottom: 8px;
}

#inventoryFormGroup {
  margin-bottom: 5px;
}

.form-inline .form-group {
  font-size: 10px;
}

ul.pagination li {display: inline;}

ul.pagination li a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

ul.pagination li a.active {
  background-color: #4CAF50;
  color: white;
}

ul.pagination li a:hover:not(.active) {background-color: #ddd;}

#idNote {
  margin-left: -10%;
  width: 100%;
  margin-top: 8%;
  font-size: 9.5px;
}

#inventoryViewRight{
  width: 18%;
}

#inventoryHeader {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-top: 76px;
  .banner-imgContainer {
    width: inherit;
    height: inherit;
    overflow: hidden;
  }
  .banner-image {
    width: 100%;
    height: auto;
  }
  .banner-image2 {
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: relative;
    vertical-align: middle;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
  }
  #inventoryRow {
    position: absolute;
    top: 35%;
    width: 100%;
    height: 50%;
    .col-md-4 {
      h1 {
        top: 10%;
        font-weight: 800;
        color: white;
        font-size: 70px;
      }

    }
  }
}

#inventoryHeaderTitle {
  margin-left: 16%;
  h1 {
    font-size: 46px;
    font-weight: 700;
  }
}

.imageWaiterList {
  position:relative;
}

#selOrder , #selFilter{
  font-size: 12px;
}

.financedColumn {
  padding-bottom: 5px;
  padding-top: 5px;
}

.labelFinanced {
  color: #777777;
  font-size: 14px;
  text-align: left;
}

.placeholderRequired::-webkit-input-placeholder {
  content:'*';
  color: #777777;
}
.placeholderRequired::-moz-placeholder {
  color: #777777;
}
.placeholderRequired:-ms-input-placeholder {
  color: #777777;
}

.placeholderRequired::-webkit-input-placeholder::before {
  content:'*';
  color: red;
}
.placeholderRequired::before {
  content:'*';
  color: red;
}

.placeholderRequired:-ms-input-placeholder:after {
  content:'*';
  color: red;
}

.btn-link-finance {
  font-weight: 400;
  color: #777777;
  border-radius: 0;
  cursor: pointer;
  text-decoration: underline;
}
.btn-link-finance:hover {
  text-decoration:underline;
}

.residenceRow ,#employmentRowAddress ,#employmentRowName{
  margin-left: -1%;
}

#employmentRowPhone {
  margin-left: 8px;
}

#employmentRowZip {
  margin-left: 5px;
}

#employmentRowYear ,#employmentRowMonth ,#residenceRowYear ,#residenceRowMonth {
  width: 13%;
}

#linkEmployment {
  width: 24%;
  margin-top: 7px;
  padding-top: 23px;
}

#residenceRowAddress {
  padding: 0;
  margin-left: -18px;
}

#residenceRowApt{
  margin-left: 13px;
}

#residenceRowZip{
  margin-left: 7px;
}

#linkResidence {
  width : 100%;
}

.financeForm #score_1 ,.financeForm #score_2 ,.financeForm #score_3 ,.financeForm #score_4 ,.financeForm #score_5 ,.financeForm #score_6,.financeForm #donotknow {
  width: 13.1%;
  padding-left: 0;
  padding-right: 0;
}

.financeForm{
  input {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    border-color:#f5f6f7;
  }
  select {
    -moz-border-radius: 0;  /* Firefox 1-3.6 */
    border-radius: 6px;
    border:#575a5d;
    border-right: none;
    background-color: white;
  }
  select:not([multiple]){
    -webkit-appearance:none;
    -moz-appearance:none;
    background-position:right 50%;
    background-repeat:no-repeat;
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: 5em 1.5em 5em 5em;
  }
  .input-group-addon {
    border: 0;
    background-color: #FFFEA6;
  }
  button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    border-color: #f5f6f7;
    width: 125px;
    font-weight: 300;
    margin: 5px;
  }

  #dobmdy ,#email{
    //--border-right: 1px solid black;
  }

  #typeId ,#licence, #btnIdNone{
    width: 20%;
    padding-left: 0;
    padding-right: 0;
  }

  .rowFinance, .rowResident, .rowEmployment{
    width: 100%;
    float: left;
  }

  .col25{
    width: 25%;
    float: left;
  }

  .col50{
    width: 50%;
    float: left;
  }

  .col80{
    width: 80%;
    float: left;
  }

  .col20{
    width: 20%;
    float: left;
  }

  .col16{
    width: 16.66%;
    float: left;
  }

  .col100{
    width: 100%;
    float: left;
    padding: 1% 1% 1% 0;
  }

  .colRequired{
    background-color: #FFFEA6;
    color: rgb(119, 119, 119);
  }

  h3{
    margin-top: 20px;
    color: #777777;
  }

  .form-control-feedback {
    top: 20px;
    color: #777777;
  }

  .popover-title {
    margin-top: 0;
  }
  .popover-content {
    color: black;
  }

  .input-group .form-control:not(:first-child):not(:last-child){
    border-radius: 5px 0 0 5px;
  }

  #dobmdy_group{
    float: left;
    input{
      background-color: #FFFEA6;
    }
  }

}

#financeViewBody {
  width: 70%;
  margin-left : 15%;
}

#textRequired {
  text-align: right;
  font-weight: 600;
  color: #e60000;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-success-finance {
  color: #fff;
  background-color: #4e4e4e;
  border-color: #4e4e4e;
  width: 250px;
  height: 60px;
  font-size: 28px;
  border-radius : 0;
}

.btnAbove {
  background-color: #19191a;
  width: 250px;
  height: 6px;
}

#financeHeader {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-top: 76px;
  .banner-imgContainer {
    width: inherit;
    height: inherit;
    overflow: hidden;
  }
  .banner-image {
    width: 100%;
  }
  .banner-image2 {
    width: 100%;
    height: 100%;
    min-height: inherit;
    position: relative;
    vertical-align: middle;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
  }
  #inventoryRow {
    position: absolute;
    top: 35%;
    width: 100%;
    height: 50%;
    .col-md-6 {
      h1 {
        top: 10%;
        font-weight: 800;
        color: white;
        font-size: 70px;
      }

    }
  }
}


.login-wrapper {
  position: absolute;
  top: 50px;
  width: 100%;
  margin-left: 0;
  animation: bounceInDownSmall 1s ease;
  -webkit-animation: bounceInDownSmall 1s ease;
  -moz-animation: bounceInDownSmall 1s ease;
  -ms-animation: bounceInDownSmall 1s ease;
  -o-animation: bounceInDownSmall 1s ease
}

.login-wrapper .login-widget {
  margin: 20px auto 0;
  width: 400px
}

.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s
}

.animation-delay8 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both
}

.text-success {
  color: #65cea7
}

.bounceIn {
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -ms-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05)
  }
  100% {
    -webkit-transform: scale(1)
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05)
  }
  100% {
    -moz-transform: scale(1)
  }
}

@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -ms-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -ms-transform: scale(1.1)
  }
  100% {
    -ms-transform: scale(1)
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.7)
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1)
  }
  100% {
    -o-transform: scale(1)
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.7)
  }
  50% {
    opacity: 1;
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

.animation-delay2 {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -ms-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both
}

.ic-tokeninput-list {
  position: absolute;
  z-index: 1;
  border: 1px solid #aaa;
  background: #fff;
  top: 100%;
  left: 0;
  padding: 5px 0;
  color: black;
  width: 100%;
  box-sizing: border-box;
}

.ic-tokeninput-option {
  display: block;
  padding: 2px 16px;
  cursor: default;
}

.ic-tokeninput-option:focus {
  outline: 0;
  color: white;
  background: hsl(200, 50%, 50%);
}

.colR50{
  width: 50%;
  float: left;
  padding-right: 10px;
}

.col50{
  width: 50%;
  float: left;
}

.colR25{
  width: 25%;
  float: left;
  padding-right: 10px;
}

.col25{
  width: 25%;
  float: left;
}

.colR16{
  width: 16.66%;
  float: left;
  padding-right: 10px;
}

.col16{
  width: 16.66%;
  float: left;
}

.col100{
  width: 100%;
  float: left;
}

.col33{
  width: 33.33%;
  float: left;
}

.col67{
  width: 67%;
  float: left;
}

.col30{
  width: 30%;
  float: left;
}

.colR80{
  width: 75%;
  float: left;
  padding-right: 10px;
}

.colR75{
  width: 75%;
  float: left;
  padding-right: 10px;
}

.col75{
  width: 75%;
  float: left;
}

.col20{
  width: 20%;
  float: left;
}

.applicant-panel{
  width: 100%;
  float: left;
  padding: 0 0 20px 0;
}

.panel-finance {
  width: 100%;
  float: left;
  margin-bottom: 4%;
  background-color: #e5e5e5;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);

  .colRequired {
    background-color: #FFFEA6;
    color: #777777;
    div{
      padding-bottom: 37px;
      font-size:14px !important;
      width:100% !important;
      div{
        padding-bottom: 15px;
      }
      div{
        hr{
          border-bottom:none !important;
        }
      }
      input{
        color: #777777 !important;
      }
    }
  }

  #dateId_group input {
    background-color: #FFFEA6;
    border-radius: 5px 0 0 5px;
  }

  .input-group-addon {
    background-color: #FFFEA6;
  }

  .btn-link{
    font-weight: 400;
    color: #777777;
    border-radius: 0;
    cursor: pointer;
    text-decoration: underline;
  }

  .link-style{
    width: 100%;
    float: left;
    padding: 1% 1% 1% 0;
  }

  .radio-button{
    background-color: black;
    color: white;
    opacity: 0.8;
  }

  .radio-button:hover {
    background-color: black;
    color: white;
  }

  .col-credit-score{
    float: left;
    width:14%;
    padding: 0 1%;
  }

  .col-type-credit{
    float: left;
    width:15%;
    padding: 0 1%;
  }

  .col-type-id{
    float: left;
    width:22%;
    padding: 0 1%;
  }

  h3{
    margin-top: 10px;
  }

}



.panel-body{
  color: #777777;
}

.header-applicant{
  background-color: rgb(119, 119, 119) !important;
  color: white !important;
  h5{
    font-weight: 700;
  }
  label{
    font-weight: 400;
  }
}

.container-car-image{
  width: 100%;
  height: 500px;
  text-align: center;
  background-color: black;
  position: relative;
}

.container-car-image-carousel{
  display: inline-block;
  margin: 5px;
  /* width: 100%; */
  height: 80px;
  text-align: center;
  background-color: black;
}

.carousel-inner {
  text-align: center;
  .item {
    img{
      display: initial !important;
    }
  }
}

.container-car-image-list{
  height: 170px;
  text-align: center;
  background-color: black;
  position: relative;
  padding: 0;
}

.container-car-image-grid{
  height: 150px;
  text-align: center;
  background-color: black;
  position: relative;
}

.menu-content{
  display: none;
}

.toggle-button {
  border: none;
  float: right;
  //background-color: #575a5d;
  //border-radius: 5px;
}

/*.toggle-menu{
  width: 100%;
  float: left;
  display: none;
  ul{
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #777;
    display: block;
    padding: 10px 0;
  }
}*/

/*.toggle-menu a:hover {
  color: red;
  cursor: pointer;
}*/

button:focus ,i:focus{
  border:0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.carViewContact{
  width: 100%;
  margin-left: -16px;
}

.line1-sold {
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(32deg);
  position: absolute;
  top: 60px;
  left: -5px;
}

.line2-sold {
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(32deg);
  position: absolute;
  top: 50px;
  left: 28px;
}

.text-sold{
  position: absolute;
  left: 29%;
  bottom: 34%;
  font-size: 50px;
  color: red;
  font-weight: 700;
}

.line1-sold-list {
  width: 100%;
  height: 80px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(32deg);
  position: absolute;
  top: 32px;
  left: -2px;
}

.line2-sold-list {
  width: 100%;
  height: 17px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(32deg);
  position: absolute;
  top: 50px;
  left: 25px;
}

.text-sold-list{
  position: absolute;
  left: 25%;
  bottom: 35%;
  font-size: 45px;
  color: red;
  font-weight: 700;
}

.line1-sold-grid {
  width: 100%;
  height: 80px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
  position: absolute;
  top: 20px;
  /* left: 30px; */
}

.line2-sold-grid {
  width: 105%;
  /* height: 15px; */
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
  position: absolute;
  top: 60px;
  left: 3px;
}

.text-sold-grid{
  position: absolute;
  left: 30%;
  bottom: 36%;
  font-size: 30px;
  color: red;
  font-weight: 700;
}

.line1-sold-car {
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
  position: absolute;
  top: 60px;
  left: -7px;
}

.line2-sold-car {
  width: 100%;
  height: 43px;
  border-bottom: 2px solid #111;
  -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
  position: absolute;
  top: 40px;
  left: 30px;
}

.text-sold-car{
  position: absolute;
  left: 27%;
  bottom: 36%;
  font-size: 45px;
  color: red;
  font-weight: 700;
}

.col-check-cars{
  float: right;
  padding-top: 5px;
}

.img-bg{
  max-width: 350px;
  position: absolute;
  left: -90px;
  top: 150px;
}

.finance-click{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
}

.label-bg{
  padding: 40px 0;
  text-align: center;
  font-size: 18px;
  color: white;
}

.container-general{
  .header-page-setup{
    height: 56px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
    opacity: 1;
  }
  .container-setup-not-complete{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 56px);
    background-color: #F5F6FA;
    .container-page-not-setup{
      .title{
        text-align: center;
        padding-bottom: 15%;
        .dealer-logo-img{
          width: 300px;
        }
      }
      .under-title{
        padding-top: 10%;
        color: #405b91;
        font-size: 27px;
        .under-title-2{
          font-weight: 900;
        }
      }
      .title-bottom{
        padding-top: 25%;
        .title-bottom-1{
          color: #787878;
          font-weight: 900;
        }
        .title-bottom-3{
          color: #2194F1;
          &:hover{
            cursor: pointer;
          }
        }
        .title-bottom-2.title-bottom-4{
          color: #929397;
        }
      }
    }
  }
}

// Media queries

@media (max-width: 1375px){

  .main-nav .navbar-default .navbar-nav {
    width: 81%;
  }
  #socialAndLoginR {
    width: 19%;
  }
  #cars {
    padding-top: 90px;
  }
}

@media (max-width: 1360px){

  .main-nav .navbar-default .navbar-nav {
    width: 82%;
  }
  #socialAndLoginR {
    width: 18%;
  }

  #banRowl{
    h1{
      margin-top: 18px;
      font-size: 35px;
    }
  }

  #panelbodyMsgBox{
    padding: 14px;
    .formGroup{
      margin-bottom: 13px;
    }
  }

  #getInTouchBtn button {
    height: 49px;
  }

}

@media (max-width: 1340px){

  .main-nav .navbar-default .navbar-nav {
    width: 83%;
  }
  #socialAndLoginR {
    width: 17%;
    ul{
      width: 100% !important;
    }
  }

  #titleHead {
    width: 24%;
    .site-name h1 {
      font-size: 35px;
    }
  }

  .menuHead {
    width: 76%;
  }

  #cars {
    padding-top: 85px;
  }

  #banRowl{
    h1{
      margin-top: 15px;
      font-size: 34px;
    }
  }

  #panelbodyMsgBox{
    padding: 13px;
    .formGroup{
      margin-bottom: 12px;
    }
  }

  #getInTouchBtn button {
    height: 48px;
  }
  .line1-sold-car {
    height: 70px;
    top: 60px;
    left: -7px;
  }
  .line2-sold-car {
    height: 37px;
    top: 40px;
    left: 27px;
  }
  .text-sold-car {
    bottom: 38%;
  }
  #carViewItem{
    .container-car-image{
      height: 195px !important;
    }
  }
}

@media (max-width: 1325px){

  .main-nav .navbar-default .navbar-nav {
    width: 84%;
  }
  #socialAndLoginR {
    width: 16%;
  }

  #banRowl{
    h1{
      margin-top: 13px;
      font-size: 33px;
    }
  }

  #panelbodyMsgBox{
    padding: 12px;
    .formGroup{
      margin-bottom: 11px;
    }
  }

  #getInTouchBtn button {
    height: 47px;
  }

}

@media (max-width: 1310px){

  .main-nav .navbar-default .navbar-nav {
    width: 85%;
  }
  #socialAndLoginR {
    width: 15%;
  }

  #titleHead {
    width: 23%;
    .site-name h1 {
      font-size: 34px;
    }
  }

  .menuHead {
    width: 77%;
  }

  #cars {
    padding-top: 80px;
  }

  #banRowl{
    h1{
      margin-top: 10px;
      font-size: 32px;
    }
  }

  #panelbodyMsgBox{
    padding: 11px;
    .formGroup{
      margin-bottom: 10px;
    }
  }

  #getInTouchBtn button {
    height: 46px;
  }

  #carViewItem{
    .container-car-image{
      height: 190px !important;
    }
  }

}

@media (max-width: 1295px){

  .main-nav .navbar-default .navbar-nav {
    width: 86%;
  }
  #socialAndLoginR {
    width: 14%;
  }

  #panelbodyMsgBox{
    padding: 10px;
    .formGroup{
      margin-bottom: 9px;
    }
  }

  #getInTouchBtn button {
    height: 45px;
  }

}

@media (max-width: 1280px){

  .main-nav .navbar-default .navbar-nav {
    width: 87%;
  }
  #socialAndLoginR {
    width: 13%;
  }

  #titleHead {
    width: 22%;
    .site-name h1 {
      font-size: 33px;
    }
  }

  .menuHead {
    width: 78%;
  }

  #cars {
    padding-top: 75px;
  }

  #banRowl{
    h1{
      margin-top: 5px;
      font-size: 31px;
    }
  }

  #panelbodyMsgBox{
    .formGroup{
      margin-bottom: 8px;
    }
  }

  #getInTouchBtn button {
    height: 44px;
  }

  .panel-finance .col-type-id {
    width: 23%;
  }

  #inventoryViewLeft{
    width: 51%;
    margin-left: 15%;
  }

}

@media (max-width: 1265px){

  .main-nav .navbar-default .navbar-nav {
    width: 88%;
  }
  #socialAndLoginR {
    width: 12%;
  }

  #cars {
    padding-top: 70px;
  }

  #banRowl{
    h1{
      margin-top: 0;
      font-size: 30px;
    }
  }

  #panelbodyMsgBox{
    padding: 9px;
    .formGroup{
      margin-bottom: 7px;
    }
  }

  #getInTouchBtn button {
    height: 43px;
  }

  .panel-finance .col-type-id {
    width: 24%;
  }

  #inventoryViewLeft{
    width: 52%;
    margin-left: 14%;
  }

}

@media (max-width: 1250px){

  .main-nav .navbar-default .navbar-nav {
    width: 89%;
  }
  #socialAndLoginR {
    width: 11%;
  }

  #titleHead {
    width: 21%;
    .site-name h1 {
      font-size: 32px;
    }
  }

  .menuHead {
    width: 79%;
  }

  #cars {
    padding-top: 65px;
  }

  #banRowl{
    h1{
      font-size: 29px;
    }
  }

  #panelbodyMsgBox{
    .formGroup{
      margin-bottom: 6px;
    }
  }

  #getInTouchBtn button {
    height: 42px;
  }

  .panel-finance .col-type-id {
    width: 25%;
  }

  #inventoryViewLeft{
    width: 53%;
    margin-left: 13%;
  }
  #carViewItem{
    .container-car-image{
      height: 185px !important;
    }
  }
  .line1-sold-car {
    height: 70px;
    top: 50px;
    left: -5px;
  }
  .line2-sold-car {
    height: 37px;
    top: 35px;
    left: 25px;
  }
  .text-sold-car {
    left: 38%;
  }

}

@media (max-width: 1240px){

  .main-nav .navbar-default .navbar-nav {
    width: 90%;
  }
  #socialAndLoginR {
    width: 10%;
  }
  #cars {
    padding-top: 60px;
  }

  #banRowl{
    h1{
      font-size: 28px;
    }
  }

  #panelbodyMsgBox{
    padding: 8px;
    .formGroup{
      margin-bottom: 5px;
    }
  }

  #getInTouchBtn button {
    height: 41px;
  }

  .panel-finance .col-type-id {
    width: 26%;
  }

  .col-credit-score{
    button{
      padding: 6px 0;
    }
  }

  #inventoryViewLeft{
    width: 54%;
    margin-left: 12%;
  }
  #carViewItem{
    .container-car-image{
      height: 180px !important;
    }
  }
  .line1-sold-car {
    height: 70px;
    top: 49px;
    left: -5px;
  }
  .line2-sold-car {
    height: 30px;
    top: 40px;
    left: 23px;
  }
  .text-sold-car {
    bottom: 38%;
    left: 36%;
  }

}

@media (max-width: 1225px){

  .main-nav .navbar-default .navbar-nav {
    width: 91%;
    font-size: 12px;
  }
  #socialAndLoginR {
    width: 9%;
  }

  #titleHead {
    width: 20%;
    .site-name h1 {
      font-size: 30px;
    }
  }

  .menuHead {
    width: 80%;
  }

  #cars {
    padding-top: 55px;
  }

  #banRowl{
    h1{
      font-size: 27px;
    }
  }

  #getInTouchBtn button {
    height: 40px;
  }

  .panel-finance .col-type-id {
    width: 27%;
  }

  #inventoryViewLeft{
    width: 55%;
    margin-left: 11%;
  }
  #carViewItem{
    .container-car-image{
      height: 175px !important;
    }
  }
  .line1-sold-car {
    top: 43px;
  }
  .line2-sold-car {
    //
  }
  .text-sold-car {
    left: 34%;
  }
}

@media (max-width: 1215px){

  #titleHead {
    width: 19%;
    .site-name h1 {
      font-size: 28px;
    }
  }

  .menuHead {
    width: 81%;
  }

  #panelBodyInventory, #panfooter, #advSearchTitle{
    padding: 13px;
  }

  #cars {
    padding-top: 50px;
  }

  #getInTouchBtn button {
    height: 39px;
  }

  .panel-finance .col-type-id {
    width: 28%;
  }

  .panel-finance .col-credit-score {
    padding: 0 3px;
  }

  #inventoryViewLeft{
    width: 56%;
    margin-left: 10%;
  }
  #carViewItem{
    .container-car-image{
      //--height: 175px !important;
    }
  }

}

@media (max-width: 1205px){
  #titleHead {
    .site-name h1 {
      font-size: 26px;
    }
  }

  #panelBodyInventory, #panfooter, #advSearchTitle{
    padding: 11px;
  }

  #cars {
    padding-top: 40px;
  }

  #getInTouchBtn button {
    height: 38px;
  }

  #banRowl{
    h1{
      font-size: 26px;
    }
  }

  #carSel .left i {
    top: 60px;
  }

  #carSel .right i {
    top: 60px;
  }

  .panel-finance .col-type-id {
    width: 29%;
  }

  #inventoryViewLeft{
    width: 57%;
    margin-left: 9%;
  }
  #inventoryViewRight {
    width: 19%;
  }

  #carViewItem{
    .container-car-image{
      height: 170px !important;
    }
  }

  .idCarOther {
    font-size: 16px;
  }

  #carViewSel .left {
    left: -50px;
  }

  #carViewSel .right {
    right: -50px;
  }

}

@media (max-width: 1200px){
  .line1-sold{
    height: 70px;
    -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
    top: 60px;
    left: -5px;
  }
  .line2-sold{
    height: 32px;
    -webkit-transform: translateY(0) translateX(5px) rotate(35deg);
    top: 47px;
    left: 28px;
  }
}

@media (max-width: 1195px){
  #titleHead {
    .site-name h1 {
      font-size: 24px;
    }
  }

  #advancedSearchForm {
    label{
      margin-bottom: 3px;
    }
    #typeahead-box{
      height: 28px;
    }
    #idRow{
      .form-group{
        margin-bottom: 10px;
        label{
          margin-bottom: 3px;
        }
        input{
          height: 28px;
        }
      }
    }
  }

  #cars {
    padding-top: 30px;
  }

  #getInTouchBtn button {
    height: 37px;
  }

  .panel-finance .col-type-id {
    width: 30%;
  }

  #inventoryViewLeft{
    width: 58%;
    margin-left: 8%;
  }
  #inventoryViewRight {
    width: 20%;
  }

  #carViewItem .container-car-image {
    height: 165px !important;
  }
}

@media (max-width: 1175px){
  #titleHead {
    width: 18%;
    .site-name h1 {
      font-size: 22px;
    }
  }
  .menuHead {
    width: 82%;
  }

  #cars {
    padding-top: 20px;
    #cars-title {
      font-size: 35px;
    }
    #carsButtons {
      margin-top: 45px;
    }
  }

  #getInTouchBtn button {
    height: 36px;
  }
  #banRowl{
    h1{
      font-size: 25px;
    }
  }

  .banner .banner-imgContainer {
    height: auto;
  }

  #inventoryViewLeft{
    width: 59%;
    margin-left: 7%;
  }
  #inventoryViewRight {
    width: 21%;
  }

  #carViewItem .container-car-image {
    height: 160px !important;
  }

}

@media (max-width: 1160px){
  #titleHead {
    display: none;
    width: 0;
  }
  .menuHead {
    width: 100%;
  }

  #advancedSearchForm {
    label{
      margin-bottom: 0;
    }
    #idRow{
      .form-group{
        label{
          margin-bottom: 0;
        }
      }
    }
  }

  #cars {
    padding-top: 15px;
    #cars-title {
      font-size: 34px;
    }
    #carsButtons {
      margin-top: 40px;
    }
  }

  #getInTouchBtn button {
    height: 35px;
  }

  #banRowl{
    h1{
      font-size: 24px;
    }
  }

  #inventoryViewLeft{
    width: 60%;
    margin-left: 6%;
  }
  #inventoryViewRight {
    width: 22%;
  }

  #idNote {
    margin-left: -9%;
  }

  #carViewItem .container-car-image {
    height: 155px !important;
  }

  #carCarouselOne .container-car-image {
    height: 490px;
  }
  .idCarOther {
    font-size: 15px;
  }

  #carViewSel .left {
    left: -45px;
  }

  #carViewSel .right {
    right: -45px;
  }
  .line1-sold-car {
    top: 32px;
    left: -4px;
  }
  .line2-sold-car {
    height: 30px;
    top: 30px;
    left: 20px;
  }
  .text-sold-car {
    left: 37%;
    font-size: 26px;
  }

}

@media (max-width: 1140px){
  #advancedSearchForm {
    #idRow{
      .form-group{
        margin-bottom: 8px;
      }
    }
  }

  #cars {
    padding-top: 15px;
    #cars-title {
      font-size: 33px;
    }
    #carsButtons {
      margin-top: 35px;
    }
  }

  #getInTouchBtn button {
    height: 34px;
  }

  #getInTouchBtn {
    margin-bottom: 13px;
  }

  #inventoryViewLeft{
    width: 61%;
    margin-left: 5%;
  }
  #inventoryViewRight {
    width: 23%;
  }

  #idNote {
    margin-left: -8%;
  }

  #carViewItem .container-car-image {
    height: 150px !important;
  }

  #carCarouselOne .container-car-image {
    height: 480px;
  }

  #idCall #btnCall {
    font-size: 16px;
  }
  #idCall #idImgPhone {
    width: 40px;
    margin-top: 10px;
  }

  #carViewSel{
    .carouselPrev, .carouselNext{
      font-size: 45px;
      margin-top: 70px;
    }
  }
}

@media (max-width: 1120px){
  #advancedSearchForm {
    #idformGroup{
      margin-bottom: 10px;
    }
  }
  #cars {
    padding-top: 10px;
    #cars-title {
      font-size: 32px;
    }
    #carsButtons {
      margin-top: 30px;
    }
  }
  #getInTouchBtn button {
    height: 33px;
  }
  #getInTouchTextArea{
    height: 85px;
  }
  #banRowl{
    h1{
      margin-bottom: 8px;
    }
  }
  #inventoryViewRight {
    width: 24%;
  }
  #inventoryViewLeft {
    margin-left: 4%;
  }

  #idNote {
    margin-left: -7%;
  }

  #carCarouselOne .container-car-image {
    height: 470px;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 65px;
    }
  }
}

@media (max-width: 1100px){
  #advancedSearchForm {
    font-size: 12px;
  }
  #inventorySearchBox #searchOr{
    margin-bottom: 5px;
  }

  .bannerCaption{
    h4{
      margin-bottom: 5px;
      font-size: 17px;
    }
    h1{
      margin-top: 15px;
      font-size: 34px;
    }
  }
  #getInTouchBtn button {
    height: 32px;
  }
  #getInTouchBtn {
    margin-bottom: 10px;
  }
  #getInTouchTextArea{
    height: 80px;
  }
  #cars {
    padding-top: 5px;
    #cars-title {
      font-size: 31px;
    }
    #carsButtons {
      margin-top: 25px;
    }
  }

  #inventoryViewRight {
    float: left;
    width: 25%;
  }

  #inventoryViewLeft {
    width: 61%;
    margin-left: 3%;
    float: left;
  }
  #idNote {
    margin-left: -6%;
  }
  #carViewItem .container-car-image {
    height: 150px !important;
  }

  #carCarouselOne .container-car-image {
    height: 460px;
  }

  #idCall #btnCall {
    font-size: 15px;
  }
  #idCall #idImgPhone {
    width: 36px;
    margin-top: 10px;
  }
  .idCarOther {
    font-size: 14px;
  }
  #carViewSel .left {
    left: -40px;
  }

  #carViewSel .right {
    right: -40px;
  }

  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 60px;
    }
  }
}

@media (max-width: 1080px){
  #advancedSearchForm {
    font-size: 12px;
  }
  #advSearchTitle{
    padding: 8px;
  }
  #main-panel-header{
    .panel-heading{
      padding: 8px;
    }
  }
  #inventorySearchBox #searchOr{
    margin-bottom: 2px;
  }

  .bannerCaption{
    h4{
      font-size: 16px;
    }
    h1{
      margin-top: 10px;
      font-size: 32px;
    }
  }

  #getInTouchBtn {
    margin-bottom: 8px;
  }
  #getInTouchTextArea{
    height: 75px;
  }
  #cars {
    padding-top: 0;
    #cars-title {
      font-size: 30px;
    }
    #carsButtons {
      margin-top: 20px;
    }
  }

  #inventoryViewRight {
    width: 26%;
  }
  #idNote {
    margin-left: -5%;
  }

  #carViewItem .container-car-image {
    height: 140px !important;
  }

  #carCarouselOne .container-car-image {
    height: 450px;
  }

  #idCall #btnCall {
    font-size: 14px;
  }
  #idCall #idImgPhone {
    width: 32px;
    margin-top: 12px;
    margin-left: -8px;
  }
  .line1-sold-car {
    top: 25px;
    left: -2px;
  }
  .line2-sold-car {
    top: 28px;
  }
  .text-sold-car {
    left: 33%;
  }
  .img-bg{
    left: -70px;
  }
}

@media (max-width: 1060px){
  .bannerCaption{
    h4{
      font-size: 15px;
    }
    h1{
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 30px;
    }
  }
  #whoText {
    padding-bottom: 5px;
    margin-left: 12%;
  }

  #getInTouchBtn {
    margin-bottom: 6px;
  }
  #getInTouchTextArea{
    height: 70px;
  }
  #banRowl{
    h1{
      margin-bottom: 5px;
    }
  }
  #cars {
    #cars-title {
      font-size: 28px;
    }
    #carsButtons {
      margin-top: 15px;
    }
  }
  #inventoryViewRight {
    width: 27%;
  }
  #idNote {
    margin-left: -4%;
  }

  #carCarouselOne .container-car-image {
    height: 440px;
  }

  #idCall #idImgPhone {
    width: 30px;
    margin-top: 12px;
    margin-left: -6px;
  }

  #carViewSel .left {
    left: -35px;
  }

  #carViewSel .right {
    right: -35px;
  }
}

@media (max-width: 1040px){
  .bannerCaption{
    h4{
      font-size: 14px;
    }
    h1{
      font-size: 28px;
    }
  }
  #whoText {
    margin-left: 11%;
  }
  #getInTouchTextArea{
    height: 65px;
  }
  #cars {
    #cars-title {
      font-size: 26px;
    }
    #carsButtons {
      margin-top: 10px;
    }
  }
  .panel-finance{
    .colR25, .colR16 {
      width: 50%;
    }
    .col25 , .col16{
      width: 50%;
      padding-right: 10px;
    }
    .col50 {
      padding-right: 10px;
    }
  }

  #inventoryViewRight {
    width: 28%;
  }

  #itemRight{
    width:64%;
  }

  .container-car-image-list {
    height: 150px;
  }
  #idNote {
    margin-left: -3%;
  }

  #carCarouselOne .container-car-image {
    height: 430px;
  }

  #idCall #idImageCar {
    height: 56px;
  }
  #idCall #btnCall {
    height: 56px;
  }
  .line1-sold-list {
    height: 70px;
    top: 28px;
    left: -2px;
  }
  .line2-sold-list {
    height: 10px;
    top: 50px;
    left: 20px;
  }
  .text-sold-list {
    bottom: 40%;
    font-size: 30px;
  }
}

@media (max-width: 1020px){
  .bannerCaption{
    h1{
      font-size: 26px;
    }
  }
  #whoText {
    margin-left: 10%;
  }
  #getInTouchTextArea{
    height: 60px;
  }
  #banRowl{
    h1{
      margin-bottom: 3px;
    }
  }
  #cars {
    #cars-title {
      font-size: 24px;
    }
    #carsButtons {
      margin-top: 5px;
    }
  }

  #inventoryViewRight {
    width: 29%;
  }

  #itemRight{
    width:63%;
  }
  #idNote {
    margin-left: -2%;
  }

  #carCarouselOne .container-car-image {
    height: 420px;
  }
}

@media (max-width: 1000px){
  #cars {
    #carsButtons {
      margin-top: 0;
    }
  }
  #btnLeft, #btnCenter ,#btnRight{
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
  }
  #whoText {
    margin-left: 9%;
  }
  #testimonialItem {
    margin-left: 0;
    padding: 0 10%;
  }
  #layTestimonial{
    margin: 0 !important;
    width: 100%;
    float: left;
  }
  #layTestimonialHeader {
    margin-left: 10%;
    padding: 0;
  }
  #idAddress{
    padding-left: 25px;
  }
  .panel-finance .col-credit-score {
    width: 25%;
    padding: 5px;
  }

  #inventoryViewRight {
    width: 30%;
  }

  .container-car-image-list {
    height: auto;
    padding: 0;
    img{
      height: 145px !important;
    }
  }
  #idNote {
    margin-left: -1%;
  }
  #carCarouselOne .container-car-image {
    height: 410px;
  }
  #carViewList{
    .column3 {
      width: 33.33%;
    }
  }

  #carViewSel .left {
    left: -30px;
  }

  #carViewSel .right {
    right: -30px;
  }
  .line1-sold-list {
    top: 25px;
  }
  .line2-sold-list {
    height: 7px;
    left: 15px;
  }
  .text-sold-list {
    bottom: 39%;
  }
  .img-bg{
    left: -50px;
  }
}

@media (max-width: 992px){
  .container {
    width: 100% !important;
  }
  #carBlogContainer{
    margin: 0;
    width: 100%;
  }
  #carBlog{
    .carousel-control{
      width: 10%;
    }
  }
  #carCarouselOne .container-car-image {
    height: 400px;
  }
}

@media (max-width: 980px){
  #idRow{
    display: none;
  }

  #carSel{
    #carSelList {
      padding: 0 45px;
    }
    .left {
      left: -10px;
    }
    .right{
      right: -10px;
    }
  }
  #cars {
    #cars-title {
      font-size: 23px;
    }
  }
  #whoText {
    margin-left: 8%;
  }
  #idAddress{
    padding-left: 20px;
  }
  .rectangleWidth{
    width: 36%;
  }
  #idViewMore{
    width: 28%;
  }

  #carViewSel .left {
    left: -25px;
  }

  #carViewSel .right {
    right: -25px;
  }

}

@media (max-width: 960px){

  .menuHead {
    .main-nav{
      display: none;
    }
  }
  .menu-content{
    display: block;
    float: right;
    padding-top: 7px;
  }

  #carSel{
    #carSelList {
      padding: 0 40px;
    }
    .left {
      left: -15px;
    }
    .right{
      right: -15px;
    }
  }

  #whoText {
    margin-left: 7%;
  }

  #idAddress{
    padding-left: 15px;
  }

  #btnInvCar {
    width: 26%;
  }

  .rectangleWidth{
    width: 34%;
  }
  #idViewMore{
    width: 32%;
  }
  .container-car-image-list img {
    height: 140px !important;
  }
  .line1-sold-list {
    top: 23px;
  }
  .text-sold-list {
    bottom: 37%;
  }
  .line2-sold-list {
    height: 5px;
  }

}


@media (max-width: 940px){
  #carSel{
    #carSelList {
      padding: 0 35px;
    }
    .left {
      left: -20px;
    }
    .right{
      right: -20px;
    }
  }
  #cars {
    #cars-title {
      font-size: 22px;
    }
    .cars-subtitle{
      font-size: 17px;
    }
  }
  #whoText {
    margin-left: 6%;
  }
  #idAddress{
    padding-left: 10px;
  }

  #btnInvCar {
    width: 27%;
  }

  #carViewSel .left {
    left: -20px;
  }

  #carViewSel .right {
    right: -20px;
  }

  .carViewContact{
    display: none;
  }

}

@media (max-width: 920px){
  #carSel{
    #carSelList {
      padding: 0 30px;
    }
  }
  #whoText {
    margin-left: 5%;
  }
  #idAddress{
    padding-left: 5px;
  }
  .panel-finance .col-type-id {
    width: 31%;
  }

  #inventoryViewRight {
    width: 35%;
  }
  #itemLeft{
    width: 34%;
  }

  #btnInvCar {
    width: 28%;
  }

  #carCarouselOne .container-car-image {
    height: 390px;
  }
}

@media (max-width: 900px){
  #carSel{
    #carSelList {
      padding: 0 25px;
    }
    .left {
      left: -25px;
    }
    .right{
      right: -25px;
    }
  }
  #cars {
    #cars-title {
      font-size: 21px;
    }
    .cars-subtitle{
      font-size: 16px;
    }
  }
  #whoText {
    margin-left: 4%;
  }
  #idAddress{
    padding-left: 0;
  }
  #lgetInTouch #banRowl {
    margin-top: -15px;
  }
  .panel-finance .col-type-id {
    width: 32%;
  }

  #itemLeft{
    width: 35%;
  }

  #btnInvCar {
    width: 29%;
  }

  #carViewList .column3 {
    padding: 0 10px;
  }

  #carCarouselOne .container-car-image {
    height: 380px;
  }

}

@media (max-width: 880px){
  #carLstItem .container-car-image{
    height: auto !important;
    img{
      height: 180px !important;
    }
  }

  #whoText {
    margin-left: 3%;
  }
  #idReference{
    padding: 0 10px;
  }
  .panel-finance .col-type-id {
    width: 33%;
  }
  #itemLeft{
    width: 36%;
  }

  #btnInvCar {
    width: 30%;
  }

  #carCarouselOne .container-car-image {
    height: 370px;
  }
  .line1-sold{
    height: 75px;
    top: 45px;
    left: -5px;
  }
  .line2-sold{
    height: 25px;
    top: 47px;
    left: 22px;
  }
  .text-sold{
    left: 36%;
    bottom: 39%;
  }
}

@media (max-width: 860px){
  #carSel .left i {
    top: 57px;
  }

  #carSel .right i {
    top: 57px;
  }
  #cars {
    #cars-title {
      font-size: 20px;
    }
    .cars-subtitle{
      font-size: 15px;
    }
  }
  #whoText {
    margin-left: 2%;
  }
  #idReference{
    padding: 0 5px;
  }
  #lgetInTouch #banRowl {
    margin-top: -18px;
  }
  .col-type-id {
    button{
      font-size: 13px;
    }
  }
  #itemLeft{
    width: 37%;
  }
  #itemRight {
    padding-right: 0;
    width: 62%;
  }

  #btnInvCar {
    width: 31%;
  }
  #carCarouselOne .container-car-image {
    height: 360px;
  }
  #carLstItem .container-car-image{
    img{
      height: 175px !important;
    }
  }
  .line1-sold {
    top: 40px;
  }
  .text-sold {
    left: 34%;
  }
}

@media (max-width: 840px){
  #carSel .left i {
    top: 54px;
  }

  #carSel .right i {
    top: 54px;
  }
  #btnLeft, #btnCenter ,#btnRight{
    width: 20%;
  }
  #whoText {
    margin-left: 1%;
  }
  #idReference{
    padding: 0;
  }
  #getInTouchTextArea{
    height: 55px;
  }
  #lgetInTouch #banRowl {
    margin-top: -20px;
    h1{
      font-size: 22px;
      margin-top: 3px;
    }
  }
  .panel-finance .col-type-credit {
    width: 16%;
    padding: 0 5px;
  }
  #itemLeft{
    //width: 38%;
  }
  #btnInvCar {
    width: 32%;
  }

  #carViewList .column3 {
    padding: 0 5px;
  }
  #carCarouselOne .container-car-image {
    height: 350px;
  }
  #carLstItem .container-car-image{
    img{
      height: 170px !important;
    }
  }
  .line1-sold {
    top: 37px;
  }
  .line2-sold {
    top: 43px;
  }
  .text-sold {
    left: 33%;
  }
}

@media (max-width: 820px){
  #carSel .left i {
    top: 51px;
  }

  #carSel .right i {
    top: 51px;
  }

  #cars {
    #cars-title {
      font-size: 19px;
    }
    .cars-subtitle{
      font-size: 14px;
    }
  }
  #whoText {
    margin-left: 0;
  }
  #lgetInTouch #banRowl {
    h1{
      margin-top: 6px;
      font-size: 20px;
    }
  }
  #getInTouchTextArea{
    height: 50px;
  }
  #inventoryList img {
    width: 100%;
  }
  .container-car-image-list img {
    height: 135px !important;
  }
  #btnInvCar {
    width: 33%;
  }
  #carCarouselOne .container-car-image {
    height: 340px;
  }
  #carLstItem .container-car-image{
    img{
      height: 165px !important;
    }
  }
  .line1-sold {
    left: -4px;
  }
  .line2-sold {
    top: 40px;
    left: 20px;
  }
  .text-sold {
    left: 32%;
  }
  .line1-sold-list {
    top: 20px;
    left: 0;
  }
  .line2-sold-list {
    height: 2px;
    left: 13px;
  }
}

@media (max-width: 800px){
  #carSel .left i {
    top: 48px;
  }

  #carSel .right i {
    top: 48px;
  }
  .col-sm-4 {
    padding: 0 12px;
  }

  #btnLeft, #btnCenter ,#btnRight{
    width: 22%;
  }
  #lgetInTouch #banRowl {
    h1{
      font-size: 18px;
    }
  }
  #getInTouchTextArea{
    height: 45px;
  }
  .panel-finance .col-type-credit {
    width: 17%;
  }
  #carCarouselOne .container-car-image {
    height: 330px;
  }
  .container-car-title{
    width: 65%;
    padding: 0;
  }
  .container-car-price{
    padding: 0;
    width: 35%;
    text-align: right;
  }
  #carLstItem .container-car-image{
    img{
      height: 160px !important;
    }
  }
}

@media (max-width: 780px){
  #banRow{
    left:28% !important;
  }
  #banRow2{
    display: none;
  }
  .menu-content{
    float: right;
    width: 100%;
    padding: 0;
    margin-top: -5px;
  }

  #carSel .left i {
    top: 45px;
  }

  #carSel .right i {
    top: 45px;
  }

  .col-sm-4 {
    padding: 0 9px;
  }

  #cars {
    #cars-title {
      font-size: 18px;
    }
    .cars-subtitle{
      font-size: 13px;
    }
  }

  #btnInv {
    height: 47px;
  }
  #whoText {
    padding: 0 5px;
    width: 100%;
  }
  #whoImage{
    width: 100%;
  }
  #getInTouchTextArea{
    height: 40px;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 50px;
  }

  .panel-finance .col-type-id {
    width: 100%;
    padding: 5px;
  }

  #photoGrid {
    margin-left: -20px;
  }
  #carCarouselOne .container-car-image {
    height: 320px;
  }
  .iconBar{
    float: left;
  }
  #idDescription{
    font-size: 16px;
  }
  #titleCar{
    font-size: 28px;
  }
  #idRectangleM{
    float: left;
  }
  .rectangleWidth{
    float: left;
  }
  #idViewMore{
    float: left;
  }
  .container-car-image-list img {
    height: 130px !important;
  }
  .line1-sold-list {
    top: 18px;
  }
  .line2-sold-list {
    height: 0;
    left: 13px;
    top: 47px;
  }
  .text-sold-list {
    bottom: 36%;
  }
}

@media (max-width: 768px){
  #tableCar {
    margin: 0;
  }
}

@media (max-width: 760px){
  #banRow{
    left:27% !important;
    #searchHead{
      top:4%
    }
  }
  .col-sm-4 {
    padding: 0 6px;
  }

  #btnLeft, #btnCenter ,#btnRight{
    width: 24%;
  }
  #getInTouchTextArea{
    height: 35px;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 48px;
  }
  .panel-finance .col-type-credit {
    width: 18%;
  }
  #carCarouselOne .container-car-image {
    height: 310px;
  }
}

@media (max-width: 740px){

  #banRow{
    left:26% !important;
    #searchHead{
      top:2%
    }
  }
  #btnInv {
    height: 44px;
    padding: 12px 0;
  }
  #getInTouchBtn button {
    height: 30px;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 46px;
  }
  #carCarouselOne .container-car-image {
    height: 300px;
  }
  .carItemImg{
    padding: 0;
    #imgIconCar {
      max-width: 42px;
    }
  }
  .container-car-image-list img {
    height: 125px !important;
  }
  .line1-sold-list {
    top: 20px;
    height: 65px;
  }
  .line2-sold-list {
    left: 10px;
    top: 45px;
  }
  .text-sold-list {
    font-size: 24px;
  }
}

@media (max-width: 720px){
  #banRow{
    left:25% !important;
  }
  #lgetInTouch #banRowl {
    h1{
      font-size: 16px;
    }
  }
  #idReference{
    display: none;
  }
  #idReferencePie{
    display: block;
    padding: 10px 0 0 0;
  }
  #idMsgBox{
    width: 100%;
    padding: 10px 10% 0 10%;
  }
  #banRowl{
    h1{
      display: none;
    }
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 44px;
  }
  .panel-finance .col-type-credit {
    width: 19%;
  }

  #carViewList .column3 {
    width: 50%;
  }

  #carViewItem .container-car-image {
    height: auto !important;
    img{
      width: 100% !important;
    }
  }

  #carCarouselOne .container-car-image {
    height: 290px;
  }

  .rectangleWidth{
    width: 32%;
  }
  #idViewMore{
    width: 36%;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 90px;
    }
  }
  .line1-sold-car {
    top: 28px;
    height: 110px;
    left: 2px;
  }
  .line2-sold-car {
    top: 62px;
    height: 20px;
    left: 25px;
  }
  .text-sold-car {
    left: 42%;
    font-size: 28px;
  }
}

@media (max-width: 705px){
  #carSelList{
    .col-sm-4 {
      width: 100% !important;
    }
  }
  #carSel{
    padding: 0 25%;
  }
  #btnLeft, #btnCenter ,#btnRight{
    width: 26%;
  }
  #carLstItem .container-car-image{
    img{
      height: 190px !important;
    }
  }
  .line1-sold {
    left: -7px;
    top: 50px;
  }
  .line2-sold {
    top: 50px;
    left: 28px;
    height: 30px;
  }
  .text-sold {
    left: 37%;
  }
}

@media (max-width: 700px){
  #banRow{
    left:24% !important;
    #searchHead{
      top:30%
    }
  }
  #main-panel-body, #searchOr{
    display: none;
  }
  #btnInv {
    height: 41px;
    padding: 10px 0;
    width: 27%;
  }
  #idMsgBox{
    padding: 5px 11% 0 10%;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 42px;
  }
  .panel-finance .col-type-credit {
    width: 20%;
  }
  #inventoryFinances{
    display: none;
  }

  #inventoryHeaderTitle {
    h1{
      font-size: 35px;
    }
  }
  #carCarouselOne .container-car-image {
    height: 280px;
  }
  #carViewDescription {
    padding: 0 10px;
  }
  #idDescription{
    font-size: 15px;
  }
  #titleCar{
    font-size: 26px;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 85px;
    }
  }
}

@media (max-width: 680px){
  #banRow{
    left:23% !important;
  }
  #carSel{
    padding: 0 24%;
  }
  #btnLeft, #btnCenter ,#btnRight{
    width: 28%;
  }
  #idMsgBox{
    padding: 5px 10% 0 9%;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 40px;
  }
  .panel-finance .col-type-credit {
    width: 21%;
  }
  #inventoryViewRight {
    display: none;
  }
  #inventoryViewLeft {
    width: 100%;
  }
  #carCarouselOne .container-car-image {
    height: 270px;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 80px;
    }
  }
  .container-car-image-list img {
    height: 170px !important;
  }
  .line1-sold-list {
    top: 48px;
    height: 58px;
    left: -9px;
  }
  .line2-sold-list {
    left: 22px;
    height: 31px;
    top: 42px;
  }
  .text-sold-list {
    bottom: 40%;
    left: 39%;
  }
  .line1-sold-car {
    top: 28px;
    height: 100px;
    left: 0;
  }
  .line2-sold-car {
    top: 58px;
    height: 20px;
    left: 22px;
  }
  .text-sold-car {
    left: 40%;
  }
}

@media (max-width: 660px){
  #banRow{
    left:22% !important;
  }
  #carSel{
    padding: 0 23%;
  }
  #btnLeft, #btnCenter ,#btnRight{
    height: 45px;
    padding:0;
  }
  .btn-arrow-left:before {
    width: 31px;
    height: 31px;
    left: -16px;
  }
  .btn-arrow-right:after {
    width: 31px;
    height: 31px;
    right: -16px;
  }
  #btnInv {
    height: 38px;
    padding: 8px 0;
    width: 29%;
  }
  #layContact{
    height: 185px !important;
  }
  #idMsgBox{
    padding: 5px 9% 0 8%;
  }
  #lgetInTouch #banRowl {
    padding-left: 2%;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 38px;
  }
  .panel-finance .col-type-credit {
    width: 22%;
  }
  #carCarouselOne .container-car-image {
    height: 260px;
  }
  #idCarouselLeft{
    padding-left: 0;
    padding-right: 10px;
  }
  .container-car-title{
    padding-left: 0;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 75px;
    }
  }
  .line1-sold-car {
    top: 20px;
    height: 105px;
    left: 2px;
  }
  .line2-sold-car {
    top: 55px;
    height: 20px;
    left: 22px;
  }
  .text-sold-car {
    left: 38%;
    bottom: 39%;
  }
}

@media (max-width: 640px){
  #banRow{
    left:21% !important;
  }
  #carSel{
    padding: 0 22%;
  }
  #idMsgBox{
    padding: 5px 8% 0 7%;
  }
  #btnGetInTouch01, #btnGetInTouch04{
    height: 36px;
  }
  .panel-finance .col-type-credit {
    width: 23%;
  }
  #carCarouselOne .container-car-image {
    height: 250px;
  }
  #titleCar{
    font-size: 25px;
  }
  .rectangleWidth{
    width: 30%;
  }
  #idViewMore{
    width: 40%;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 70px;
    }
  }
}

@media (max-width: 620px){
  #banRow{
    left:20% !important;
  }
  #carSel{
    padding: 0 21%;
  }
  #idMsgBox{
    padding: 5px 7% 0 6%;
  }
  .panel-finance .col-credit-score {
    width: 33%;
  }
  .panel-finance .col-type-credit {
    width: 24%;
  }

  #idCarouselLeft{
    padding-right: 5px;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 65px;
    }
  }
  .line1-sold-car {
    top: 5px;
    height: 113px;
    left: 6px;
  }
  .line2-sold-car {
    top: 50px;
    height: 20px;
    left: 21px;
  }
  .text-sold-car {
    bottom: 37%;
  }
}

@media (max-width: 600px){

  #carSel{
    padding: 0 20%;
  }
  #btnLeft, #btnCenter ,#btnRight{
    height: 40px;
    font-size: 12px;
  }
  .btn-arrow-left:before {
    width: 27.3px;
    height: 27.3px;
    left: -14px;
  }
  .btn-arrow-right:after {
    width: 27.3px;
    height: 27.3px;
    right: -14px;
  }

  #btnInv {
    height: 35px;
    padding: 6px 0;
    width: 31%;
  }

  #idMsgBox{
    padding: 5px 6% 0 5%;
  }

  #lgetInTouch #banRowl {
    padding-left: 3%;
  }

  #idAddressPie{
    padding-left: 30px;
  }

  .panel-finance .col-type-credit {
    width: 25%;
  }

  #inventoryHeaderTitle {
    h1{
      font-size: 34px;
      margin-top: 10px;
    }
  }

  #titleCar{
    font-size: 24px;
  }
  #carViewDescription {
    padding: 0 5px;
  }
  #idDescription{
    font-size: 14px;
  }
  #idCarouselLeft{
    padding-right: 0
  }
  .container-car-image-list img {
    height: 160px !important;
  }
  .line1-sold-list {
    top: 35px;
    height: 70px;
    left: -4px;
  }
  .line2-sold-list {
    left: 15px;
    height: 20px;
    top: 43px;
  }
  .text-sold-list {
    bottom: 38%;
  }
  .line1-sold-car {
    top: 5px;
    height: 108px;
    left: 6px;
  }
  .line2-sold-car {
    top: 46px;
    height: 20px;
    left: 21px;
  }
  .text-sold-car {

  }
}

@media (max-width: 580px){
  #banRow{
    left:19% !important;
  }
  #carSel{
    padding: 0 19%;
  }
  #btnInv {
    height: 32px;
    width: 33%;
  }

  #idMsgBox{
    padding: 5px 5% 0 4%;
  }

  .panel-finance .col-type-credit {
    width: 26%;
  }

  #carViewList .column3 {
    width: 100%;
  }
  #titleCar{
    font-size: 23px;
  }
  #idCarouselLeft{
    width: 100%;
  }
  #idCarouselRight{
    width: 100%;
  }
  #carViewSel{
    .carouselPrev, .carouselNext{
      margin-top: 85px;
    }
  }
  #carViewItem .container-car-image img {
    width: 240px !important;
  }
  #carViewItem .container-car-image {
    width: 260px !important;
    margin-left: 90px;
  }
  .line1-sold-car {
    top: 15px;
    height: 100px;
    left: 0;
  }
  .line2-sold-car {
    top: 57px;
    height: 20px;
    left: 21px;
  }
  .text-sold-car {
    bottom: 38.5%;
  }
}

@media (max-width: 560px){
  #banRow{
    left:18% !important;
  }
  #carSel{
    padding: 0 18%;
  }
  #btnInv {
    width: 35%;
  }
  #idMsgBox{
    padding: 5px 4% 0 3%;
  }

  #idAddressPie{
    padding-left: 28px;
  }
  .panel-finance .col-type-credit {
    width: 27%;
  }
  #inventoryList img {
    width: 200px;
  }
  #itemLeft{
    width: 100%;
    background-color: transparent;
  }
  #itemRight{
    width: 100%;
  }
  #titleCar{
    font-size: 22px;
  }
  .rectangleWidth{
    width: 28%;
  }
  #idViewMore{
    width: 44%;
  }
  .text-sold-list{
    left: 45%;
    bottom: 40%;
    font-size: 24px;
  }
  .container-car-image-list img {
    height: 150px !important;
  }
  .line1-sold-list {
    width: 39%;
    left: 32%;
    top: 27px;
  }
  .line2-sold-list {
    width: 36%;
    left: 34%;
    top: 38px;
  }
  .text-sold-list {
    left: 46%;
    bottom: 42%;
    font-size: 22px;
  }
  #carViewItem .container-car-image {
    margin-left: 85px;
  }
}

@media (max-width: 540px){
  #banRow{
    left:17% !important;
  }
  #carSel{
    padding: 0 17%;
  }
  #btnInv {
    width: 37%;
  }
  #idMsgBox{
    padding: 5px 3% 0 2%;
  }
  .panel-finance .col-type-credit {
    width: 28%;
  }
  #titleCar{
    font-size: 21px;
  }
  .rectangleWidth{
    width: 26%;
  }
  #idViewMore{
    width: 48%;
  }
  .line1-sold-list{
    width: 38%;
    left: 32%;
  }
  .line2-sold-list{
    width: 37%;
    left: 33.5%;
  }
  #carViewItem .container-car-image {
    margin-left: 80px;
  }
}


@media (max-width: 520px){
  #banRow{
    left:16% !important;
  }
  #carSel{
    padding: 0 16%;
  }
  #btnLeft, #btnCenter ,#btnRight{
    font-size: 11px;
  }
  #btnInv {
    width: 39%;
  }
  #idMsgBox{
    padding: 5px 2% 0 1%;
  }
  .panel-finance .col-type-credit {
    width: 29%;
  }
  .panel-finance{
    .colR25, .colR16 , .col25 , .col16, .col50, .colR50, .colR80, .col20{
      width: 100%;
      padding-right: 0;
    }
  }
  #financeViewBody{
    width: 100%;
    padding: 0 13%;
    margin-left:0
  }
  #titleCar{
    font-size: 20px;
  }
  .line1-sold-list{
    width: 40%;
    left: 31.5%;
  }
  .line2-sold-list{
    width: 38%;
  }
  #carViewItem .container-car-image {
    margin-left: 70px;
  }
}


@media (max-width: 500px){
  #banRow{
    left:15% !important;
  }
  #carSel{
    padding: 0 15%;
  }
  #btnInv {
    width: 41%;
  }
  #idMsgBox{
    padding: 5px 1% 0 1%;
  }
  #idAddressPie{
    padding-left: 25px;
  }
  .panel-finance .col-type-credit {
    width: 30%;
  }
  #financeViewBody{
    padding: 0 11%;
  }
  #inventoryHeaderTitle {
    h1{
      font-size: 32px;
      margin-top: 5px;
    }
  }
  #carViewItem .container-car-image {
    margin-left: 60px;
  }
}

@media (max-width: 480px){
  #banRow{
    left:14% !important;
  }
  #carSel{
    padding: 0 14%;
  }
  #btnLeft, #btnCenter ,#btnRight{
    font-size: 10px;
  }
  #btnInv {
    width: 43%;
  }
  .panel-finance .col-credit-score {
    width: 50%;
  }
  .panel-finance .col-type-credit {
    button{
      font-size: 13px;
    }
  }
  #financeViewBody{
    padding: 0 9%;
  }
  #carViewSel .left {
    left: -10px;
  }
  #carViewSel .right {
    right: -10px;
  }
  .line1-sold-list{
    width: 42%;
    left: 30%;
  }
  .line2-sold-list{
    width: 39%;
    left: 34%;
    top: 37px;
  }
  .text-sold-list {
    bottom: 41%;
  }
  #carViewItem .container-car-image {
    margin-left: 50px;
  }
}

@media (max-width: 460px){
  #banRow{
    left:13% !important;
  }
  #carSel{
    padding: 0 13%;
  }
  #btnInv {
    width: 45%;
  }
  #financeViewBody{
    padding: 0 7%;
  }
  .rectangleWidth{
    width: 24%;
  }
  #idViewMore{
    width: 52%;
  }
  #carViewItem .container-car-image {
    margin-left: 40px;
  }
}

@media (max-width: 440px){
  #banRow{
    left:11% !important;
  }
  #carSel{
    padding: 0 12%;
  }
  #btnInv {
    width: 47%;
  }
  #financeViewBody{
    padding: 0 5%;
  }
  #carViewItem .container-car-image {
    margin-left: 30px;
  }
}

@media (max-width: 420px){
  #banRow{
    left:10% !important;
  }
  #carSel{
    padding: 0 11%;
  }
  #btnInv {
    width: 49%;
  }
  .rectangleWidth{
    width: 22%;
  }
  #idViewMore{
    width: 56%;
  }
  #containerTitleCar{
    width: 100%;
  }
  .line1-sold-list{
    width: 48%;
    left: 27%;
  }
  .line2-sold-list{
    width: 44%;
    left: 32%;
    top: 37px;
  }
  .text-sold-list{
    bottom: 39%;
  }
  #carViewItem .container-car-image {
    margin-left: 20px;
  }
}

@media (max-width: 400px){
  #banRow{
    left:8% !important;
  }
  #carSel{
    padding: 0 10%;
  }
  #btnInv {
    width: 51%;
  }
  #carViewSel .left {
    left: -5px;
  }
  #carViewSel .right {
    right: -5px;
  }
  .line1-sold-list{
    width: 50%;
    left: 25.5%;
    top: 20px;
  }
  .line2-sold-list{
    width: 45%;
    left: 32.5%;
    top: 33px;
  }
  .text-sold-list{
    left: 43%;
    bottom: 42%;
    font-size: 24px;
  }
  .line1-sold{
    left: -7px;
    top: 50px;
  }
  .line2-sold{
    height: 32px;
    top: 40px;
    left: 25px;
  }
  .text-sold{
    left: 36%;
    bottom: 39%;
  }
  .container-car-image-list img {
    height: 140px !important;
  }
  #carViewItem .container-car-image {
    margin-left: 10px;
  }
}

.mod-customers {
  background-color: #337ab7;
  color: white;
}

.panel-body {
  padding: 15px 15px 15px 15px;
}

.hideMessage, .showMessage {
  display: block;
  position: fixed;
  text-align: center;
  left: 40%;
  top: 40%;
  z-index: 1000;
}

showMessage, .showMessageAttribute {
  z-index: 1000;
}

.hideMessage {
  z-index: -1;
}

.fadeOut {
  opacity: 0;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.col-sec-sub{
  width: 100%;
  float: left;
}

.layout-inventory-5 .col-inv-filter .col-keyboard {
  float: left;
  width: 30%;
  position: relative;
}

.layout-inventory-5 .col-inv-filter .col-keyboard .icon-search {
  height: 16px;
  fill: #e3e3e3;
  position: absolute;
  top: 10px;
  right: 13px;
}