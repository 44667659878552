.dp-text-input-container {
  display: flex;
  flex-direction: column;

  .dp-text-input-label {
    font-weight: 400;
  }

  .input-container {
    .dp-text-input {
      outline: none;
      padding: 5px;
      border: none
    }
  }

  .disabled {
    cursor: not-allowed;
  }
}

.dp-text-input-error {
  border-color: #ff0000 !important;
}

.dp-text-input-error-message {
  color: #ff0000 !important;
}
