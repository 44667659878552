//SideDrawer
.side-drawer {
  position: fixed;
  width: 300px;
  max-width: 80%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 200;
  background-color: #FFFFFF;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  .toggle-button{
    margin-top: 20px;
  }

  .side-drawer-body{
    margin-top: 70px;
    margin-left: 40px;
  }
}

.side-drawer-open{
  transform: translateX(0);
}

.side-drawer-close{
  transform: translateX(+150%);
}