@import "../../../../common/styles/utils";
.opening-hours-container{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;

  .left-container{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .container-hours{
    display: flex;
    .form-control{
      width: 100px;
      height: 40px;
      font-size: 14px;
    }
    span{
      @include item-center;
      padding: 0 10px;
    }
    .dp-select-dropdown{
      width: 126px;
    }
  }
  .dp-select-dropdown{
    width: 186px;
    .select{
      height: 40px;
      border-radius: 10px;
    }
  }

  .check-box-hours{
    width: 180px !important;
    .a{
      fill: #2A5AFA
    }
    .b{
      fill: #FFFFFF;
    }
    label{
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      width: auto !important;
    }
  }

  .delete-icon{
    svg{
      width: 15px;
      fill: #809FB8;
      &:hover{
        fill: #0B1524
      }
    }
  }

  .from-input{
    padding: 0;
  }
}